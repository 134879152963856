define("web-directory/router", ["exports", "web-directory/config/environment", "web-directory/iframe-client-config"], function (_exports, _environment, _iframeClientConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NOOP_TRANSITION = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var NOOP_TRANSITION = 'NOOP_TRANSITION';
  _exports.NOOP_TRANSITION = NOOP_TRANSITION;
  var Router = Ember.Router.extend({
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    logCapture: Ember.inject.service('log-capture'),
    sidebar: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    titleDivider: '-',
    transitionTo: function transitionTo() {
      var _this = this;

      var transition = this._super.apply(this, arguments);

      if (transition && transition.then && typeof transition.then === 'function') {
        transition.then(function () {
          if (transition.isActive && !transition.isAborted) {
            // Trigger an event if the route is already active
            // Useful to notify on noop transition attempts (e.g. sidebar collapse)
            _this.trigger(NOOP_TRANSITION);
          }
        });
      }

      return transition;
    }
  });
  Router.map(function () {
    var _this2 = this;

    this.route('maintenance');
    this.route('login');
    this.route('login-oauth');
    this.route('signup', {
      path: 'signup/:token'
    });
    this.route('reset-password', {
      path: 'reset-password/:token'
    });
    this.route('search');
    this.route('agent-ui');
    this.route('download');
    this.route('person', {
      resetNamespace: true
    }, function () {
      this.route('list');
      this.route('detail', {
        path: ':person_id'
      }, function () {
        this.route('edit');
      });
      this.route('new-detail', {
        path: 'new/:person_id'
      });
    });
    this.route('card', {
      resetNamespace: true
    }, function () {
      this.route('detail', {
        path: ':person_id'
      });
      this.route('tree', {
        path: 'tree/:person_id'
      });
    });
    this.route('group', {
      resetNamespace: true
    }, function () {
      this.route('detail', {
        path: ':group_id'
      }, function () {
        this.route('edit');
      });
    });
    this.route('skillgroup', {
      resetNamespace: true
    }, function () {
      this.route('detail', {
        path: ':skillgroup_id'
      }, function () {
        this.route('edit');
      });
    });
    this.route('admin', {
      resetNamespace: true,
      path: 'admin/*adminPath'
    });
    this.route('admin-emergency-group-list-redirect', {
      path: 'admin/routing/emergencies'
    });
    this.route('admin-emergency-group-detail-redirect', {
      path: 'admin/routing/emergencies/:emergencyGroupId'
    });
    this.route('admin-entry', {
      path: 'admin'
    });
    this.mount('ember-engine-custom-fields', {
      as: 'custom-fields'
    });
    this.route('custom-fields-contacts-redirect', {
      path: 'admin/custom-fields/contacts'
    });
    this.route('custom-fields-orgs-redirect', {
      path: 'admin/custom-fields/external-orgs'
    });
    this.route('partners.clients', {
      path: 'partners/clients',
      resetNamespace: true
    });
    this.route('interaction-detail-redirect', {
      path: 'engage/admin/interactions/:interactionId/'
    });
    this.route('interaction-detail-redirect', {
      path: 'engage/admin/interactions/:interactionId/*interactionDetailPath'
    });
    this.route('interaction-detail-redirect-agent', {
      path: 'engage/agent/interactions/:interactionId/'
    });
    this.route('interaction-detail-redirect-agent', {
      path: 'engage/agent/interactions/:interactionId/*interactionDetailPath'
    });
    this.route('engage.recording-management-redirect', {
      resetNamespace: true,
      path: 'quality/screenrecordings/inprogress'
    });
    this.route('engage.recording-management-redirect', {
      path: 'admin/quality/recordings/screensessions',
      resetNamespace: true
    });
    this.route('engage', function () {
      this.route('quality', function () {
        this.route('engage.inprogress-screenrecordings-redirect', {
          path: 'recordings/screensessions',
          resetNamespace: true
        });
        this.route('engage.interaction-screenrecordings-redirect', {
          path: 'interactions/:interaction_id/screen-recordings',
          resetNamespace: true
        });
      });
      this.route('queue-activity-summary-redirect', {
        path: 'queues/activity'
      });
      this.route('queue-activity-detail-redirect', {
        path: 'queues/activity/:queueId'
      });
      this.route('queue-performance-summary-redirect', {
        path: 'queues/performance'
      });
      this.route('queue-performance-detail-redirect', {
        path: 'queues/performance/:queueId'
      });
      this.route('interaction-search-redirect', {
        path: 'interactions'
      });
      this.route('interaction-search-redirect', {
        path: 'admin/interactions'
      });
      this.route('interaction-search-redirect', {
        path: 'admin/interactions/search'
      });
      this.route('did-assignment-redirect', {
        path: 'telephonyAdmin/did/assignments'
      });
      this.route('telephony-admin-extensions-redirect', {
        path: 'telephonyAdmin/extensions'
      });
      this.route('telephony-admin-redirect', {
        path: 'telephonyAdmin/*telephonyPath'
      });
      this.route('wfm-admin-redirect', {
        path: 'wfm/admin/*wfmPath'
      });
      this.route('quality-redirect', {
        path: 'admin/*qualityPath'
      });
      this.route('quality-redirect', {
        path: 'quality/admin/*qualityPath'
      });
      this.route('embedded', {
        path: '*engagePath'
      });
    });
    this.route('content-management', {
      path: 'content-management/*cmPath'
    });
    this.route('conversations');
    this.route('feedback');
    this.route('internal-logs');
    this.route('no-access');
    this.route('region-chooser');
    this.route('about', {
      resetNamespace: true
    }, function () {});
    this.route('timeline');
    this.route('relate.contacts-ui-redirect', {
      path: 'contact/:contact_id'
    }); // Todo : rewrite route instead of redirecting

    this.route('relate.organizations-ui-redirect', {
      path: 'organization/:organization_id'
    }); // Todo : rewrite route instead of redirecting

    this.route('chat-room', {
      path: 'chat-room/:roomJid'
    });
    this.route('interaction', {
      path: 'interaction/:interactionId'
    });
    this.route('interactions');
    this.route('time-machine', {
      path: 'timemachine/*buildTag'
    });
    this.route('third-party', {
      path: '/apps'
    }, function () {
      this.route('app-embed', {
        path: ':app_id'
      });
    });
    this.route('screen-share', {
      path: 'screen-share/:roomJid'
    });
    this.route('phone');
    /* IFRAME COORDINATOR ROUTES
     * Each frame needs a separate logical route to handle the base path
     * and internal paths separately for routing to work correctly.
     *
     * The lookup of the named paths is mapped to a single implementation in
     * app/resover.js
     */

    Object.entries(_iframeClientConfig.centerStageClients).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          clientId = _ref2[0],
          clientInfo = _ref2[1];

      var baseClientPath = clientInfo.assignedRoute;
      var clientPath = baseClientPath.replace(/\/$/, '') + '/*clientPath';

      _this2.route("center-stage-frame-".concat(clientId), {
        path: baseClientPath
      });

      _this2.route("center-stage-frame-".concat(clientId, ".internal"), {
        path: clientPath
      });
    });
    Object.entries(_iframeClientConfig.modalClients).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          clientId = _ref4[0],
          clientInfo = _ref4[1];

      var baseClientPath = clientInfo.assignedRoute;
      var clientPath = baseClientPath.replace(/\/$/, '') + '/*clientPath';

      _this2.route("modal-".concat(clientId), {
        path: baseClientPath
      });

      _this2.route("modal-".concat(clientId, ".internal"), {
        path: clientPath
      });
    }); // Not-Found redirects to the user profile.
  });
  Router.reopen({
    currentRouteDidTransition: Ember.on('didTransition', function () {
      var _this3 = this;

      var prevRouteName = this.get('_prevRouteName');
      this.set('_prevRouteName', this.currentRouteName);
      this.get('sidebar').instrumentSidebarPanel('routeDidChange', {
        prevRouteName: prevRouteName,
        newRouteName: this.currentRouteName
      });
      var currentRoute = window.location.hash.substring(2);
      Ember.run.scheduleOnce('afterRender', this, function () {
        $('meta[name=apple-itunes-app]').attr('content', 'app-id=777864400, app-argument=purecloud-collaborate://' + currentRoute);

        _this3.get('logCapture').restart();
      });
    })
  });
  var _default = Router;
  _exports.default = _default;
});