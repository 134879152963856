define("web-directory/routes/group/detail", ["exports", "lodash", "web-directory/mixins/model-route"], function (_exports, _lodash, _modelRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isGuid(id) {
    if (!id) {
      return false;
    }

    return !!id.match(/^[a-z0-9-]{36}$/i);
  }

  var GroupDetailRoute = Ember.Route.extend(_modelRoute.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    notification: Ember.inject.service(),
    routeTitle: 'Group Profile',
    canEditRoles: Ember.computed('session.namedPermissions', function () {
      var namedPermissions = this.get('session.namedPermissions') || [];

      var hasRolesView = _lodash.default.includes(namedPermissions, 'authorization:role:view');

      var hasGrantsEdit = _lodash.default.includes(namedPermissions, 'authorization:grant:add');

      return hasRolesView && hasGrantsEdit;
    }),
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controllerFor('group.detail');

        if (controller.get('isEditing')) {
          var msg = this.get('intl').lookup('notification.group.navigate').toString();

          if (!window.confirm(msg)) {
            transition.abort();
            return false;
          }
        }

        controller.set('isEditing', false);
        return true;
      }
    },
    model: function model(params, transition) {
      var _this = this;

      var id = params.group_id;
      var currentModel = this.store.peekRecord('group', id);

      if (currentModel && currentModel.get('isDeleted')) {
        transition.abort();
        var message = this.get('intl').lookup('notification.group.deletedGroup').toString();
        this.get('notification').info(null, message, {
          hideAfter: 8,
          forceBrowserNotification: true
        });
        return;
      }

      var isToggleEnabled = this.get('session.features')['ATLAS-467_integrate-connections-ui'];

      if (isToggleEnabled) {
        return this.replaceWith("/group-v2/".concat(id));
      }

      return this.store.findRecord('group', id, {
        include: 'policy'
      }).then(function (group) {
        if (!group.get('guid') || isGuid(group.get('id'))) {
          return group;
        }

        _this.transitionTo('group.detail', group.get('guid'));
      });
    },
    afterModel: function afterModel(group) {
      var _this2 = this;

      var state = group.get('state');

      if (state === 'deleted' || group.get('isDeleted')) {
        var message = this.get('intl').lookup('notification.group.deletedGroup').toString();
        this.get('notification').info(null, message, {
          hideAfter: 8,
          forceBrowserNotification: true
        });
        this.transitionTo('person.detail', this.get('session.person.id'));
        return;
      }

      return group.reload().then(function (group) {
        if (_this2.get('canEditRoles')) {
          return group.getGrants();
        } else {
          return Ember.RSVP.resolve();
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.set('routeTitle', model.get('name'));
      var sidebar = this.get('sidebarService');

      if (sidebar) {
        sidebar.set('routeTitle', model.get('name'));
      }
    }
  });
  var _default = GroupDetailRoute;
  _exports.default = _default;
});