define("web-directory/app-providers/profile-panel-provider", ["exports", "web-directory/utils/boolean-expressions", "web-directory/models/app"], function (_exports, _booleanExpressions, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var localhostOrigin = 'https://localhost:4444';
  var deprecatedLocalhostOrigin = 'https://localhost:4400';
  var ProfilePanelProvider = Ember.Object.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    application: Ember.inject.service('application'),
    id: 'builtInExternalContactsInterapptionsProvider',
    type: 'programmatic',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    isUsingProfilePanelRefresh: Ember.computed.reads('session.features.relate-ui-profilePanelRefresh'),
    externalContactId: '',
    useLocalProfilePanel: Ember.computed('application.runtime', function () {
      var publicDomainName = this.get('application.runtime.publicDomainName');
      var runningWebDirectoryLocally = this.get('application.runtime.local');
      return this.getVersion() === 'local' && (publicDomainName === 'inindca.com' || publicDomainName === 'inintca.com' || runningWebDirectoryLocally);
    }),
    targetOrigin: Ember.computed('isUsingProfilePanelRefresh', 'useLocalProfilePanel', function () {
      if (this.get('useLocalProfilePanel')) {
        return this.get('isUsingProfilePanelRefresh') ? localhostOrigin : deprecatedLocalhostOrigin;
      } // This is only included as the URL constructor needs a valid base.
      // When running Web-Directory locally, this base is ignored and the environment public name is used.


      return window.location.origin;
    }),
    getVersion: function getVersion() {
      var url = new URLSearchParams(window.location.search);
      return url.get('externalContactsACDBranch') || undefined;
    },
    url: Ember.computed('session.org.orgDefaultCountryCode', 'session.accessToken', 'targetOrigin', 'useLocalProfilePanel', function () {
      var branch = this.getVersion() === 'local' ? undefined : this.getVersion();
      var useLocalProfilePanel = this.get('useLocalProfilePanel');
      var url = new URL(useLocalProfilePanel ? '' : "/profile-panel-ui/".concat(branch ? "".concat(branch, "/") : ''), this.get('targetOrigin'));
      url.searchParams.set('defaultCountryCode', this.get('session.org.orgDefaultCountryCode') || '');
      url.searchParams.set('locale', _app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN);
      url.searchParams.set('gcTargetEnv', _app.GC_TARGET_ENV_REPLACEMENT_TOKEN);
      url.searchParams.set('gcHostOrigin', _app.GC_HOST_ORIGIN_REPLACEMENT_TOKEN);
      url.searchParams.set('gcConversationId', _app.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN);

      if (useLocalProfilePanel) {
        url.searchParams.set('isLocal', useLocalProfilePanel);
        url.searchParams.set('token', this.get('session.accessToken') || '');
      }

      return url;
    }),
    deprecatedUrl: Ember.computed('session.org.orgDefaultCountryCode', 'session.accessToken', 'targetOrigin', 'useLocalProfilePanel', function () {
      var branch = this.getVersion() === 'local' ? undefined : this.getVersion();
      var useLocalProfilePanel = this.get('useLocalProfilePanel');
      var url = new URL("/external-contacts-ACD/".concat(!useLocalProfilePanel && branch ? "".concat(branch, "/") : '', "#/agent/contact/").concat(this.get('externalContactId')), this.get('targetOrigin'));
      url.searchParams.set('defaultCountryCode', this.get('session.org.orgDefaultCountryCode') || '');
      url.searchParams.set('locale', _app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN);
      url.searchParams.set('pcEnvironment', _app.PC_ENV_URL_REPLACEMENT_TOKEN);
      url.searchParams.set('interactionId', _app.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN);
      url.searchParams.set('usePopupAuth', _app.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN);
      url.searchParams.set('pcTLD', this.get('application.envDetails.envTld'));

      if (useLocalProfilePanel) {
        url.searchParams.set('isLocal', useLocalProfilePanel);
        url.searchParams.set('token', this.get('session.accessToken') || '');
      }

      return url;
    }),
    getAppConfigs: function getAppConfigs(appContext) {
      if (appContext.type === 'workitem') {
        return Ember.RSVP.resolve([]);
      }

      this.set('externalContactId', appContext.payload.get('participantForCustomer.externalContactId') || '');
      var url = this.get('isUsingProfilePanelRefresh') ? this.get('url') : this.get('deprecatedUrl');
      return Ember.RSVP.resolve([_objectSpread({
        id: 'external-contact-acd-profile-panel',
        name: this.get('intl').t('purecloud.acdInteraction.relate.profile'),
        icon: {
          guxIcon: {
            name: 'user-circle',
            decorative: true
          }
        },
        monochromicIcon: {
          guxIcon: {
            name: 'user-circle',
            decorative: true
          }
        },
        sandbox: ['allow-scripts', 'allow-downloads', 'allow-same-origin', 'allow-popups', 'allow-modals', 'allow-forms'],
        lifecycle: {
          ephemeral: false,
          hooks: {
            stop: true,
            blur: true,
            focus: true,
            bootstrap: true
          }
        }
      }, _objectSpread({}, this.get('useLocalProfilePanel') ? {
        url: decodeURIComponent(url.toString())
      } : {
        path: "".concat(url.pathname).concat(url.hash),
        query: "?".concat(decodeURIComponent(url.searchParams.toString()))
      }))]);
    }
  });
  var boolExpr = (0, _booleanExpressions.perm)('externalContacts:contact:view');
  _exports.boolExpr = boolExpr;
  var _default = ProfilePanelProvider;
  _exports.default = _default;
});