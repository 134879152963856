define("web-directory/app-providers/canned-responses-gadget-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    // Attributes
    id: 'cannedResponsesProvider',
    type: 'programmatic',
    group: 'contextual',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var application = this.get('application');

      if (application.runtime.local) {
        return "https://apps.".concat(application.runtime.publicDomainName);
      }

      return window.location.origin;
    }),
    getAppConfigs: function getAppConfigs(appContext) {
      var appDefs = [];
      var urlParams = new URLSearchParams(window.location.search);
      var cannedResponseGadgetVersion = urlParams.get('cannedResponseGadgetVersion');
      var gadgetOrigin = this.get('gadgetTargetOrigin');
      var local = cannedResponseGadgetVersion === 'local';
      var url = cannedResponseGadgetVersion ? local ? "http://localhost:9092/responseManagement/#/agentPanel" : "".concat(gadgetOrigin, "/responseManagement/").concat(cannedResponseGadgetVersion, "/#/agentPanel") : "".concat(gadgetOrigin, "/responseManagement/#/agentPanel");

      if (appContext.type === 'conversation') {
        // Moved here because if payload.get is not called on a conversation it will blow up. ie: called on a workItem
        var myselfIsCoachOrMonitor = appContext.payload.get('myselfIsMonitor') || appContext.payload.get('myselfIsCoach') || appContext.payload.get('myselfIsMonitorV2');

        if (!myselfIsCoachOrMonitor) {
          appDefs.push(buildCannedResponsesGadgetDef(url, this.get('intl').t('purecloud.responses.responses')));
        }
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.and)((0, _booleanExpressions.ft)('PURE-5421_CannedResponse_Gadget'), (0, _booleanExpressions.perm)('responses:library:view'), (0, _booleanExpressions.perm)('responses:response:view'));
  _exports.boolExpr = boolExpr;

  var buildCannedResponsesGadgetDef = function buildCannedResponsesGadgetDef(url, displayName) {
    return {
      id: 'responses',
      displayName: displayName,
      type: 'iframe',
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['quote-left']
      },
      trustedDataPassing: true,
      startBehavior: 'lazy',
      lifecycleConfig: {
        bootstrap: true,
        // Added a longer bootstrap timeout to handle reduced network conditions
        bootstrapTimeoutMillis: 15000,
        bootstrapReadyTimeoutMillis: 15000,
        loadTimeoutMillis: 15000
      },
      allow: ['clipboard-write'],
      sandbox: ['allow-same-origin', 'allow-scripts', 'allow-modals', 'allow-popups', 'allow-forms', 'allow-downloads'],
      src: new URL(url)
    };
  };
});