define("web-directory/components/acd-interactions-panel/interaction-session-share/interaction-co-browse/component", ["exports", "ember-purecloud-components/mixins/logger", "web-directory/mixins/embedded-message-event-handler"], function (_exports, _logger, _embeddedMessageEventHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component,
      run = Ember.run,
      inject = Ember.inject;

  var _default = Component.extend(_logger.default, _embeddedMessageEventHandler.default, {
    application: inject.service(),
    interaction: inject.service(),
    session: inject.service(),
    fullscreen: inject.service(),
    isAgentDisconnectedFromSharedSession: true,
    isDisconnected: false,
    popoutAuth: false,
    isFullScreen: false,
    classNames: ['interaction-co-browse'],
    tagName: 'iframe',
    attributeBindings: ['frameUrl:src', 'role'],
    role: 'presentation',
    openRoom: function openRoom(jid) {
      this.bubbleAction({
        action: 'openChatRoom',
        actionContext: [jid]
      });
      this.bubbleAction({
        action: 'closeModal'
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_timers', []);
      this.set('popoutAuth', this.get('session.features')['cobrowse.popoutAuthWindowFlow']);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('selectedInteraction')) {
        //Send fullscreen message
        this.sendMessage('fullscreen', this.get('isFullScreen')); //Send end cobrowse message if agent is disconnected from main media

        if (!this.get('isAgentDisconnectedFromSharedSession') && this.get('isDisconnected')) {
          this.get('_timers').push(run.debounce(this, this.sendMessage, 'endSession', true, 300));
        }
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.get('selectedInteraction')) {
        this.startMessageListener();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('_timers').forEach(function (item) {
        return run.cancel(item);
      });
    },
    frameUrl: computed('selectedInteraction.{mostRecentCustomerSessionShare.viewerUrl,sourceCommunicationId}', 'application.isoLocale', function () {
      var context = this.get('selectedInteraction.mostRecentCustomerSessionShare.viewerUrl');
      return "".concat(context, "?sourceCommunicationId=").concat(encodeURIComponent(this.get('selectedInteraction.sourceCommunicationId')), "&locale=").concat(this.get('application.isoLocale'), "&popoutAuth=").concat(this.get('popoutAuth'));
    }),
    //These handlers are used by the EmbeddedMessageEventHandlerMixin, when the message type matches the key
    messageHandlers: {
      fullscreen: function fullscreen(data) {
        if (data) {
          this.bubbleAction({
            action: 'showCoBrowseFullScreen'
          });
        } else {
          this.get('fullscreen').endFullScreen();
        }
      },
      sessiondisconnected: function sessiondisconnected() {
        this.forceDisconnectSession();
      },
      sessionended: function sessionended() {
        this.forceDisconnectSession();
      }
    },
    forceDisconnectSession: function forceDisconnectSession() {
      this.bubbleAction({
        action: 'forceDisconnectSessionShare'
      });
    },
    sendMessage: function sendMessage(type, data) {
      var iframe = this.get('element');
      var message = {
        type: type,
        data: data
      };

      if (iframe) {
        this.logger.debug('Co-browse - parent iFrame: sending message type', type, data);

        try {
          iframe.contentWindow.postMessage(message, '*'); //TODO: Fix this cross origin issue here
        } catch (err) {
          var conversationId = this.get('selectedInteraction.id');
          this.logger.error('Co-browse - sendMessage error', {
            error: err,
            remoteData: {
              interactionId: conversationId
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});