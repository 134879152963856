define("web-directory/utils/conversation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSize = formatSize;
  _exports.isAppleURN = isAppleURN;
  _exports.isGuid = isGuid;
  _exports.userGuidFromParticipant = void 0;

  function isGuid(id) {
    if (!id) return false;
    return !!id.match(/^[a-z0-9-]{36}$/i);
  }

  function isAppleURN(id) {
    if (!id) return false;
    return !!id.match(/^urn:mbid:/);
  }
  /**
   * Get userId
   * @param user participant.
   * @returns {string or undefined} the userId
   */


  var userGuidFromParticipant = function userGuidFromParticipant(user) {
    if (!user) {
      return;
    }

    var userId = user.userId,
        userUri = user.userUri;

    if (userId) {
      return userId;
    }

    if (userUri) {
      return userUri.substring(userUri.lastIndexOf('/') + 1);
    }
  };
  /**
   * Convert size in bytes to KB, MB, GB or GB.
   * @param contentLength file size in bytes to be converted against.
   * @returns {string} the converted value with the correct unit.
   */


  _exports.userGuidFromParticipant = userGuidFromParticipant;

  function formatSize(contentLength) {
    var units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    var i = Math.floor(Math.log(contentLength) / Math.log(1000));
    if (!+contentLength) return '0bytes';
    return "".concat(parseFloat((contentLength / Math.pow(1000, i)).toFixed(1))).concat(units[i]);
  }
});