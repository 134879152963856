define("web-directory/utils/collab-chat-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USE_CHAT_SYNC_FIX_FT = _exports.TOAST_TYPE = _exports.REMOVE_GC_USER_FROM_CHAT_FT = _exports.COLLABORATE_CHAT_FRAME_ID = _exports.COLLABORATE_CHAT_EVENTS = _exports.COLLABORATE_CHAT = void 0;
  // ---- COLLABRATE CHAT FRAME CONSTANTS -----
  var COLLABORATE_CHAT = 'gcucc.ui.apps.collaborateChat';
  _exports.COLLABORATE_CHAT = COLLABORATE_CHAT;
  var REMOVE_GC_USER_FROM_CHAT_FT = 'gcucc.ui.removeGcUserFromGroup.PCUCC-2543';
  _exports.REMOVE_GC_USER_FROM_CHAT_FT = REMOVE_GC_USER_FROM_CHAT_FT;
  var USE_CHAT_SYNC_FIX_FT = 'gcucc-ui-PCUCC-3498';
  _exports.USE_CHAT_SYNC_FIX_FT = USE_CHAT_SYNC_FIX_FT;
  var TOAST_TYPE = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error'
  });
  _exports.TOAST_TYPE = TOAST_TYPE;
  var COLLABORATE_CHAT_FRAME_ID = {
    MAIN: 'collaborate-chat-frame',
    VOLT: 'collaborate-chat-frame-volt',
    PHONE: 'collaborate-chat-frame-phone-call'
  };
  _exports.COLLABORATE_CHAT_FRAME_ID = COLLABORATE_CHAT_FRAME_ID;
  var COLLABORATE_CHAT_EVENTS = {
    /**
     * Domain for publish event names
     */
    publish: {
      /**
       * Publish the sidebar expand state
       */
      sidebarExpanded: 'collaborate-chat-sidebar-expanded',

      /**
       * Publish the chat room change
       */
      roomChanged: 'collaborate-chat-host-changed-room',
      toCollabChatCommand: 'webdir-to-collaborate-chat-command',

      /**
       * Publish to initiate a conference video call
       */
      startVideoConference: 'collaborate-chat-host-started-video-conference',

      /**
       * Publish to indicate that a chat room has started showing video
       */
      toggleShowingVideo: 'collaborate-chat-host-toggled-showing-video',

      /**
       * Publish to indicate the current video participant count
       */
      videoParticipantCount: 'webdir-to-collaborate-chat-video-participant-count',

      /**
       * Publish to indicate that a chat room or a user to user chat has been favourited or unfavourited
       */
      favoriteChanged: 'collaborate-chat-host-changed-favorite',

      /**
       * Publish to indicate that the desktop app has woken after a period of sleep (user PC went for a sleep and is now back)
       */
      desktopWakeUp: 'desktop-wake-up',

      /**
       * Publish to indicate that the desktop app is going to asleep
       */
      desktopAsleep: 'desktop-asleep',

      /**
       * Publish to indicate that a chatRoom has been popped out to a new window
       */
      RoomPoppedOut: 'room-popped-out',

      /**
       * Publish to indicate the profile image has been updated
       */
      profileImage: 'webdir-to-collaborate-chat-profile-image-update',

      /**
       * Publish to indicate org setting around free seating for stations
       */
      freeSeatPreference: 'webdir-to-collaborate-chat-free-seat-pref',

      /**
       * Publish to update chat preference
       */
      updateChatPreference: 'web-dir-update-preference-setting',

      /**
       * Publish to update chat volumes
       */
      updateChatVolumes: 'web-dir-update-volume-setting',
      realtimeConnected: 'realtime-event-connected',
      realtimeDisconnected: 'realtime-event-disconnected'
    },

    /**
     * Domain for listener event names
     */
    listeners: {
      /**
       * Listen for the client initialized event.
       * Used to determine when app is ready.
       */
      clientInitialized: 'clientInitialized',

      /**
       * Listen for the hide event to hide the chat panel.
       */
      hide: 'collaborate-chat-hide',

      /**
       * Listen for the toggle sidebar event to toggle the chat side bar.
       */
      toggleSidebar: 'collaborate-chat-toggle-sidebar',

      /**
       * Listen for the popout event to pop out chat
       */
      popout: 'collaborate-chat-popout',

      /**
       * Listens for switching of the active chat room
       */
      changeRoom: 'collaborate-chat-client-changed-room',

      /**
       * Listens for chat room join events from the client
       */
      joinRoom: 'collaborate-chat-client-joined-room',

      /**
       * listen for events for custom commands that uses 'addCommand'
       */
      fromCollabChatCommand: 'collaborate-chat-to-webdir-commands',

      /**
       * Listens for requests to open the video pane
       */
      openVideoPane: 'collaborate-chat-client-opened-video-pane',

      /**
       * listen to this event trigger a toast message
       */
      toast: 'collaborate-chat-to-webdir-toast',

      /**
       * open preference modal
       */
      openPreferenceModal: 'collaborate-chat-to-webdir-open-preference-modal',

      /**
       * display error toast when received from collaborate chat
       */
      collabChatIntegrationError: 'collaborate-chat-to-webdir-integration-error',

      /**
       * Listens for event indicating a conference video call was started
       */
      collabChatStartedConference: 'collaborate-chat-started-video-conference',

      /**
       * listen to when collab-chat is fully loaded, signaling that the frame is ready for interaction (like selecting rooms and displaying messages)
       */
      collabChatPhoneFrameReady: 'collaborate-chat-phone-frame-ready',

      /**
       * clear the notification on the chat tab
       */
      clearNotificationSidebar: 'collaborate-chat-to-webdir-clear-notification-sidebar',

      /**
       * listen to event when app is unauthorized
       */
      unauthorized: 'collaborate-chat-to-webdir-unauthorized',

      /**
       * display informational toast about how at-mentioning users works when direct messaging
       */
      directMessageAtMention: 'at-mention-in-direct-message',

      /**
       * Listens for change in favourited value for room and user to user chat
       */
      changeFavorite: 'collaborate-chat-client-changed-favorite',

      /***
       * listens for the event emitted by the main chat window indicating that the video-chat should be closed
       */
      closeMainWindowVideo: 'close-main-window-video',

      /**
       * Listens to when a collaborate chat application has received a new message
       */
      collabChatMessageReceived: 'collaborate-chat-realtime-message-received',

      /**
       * Listens to when a collaborate chat application has updated its display preference
       */
      collabChatUpdatePreference: 'collaborate-chat-update-display-preference',

      /**
       * Listens to when a collaborate chat application has updated the display splat value
       */
      collabChatUpdateSplatValue: 'updateChatSplatValue',

      /**
       * Listens to when a room's mute status changes
       */
      collabChatToggleMute: 'collaborate-chat-toggled-chat-mute',
      realtimeConnected: 'realtime-event-connected',
      realtimeDisconnected: 'realtime-event-disconnected'
    }
  };
  _exports.COLLABORATE_CHAT_EVENTS = COLLABORATE_CHAT_EVENTS;
});