define("web-directory/models/interaction/callback-interaction", ["exports", "lodash", "web-directory/utils/dates", "web-directory/models/interaction/call-interaction", "web-directory/utils/acdParticipantStatuses"], function (_exports, _lodash, _dates, _callInteraction, _acdParticipantStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer;

  function _distinguishPhoneType(phoneColumns) {
    var _this = this;

    if (phoneColumns.length > 1) {
      _lodash.default.forEach(phoneColumns, function (phoneColumn) {
        phoneColumn.displayType = _this.get('intl').lookup('purecloud.acdInteraction.phoneNumberDisplayType', {
          phoneType: phoneColumn.type,
          columnName: phoneColumn.columnName
        });
      });
    } else if (phoneColumns.length === 1) {
      phoneColumns[0].displayType = phoneColumns[0].type;
    }
  }

  function _createCallbackNumber(contactData, phoneColumn) {
    return {
      name: this.get('intl').lookup('purecloud.acdInteraction.phoneNumber', {
        phoneNumber: contactData[phoneColumn.columnName],
        phoneType: phoneColumn.displayType
      }).toString(),
      number: contactData[phoneColumn.columnName],
      phoneColumn: phoneColumn.columnName
    };
  }

  var _default = _callInteraction.default.extend({
    type: 'callback',
    campaignId: null,
    contact: null,
    contactId: null,
    skipEnabled: true,
    callbackTimeoutTime: null,
    currentCallback: computed.reads('participantForCurrentUser.callbacks.firstObject'),
    dialerPreview: computed.reads('participantForCurrentUser.callbacks.firstObject.dialerPreview'),
    skipDisabled: computed.not('participantForCurrentUser.callbacks.firstObject.skipEnabled'),
    timeoutSeconds: computed.reads('participantForCurrentUser.callbacks.firstObject.timeoutSeconds'),
    isCampaignCallback: computed.bool('currentCallback.dialerPreview.campaignId'),
    hasVoicemail: computed.bool('participantForCurrentUser.callbacks.firstObject.voicemail'),
    isNotCampaignCallback: computed.not('isCampaignCallback'),
    isExternalCampaign: computed.bool('participantForCurrentUser.callbacks.firstObject.externalCampaign'),

    /**
     * An array of calls that the agent has already placed.
     */
    placedCalls: computed.reads('participantForCurrentUser.calls'),

    /**
     * An array of phone numbers associated with the callback. If a dialer preview
     * is present, the phone number type is include in the phone number name.
     * {
     *     name: 317-222-2222 (Work)
     *     number: 317-222-2222
     * }
     */
    callbackNumbers: computed('isCampaignCallback', 'agentAddedCallbackNumbers', 'currentCallback.callbackNumbers', 'dialerPreview.phoneNumberColumns', 'contact', 'placedCalls', 'placedBlockedCalls', function () {
      var _this2 = this;

      var contactData = this.get('contact.data');
      var currentCallback = this.get('currentCallback');
      var phoneNumbers = [];
      var placedBlockedCalls = this.get('placedBlockedCalls');

      if (contactData && this.get('dialerPreview.phoneNumberColumns.length') > 0) {
        phoneNumbers = _lodash.default.chain(this.get('dialerPreview.phoneNumberColumns')).filter(function (phoneColumn) {
          return _lodash.default.has(contactData, phoneColumn.columnName);
        }) // distinguish the phone columns by columnName if a type occurs more than once
        .groupBy('type').values().forEach(_distinguishPhoneType.bind(this)).flatten().map(_createCallbackNumber.bind(this, contactData)).value();
      } else if (currentCallback) {
        phoneNumbers = _lodash.default.map(currentCallback.callbackNumbers, function (number) {
          return {
            name: number,
            number: number
          };
        });
        var agentAddedCallbackNumbers = this.get('agentAddedCallbackNumbers') || []; //
        // Please note when multipanel is ON the agentAddedCallbackNumbers is of the format:
        //          {name: '555-555-5555', value: '555-555-5555'}
        // When the multipanel is turned OFF and the agentAddedCallbackNumbers is of the format:
        //          {name: '555-555-5555', number: '555-555-5555'} - this format may no longer be needed if numberInputMode is deprecated from dialpad
        //
        // We then will tolerate both formats for now.

        agentAddedCallbackNumbers = agentAddedCallbackNumbers.map(function (entry) {
          return {
            name: entry.name,
            number: entry.value || entry.number
          };
        }).filter(function (entry) {
          return !!entry.name && !!entry.number;
        });
        phoneNumbers = phoneNumbers.concat(agentAddedCallbackNumbers);
      }

      var parseAddress = function parseAddress(address) {
        return _this2.get('addressformat').formatAddress(address || '');
      };

      var dialedNumbers = [];

      if (this.get('isCampaignCallback')) {
        dialedNumbers = _lodash.default.flatten(_lodash.default.map(this.get('placedCalls'), function (call) {
          return [parseAddress(Ember.get(call, 'other.addressRaw')), parseAddress(Ember.get(call, 'other.addressNormalized'))];
        }));
      }

      return _lodash.default.filter(phoneNumbers, function (phoneNumber) {
        return phoneNumber.number && !_lodash.default.includes(dialedNumbers, _this2.get('addressformat').formatAddress(phoneNumber.number)) && !_lodash.default.includes(placedBlockedCalls, phoneNumber.number);
      });
    }),
    dialedNumbers: computed('placedCalls', 'placedCalls.[]', function () {
      var _this3 = this;

      return _lodash.default.map(this.get('placedCalls'), function (call) {
        var normalized = Ember.get(call, 'other.addressNormalized');
        var raw = Ember.get(call, 'other.addressRaw');
        var numberToFormat = normalized || raw;

        if (numberToFormat) {
          return _this3.get('addressformat').formatAddressForDisplay(numberToFormat);
        } else {
          return null;
        }
      });
    }),
    setCallbackTimeoutTime: observer('callbackNumbers.length', 'timeoutSeconds', 'participantForCurrentUser.connectedTime', 'participantForCurrentUser.wrapup.endTime', function () {
      var startTime = this.get('participantForCurrentUser.wrapup.endTime') || this.get('participantForCurrentUser.connectedTime');

      if (this.get('callbackNumbers.length') > 0 && this.get('timeoutSeconds') > 0 && startTime) {
        this.set('callbackTimeoutTime', (0, _dates.default)(startTime).add(this.get('timeoutSeconds'), 'seconds'));
      } else {
        this.set('callbackTimeoutTime', null);
      }
    }),
    isCurrentUserInConversation: computed('participants.@each.{purpose,userId}', 'session.person.guid', function () {
      var _this4 = this;

      return _lodash.default.some(this.get('participants'), function (participant) {
        var purpose = participant.purpose;
        var participantUserId = participant.userId;

        var userId = _this4.get('session.person.guid');

        return userId === participantUserId && (purpose === 'user' || purpose === 'agent');
      });
    }),
    isAfterCallWorkCompleted: computed('participantForCurrentUser.wrapup', 'participantForCurrentUser.wrapupRequired', 'skipAfterCallWork', 'needsCallWrapUp', function () {
      if (this.get('needsCallWrapUp')) {
        return false;
      } else if (!this.get('participantForCurrentUser.wrapupRequired')) {
        return true;
      } else if (this.get('participantForCurrentUser.wrapup') || this.get('skipAfterCallWork')) {
        return true;
      }

      return false;
    }),
    hasActiveCall: computed('participantForCurrentUser.calls.firstObject.state', function () {
      var state = this.get('participantForCurrentUser.calls.firstObject.state');

      if (_lodash.default.isEmpty(state)) {
        return false;
      }

      return state.toLowerCase() === _acdParticipantStatuses.default.CONNECTED.toLowerCase() || state.toLowerCase() === _acdParticipantStatuses.default.DIALING.toLowerCase() || state.toLowerCase() === _acdParticipantStatuses.default.CONTACTING.toLowerCase();
    }),
    hasActiveCallback: computed('participantForCurrentUser.callbacks.firstObject.state', function () {
      var state = this.get('participantForCurrentUser.callbacks.firstObject.state');

      if (_lodash.default.isEmpty(state)) {
        return false;
      }

      return state.toLowerCase() === _acdParticipantStatuses.default.CONNECTED.toLowerCase() || state.toLowerCase() === _acdParticipantStatuses.default.ALERTING.toLowerCase();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setCallbackTimeoutTime();
      this.set('placedBlockedCalls', []);
      this.set('agentAddedCallbackNumbers', []);
    }
  });

  _exports.default = _default;
});