define("web-directory/components/command-banner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['command-banner'],
    application: Ember.inject.service('application'),
    willRender: function willRender() {
      this._super.apply(this, arguments);
    },
    actions: {
      primaryButtonAction: function primaryButtonAction(action) {
        this.sendAction('primaryButtonAction', action);
      },
      secondaryButtonAction: function secondaryButtonAction(action) {
        this.sendAction('secondaryButtonAction', action);
      }
    }
  });

  _exports.default = _default;
});