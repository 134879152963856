define("web-directory/mixins/cache-chat-rooms", ["exports", "lodash", "web-directory/models/chat-room", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _lodash, _chatRoom, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
      getOwner = Ember.getOwner;

  var _default = Ember.Mixin.create({
    presenceSubscription: Ember.inject.service(),
    batchDataRequests: Ember.inject.service(),
    // this is set in chat service
    useCollaborateChat: false,
    // this is set in chat service
    useChatBetaCleanUp: false,
    initRoomCaching: Ember.on('init', function () {
      return this.set('rooms', Ember.Object.create());
    }),
    getChatRoom: function getChatRoom(jid) {
      var _this = this;

      return new RSVP.Promise(function (resolve) {
        var objectId = (0, _jidHelpers.getEntityObjectId)(jid);

        var room = _this.get("rooms.".concat(objectId));

        if (room) {
          if (_this.get('useChatBetaCleanUp')) {
            var isGroupRoom = !(0, _jidHelpers.isPersonJid)(room.get('jid'));
            var passedInJidIsPerson = (0, _jidHelpers.isPersonJid)(jid) && !(0, _jidHelpers.isSupervisorJid)(jid);

            if (passedInJidIsPerson && isGroupRoom) {
              _this.logger.debug("<cache-chat-rooms-mixin> Found incorrect chat room with jid\n                            ".concat(room.get('jid'), ". Closing the room. Passed in jid is ").concat(jid)); // bad jid from early chat beta
              // need to leave the room so it will be deleted on realtime side


              _this.closeActiveRoom(room); // create the correct 1-1 chat room and store in `rooms` dictionary


              var personRoom = _chatRoom.default.create({
                jid: jid,
                objectId: objectId
              }, getOwner(_this).ownerInjection());

              Ember.set(_this.rooms, objectId, personRoom, true); // shouldn't need to run this feature toggle check but to be safe

              if (_this.get('useCollaborateChat')) {
                return resolve(_this.setupRoomContextV2(personRoom));
              }

              return resolve(_this.setupRoomContext(personRoom));
            }
          }

          return resolve(room);
        }

        room = _chatRoom.default.create({
          jid: jid,
          objectId: objectId
        }, getOwner(_this).ownerInjection());
        Ember.set(_this.rooms, objectId, room, true);

        if (_this.get('useCollaborateChat')) {
          return resolve(_this.setupRoomContextV2(room));
        }

        return resolve(_this.setupRoomContext(room));
      });
    },
    setupRoomContext: function setupRoomContext(room) {
      var jid = room.get('jid');
      room.set('muted', this.roomMuted(jid));

      if (room.get('isSoftphoneRoom')) {
        return Ember.RSVP.resolve(room);
      }

      if ((0, _jidHelpers.isPersonJid)(jid)) {
        return this.addChatTargetAsParticipant(room);
      }

      if ((0, _jidHelpers.isGroupJid)(jid)) {
        return this.loadGroup(room);
      }

      return Ember.RSVP.resolve(room);
    },
    setupRoomContextV2: function setupRoomContextV2(room) {
      var jid = room.get('jid');
      room.set('muted', this.roomMuted(jid));

      if (room.get('isSoftphoneRoom')) {
        return Ember.RSVP.resolve(room);
      }

      if ((0, _jidHelpers.isPersonJid)(jid)) {
        room.set('oneToOneTarget', jid);
      }

      if ((0, _jidHelpers.isGroupJid)(jid)) {
        return this.loadGroup(room);
      }

      return Ember.RSVP.resolve(room);
    },
    addChatTargetAsParticipant: function addChatTargetAsParticipant(room) {
      var _this2 = this;

      var jid = room.get('jid');
      var user = this.get('session.person');
      return this.get('batchDataRequests').getChatUser(jid).then(function (person) {
        room.set('oneToOneTarget', person);

        _this2.addParticipantToRoom(room, person);

        _this2.addParticipantToRoom(room, user);

        _this2.get('presenceSubscription').subscribeToPresence(person);

        person.set('oneToOneRoom', room);
        return room;
      });
    },
    loadRoomSubject: function loadRoomSubject(room) {
      var _this3 = this;

      if (room.isOneToOneRoom() || room.get('rawSubject')) {
        return Ember.RSVP.resolve(room);
      }

      var roomJid = room.get('jid');
      return this.getStreamingRoomInfo(roomJid).then(function (_ref) {
        var subject = _ref.subject;

        _this3.updateRoomSubject(room, subject);

        return room;
      });
    },
    addRoomToCache: function addRoomToCache(room) {
      var objectId = (0, _jidHelpers.getEntityObjectId)(room.jid);
      Ember.set(this.rooms, objectId, room, true);
    },
    addAllParticipantsToRoom: function addAllParticipantsToRoom(room, participantJids) {
      var _this4 = this;

      var cachedParticipants = room.get('participants');

      _lodash.default.forEach(cachedParticipants, function (cachedParticipant) {
        var cachedParticipantJid = cachedParticipant && cachedParticipant.get('jid');

        if (participantJids.indexOf(cachedParticipantJid) === -1) {
          _this4.removeParticipantFromRoom(room, cachedParticipant);
        } else {
          participantJids.removeObject(cachedParticipantJid);
        }
      });

      _lodash.default.forEach(participantJids, function (jid) {
        _this4.get('batchDataRequests').getChatUser(jid).then(function (person) {
          return _this4.addParticipantToRoom(room, person);
        });
      });
    },
    loadGroup: function loadGroup(room) {
      var _this5 = this;

      if (!room.isGroupRoom()) {
        return Ember.RSVP.resolve(room);
      }

      return this.get('batchDataRequests').getGroup(room.get('objectId')).then(function (group) {
        room.set('group', group);

        if (group && _lodash.default.isEmpty(group.get('name'))) {
          _this5.loadRoomSubject(room);
        }

        return room;
      });
    }
  });

  _exports.default = _default;
});