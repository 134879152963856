define("web-directory/app-providers/task-management-interapption-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = _exports.TASK_MGMT_APP_CONFIGS = void 0;
  var TASK_MGMT_APP_CONFIGS = [{
    iconName: 'configuration',
    name: 'purecloud.acdInteraction.workitem.workitemStatus',
    path: 'status',
    type: 'status',
    requiredPermissions: ['workitems:workitem:view', 'workitems:workitem:edit', 'workitems:status:view']
  }, {
    iconName: 'clipboard-success',
    name: 'purecloud.acdInteraction.workitem.workitemWrapup',
    path: 'wrapup',
    type: 'wrapup',
    requiredPermissions: ['workitems:workitem:view', 'workitems:wrapup:view', 'workitems:wrapupSelf:edit']
  }];
  _exports.TASK_MGMT_APP_CONFIGS = TASK_MGMT_APP_CONFIGS;

  var _default = Ember.Object.extend({
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    permissions: Ember.inject.service('permissions'),

    /**
     * @attribute
     * Provider id
     */
    id: 'builtInTaskmanagementInterapptionsProvider',

    /**
     * @attribute
     * Provider type
     */
    type: 'programmatic',

    /**
     * @attribute
     */
    trustedProvider: true,

    /**
     * @attribute
     */
    monitorInteractionUpdates: true,

    /**
     * Provider configuration
     * @param appContext { type: 'conversation' | 'workitem', payload: interaction | workitem }
     */
    getAppConfigs: function getAppConfigs(appContext) {
      var _this = this;

      var type = appContext.type,
          interaction = appContext.payload;
      var apps = [];

      if (type === 'workitem' && interaction.workitem.assignmentState !== 'Alerting') {
        TASK_MGMT_APP_CONFIGS.forEach(function (config) {
          if (_this._validatePermissions(config.requiredPermissions)) {
            apps.push(_this._getTaskManagementPanelConfig(interaction, config));
          }
        });
      }

      return Ember.RSVP.resolve(apps);
    },

    /**
     * Task Management Panel configuration
     * @param interaction - workitem interaction
     * @param config - task management app config
     */
    _getTaskManagementPanelConfig: function _getTaskManagementPanelConfig(interaction, config) {
      var id = interaction.id,
          modifiedBy = interaction.workitem.modifiedBy,
          changes = interaction.workitem.changes;

      var _Ember$get = Ember.get(this.get('session'), 'user'),
          userId = _Ember$get.id;

      var hasStatusChanged = (changes === null || changes === void 0 ? void 0 : changes.some(function (change) {
        return change.field === 'statusId';
      })) || false;
      var taskRoutingPath = "task-management-agent-ui/#/workitems/".concat(id, "/").concat(config.path);
      var localUrl = "https://localhost:4800/#/workitems/".concat(id, "/").concat(config.path);

      if (config.type === 'status' && userId !== modifiedBy && hasStatusChanged) {
        var statusId = interaction.workitem.statusId;
        var statusQueryParam = "?statusId=".concat(statusId);
        localUrl += statusQueryParam;
        taskRoutingPath += statusQueryParam;
      }

      var icon = {
        guxIcon: {
          name: config.iconName,
          decorative: true
        }
      };
      var out = {
        icon: icon,
        id: "taskmanagement-".concat(config.type, "-panel"),
        monochromicIcon: icon,
        name: this.get('intl').t(config.name),
        sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups', 'allow-forms']
      };

      var branchName = this._getTaskManagementTargetBranch();

      if (branchName === 'local') {
        out.url = localUrl;
      } else if (branchName) {
        out.path = taskRoutingPath.replace('/#/', "/".concat(branchName, "/#/"));
      } else {
        out.path = taskRoutingPath;
      }

      return out;
    },

    /**
     * Returns source name from query params if present otherwise null
     */
    _getTaskManagementTargetBranch: function _getTaskManagementTargetBranch() {
      // window.location.hash to support old time machine links that were initially formatted incorrectly
      var queryParams = new URLSearchParams(window.location.search ? window.location.search : window.location.hash.split('?')[1]);
      return queryParams.get('workitemsInterapptionsVersion');
    },

    /**
     * Validates that the user has a set of permissions
     */
    _validatePermissions: function _validatePermissions(permissions) {
      var permissionsService = this.get('permissions');
      return permissions.every(function (permission) {
        return permissionsService.validateDonutPermission(permission);
      });
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.perm)('workitems:workitem:view');
  _exports.boolExpr = boolExpr;
});