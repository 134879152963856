define("web-directory/app-providers/consult-gadget-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    // Attributes
    id: 'consultGadgetProvider',
    type: 'programmatic',
    group: 'contextual',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    // Derived
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var runtimeEnv = this.get('application.runtime');

      if (runtimeEnv.local) {
        return "https://apps.".concat(runtimeEnv.publicDomainName);
      }

      return window.location.origin;
    }),
    // Methods
    getAppConfigs: function getAppConfigs(interaction) {
      var appDefs = [];
      var urlParams = new URLSearchParams(window.location.search);
      var consultGadgetVersion = urlParams.get('consultGadgetVersion');
      var gadgetOrigin = this.get('gadgetTargetOrigin');
      var local = consultGadgetVersion === 'local';
      var url = consultGadgetVersion ? local ? 'https://localhost:3001/consult-gadget.html' : "".concat(gadgetOrigin, "/consult-gadget/").concat(consultGadgetVersion, "/consult-gadget.html") : "".concat(gadgetOrigin, "/consult-gadget/consult-gadget.html");

      if (interaction && interaction.type === 'conversation' && Ember.typeOf(interaction.payload) === 'instance' && interaction.payload.get('type') === 'message') {
        var _currentParticipant$i, _peerParticipant$inte;

        var currentParticipant = interaction.payload.get('participantForCurrentUser');
        var currentParticipantInternalMessage = (_currentParticipant$i = currentParticipant.internalMessages) === null || _currentParticipant$i === void 0 ? void 0 : _currentParticipant$i[0]; // Current participant has no internal message communications

        if (!currentParticipantInternalMessage) {
          return Ember.RSVP.resolve(appDefs);
        }

        var peerId = currentParticipantInternalMessage.peerId;
        var participants = interaction.payload.get('participants');
        var peerParticipant = peerId && participants.find(function (participant) {
          var _participant$internal;

          return ((_participant$internal = participant.internalMessages) === null || _participant$internal === void 0 ? void 0 : _participant$internal.length) > 0 && participant.internalMessages[0].id === peerId;
        });
        var peerParticipantInternalMessage = peerParticipant && ((_peerParticipant$inte = peerParticipant.internalMessages) === null || _peerParticipant$inte === void 0 ? void 0 : _peerParticipant$inte[0]); // Peer participant can't be found or has no internal message communications

        if (!peerParticipantInternalMessage) {
          return Ember.RSVP.resolve(appDefs);
        } // Show gadgets on both sides after the consultant (which can be both participants) has accepted


        if (currentParticipantInternalMessage.state !== 'alerting' && peerParticipantInternalMessage.state !== 'alerting') {
          appDefs.push(buildConsultGadgetDef(url, this.get('intl').t('purecloud.digitalConsultation')));
        }
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.and)((0, _booleanExpressions.ft)('PURE-5007_digitalConsult'), (0, _booleanExpressions.perm)('conversation:internalMessaging:view'));
  _exports.boolExpr = boolExpr;

  var buildConsultGadgetDef = function buildConsultGadgetDef(url, displayName) {
    return {
      id: 'consultConversationGadget',
      displayName: displayName,
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['legacy/agent-group']
      },
      type: 'iframe',
      startBehavior: 'lazy',
      src: new URL(url),
      sandbox: ['allow-scripts', 'allow-downloads', 'allow-same-origin', 'allow-popups'],
      trustedDataPassing: true,
      lifecycleConfig: {
        bootstrap: true,
        bootstrapTimeoutMillis: 15000,
        bootstrapReadyTimeoutMillis: 15000,
        loadTimeoutMillis: 15000,
        focus: true,
        blur: true,
        stop: true
      }
    };
  };
});