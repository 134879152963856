define("web-directory/components/acd-interactions-panel/interaction-group/component", ["exports", "lodash/findLast", "web-directory/utils/acd-ts"], function (_exports, _findLast2, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-group'],
    classNameBindings: ['isSelected', 'isAlerting'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._updateDivisionName();
    },

    /**
     * @Attribute
     * Array of external contacts from external contact service
     */
    externalContacts: null,

    /**
     * @Attribute
     * The adhoc group object create by the roster component
     */
    group: null,

    /**
     * @Attribute
     * The currently selected interaction
     */
    selectedInteraction: null,

    /**
     * @Attribute
     * The currently selected workitem
     */
    selectedWorkitem: null,

    /**
     * @Attribute
     * Flag to enable outbound contact, passed through this component
     */
    enableOutboundContact: null,

    /**
     * @Attribute
     * useEmailDraftEvent FT
     */
    useEmailDraftEvent: false,

    /**
     * @Attribute
     * FT for external contact division labels
     */
    acdExternalContactDivNameFT: false,

    /**
     * @Attribute
     * Object containing draft updates
     */
    draftUpdateHash: null,

    /**
     * @attribute
     */
    externalContactDivisionName: undefined,

    /**
     * @action
     */
    loadDivisionName: function loadDivisionName(divisionId) {
      return (0, _acdTs.warnClosureAction)('loadDivisionName', divisionId);
    },

    /**
     * Group conversation interactions sorted by startTimestamp.
     * Group workitem interactions by dueDate or dateCreated if dueDate is not present.
     */
    interactions: Ember.computed.sort('group.interactions', function (a, b) {
      function getStartTime(conversation) {
        var startTime = Ember.get(conversation, 'startTimestamp') || Ember.get(conversation, 'workitem.dateDue');
        return new Date(startTime).valueOf();
      }

      var startA = getStartTime(a);
      var startB = getStartTime(b);
      return startA - startB;
    }),

    /**
     * Ids of queues for interactions in the group
     */
    interactionQueueIds: Ember.computed('interactions.[]', 'interactions.@each.participantForQueue.queueId', 'interactions.@each.participantForCurrentUser.{queueId,monitoredParticipantId,coachedParticipantId,bargedParticipantId}', 'interactions.@each.workitem.queueId', 'interactions.@each.workitem', function () {
      var interactions = this.get('interactions');
      return (Array.isArray(interactions) ? interactions : []).map(function (interaction) {
        var queueId = Ember.get(interaction, 'participantForCurrentUser.queueId') || Ember.get(interaction, 'participantForQueue.queueId') || Ember.get(interaction, 'workitem.queueId');

        if (!queueId && !Ember.get(interaction, 'workitem')) {
          var currentUser = Ember.get(interaction, 'participantForCurrentUser');
          var mcbedParticipantId = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.monitoredParticipantId) || (currentUser === null || currentUser === void 0 ? void 0 : currentUser.coachedParticipantId) || (currentUser === null || currentUser === void 0 ? void 0 : currentUser.bargedParticipantId);

          if (!!mcbedParticipantId) {
            var _findLast;

            //queue-name is needed even when the interaction is disconnected
            queueId = (_findLast = (0, _findLast2.default)(Ember.get(interaction, 'participants') || [], function (_ref) {
              var id = _ref.id,
                  queueId = _ref.queueId;
              return id === mcbedParticipantId && !!queueId;
            })) === null || _findLast === void 0 ? void 0 : _findLast.queueId;
          }
        }

        return queueId;
      });
    }),

    /**
     * Flag to determine if the group is in an alerting state
     */
    isAlerting: Ember.computed('interactions.firstObject.isAlerting', 'interactions.firstObject.workitem.assignmentState', function () {
      return this.get('interactions.firstObject.isAlerting') || this.get('interactions.firstObject.workitem.assignmentState') === 'Alerting';
    }),

    /**
     * Maintain a reference to the external contact object for the group
     */
    externalContact: Ember.computed('externalContacts.[]', 'group.groupId', function () {
      var contactFromGroupId = this.get('group.groupId');
      return contactFromGroupId ? this.get('externalContacts').findBy('id', contactFromGroupId) : null;
    }),
    _updateDivisionName: function _updateDivisionName() {
      var _this = this;

      if (this.get('acdExternalContactDivNameFT')) {
        var interaction = this.get('interactions')[0];

        var setDivisionName = function setDivisionName(name) {
          if (_this && !(_this.get('isDestroyed') || _this.get('isDestroying'))) _this.set('externalContactDivisionName', name);
        };

        if (Ember.get(interaction || {}, 'workitem')) {
          setDivisionName(undefined);
          return;
        }

        var externalContact = this.get('externalContact');

        if (externalContact) {
          var _externalContact$divi;

          setDivisionName(externalContact === null || externalContact === void 0 ? void 0 : (_externalContact$divi = externalContact.division) === null || _externalContact$divi === void 0 ? void 0 : _externalContact$divi.name);
          return;
        }

        var divisionId = Ember.get(interaction || {}, 'participantForCustomer.externalContactInitialDivisionId');
        this.loadDivisionName(divisionId).then(function (divisionName) {
          setDivisionName(divisionName);
        });
      }
    },

    /**
     * Determine if group contains the currently selected interaction
     */
    isSelected: Ember.computed('interactions', 'selectedInteraction', function () {
      var interactions = this.get('interactions');
      var selectedInteractionId = this.get('selectedInteraction.id');
      var selectedWorkitemId = this.get('selectedWorkitem.id');
      return !!interactions.find(function (i) {
        return Ember.get(i, 'id') === selectedInteractionId || Ember.get(i, 'id') === selectedWorkitemId;
      });
    })
  });

  _exports.default = _default;
});