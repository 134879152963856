define("web-directory/app-providers/messaging-gadget-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    // Attributes
    id: 'messagingGadgetProvider',
    type: 'programmatic',
    group: 'conversation',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    // Derived
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var runtimeEnv = this.get('application.runtime');

      if (runtimeEnv.local) {
        return "https://apps.".concat(runtimeEnv.publicDomainName);
      }

      return window.location.origin;
    }),
    // Methods
    getAppConfigs: function getAppConfigs(interaction) {
      var appDefs = [];
      var urlParams = new URLSearchParams(window.location.search);
      var messagingGadgetVersion = urlParams.get('messagingGadgetVersion');
      var gadgetOrigin = this.get('gadgetTargetOrigin');
      var local = messagingGadgetVersion === 'local';
      var url = messagingGadgetVersion ? local ? 'https://localhost:3000/messaging-gadget.html' : "".concat(gadgetOrigin, "/messaging-gadget/").concat(messagingGadgetVersion, "/messaging-gadget.html") : "".concat(gadgetOrigin, "/messaging-gadget/messaging-gadget.html");

      if (interaction && interaction.type === 'conversation' && Ember.typeOf(interaction.payload) === 'instance') {
        if (interaction.payload.get('type') === 'message') {
          appDefs.push(buildMessagingGadgetDef(url, this.get('intl').t('purecloud.message')));
        }
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.ft)('messagingGadget');
  _exports.boolExpr = boolExpr;

  var buildMessagingGadgetDef = function buildMessagingGadgetDef(url, displayName) {
    return {
      id: 'messagingConversationGadget',
      displayName: displayName,
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['legacy/roster-sms']
      },
      type: 'iframe',
      startBehavior: 'lazy',
      src: new URL(url),
      sandbox: ['allow-scripts', 'allow-downloads', 'allow-same-origin', 'allow-popups'],
      trustedDataPassing: true,
      lifecycleConfig: {
        bootstrap: true,
        bootstrapTimeoutMillis: 15000,
        bootstrapReadyTimeoutMillis: 15000,
        loadTimeoutMillis: 15000,
        focus: true,
        blur: true,
        stop: true
      }
    };
  };
});