define("web-directory/services/intl", ["exports", "ember-intl/services/intl", "lodash", "should-send-same-site-none", "web-directory/config/environment", "web-directory/utils/dates", "web-directory/utils/escape", "web-directory/utils/intl/missing-message", "web-directory/utils/net", "web-directory/utils/testing"], function (_exports, _intl, _lodash, _shouldSendSameSiteNone, _environment, _dates, _escape, _missingMessage, _net, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var get = Ember.get,
      inject = Ember.inject,
      computed = Ember.computed,
      RSVP = Ember.RSVP;

  var addServerPrefix = function addServerPrefix(key) {
    return "server_translations.".concat(key);
  };

  var safeString = function safeString(s) {
    return Ember.String.htmlSafe(s);
  };

  var rtlLangs = ['he', 'ar']; // Object [locale String]: languageName String
  // values come directly from each locale's `languageName` key

  var languages = _environment.default.APP.INTL_LANGUAGES;
  var localeCookie = 'pcAuth-userPrefLangTag';
  var localeCookieOptions = {
    path: '/',
    expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000) // expiration is 90 days into the future

  };

  var _default = _intl.default.extend({
    ajax: inject.service(),
    cookies: inject.service(),
    application: inject.service(),
    loaded: false,
    loadedPromise: null,
    languages: languages,
    baseLocale: 'en-us',
    supportedLocales: Object.keys(languages),
    systemLocale: computed('baseLocale', function () {
      var _window$navigator = window.navigator,
          languages = _window$navigator.languages,
          language = _window$navigator.language,
          userLanguage = _window$navigator.userLanguage;
      var lang = Array.isArray(languages) && languages[0];
      var navLocale = lang || language || userLanguage;

      if (navLocale) {
        return navLocale;
      }

      return this.get('baseLocale');
    }),
    isRTL: computed('isoLocale', function () {
      var isoLocale = this.get('isoLocale');
      return rtlLangs.includes(isoLocale);
    }),
    isoLocale: computed('locale.firstObject', function () {
      return this.get('locale.firstObject');
    }),
    iso2LocaleCountry: computed('isoLocale', function () {
      var isoLocale = this.get('isoLocale');

      if (isoLocale) {
        var countryData = window.intlTelInputGlobals.getCountryData();
        var region = isoLocale.split('-')[1] || isoLocale;

        if (region) {
          return _lodash.default.find(countryData, function (country) {
            return country && country.iso2 === region;
          });
        }
      }

      return null;
    }),
    isoRegionalLocale: computed('isoLocale', function () {
      var _this$get$split = this.get('isoLocale').split('-'),
          _this$get$split2 = _slicedToArray(_this$get$split, 2),
          locale = _this$get$split2[0],
          region = _this$get$split2[1];

      if (region) {
        return "".concat(locale, "-").concat(region.toUpperCase());
      }

      return locale;
    }),
    t: function t(key, opts, noEscape) {
      if (!key) {
        Ember.Logger.warn('There was no key provided to translate.');
        return '';
      }

      if (noEscape === true) {
        return this._super(key, opts);
      }

      if (!opts) {
        return this._super(key);
      }

      return this._super(key, (0, _escape.default)(opts));
    },
    _initialLocale: function _initialLocale() {
      var authLocale = this.get('cookies').read(localeCookie);
      var systemLocale = this.get('systemLocale');
      return authLocale || systemLocale;
    },
    _normalizeLocale: function _normalizeLocale(locale) {
      var localeRootFallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      locale = locale.toLowerCase().replace('_', '-');

      if (locale.startsWith('en')) {
        locale = 'en-us';
      }

      if (locale.startsWith('nn') || locale.startsWith('nb')) {
        // Norwegian: nn-NO; nb-NO in Windows
        locale = 'no';
      }

      var supportedLocales = this.get('supportedLocales');

      if (!supportedLocales.includes(locale)) {
        var rootLocale = locale.slice(0, 2);

        if (supportedLocales.includes(rootLocale)) {
          return rootLocale;
        } else if (localeRootFallback) {
          return supportedLocales.find(function (lang) {
            return lang.slice(0, 2) === rootLocale;
          });
        } else {
          return null;
        }
      }

      return locale;
    },
    getWithServerKey: function getWithServerKey(key) {
      return safeString(this.t(addServerPrefix(key)));
    },
    lookup: function lookup(key, replacements, suppressError) {
      if (this.exists(addServerPrefix(key))) {
        return this.getWithServerKey(key);
      }

      if (!this.exists(key)) {
        if (!_testing.isTesting && this.get('loaded')) {
          Ember.Logger.warn('Undefined intl key', {
            key: key,
            locale: this.get('locale')
          });
        }

        return (0, _missingMessage.default)(key, [this.get('locale')], suppressError);
      } //Achieves parity with old i18n functionality: all HTML was allowed in all translation strings


      return safeString(this.t(key, replacements || {}));
    },
    query: function query(key) {
      return this.exists(key) || this.exists(addServerPrefix(key));
    },
    setLocale: function setLocale(locales) {
      var _this = this;

      if (!Array.isArray(locales)) {
        locales = [locales];
      }

      var validLocales = locales.map(function (l) {
        return _this._normalizeLocale(l);
      }).filter(function (l) {
        return l;
      });
      var baseLocale = this.get('baseLocale');

      if (!validLocales.includes(baseLocale)) {
        validLocales.push(baseLocale);
      }

      if (validLocales.length) {
        // Set the moment locale
        var lang = _dates.default.locale(validLocales[0]); // Set HTML locale (moment.locale returns just the language portion)


        Ember.$('html').attr('lang', lang);
      }

      return this._super(validLocales);
    },
    setPreferredLanguage: function setPreferredLanguage(locale) {
      var safeLocale = this._normalizeLocale(locale);

      if (!safeLocale) {
        var error = new Error("\"".concat(locale, "\" is not a supported locale."));
        return RSVP.reject(error);
      }

      try {
        var dynamicCookieOptions = {
          domain: (0, _net.getEnvCookieDomain)()
        };

        if (window && window.location && window.location.protocol === 'https:') {
          dynamicCookieOptions.secure = true;
        }

        if (navigator && navigator.userAgent && (0, _shouldSendSameSiteNone.isSameSiteNoneCompatible)(navigator.userAgent)) {
          dynamicCookieOptions.sameSite = 'none';
        }

        var options = Ember.assign(dynamicCookieOptions, localeCookieOptions);
        this.get('cookies').write(localeCookie, safeLocale, options);
        return RSVP.resolve();
      } catch (e) {
        var msg = 'Failed to persist new locale setting.';

        if (e && e.message) {
          msg += "  Details: ".concat(e.message);
        }

        return RSVP.reject(new Error(msg));
      }
    },
    isLocale12Hour: function isLocale12Hour() {
      return _dates.default.localeData(this.get('locale')).longDateFormat('LT').indexOf('A') > -1;
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_cache', {});
      this.setLocale(this._initialLocale());
      var defer = RSVP.defer();
      this.setProperties({
        _loadedResolve: defer.resolve,
        loadedPromise: defer.promise
      });
    },
    loadLanguage: function loadLanguage(locale) {
      var _this2 = this;

      return this.get('ajax').request("".concat(this.get('application.urlprefix'), "translations/").concat(locale, ".json"), {
        contentType: 'text/plain'
      }).then(function (text) {
        if (!text) {
          return RSVP.resolve();
        }

        try {
          // gotta be careful here...
          var lang = text;

          if (typeof text === 'string') {
            lang = JSON.parse(text);
          }

          if (lang) {
            get(_this2, '_cache')[locale] = lang;
            return _this2.addTranslations(locale, lang);
          }
        } catch (e) {
          return RSVP.resolve();
        }
      });
    },
    loadTranslations: function loadTranslations() {
      var _this3 = this;

      var baseLocale = this.get('baseLocale');
      var isoLocale = this.get('isoLocale');
      var locales = [baseLocale];

      if (isoLocale !== baseLocale) {
        locales.push(isoLocale);
      }

      var translations = locales.map(function (locale) {
        var cache = get(_this3, '_cache');
        var cached = get(cache, locale);

        if (cached) {
          return Ember.RSVP.resolve(cached);
        }

        return _this3.loadLanguage(locale);
      });
      return Ember.RSVP.all(translations).then(function () {
        _this3.set('loaded', true);

        _this3.get('_loadedResolve')();
      });
    },
    setupServerTranslations: function setupServerTranslations() {
      var _this4 = this;

      // go ahead and kick off local file loading
      this.loadTranslations();
      var locale = this.get('isoLocale');
      var url = this.get('application').pcV2Uri('api/v2/languages/translations/organization');
      var defaultRequest = this.get('ajax').request(url, {
        data: {
          language: 'en-us'
        }
      });
      var localeRequest = Ember.RSVP.resolve({});

      if (locale !== 'en-us') {
        localeRequest = this.get('ajax').request(url, {
          data: {
            language: locale
          }
        });
      }

      return Ember.RSVP.allSettled([defaultRequest, localeRequest]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            defaultTranslationsResult = _ref2[0],
            localeTranslationsResult = _ref2[1];

        var defaultTranslations = defaultTranslationsResult.value;
        var localeTranslations = localeTranslationsResult.value;

        if (!defaultTranslations) {
          Ember.Logger.warn("Couldn't load API translations", {
            remoteData: {
              reason: defaultTranslationsResult.reason
            }
          });
          return;
        }

        if (_lodash.default.isEmpty(localeTranslations)) {
          return _this4.addTranslations(locale, {
            server_translations: defaultTranslations
          });
        }

        _lodash.default.forIn(defaultTranslations, function (value, key) {
          if (!Object.prototype.hasOwnProperty.call(localeTranslations, key)) {
            localeTranslations[key] = value;
          }
        });

        return _this4.addTranslations(locale, {
          server_translations: localeTranslations
        });
      }).catch(function (err) {
        Ember.Logger.warn("Couldn't load API translations:", {
          err: err
        });
      });
    }
  });

  _exports.default = _default;
});