define("web-directory/components/entity-profile/component", ["exports", "lodash/isEmpty", "web-directory/mixins/entity-controller", "web-directory/components/entity-profile/profiles/profile", "web-directory/utils/focus-management", "web-directory/utils/masonry", "web-directory/utils/presence-utils", "web-directory/components/entity-profile/permissions", "web-directory/services/media-providers", "web-directory/utils/presence-label-util"], function (_exports, _isEmpty, _entityController, _profile, _focusManagement, _masonry, _presenceUtils, _permissions, _mediaProviders, _presenceLabelUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      inject = Ember.inject,
      getOwner = Ember.getOwner,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend(_entityController.default, _permissions.default, {
    classNames: ['entity-profile', 'person-detail', 'profile', 'entity'],
    classNameBindings: ['isEditingSection:editing-section', 'onOwnProfile:own-profile', 'compactLayout:compact-layout'],
    intl: inject.service(),
    session: inject.service(),
    permissions: inject.service(),
    mediaProviders: inject.service(),
    sidebar: inject.service(),
    favorites: Ember.inject.service(),
    resizeDetector: inject.service(),
    application: inject.service(),
    emergencyLocation: Ember.inject.service('emergency-location'),
    emergencyLocationOn: Ember.computed.reads('session.features.emergencyLocation'),
    editEmergencyLocation: false,
    isPresenceLabelToggleEnabled: Ember.computed.readOnly('session.features.epcDecompPresenceLabel'),
    isEntityLocationToggleEnabled: Ember.computed.readOnly('session.features.epcDecompEntityLocation'),
    eLocation: Ember.computed.reads('emergencyLocation.eLocation'),
    geolocation: Ember.inject.service(),
    isGeolocationEnabled: Ember.computed.readOnly('geolocation.isEnabled'),
    canViewChat: computed.bool('permissions.canViewBusinessChat'),
    canViewVideo: computed.bool('permissions.canViewCollaborateVideo'),
    attrs: {
      entity: null,
      setEditingSection: function setEditingSection() {}
    },
    entity: null,
    lastEntity: null,
    editing: false,
    showCardView: false,
    compactLayout: false,
    excludedContactFields: ['chat'],
    excludedEditingFields: ['chat'],
    conversationSummary: Ember.computed.readOnly('entity.activities.conversationSummary'),
    message: Ember.computed.readOnly('conversationSummary.message'),
    socialExpression: Ember.computed.readOnly('conversationSummary.socialExpression'),
    totalMessages: Ember.computed('message.{contactCenter,enterprise}', 'message.contactCenter.active', 'message.enterprise.active', function () {
      var contactCenter = this.get('message.contactCenter');
      var enterprise = this.get('message.enterprise');
      return (0, _presenceLabelUtil.activityCounter)(contactCenter, enterprise);
    }),
    totalSocialExpression: Ember.computed('socialExpression.{contactCenter,enterprise}', 'socialExpression.contactCenter.active', 'socialExpression.enterprise.active', function () {
      var contactCenter = this.get('socialExpression.contactCenter');
      var enterprise = this.get('socialExpression.enterprise');
      return (0, _presenceLabelUtil.activityCounter)(contactCenter, enterprise);
    }),
    presenceLabelData: Ember.computed('entity.{activityPresenceClass,presenceLabel,chatStatus,showConversationSummaryIndicator}', 'entity.activityValues.{calls,chats,emails}', function () {
      return {
        wrap: true,
        showStatus: false,
        showActivity: true,
        eActivityPresenceClass: this.entity.get('activityPresenceClass'),
        totalCalls: this.entity.get('activityValues.calls'),
        totalChats: this.entity.get('activityValues.chats'),
        totalEmails: this.entity.get('activityValues.emails'),
        totalMessages: this.get('totalMessages'),
        totalSocialExpression: this.get('totalSocialExpression'),
        presenceLabel: this.entity.get('presenceLabel'),
        eChatStatus: this.entity.get('chatStatus'),
        isShowingActivitySummary: this.entity.get('showConversationSummaryIndicator')
      };
    }),
    location: Ember.computed('entity.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.entity, 'geolocation.city'),
        region: Ember.get(this.entity, 'geolocation.region'),
        country: Ember.get(this.entity, 'geolocation.country')
      };
    }),
    selfLocation: Ember.computed('session.person.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.session.person, 'geolocation.city'),
        region: Ember.get(this.session.person, 'geolocation.region'),
        country: Ember.get(this.session.person, 'geolocation.country')
      };
    }),
    entityLocationData: Ember.computed('location', 'selfLocation', 'isGeolocationEnabled', function () {
      return {
        isGeolocationEnabled: this.get('isGeolocationEnabled'),
        eLocation: this.get('location'),
        selfLocation: this.get('selfLocation'),
        iconHoverText: this.get('intl').t('components.navMenu.userDropdown.setViaGeolocation')
      };
    }),
    scrollToBottom: function scrollToBottom() {
      var parentNode = this.get('element.parentNode');
      parentNode.scrollTop = parentNode.scrollHeight; //Scroll to very bottom
    },
    actions: {
      toggleEditProfile: function toggleEditProfile() {
        var editing = this.get('editingProfile');

        if (editing) {
          if (!this.get('entity.isSaving')) {
            this.get('entity').reload();
          }
        }

        this.toggleProperty('editingProfile');

        if (this.get('emergencyLocationOn') && !this.get('editingProfile')) {
          this.get('emergencyLocation').cancelEditElocation();
        }

        this.updateMasonry();
      },
      editProfilePicture: function editProfilePicture() {
        if (this.get('editingProfile') && this.get('canEditProfilePicture')) {
          this.set('editingPictures', true);
        }
      },
      editContactInfo: function editContactInfo() {
        this.get('contactInfoSection').send('showModal');
      },
      setContactInfo: function setContactInfo(section) {
        this.set('contactInfoSection', section);
      },
      addSection: function addSection(sectionInfo) {
        sectionInfo.set('adding', true);
        run.scheduleOnce('afterRender', this, function () {
          var key = sectionInfo.get('section.key');
          var $section = this.$(".".concat(key));
          var top = $section.position().top;
          Ember.$('#orgspan').animate({
            scrollTop: top
          }, 600);
          this.updateMasonry();
          var focusTarget = this.$(".".concat(key, ":first"));
          run.debounce(this, function () {
            (0, _focusManagement.doFocus)(Ember, focusTarget);
          }, 250);
        });
      },
      removeSection: function removeSection(sectionInfo) {
        this.get('fieldSections').removeObject(sectionInfo);
        this.updateMasonry();
      },
      toggleCardView: function toggleCardView() {
        this.toggleProperty('showCardView');
      },
      componentUpdated: function componentUpdated() {
        this.updateMasonry();
      },
      noop: function noop() {},
      resize: function resize(data) {
        Ember.run.once(this, this.handleResize, data);
      },
      saveElocation: function saveElocation(updatedElocationAddress) {
        return this.get('emergencyLocation').saveElocation(updatedElocationAddress);
      },
      editElocation: function editElocation() {
        this.set('editEmergencyLocation', true);
      },
      cancelEditElocation: function cancelEditElocation() {
        this.set('editEmergencyLocation', false);
      },
      refreshAllEmergencylocationInfo: function refreshAllEmergencylocationInfo(notify) {
        if (this.get('session.features.emergencyLocation')) {
          return this.get('emergencyLocation').refreshAllEmergencylocationInfo(notify);
        }
      },
      getEmergencyLocation: function getEmergencyLocation(phoneNumber) {
        if (this.get('session.features.emergencyLocation')) {
          return this.get('emergencyLocation').getEmergencyLocation(phoneNumber);
        }
      }
    },
    allSections: computed.reads('profile.profileSections'),
    canEditStatus: computed.and('onOwnProfile', 'isPurecloudSource'),
    isPurecloudSource: computed('session.features.acdHybridToastNoActiveQueue', 'session.user.presence.source', function () {
      if (this.get('session.features.acdHybridToastNoActiveQueue')) {
        return (0, _presenceUtils.isPurecloudSource)(this.get('session.user.presence.source'));
      }

      return true;
    }),
    editingProfile: computed('editing', 'canEditProfile', {
      get: function get() {
        if (!this.get('canEditProfile')) {
          return false;
        }

        return this.get('editing');
      },
      set: function set(key, value) {
        if (!this.get('canEditProfile')) {
          return false;
        }

        return value;
      }
    }),
    name: computed('entity.general.name.[]', function () {
      return this.get('entity').getFieldValue('general.name');
    }),
    title: computed('entity.general.title.[]', function () {
      return this.get('entity').getFieldValue('general.title');
    }),
    department: computed('entity.general.department.[]', function () {
      return this.get('entity').getFieldValue('general.department');
    }),
    profile: computed('entity.id', function () {
      var entity = this.get('entity');
      return _profile.default.getProfileForModel(entity, getOwner(this));
    }),
    emptySections: computed('allSections.[]', 'allSections.@each.{adding,isEmpty}', function () {
      var sections = this.get('allSections');
      return sections.filter(function (sectionInfo) {
        return sectionInfo.get('isEmpty') && !sectionInfo.get('adding');
      });
    }),
    fieldSections: computed('allSections.[]', 'allSections.@each.{adding,isEmpty}', function () {
      var sections = this.get('allSections');
      return sections.filter(function (sectionInfo) {
        return !sectionInfo.get('isEmpty') || sectionInfo.get('adding');
      });
    }),
    emptyWriteableSections: computed('emptySections.[]', 'profile.writeableSections.[]', function () {
      var sections = this.get('emptySections');
      var sectionKeys = this.get('profile.writeableSections');
      return sections.filter(function (sectionInfo) {
        return sectionKeys.includes(sectionInfo.get('section.key'));
      });
    }),
    isEditingSection: computed('fieldSections.@each.editingSection', 'editEmergencyLocation', function () {
      if (this.get('editEmergencyLocation')) {
        return true;
      }

      return !(0, _isEmpty.default)(this.get('fieldSections').findBy('editingSection', true));
    }),
    showStatus: computed('editingProfile', 'onOwnProfile', 'entity.hasChatStatus', 'chatService.enabled', function () {
      if (!this.get('chatService.enabled')) {
        return false;
      }

      return this.get('editingProfile') && this.get('onOwnProfile') || this.get('entity.hasChatStatus');
    }),
    videoProviders: computed('user', 'user.profile', function () {
      return this.get('mediaProviders').getVideoProviders(this.get('user'));
    }),
    haveVideoMediaProviders: computed('videoProviders.@each.haveAccess', function () {
      return (0, _mediaProviders.hasSupplementalVideoProvider)(this.get('videoProviders'));
    }),
    entityData: Ember.computed('entity.{id,name,isFavorite,type}', function () {
      return {
        id: this.get('entity.id'),
        name: this.get('entity.name'),
        isFavorite: this.get('entity.isFavorite'),
        type: this.get('entity.type')
      };
    }),
    enableFavoriteEvent: Ember.on('didInsertElement', function () {
      var _this = this;

      this.$().on('favoriteEvent', function () {
        _this.get('favorites').toggleFavorite(_this.get('entity'));
      });
      this.notifyPropertyChange('entityData');
    }),
    profileChanged: observer('entity.id', function () {
      Ember.run.once(this, 'handleProfileChange');
    }),
    editingSectionChange: observer('isEditingSection', function () {
      Ember.run.once(this, 'handleEditingSectionChange');
    }),
    handleProfileChange: function handleProfileChange() {
      var lastId = this.get('lastEntity.id');

      if (this.get('entity.id') !== lastId) {
        this.set('lastEntity', this.get('entity'));
      }
    },
    handleEditingSectionChange: function handleEditingSectionChange() {
      var _this2 = this;

      if (this.get('editEmergencyLocation')) {
        Ember.run.later(function () {
          _this2.scrollToBottom();
        }, 800);
      }

      if (this.get('setEditingSection')) {
        this.get('setEditingSection')(this.get('isEditingSection'));
      }
    },
    // masonry configuration
    masonryEl: null,
    _useMasonry: function _useMasonry() {
      var debug = this.get('application.debug');
      var enableMasonry = this.get('application.debugSettings.masonry');
      return !debug || enableMasonry;
    },
    _masonryId: function _masonryId() {
      return "entity-profile-".concat(this.get('elementId'));
    },
    _isInDOM: function _isInDOM() {
      return this.get('_state') === 'inDOM';
    },
    fieldSectionsUpdate: Ember.observer('fieldSections.[]', function () {
      var _this3 = this;

      if ((0, _isEmpty.default)(this.get('fieldSections'))) {
        return;
      }

      Ember.run.scheduleOnce('afterRender', function () {
        _this3.updateMasonry();
      });
    }),
    attachMasonry: function attachMasonry() {
      var _this4 = this;

      if (this._useMasonry()) {
        var masonryEl = this.$('.masonry-container');
        masonryEl.masonry({
          itemSelector: '.field-section',
          columnWidth: '.field-section',
          fitWidth: true
        });
        masonryEl.on('click submit', function () {
          return Ember.run(function () {
            return _this4.updateMasonry();
          });
        });
        this.set('masonryEl', masonryEl);
        (0, _masonry.registerLayout)(this._masonryId(), this);
      }
    },
    _updateMasonry: function _updateMasonry() {
      var _this5 = this;

      if (this._useMasonry()) {
        Ember.run.scheduleOnce('afterRender', function () {
          var masonryEl = _this5.get('masonryEl');

          if (masonryEl && _this5._isInDOM()) {
            masonryEl.masonry('reloadItems').masonry();
          }
        });
      }
    },
    rerun: function rerun() {
      var _this6 = this;

      // ^ this method is required for the utils/masonry#rerunLayouts()
      Ember.run(function () {
        return _this6.updateMasonry();
      });
    },
    updateMasonry: function updateMasonry() {
      Ember.run.throttle(this, this._updateMasonry, 500, false);
    },
    detachMasonry: function detachMasonry() {
      if (this._useMasonry()) {
        var masonryEl = this.get('masonryEl');
        this.set('masonry', null);

        if (masonryEl) {
          masonryEl.masonry('destroy');
          masonryEl.off('click submit');
        }

        (0, _masonry.deregisterLayout)(this._masonryId());
      }
    },
    startMasonry: Ember.on('didInsertElement', function () {
      this.attachMasonry();
    }),
    stopMasonry: Ember.on('willDestroyElement', function () {
      this.detachMasonry();
    }),
    handleResize: function handleResize(data) {
      if (!data.width) {
        return;
      }

      if (data.width < 500) {
        this.set('compactLayout', true);
      } else {
        this.set('compactLayout', false);
      }
    }
  });

  _exports.default = _default;
});