define("web-directory/utils/presence-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDefaultPresence = isDefaultPresence;
  _exports.isPurecloudSource = isPurecloudSource;

  /**
   * Checks if the presence source is a default presence source.
   * @param {string} source the presence source to check
   * @param {boolean} useDefaultName should we use 'default' as a presence source
   * @returns
   */
  function isDefaultPresence(source) {
    // we will want to switch completely to 'default'
    // as the only default presence source but will be blocked until
    // the Presence Service is only returning 'default'.
    return isPurecloudSource(source) || source === 'default';
  }
  /**
   * Checks if the presence source is PURECLOUD.
   * @param {string} source the presence source to check
   * @returns
   */


  function isPurecloudSource(source) {
    return source && source.toLowerCase() === 'purecloud';
  }
});