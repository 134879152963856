define("web-directory/components/acd-interactions-panel/interaction-conference-roster-dropdown/component", ["exports", "web-directory/mixins/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['interaction-conference-roster-dropdown'],
    classNameBindings: ['removeWrapperStyling:no-wrapper', 'removePadding'],

    /**
     * @attribute
     *
     * removes the styling applied to the component that makes it appear as it's own dropdown
     * component such as the border, shadow, and arrows.
     */
    removeWrapperStyling: false,

    /**
     * @attribute
     * `true` if this component needs to remove it's padding throughout the context. This  may
     * need to be `true` if the container that is rendering it does not have built in padding.
     *
     * @note
     * This component was initially built without gux context, when implementing it with a
     * `gux-popover` component, we needed to remove the initial padding the component was once
     * responsible for. However, there are renditions of the component that still require it to
     * manage it's own padding.
     */
    removePadding: false,

    /**
     * All of the current participants apart of the conference
     */
    participants: [],

    /**
     * The currently authenticated user.
     */
    authUser: null,

    /**
     * A map containing the connected states of all the participants in the dropdown.
     */
    connectedStates: new Map(),

    /**
     * `true` if another participant can be added to the conference.
     */
    disableInviteParticipant: Ember.computed('participants', function () {
      var participants = this.get('participants') || [];
      return participants.some(function (p) {
        var call = Ember.get(p, 'calls.firstObject');
        return !call.connectedTime && !call.endTime;
      });
    }),

    /**
     * Invoked when the attributes of this component have changed for any reason
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var connectedStates = this.get('connectedStates');
      var participants = this.get('participants');
      participants.forEach(function (p) {
        if (connectedStates.has(p.id)) {
          var connectedTime = connectedStates.get(p.id);

          if (connectedTime == null && p.connectedTime) {
            _this.onConnected(p.id);
          }
        }

        connectedStates.set(p.id, p.connectedTime);
      });
      this.set('connectedStates', connectedStates);
    },

    /**
     * @Action
     * Closure action invoked when a participant has connected into the conference.
     * Should be passed down by the parent.
     * @param value The participant id that was connected
     */
    onConnected: function onConnected(value) {
      Ember.Logger.warn('onConnected closure action has not been set up for this component. Value emitted: ', value);
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    invite: function invite() {
      Ember.Logger.warn('Invite closure action has not been set up for this component');
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    mute: function mute(participant, value) {
      Ember.Logger.warn('Mute closure action has not been set up for this component', participant, value);
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    muteAll: function muteAll() {
      Ember.Logger.warn('Mute all closure action has not been set up for this component');
    },

    /**
     * @Action
     * Closure action passed to the component to be invoked at the parent level
     */
    dismiss: function dismiss(participant) {
      Ember.Logger.warn('Dismiss closure action has not been set up for this component', participant);
    },
    actions: {
      /**
       * Invoked when the invite participant button is selected
       */
      onInviteSelected: function onInviteSelected() {
        this.invite();
      },

      /**
       * Mutes the provided participant in the conference.
       * @param participant The participant to mute in the conference
       */
      onMute: function onMute(participant, value) {
        this.mute(participant, value);
      },

      /**
       * Dismiss the provided participant from the conference, dropping them from the call
       * @param participant The participant to dismiss
       */
      onDismiss: function onDismiss(participant) {
        this.dismiss(participant);
      },

      /**
       * Invoked when the user mutes everyone in the conference
       */
      onMuteAll: function onMuteAll() {
        this.muteAll();
      }
    }
  });

  _exports.default = _default;
});