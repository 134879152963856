define("web-directory/components/user-dropdown/component", ["exports", "lodash", "ember-webrtc-components/mixins/webrtc-service/auto-save-devices", "web-directory/utils/presence-utils", "web-directory/utils/presence-label-util"], function (_exports, _lodash, _autoSaveDevices, _presenceUtils, _presenceLabelUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserDropdownComponent = Ember.Component.extend(_autoSaveDevices.default, {
    classNames: ['user-dropdown', 'dropdown', 'primary-presences-v2'],
    classNameBindings: ['chat.enabled::chat-disabled', 'session.user.isTrustee:in-authorized-org', 'disablePresenceSelector:disable-presence-selector', 'session.features.emergencyLocation:emergency-location-on'],
    chat: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    geolocation: Ember.inject.service(),
    phone: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    webrtcSdkService: Ember.inject.service('webrtc-sdk'),
    settings: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    intl: Ember.inject.service(),
    presenceSubscription: Ember.inject.service(),
    personId: Ember.computed.reads('session.person.guid'),
    runLoopTimers: [],
    isEntityLocationToggleEnabled: Ember.computed.readOnly('session.features.epcDecompEntityLocation'),
    showStationPicker: false,
    enableStationPicker: true,
    isPresenceLabelToggleEnabled: Ember.computed.readOnly('session.features.epcDecompPresenceLabel'),
    sessionPerson: Ember.computed.reads('session.person'),
    myGuid: Ember.computed.reads('session.person.guid'),
    userPresenceLabel: Ember.computed.reads('session.person.presenceLabel'),
    showDropdown: false,
    configuringCallForwarding: false,
    audioCallCapable: Ember.computed.reads('webrtc.audioCallCapable'),
    // TODO: move this back to call forwarding mixin or phone service
    // see duplicate in call-controls.js
    canCallForward: Ember.computed.reads('phone.canCallForward'),
    callForwardingActive: Ember.computed.reads('phone.callForwardingActive'),
    offQueue: Ember.computed.not('phone.onQueue'),
    showEnableCallForwarding: Ember.computed('callForwardingActive', 'offQueue', function () {
      return !this.get('callForwardingActive') && this.get('offQueue');
    }),
    showOutOfOfficeMenu: false,
    hasStation: Ember.computed.not('phone.noStationSelected'),
    currentPerson: Ember.computed.reads('session.person'),
    canSelectDevices: Ember.computed.and('webrtc.audioCallCapable', 'currentStation.isWebrtc'),
    stationStatus: Ember.computed('hasStation', function () {
      return this.get('hasStation') ? this.get('intl').t('purecloud.station.current') : this.get('intl').t('purecloud.station.noPhone');
    }),
    attachTooltips: Ember.observer('showDropdown', function () {
      if (this.get('showDropdown') && this.$('.toggle-webrtc-settings').length > 0) {
        this.$('.toggle-webrtc-settings').tooltip();
      }
    }),
    currentStation: Ember.computed('session.person.guid', 'phone.station.id', function () {
      var stationId = this.get('phone.station.id');

      if (stationId) {
        return this.get('phone.station');
      }
    }),
    generalPersonData: Ember.computed('session.person.general.{name,title,department}', function () {
      var person = this.get('session.person');
      return {
        name: _lodash.default.head(person.get('general.name')),
        department: _lodash.default.head(person.get('general.department')),
        title: _lodash.default.head(person.get('general.title'))
      };
    }),
    orgName: Ember.computed('session.org.name', 'session.user.isTrustee', 'session.publicApiUser.organization.name', function () {
      if (this.get('session.user.isTrustee')) {
        return this.get('session.publicApiUser.organization.name');
      }

      return this.get('session.org.name');
    }),
    isPurecloudSource: Ember.computed('session.features.acdHybridToastNoActiveQueue', 'session.user.presence.source', function () {
      if (this.get('session.features.acdHybridToastNoActiveQueue')) {
        return (0, _presenceUtils.isPurecloudSource)(this.get('session.user.presence.source'));
      }

      return true;
    }),
    disablePresenceSelector: Ember.computed('currentPerson.primaryPresence.source', function () {
      var source = this.get('currentPerson.primaryPresence.source');

      if (!source) {
        return false;
      }

      source = source.toLowerCase();
      return !(0, _presenceUtils.isDefaultPresence)(source);
    }),
    sendActionsForVisibilityToggle: Ember.observer('showDropdown', function () {
      if (this.get('showDropdown')) {
        this.sendAction('show');
      } else {
        this.sendAction('hide');
      }
    }),
    canActivateQueues: Ember.computed('permissions.canActivateQueues', 'permissions.canGoOnQueue', 'session.hasTrusteeAccess', function () {
      return this.get('permissions.canActivateQueues') && this.get('permissions.canGoOnQueue') && !this.get('session.hasTrusteeAccess');
    }),
    attachLinkListener: Ember.observer('showDropdown', function () {
      var _this = this;

      var showing = this.get('showDropdown');

      if (showing) {
        this.$('a').on('click', function (event) {
          Ember.run(function () {
            var target = Ember.$(event.target);

            if (target.is('.to-profile')) {
              _this.send('closeMenu');
            }
          });
        });
      } else {
        this.$('a').off();
      }
    }),
    conversationSummary: Ember.computed.readOnly('session.person.activities.conversationSummary'),
    message: Ember.computed.readOnly('conversationSummary.message'),
    socialExpression: Ember.computed.readOnly('conversationSummary.socialExpression'),
    totalMessages: Ember.computed('message.{contactCenter,enterprise}', 'message.contactCenter.active', 'message.enterprise.active', function () {
      var contactCenter = this.get('message.contactCenter');
      var enterprise = this.get('message.enterprise');
      return (0, _presenceLabelUtil.activityCounter)(contactCenter, enterprise);
    }),
    totalSocialExpression: Ember.computed('socialExpression.{contactCenter,enterprise}', 'socialExpression.contactCenter.active', 'socialExpression.enterprise.active', function () {
      var contactCenter = this.get('socialExpression.contactCenter');
      var enterprise = this.get('socialExpression.enterprise');
      return (0, _presenceLabelUtil.activityCounter)(contactCenter, enterprise);
    }),
    presenceLabelData: Ember.computed('session.person.{activityPresenceClass,presenceLabel,showConversationSummaryIndicator}', 'session.person.activityValues.{calls,chats,emails}', function () {
      return {
        wrap: false,
        showStatus: false,
        showActivity: true,
        totalCalls: this.session.person.get('activityValues.calls'),
        totalChats: this.session.person.get('activityValues.chats'),
        totalEmails: this.session.person.get('activityValues.emails'),
        totalMessages: this.get('totalMessages'),
        totalSocialExpression: this.get('totalSocialExpression'),
        isShowingActivitySummary: this.session.person.get('showConversationSummaryIndicator')
      };
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('runLoopTimers').forEach(function (timer) {
        Ember.run.cancel(timer);
      });
      this.$('input.status-input').off();
      this.$('.toggle-webrtc-settings').tooltip('destroy');
    },
    isGeolocationEnabled: Ember.computed.readOnly('geolocation.isEnabled'),
    selfLocation: Ember.computed('session.person.geolocation.{city,region,country}', function () {
      return {
        city: Ember.get(this.session.person, 'geolocation.city'),
        region: Ember.get(this.session.person, 'geolocation.region'),
        country: Ember.get(this.session.person, 'geolocation.country')
      };
    }),
    entityLocationData: Ember.computed('selfLocation', 'isGeolocationEnabled', function () {
      return {
        isGeolocationEnabled: this.get('isGeolocationEnabled'),
        eLocation: this.get('selfLocation'),
        selfLocation: this.get('selfLocation'),
        noLocationText: this.get('intl').t('components.navMenu.userDropdown.noLocationText'),
        noLocationHoverText: this.get('intl').t('components.navMenu.userDropdown.noLocationAdditional'),
        isInlineLocation: true,
        fontSize: 14
      };
    }),
    actions: {
      logout: function logout() {
        this.send('closeMenu');

        if (this.get('webrtc.activeSessions.length')) {
          if (window.confirm(this.get('intl').lookup('purecloud.promptForRefreshActiveCall'))) {
            this.get('webrtc').leaveRtcSessions();
            this.bubbleAction({
              action: 'logout'
            });
          }
        } else if (this.get('webrtcSdkService.hasActiveSoftphoneSession')) {
          var skipConfirmation = !this.get('webrtcSdkService.hasActiveSoftphoneConversation');

          if (skipConfirmation || window.confirm(this.get('intl').lookup('purecloud.promptForRefreshActiveCall'))) {
            this.get('webrtcSdkService').endAllSoftphoneSessions();
            this.bubbleAction({
              action: 'logout'
            });
          }
        } else {
          this.bubbleAction({
            action: 'logout'
          });
        }
      },
      closeMenu: function closeMenu() {
        this.set('showDropdown', false);
        this.get('sidebar').ensureRouteVisibility();
      },
      closeStationPicker: function closeStationPicker() {
        this.set('showStationPicker', false);
      },
      openStationPicker: function openStationPicker() {
        this.set('showStationPicker', true);
      },
      chooseStation: function chooseStation(uri) {
        return this.get('phone').chooseStation(uri);
      },
      disassociateStation: function disassociateStation() {
        return this.get('phone').disassociateStation();
      },
      disconnectPersistentConnection: function disconnectPersistentConnection() {
        return this.get('phone').disconnectPersistentConnection();
      },
      openPreferences: function openPreferences() {
        this.get('settings').open();
      },
      cancelCallForwarding: function cancelCallForwarding() {
        this.set('configuringCallForwarding', false);
      },
      showActivateQueue: function showActivateQueue() {
        this.bubbleAction({
          action: 'linkTo',
          actionContext: ['engage.embedded', 'activateQueues']
        });
        this.send('closeMenu');
      },
      openTroubleshoot: function openTroubleshoot() {
        // to get around the dom removal in dropdown problem, run in next run loop
        var timerId = Ember.run.next(this, function () {
          this.set('showTroubleshoot', true);
        });
        this.get('runLoopTimers').push(timerId);
      },
      closeTroubleshoot: function closeTroubleshoot() {
        // to get around the dom removal in dropdown problem, run in next run loop
        var timerId = Ember.run.next(this, function () {
          this.set('showTroubleshoot', false);
        });
        this.get('runLoopTimers').push(timerId);
      },
      toggleOutOfOfficeMenu: function toggleOutOfOfficeMenu() {
        this.toggleProperty('showOutOfOfficeMenu');
      },
      closeOutOfOfficeMenu: function closeOutOfOfficeMenu() {
        this.set('showOutOfOfficeMenu', false);
      },
      displayCallForwardingPanel: function displayCallForwardingPanel() {
        this.set('configuringCallForwarding', true);
      }
    }
  }).reopenClass({
    positionalParams: ['show', 'hide']
  });
  var _default = UserDropdownComponent;
  _exports.default = _default;
});