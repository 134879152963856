define("web-directory/components/acd-interactions-panel/interaction-wrapup-codes-copilot/interaction-wrapup-codes-copilot-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Component = Ember.Component,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: ['interaction-wrapup-codes-copilot-summary'],
    conversationSummaryEvent: null,
    isCopilotSummaryEnabled: false,
    summary: '',
    reason: '',
    resolution: '',
    isFeedbackSelected: false,
    isReadabilityImprovementEnabled: false,
    isHovered: false,
    isFocused: false,
    isGlobalCopied: false,
    isSummaryCopied: false,
    sentences: computed('summary', 'isReadabilityImprovementEnabled', function () {
      var _this$get, _window, _window$Intl, _window2, _window2$Intl;

      var text = this.get('summary');
      var delimiter = '\n\n';

      if (!this.get('isReadabilityImprovementEnabled')) {
        return text;
      }

      var lang = (_this$get = this.get('conversationSummaryEvent')) === null || _this$get === void 0 ? void 0 : _this$get.language; // @ts-expect-error

      var isSegmenterSupported = !!((_window = window) !== null && _window !== void 0 && (_window$Intl = _window.Intl) !== null && _window$Intl !== void 0 && _window$Intl.Segmenter) && typeof ((_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$Intl = _window2.Intl) === null || _window2$Intl === void 0 ? void 0 : _window2$Intl.Segmenter) === 'function';

      if (isSegmenterSupported && lang) {
        // @ts-expect-error
        var segmenter = new Intl.Segmenter(lang, {
          granularity: 'sentence'
        });
        var textToSplit = text !== null && text !== void 0 ? text : '';

        var segmentsArray = _toConsumableArray(segmenter.segment(textToSplit)).map(function (s) {
          return s.segment.trim();
        });

        var sentences = segmentsArray.filter(function (val) {
          return !!val;
        }).join(delimiter);
        return sentences;
      } else {
        var _text$match;

        /*  This regular expression will match sentences that end with
            a valid sentence ending character (period, exclamation mark, or question mark)
            or sentences that do not have a valid ending but are followed by whitespace or the end of the string.
        */
        var sentenceRegex = /[^.!?\n]+[.!?]?(?=\s+|$)/g;

        var _sentences = (text === null || text === void 0 ? void 0 : (_text$match = text.match(sentenceRegex)) === null || _text$match === void 0 ? void 0 : _text$match.map(function (sentence) {
          return sentence === null || sentence === void 0 ? void 0 : sentence.trim();
        })) || [];

        return _sentences.join(delimiter);
      }
    }),
    isSummaryCopyButtonDisabled: computed('summary', function () {
      return this.get('summary') ? false : true;
    }),
    isGlobalCopyButtonDisabled: computed('summary', 'reason', 'resolution', function () {
      return this.get('summary') || this.get('reason') || this.get('resolution') ? false : true;
    }),
    handleInputChange: function handleInputChange(_name, _val) {},
    feedbackInputChange: function feedbackInputChange(_feedbackType, _val) {},
    globalCopy: function globalCopy(_val) {},
    summaryCopy: function summaryCopy(_val) {},
    overrideInputStyles: function overrideInputStyles() {
      var _this = this;

      var GUX_INPUT_TAG_NAME = 'gux-form-field-text-like';
      var GUX_INPUT_CONTAINER_SELECTOR = '.gux-input-container';
      var styleOverrideCounter = 0;
      var interval = setInterval(function () {
        var guxInputContainerElements = _this.$(GUX_INPUT_TAG_NAME);

        if ((guxInputContainerElements === null || guxInputContainerElements === void 0 ? void 0 : guxInputContainerElements.length) > 0) {
          for (var i = 0; i < guxInputContainerElements.length; i++) {
            var _guxInputContainerEle, _guxInputContainerEle2;

            var guxInputContainerElement = (_guxInputContainerEle = guxInputContainerElements[i]) === null || _guxInputContainerEle === void 0 ? void 0 : (_guxInputContainerEle2 = _guxInputContainerEle.shadowRoot) === null || _guxInputContainerEle2 === void 0 ? void 0 : _guxInputContainerEle2.querySelector(GUX_INPUT_CONTAINER_SELECTOR);

            if (guxInputContainerElement) {
              guxInputContainerElement.style.backgroundColor = 'white';
              guxInputContainerElement.style.boxShadow = 'none';
              styleOverrideCounter++;
            }
          }

          if (styleOverrideCounter === guxInputContainerElements.length) {
            clearInterval(interval);
          }
        }
      }, 100);
    },
    setCopyButtonState: function setCopyButtonState(val) {
      var copyButton = this.element.querySelector('#summary-copy-button');

      if (copyButton) {
        val ? copyButton.style.display = 'block' : copyButton.style.display = 'none';
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.overrideInputStyles();
    },
    actions: {
      handleSummaryInputChange: function handleSummaryInputChange(val) {
        this.handleInputChange('summary', val);
      },
      handleReasonInputChange: function handleReasonInputChange(val) {
        this.handleInputChange('reason', val);
      },
      handleResolutionInputChange: function handleResolutionInputChange(val) {
        this.handleInputChange('resolution', val);
      },
      handleFeedbackInputChange: function handleFeedbackInputChange(_feedbackType, _val) {
        this.feedbackInputChange(_feedbackType, _val);
      },
      handleGlobalCopy: function handleGlobalCopy() {
        if (this.get('sentences')) {
          this.globalCopy(this.get('sentences'));
        } else {
          this.globalCopy(this.get('summary'));
        }

        this.set('isGlobalCopied', true);
      },
      handleFocus: function handleFocus() {
        this.setCopyButtonState(false);
        this.set('isFocused', true);
      },
      handleBlur: function handleBlur() {
        this.setCopyButtonState(this.isHovered);
        this.set('isFocused', false);
      },
      handleKeepIconDisplayed: function handleKeepIconDisplayed() {
        this.setCopyButtonState(true);
      },
      handleMouseEnter: function handleMouseEnter() {
        this.setCopyButtonState(!this.isFocused);
        this.set('isHovered', true);
      },
      handleMouseLeaveGlobal: function handleMouseLeaveGlobal() {
        this.set('isGlobalCopied', false);
      },
      handleMouseLeaveSummary: function handleMouseLeaveSummary() {
        this.setCopyButtonState(false);
        this.set('isHovered', false);
        this.set('isSummaryCopied', false);
      },
      handleSummaryCopy: function handleSummaryCopy() {
        if (this.get('sentences')) {
          this.summaryCopy(this.get('sentences'));
        } else {
          this.summaryCopy(this.get('summary'));
        }

        this.set('isSummaryCopied', true);
      }
    }
  });

  _exports.default = _default;
});