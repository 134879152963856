define("web-directory/components/command-panel/component", ["exports", "web-directory/services/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import type { PanelDefinition } from './panel-navigation/component';
  var DEFAULT_PANEL = 'panel-not-active';

  var _default = Ember.Component.extend({
    classNames: ['command-panel'],
    classNameBindings: ['panelIsNotActive::active'],
    events: Ember.inject.service('events'),
    currentPanel: DEFAULT_PANEL,
    currentDrawerId: null,
    // these definitions should be defined in the
    // the panel render components and imported
    panelDefinitions: [{
      id: 'panel-help',
      name: 'Help',
      icon: 'help',
      defaultDrawerId: 'help-current-page',
      drawers: [{
        id: 'help-current-page',
        name: 'Current Page',
        layout: 'command-panel-help'
      }, {
        id: 'help-release-notes',
        name: 'Release Notes'
      }, {
        id: 'help-resources',
        name: 'Resources'
      }]
    }, {
      id: 'panel-collaborate',
      name: 'Collaborate',
      icon: 'chat',
      defaultDrawerId: 'uc-chat',
      drawers: [{
        id: 'uc-chat',
        name: 'Chats',
        layout: 'chat-frame-router'
      }, {
        id: 'uc-voice',
        name: 'Voice',
        layout: 'call-controls'
      }, {
        id: 'uc-call-history',
        name: 'Call History',
        layout: 'call-history-v2'
      }, {
        id: 'uc-files',
        name: 'My Files',
        layout: ''
      }, {
        id: 'uc-faxes',
        name: 'Send Fax',
        layout: 'fax-panel'
      }]
    }, {
      id: 'panel-inbox',
      name: 'Inbox',
      icon: 'inbox',
      layout: 'inbox-panel-v2'
    }, {
      id: 'panel-apps',
      name: 'Apps',
      icon: 'toolbar-apps',
      layout: ''
    }],
    panelIsNotActive: Ember.computed.equal('currentPanel', DEFAULT_PANEL),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('events').on(_events.TOGGLE_EVENT, this, this.handleToggleEvent);
      this.get('events').on(_events.TOGGLE_ACTIVE_DRAWER_EVENT, this, this.handleDrawerSelection);
    },
    destroy: function destroy() {
      // @ts-ignore
      this.get('events').off(_events.TOGGLE_EVENT, this, this.handleToggleEvent);

      this._super.apply(this, arguments);
    },
    actions: {
      closePanel: function closePanel() {
        this.set('currentPanel', DEFAULT_PANEL);
      }
    },
    handleDrawerSelection: function handleDrawerSelection(evt) {
      if (evt && evt.panelId && evt.drawerId) {
        console.log('jrr drawer', evt.panelId, evt.drawerId);

        if (evt.panelId === this.get('currentPanel')) {
          this.set('currentDrawerId', evt.drawerId);
        }
      }
    },
    handleToggleEvent: function handleToggleEvent(evt) {
      var _console;

      (_console = console).log.apply(_console, ['jrr'].concat(Array.prototype.slice.call(arguments)));

      if (evt && evt.panel) {
        var panel = evt.panel;

        if (this.get('currentPanel') !== "panel-".concat(panel)) {
          this.set('currentPanel', "panel-".concat(panel)); // @ts-ignore

          var defaultDrawer = this.get('panelDefinitions').findBy('id', "panel-".concat(panel)).defaultDrawerId;
          this.set('currentDrawerId', defaultDrawer);
        } else {
          this.set('currentPanel', DEFAULT_PANEL);
        }

        console.log('jrr: open panel', panel);
      }
    }
  });

  _exports.default = _default;
});