define("web-directory/components/apps-sidebar-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AlVxXtzg",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"panel-header item-toggle\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"panel-title item-toggle-title\"],[13],[0,\"\\n        \"],[11,\"h3\",[]],[15,\"class\",\"text text-static\"],[16,\"title\",[34,[[33,[\"t\"],[\"components.navMenu.apps\"],null]]]],[13],[0,\"\\n            \"],[1,[33,[\"t\"],[\"components.navMenu.apps\"],null],false],[0,\"\\n        \"],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"showPanelControls\"]]],null,{\"statements\":[[0,\"            \"],[1,[33,[\"chat-panel/conversation/header/control-buttons-panel\"],null,[[\"menuName\",\"showExpandButton\",\"shouldShowPopout\"],[[33,[\"t\"],[\"components.navMenu.apps\"],null],false,false]]],false],[0,\"\\n\"]],\"locals\":[]},null],[0,\"    \"],[14],[0,\"\\n    \"],[1,[33,[\"toggle-item-bar\"],null,[[\"toggleItemConfigs\",\"selection\",\"attentionStarburst\",\"onchange\"],[[28,[\"widgetAppTabConfigs\"]],[28,[\"activeWidgetAppId\"]],[28,[\"appPool\",\"appAttentionCounts\"]],[33,[\"action\"],[[28,[null]],[28,[\"itemToggled\"]]],null]]]],false],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"panel-content\"],[15,\"tabindex\",\"-1\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"tab-content\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"tab-pane active\"],[15,\"role\",\"tabpanel\"],[13],[0,\"\\n            \"],[1,[33,[\"app-pool\"],null,[[\"appPool\",\"class\",\"onAppInstanceApiCall\",\"onDeletedAppClose\",\"appsSdkEnabled\"],[[28,[\"appPool\"]],\"widget-app-pool\",[28,[\"onAppInstanceApiCall\"]],[33,[\"action\"],[[28,[null]],\"onDeletedAppClose\"],null],true]]],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/apps-sidebar-panel/template.hbs"
    }
  });

  _exports.default = _default;
});