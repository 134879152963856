define("web-directory/services/apps", ["exports", "lodash", "web-directory/config/environment", "web-directory/utils/apps", "web-directory/utils/net", "web-directory/utils/newrelic", "web-directory/models/app", "web-directory/models/internally-hosted-app", "web-directory/models/app-api-call", "web-directory/models/app-pool", "web-directory/services/apps-api-handlers/toast-handler", "web-directory/services/apps-api-handlers/directory-nav-handler", "web-directory/services/apps-api-handlers/resource-center-handler"], function (_exports, _lodash, _environment, _apps2, _net, _newrelic, _app, _internallyHostedApp, _appApiCall, _appPool, _toastHandler, _directoryNavHandler, _resourceCenterHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.APP_SIDEBAR_PANEL_NAME_PREFIX = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var APP_SIDEBAR_PANEL_NAME_PREFIX = 'apps-';
  _exports.APP_SIDEBAR_PANEL_NAME_PREFIX = APP_SIDEBAR_PANEL_NAME_PREFIX;
  var APPS_BASE_URL = 'api/v2/integrations/clientapps';
  var REMOTE_APPS_CACHE_TTL_MILLIS = 5 * 60 * 1000;
  var STANDALONE_LOAD_RETRY_BASE_MILLIS = 1000;
  var STANDALONE_LOAD_RETRY_MAX_BACKOFF_MILLIS = 60 * 1000;
  var STANDALONE_RELOAD_INTERVAL_MILLIS = REMOTE_APPS_CACHE_TTL_MILLIS + 10;
  var STANDALONE_RELOAD_RETRY_BASE_MILLIS = 1000;
  var WIDGET_LOAD_RETRY_BASE_MILLIS = 1000;
  var WIDGET_LOAD_RETRY_MAX_BACKOFF_MILLIS = 60 * 1000;
  var WIDGET_RELOAD_INTERVAL_MILLIS = REMOTE_APPS_CACHE_TTL_MILLIS + 10;
  var WIDGET_RELOAD_RETRY_BASE_MILLIS = 1000;
  /**
   * List of SDK calls pre-dating the protocol field on the SDK payload.
   *
   * This list allows us to log internal usages of the SDK without the protocol field
   * as deprecations to ensure we're ready to remove the lenient parse of the sdk message.
   *
   * [IMPORTANT:] New SDK methods should not be added to this list.
   */

  var PROTOCOL_LENIENT_INTERNAL_ACTION_NAMES = [_directoryNavHandler.SHOW_PROFILE_APP_API_ACTION_NAME, _toastHandler.SHOW_TOAST_APP_API_ACTION_NAME, _resourceCenterHandler.SHOW_HELP_APP_API_ACTION_NAME, _resourceCenterHandler.HIDE_HELP_APP_API_ACTION_NAME, _resourceCenterHandler.SHOW_RC_ARTIFACT_APP_API_ACTION_NAME];
  /* App Parsing Props */

  var APP_PROTOCOL_CHECK_REG_EXG = new RegExp(/^\s*https?:\/\/.+$/);
  var ALLOWED_SANDBOX_VALUES = ['allow-forms', 'allow-modals', 'allow-popups', 'allow-presentation', 'allow-same-origin', 'allow-scripts', 'allow-downloads'];
  var ALLOWED_PERMISSION_VALUES = ['camera', 'clipboard-write', 'display-capture', 'fullscreen', //"fullscreen 'none'" is explicitly required to disable fullscreen for nested iframes
  "fullscreen 'none'", 'geolocation', 'microphone'];
  var VECTOR_IMG_KEY = 'vector';
  var RASTER_IMG_KEY_REG_EXP = /^(\d+)x(\d+)$/i;
  var LIFECYCLE_HOOK_KEYS = ['blur', 'stop', 'focus', 'bootstrap'].map(function (hook) {
    return "hooks.".concat(hook);
  });
  var LIFECYCLE_KEYS = ['ephemeral'].concat(_toConsumableArray(LIFECYCLE_HOOK_KEYS));
  var INTERAPPTION_PROP_MAPPING_CONFIG = {
    name: {
      type: 'string',
      destPath: 'name'
    },
    sandbox: {
      destPath: 'properties.sandbox',
      convert: function convert(valueIn) {
        var typeIn = Ember.typeOf(valueIn);

        if (typeIn === 'array') {
          return valueIn.join(',');
        } else if (typeIn === 'string') {
          return valueIn;
        } else {
          throw new Error('invalidValue');
        }
      }
    },
    permissions: {
      destPath: 'properties.permissions',
      convert: function convert(valueIn) {
        var typeIn = Ember.typeOf(valueIn);

        if (typeIn === 'array') {
          return valueIn.join(',');
        } else if (typeIn === 'string') {
          return valueIn;
        } else {
          throw new Error('invalidValue');
        }
      }
    },
    url: {
      type: 'string',
      destPath: 'properties.url'
    },
    lifecycle: {
      destPath: 'advanced.lifecycle',
      convert: function convert(lifecycleObj) {
        if (Ember.typeOf(lifecycleObj) !== 'object') {
          throw new Error('lifecycle must be an object if specified');
        }

        var result = {};

        var _iterator = _createForOfIteratorHelper(LIFECYCLE_KEYS),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var key = _step.value;

            var value = _lodash.default.get(lifecycleObj, key);

            var valueType = Ember.typeOf(value); // Set boolean/Boolean values and ignore undefined (use default)

            if (valueType === 'boolean') {
              _lodash.default.set(result, key, value.valueOf());
            } else if (valueType !== 'undefined') {
              throw new Error("Expected ".concat(key, " to be boolean or undefined"));
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        return result;
      }
    },
    subdomain: {
      type: 'string',
      destPath: 'properties.subdomain',
      trustedOnly: true
    },
    path: {
      type: 'string',
      destPath: 'properties.path',
      trustedOnly: true
    },
    query: {
      type: 'string',
      destPath: 'properties.query',
      trustedOnly: true
    }
  };

  _app.ASSET_CATEGORIES.forEach(function (currCategory) {
    INTERAPPTION_PROP_MAPPING_CONFIG[currCategory] = {
      type: 'object',
      destPath: "advanced.".concat(currCategory)
    };
  });

  var _default = Ember.Service.extend({
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    stashLogger: Ember.inject.service('stash-logger'),
    sidebar: Ember.inject.service('sidebar'),
    intl: Ember.inject.service(),
    notification: Ember.inject.service(),
    ajax: Ember.inject.service(),
    help: Ember.inject.service(),
    permissions: Ember.inject.service(),
    standaloneAppPool: null,
    standaloneAppPoolVisible: false,
    // true when an app route is active
    widgetAppPool: null,
    externalUrlExemptIntegrationTypeIds: Ember.get(_environment.default, 'purecloud-apps.externalUrlExemptIntegrationTypeIds'),
    // Exposed only for automation to test with smaller pool sizes.
    // These can only be changed prior to loading any apps.
    standaloneMaxActivePoolSize: Ember.get(_environment.default, 'purecloud-apps.maxActivePoolSize.standalone'),
    widgetMaxActivePoolSize: Ember.get(_environment.default, 'purecloud-apps.maxActivePoolSize.widget'),
    // Bypass all (client/server) app caching.  Should only be used internally (Testing/Automation)
    _bypassAppCache: false,
    // All globally available handlers (not pool or instance specific) will be injected by the instance initializer
    globalApiHandlers: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('standaloneAppPool', _appPool.default.create({
        poolName: 'standaloneAppPool',
        getMaxActive: function getMaxActive() {
          return _this.get('standaloneMaxActivePoolSize');
        },
        fetchAppsFn: this.get('_findAllStandaloneApps').bind(this),
        loadRetryBaseMillis: STANDALONE_LOAD_RETRY_BASE_MILLIS,
        loadRetryMaxBackoffMillis: STANDALONE_LOAD_RETRY_MAX_BACKOFF_MILLIS,
        reloadAppsIntervalMillis: STANDALONE_RELOAD_INTERVAL_MILLIS,
        reloadAppsRetryBaseMillis: STANDALONE_RELOAD_RETRY_BASE_MILLIS
      }, Ember.getOwner(this).ownerInjection()));
      this.set('widgetAppPool', _appPool.default.create({
        poolName: 'widgetAppPool',
        getMaxActive: function getMaxActive() {
          return _this.get('widgetMaxActivePoolSize');
        },
        fetchAppsFn: this.get('_findAllWidgetApps').bind(this),
        loadRetryBaseMillis: WIDGET_LOAD_RETRY_BASE_MILLIS,
        loadRetryMaxBackoffMillis: WIDGET_LOAD_RETRY_MAX_BACKOFF_MILLIS,
        reloadAppsIntervalMillis: WIDGET_RELOAD_INTERVAL_MILLIS,
        reloadAppsRetryBaseMillis: WIDGET_RELOAD_RETRY_BASE_MILLIS
      }, Ember.getOwner(this).ownerInjection())); // Listen to local PC-sourced app messages

      var localMsgListener = Ember.run.bind(this, '_handleLocalMsg');
      this.set('_localMsgListener', localMsgListener);
      Ember.$(window).on('message onmessage', localMsgListener);
    },
    appsSupported: Ember.computed(function () {
      /* globals Modernizr */
      return !!(Modernizr && Modernizr.sandbox);
    }),

    /**
     * Process the app api call from the app specified in app or appInstance
     */
    handleAppApiCall: function handleAppApiCall(apiCall, contextualApiHandlers) {
      this._handleApiCall(apiCall, contextualApiHandlers);
    },

    /**
     * Checks if an app instance (App) is trusted or not
     * It's considered trusted if the App is an internal app (InternallyHostedApp)
     * or if the app type is part of exempted external URLs.
     *
     * This should be used to check if an app can receive internal data or not.
     *
     * @param {App} appInstance The app instance
     * @returns whether the app instance is considered trusted or not
     */
    isTrustedApp: function isTrustedApp(appInstance) {
      return appInstance instanceof _internallyHostedApp.default || this.get('externalUrlExemptIntegrationTypeIds').includes(appInstance.typeId);
    },

    /**
     * Builds and validates Interapptions from the provided config.
     *
     * Config must conform to supported properties and the resulting app instance must be valid.
     * Invalid config or the built app will log in addition to returning null.
     *
     * @param {string} typeId - The typeId of the interapption
     * @param {string} id - The id of the interapption
     * @param {Object} config - Interapption config
     * @param {string} config.name - Interapption name
     * @param {string|string[]} config.sandbox - Interapption sandbox (comma-separated string or array)
     * @param {string|string[]} config.permissions - Interapption permissions (comma-separated string or array)
     * @param {string} config.url - Interapption url. Only URL or path can be specified
     * @param {Object} config.icon - URLs of assets for the icon class of images for this Interapption
     * @param {Object} config.icon.vector - A URL to a vector icon asset
     * @param {Object} config.icon.{width}x{height} - A URL to a raster icon asset of the size class indicated by the property
     * @param {Object} config.monochromicIcon - URLs of assets for the monochrome icon class of images for this Interapption
     * @param {Object} config.monochromicIcon.vector - A URL to a vector monochrome icon asset
     * @param {Object} config.monochromicIcon.{width}x{height} - A URL to a raster monochrome icon asset of the size class indicated by the property
     * @param {Object} config.lifecycle - Lifecycle config
     * @param {boolean} config.lifecycle.ephemeral - Is app ephemeral
     * @param {Object} config.lifecycle.hooks - Opt-in lifecycle hooks config
     * @param {boolean} config.lifecycle.hooks.bootstrap - Should app receive bootstrap event
     * @param {boolean} config.lifecycle.hooks.focus - Should app receive focus event
     * @param {boolean} config.lifecycle.hooks.blur - Should app receive blur event
     * @param {boolean} config.lifecycle.hooks.stop - Should app receive stop event
     * @param {string} config.subdomain - A subdomain for an InternallyHostedApp (Trusted Only)
     * @param {string} config.path - A Path for an InternallyHostedApp (Trusted Only)
     * @param {string} config.query - A querystring for an InternallyHostedApp (Trusted Only)
     * @param {boolean} trustedProvider - Indicates if this provider can generate trusted apps.
     *  This should only be used for internal app providers that wish to register internal apps.
     *  e.g. Altocloud, Relate, Scripter, etc.
     *
     * @returns an App or InternallyHostedApp instance if valid; null otherwise
     */
    buildValidInterapption: function buildValidInterapption(typeId, id, config) {
      var trustedProvider = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      // FUTURE: Support for i10n, full validation, etc. (See: ATEAM-152)
      if (Ember.typeOf(typeId) !== 'string' || typeId.trim().length === 0) {
        this.get('stashLogger').debug(_apps2.default.buildInterapptionsLogMsg('Interapption is being ignored due to a missing/empty typeId.'), {
          config: config,
          remoteData: {
            typeId: typeId,
            id: id,
            trustedProvider: trustedProvider
          }
        });
        return null;
      }

      if (Ember.typeOf(id) !== 'string' || id.trim().length === 0) {
        this.get('stashLogger').debug(_apps2.default.buildInterapptionsLogMsg('Interapption is being ignored due to a missing/empty id.'), {
          config: config,
          remoteData: {
            typeId: typeId,
            id: id,
            trustedProvider: trustedProvider
          }
        });
        return null;
      }

      if (Ember.typeOf(config) !== 'object') {
        this.get('stashLogger').debug(_apps2.default.buildInterapptionsLogMsg('Interapption is being ignored due to an invalid config object type.'), {
          config: config,
          remoteData: {
            type: Ember.typeOf(config),
            typeId: typeId,
            id: id,
            trustedProvider: trustedProvider
          }
        });
        return null;
      }

      var normalizedConfig = {
        properties: {
          displayType: _app.DISPLAY_TYPES.INTERAPPTION
        },
        advanced: {}
      }; // Validate and map config properties to normalized config

      var propError = null;
      var configKeys = Object.keys(config);

      for (var index = 0; index < configKeys.length; index++) {
        var currKey = configKeys[index];
        var currValue = config[currKey];
        var currPropConfig = INTERAPPTION_PROP_MAPPING_CONFIG[currKey];

        if (!currPropConfig) {
          propError = "".concat(currKey, " is not a supported interapption property");
          break;
        }

        if (currPropConfig.convert) {
          try {
            currValue = currPropConfig.convert(currValue);
          } catch (e) {
            propError = "Failed to convert ".concat(currKey, " to a valid input");
            break;
          }
        } else if (Ember.typeOf(currValue) !== currPropConfig.type) {
          propError = "".concat(currKey, " should be of type ").concat(currPropConfig.type);
          break;
        }

        if (currPropConfig.trustedOnly && !trustedProvider) {
          propError = "".concat(currKey, " is only allowed to be configured for trustedProviders");
          break;
        }

        Ember.set(normalizedConfig, currPropConfig.destPath, currValue);
      }

      if (!propError && Object.prototype.hasOwnProperty.call(config, 'url') && (Object.prototype.hasOwnProperty.call(config, 'subdomain') || Object.prototype.hasOwnProperty.call(config, 'path') || Object.prototype.hasOwnProperty.call(config, 'query'))) {
        propError = 'Cannot specify both a URL and any of subdomain, path, or query';
      }

      if (propError) {
        this.get('stashLogger').error(_apps2.default.buildInterapptionsLogMsg('Interapption is being ignored due to invalid config.'), {
          config: config,
          details: propError,
          remoteData: {
            typeId: typeId,
            id: id,
            trustedProvider: trustedProvider
          }
        });
        return null;
      }

      var result = this._buildApp(typeId, id, normalizedConfig, trustedProvider && Object.prototype.hasOwnProperty.call(config, 'path'));

      if (!this._isValidApp(result, 'Interapption')) {
        return null;
      }

      return result;
    },

    /**
     * Handles app postMessage events from the local frame to support app-like
     * integrations.
     */
    _handleLocalMsg: function _handleLocalMsg(evt) {
      if (!evt.originalEvent.source || !this._isLocalMsg(evt.originalEvent.source)) {
        // Not a valid internal app event
        return;
      }

      var apiCall = null;

      try {
        // Strict parse
        apiCall = (0, _appApiCall.parseMessage)(evt.originalEvent.data, true);
      } catch (parseError) {
        // TECHDEBT: Remove lenient retry after deprecation period; will have to change logic to ensure only error cases are logged
        if (parseError.message === 'missingProtocol') {
          try {
            // Re-parse leniently to allow for short deprecation period and logging
            apiCall = (0, _appApiCall.parseMessage)(evt.originalEvent.data, false); // Check the action to reduce deprecation log false positives further

            if (PROTOCOL_LENIENT_INTERNAL_ACTION_NAMES.indexOf(apiCall.action) >= 0) {
              this.get('stashLogger').info(_apps2.default.buildApiLogMsg('Potential internal apps message posted message without protocol information.  This will be deprecated soon.'), {
                apiCall: apiCall,
                remoteData: {
                  internalPcCall: apiCall.get('internalPcCall'),
                  appId: apiCall.get('appId')
                }
              });
            } else {
              // No protocol and either an unknown or not a legacy action.
              // Safe to assume this is not an apps message or is a new action type which should require the protocol
              apiCall = null;
            }
          } catch (ignored) {// Not a client-apps message (e.g. no action field)
          }
        } else if (parseError.message === 'missingAction' && _typeof(evt.originalEvent.data) === 'object' && evt.originalEvent.data.protocol) {
          // Apps Protocol and no action
          this.get('stashLogger').error(_apps2.default.buildApiLogMsg('(Local) sent an apps message with no action.'), evt.originalEvent.data);
        } else {// Not a client-apps message (e.g. invalid payload, unknown protocol, no action)
        }
      }

      if (apiCall) {
        apiCall.set('internalPcCall', true);

        this._handleApiCall(apiCall);
      }
    },
    _isLocalMsg: function _isLocalMsg(msgSource) {
      return msgSource === window;
    },
    _handleApiCall: function _handleApiCall(apiCall) {
      var _this2 = this;

      var contextualApiHandlers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      if (!apiCall || !apiCall.get('internalPcCall') && !apiCall.get('appId')) {
        // Not a valid app call
        return;
      }

      var apiHandlers = contextualApiHandlers.concat(this.get('globalApiHandlers'));
      var appId = apiCall.get('appId');
      var apiActionDetails = apiCall.get('actionDetails');
      var asyncHandlerPromise;

      if (apiHandlers.length > 0) {
        var availHandler = apiHandlers.find(function (currHandler) {
          try {
            var result = currHandler.canHandle(apiCall);

            if (Ember.typeOf(result) === 'boolean') {
              return result;
            } else {
              _this2.get('stashLogger').error(_apps2.default.buildApiLogMsg('Handler returned a non-boolean result from canHandle'));
            }
          } catch (e) {
            _this2.get('stashLogger').error(_apps2.default.buildApiLogMsg('Handler threw an Error when running canHandle'), e);
          }

          return false;
        });

        if (availHandler) {
          try {
            asyncHandlerPromise = availHandler.handle(apiCall);
          } catch (error) {
            asyncHandlerPromise = Ember.RSVP.reject(error);
          }
        } else {
          asyncHandlerPromise = Ember.RSVP.reject('No handler exists for the specified call');
        }
      } else {
        asyncHandlerPromise = Ember.RSVP.reject('No handler exists for the specified call');
      }

      asyncHandlerPromise.then(function () {
        _this2._instrumentApiCall(apiCall);
      }).catch(function (reason) {
        _this2.get('stashLogger').error(_apps2.default.buildApiLogMsg("".concat(apiCall.get('internalPcCall') ? '(Local)' : 'App', " sent a message that could not be handled.")), {
          apiActionDetails: apiActionDetails,
          msgFailureDetails: reason,
          remoteData: {
            appId: appId,
            internalPcCall: apiCall.get('internalPcCall')
          }
        });
      });
    },
    _instrumentApiCall: function _instrumentApiCall(apiCall) {
      if (!(apiCall instanceof _appApiCall.default)) {
        throw new Error('ApiCall instance must be provided');
      } // Redact any non-standard protocols/agents ... just in case


      var pcAppProtocol = apiCall.get('protocol');

      if (pcAppProtocol && pcAppProtocol !== _appApiCall.APPS_API_PROTOCOL) {
        pcAppProtocol = 'redactedProtocol';
      }

      var pcAppProtocolAgentName = apiCall.get('protocolAgentName');
      var pcAppProtocolAgentVersion = apiCall.get('protocolAgentVersion');

      if (pcAppProtocolAgentName && pcAppProtocolAgentName !== 'purecloud-client-app-sdk') {
        pcAppProtocolAgentName = 'redactedProtocolAgentName';
        pcAppProtocolAgentVersion = 'redactedProtocolAgentVersion';
      } // Note: Some details are not applicable in all cases.
      // Providing the entire payload allows for easy filtering on the PageAction


      this._addPageAction('clientAppApiCall', {
        apiAction: apiCall.get('action'),
        pcAppProtocol: pcAppProtocol,
        pcAppProtocolAgentName: pcAppProtocolAgentName,
        pcAppProtocolAgentVersion: pcAppProtocolAgentVersion,
        // App Details
        internalPcCall: apiCall.get('internalPcCall'),
        pcAppTypeId: apiCall.get('srcApp.typeId'),
        pcAppId: apiCall.get('srcApp.id'),
        pcAppDisplayType: apiCall.get('srcApp.displayType'),
        pcAppInternallyHosted: apiCall.get('srcApp') instanceof _internallyHostedApp.default,
        pcAppFeatureCategory: apiCall.get('srcApp.featureCategory'),
        // AppInstance Details
        pcAppInstanceId: apiCall.get('srcAppInstance.instanceId'),
        pcAppProblemName: apiCall.get('srcAppInstance.problem.name'),
        // Interapption Details
        interactionId: apiCall.get('srcApp.interactionId')
      });
    },
    _addPageAction: function _addPageAction() {
      _newrelic.addPageAction.apply(void 0, arguments);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var localMsgListener = this.get('_localMsgListener');

      if (localMsgListener) {
        Ember.$(window).off('message onmessage', localMsgListener);
        this.set('_localMsgListener', null);
      } // Clean up pools


      [this.get('standaloneAppPool'), this.get('widgetAppPool')].forEach(function (currPool) {
        if (currPool && !currPool.isDestroying && !currPool.isDestroyed) {
          currPool.destroy();
        }
      });
    },
    _findAllStandaloneApps: function _findAllStandaloneApps() {
      return this._fetchAppsByType(_app.DISPLAY_TYPES.STANDALONE);
    },
    _findAllWidgetApps: function _findAllWidgetApps() {
      return this._fetchAppsByType(_app.DISPLAY_TYPES.WIDGET);
    },
    findAllInterapptions: function findAllInterapptions() {
      return this._fetchAppsByType(_app.DISPLAY_TYPES.INTERAPPTION);
    },
    _fetchAppsByType: function _fetchAppsByType(type) {
      return this._fetchAppsByTypeRemote(type);
    },

    /*
     * Cache the list of apps for awhile reduce network traffic
     * due to fetching multiple app types at boot and reload
     */
    _remoteAppPromise: null,
    _remoteAppRequestTimestamp: null,
    _fetchAppsByTypeRemote: function _fetchAppsByTypeRemote(type) {
      var _this3 = this;

      var now = Date.now();
      var allAppsPromise = this.get('_remoteAppPromise');
      var lastRequestTs = this.get('_remoteAppRequestTimestamp');

      if (!allAppsPromise || this.get('_bypassAppCache') || lastRequestTs && now - lastRequestTs > REMOTE_APPS_CACHE_TTL_MILLIS) {
        allAppsPromise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this3._accumulateApps(function (apps) {
            if (apps && apps.length > 1) {
              apps.sort(function (app1, app2) {
                return app1.get('name').localeCompare(app2.get('name'));
              });
            }

            resolve(apps);
          }, function (reason) {
            // clear the instance's promise on falures so we dont cache failed requests
            _this3.set('_remoteAppPromise', null);

            reject(reason);
          }, 1, 50, null);
        });
        this.set('_remoteAppPromise', allAppsPromise);
        this.set('_remoteAppRequestTimestamp', now);
      }

      return allAppsPromise.then(function (apps) {
        return apps.filter(function (currApp) {
          return currApp.get('displayType') === type;
        });
      });
    },
    _accumulateApps: function _accumulateApps(resolve, reject, pageNumber, pageSize, type, apps) {
      var _this4 = this;

      if (typeof apps === 'undefined' || apps === null) {
        apps = [];
      }

      var appsUrl = this.get('application').pcV2Uri("".concat(APPS_BASE_URL));
      var queryParams = {
        pageNumber: pageNumber,
        pageSize: pageSize
      };
      appsUrl += "?".concat(Ember.$.param(queryParams, true));
      var headers = {};

      var primaryLocale = this._getPrimaryLocale();

      if (primaryLocale) {
        headers['Accept-Language'] = primaryLocale;
      }

      if (this.get('_bypassAppCache')) {
        headers['Cache-Control'] = 'no-cache';
      }

      this.get('ajax').request(appsUrl, {
        headers: headers
      }).then(function (result) {
        if (!result) {
          reject('Invalid clientapps return value');
          return;
        }

        if (Array.isArray(result.entities)) {
          var _apps;

          var validAppObjs = _lodash.default.chain(result.entities).filter(function (currIntegration) {
            return currIntegration && _typeof(currIntegration) === 'object' && Ember.typeOf(Ember.get(currIntegration, 'integrationType.id')) === 'string' && !_lodash.default.isEmpty(Ember.get(currIntegration, 'config.effective.properties'));
          }).map(function (currIntegration) {
            var internallyHosted = _this4._isInternallyHostedApp(currIntegration.integrationType.id);

            return _this4._buildApp(currIntegration.integrationType.id, currIntegration.id, currIntegration.config.effective, internallyHosted);
          }).filter(function (currApp) {
            return !!currApp && _this4._isValidApp(currApp, currApp.get('displayType') === _app.DISPLAY_TYPES.INTERAPPTION ? 'Interapption' : undefined) && (!type || currApp.get('displayType') === type);
          }).value();

          (_apps = apps).push.apply(_apps, _toConsumableArray(validAppObjs));
        }

        if (result.pageNumber < result.pageCount) {
          _this4._accumulateApps(resolve, reject, result.pageNumber + 1, pageSize, type, apps);
        } else {
          resolve(apps);
        }
      }).catch(reject);
    },
    _getPrimaryLocale: function _getPrimaryLocale() {
      var isoLocale = this.get('intl.isoLocale');

      if (Ember.typeOf(isoLocale) === 'string' && Ember.isPresent(isoLocale)) {
        return isoLocale;
      } else {
        this.get('stashLogger').warn(_apps2.default.buildLogMsg('Unable to find current locale'), {
          remoteData: {
            intlLocale: this.get('intl.locale'),
            intlIsoLocale: this.get('intl.isoLocale')
          }
        });
        return null;
      }
    },
    _buildApp: function _buildApp(typeId, id, config) {
      var _this5 = this;

      var internallyHosted = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var result = null;

      var langTag = this._getPrimaryLocale(); // Common app props


      var appProps = {
        typeId: typeId,
        id: id,
        name: this._parseLangTagProp(config, 'advanced.i10n', langTag, 'name') || Ember.get(config, 'name'),
        sandbox: this._parseMultiValueProp(config, 'properties.sandbox', ALLOWED_SANDBOX_VALUES),
        displayType: this._parseProp(config, 'properties.displayType', _lodash.default.values(_app.DISPLAY_TYPES)),
        featureCategory: this._parseProp(config, 'properties.featureCategory', _lodash.default.values(_app.FEATURE_CATEGORIES)),
        permissionsPolicy: this._parseMultiValueProp(config, 'properties.permissions', ALLOWED_PERMISSION_VALUES),
        enabledQueueIds: this._parseProp(config, 'properties.queueIdFilterList'),
        lifecycle: Ember.get(config, 'advanced.lifecycle'),
        assets: {},
        // Set runtime state for internal model use
        langTag: langTag || '',
        envTld: this.get('application.envDetails.envTld') || '',
        usePopupAuth: !!this.get('session').get('popoutAuthForIframes'),
        ucIntegrationKey: this._parseProp(config, 'properties.ucIntegrationKey'),
        // UC Integration use
        integrationPresenceSource: this._parseProp(config, 'properties.integrationPresenceSource'),
        // UC Integration use
        pbxPermission: this._parseProp(config, 'properties.pbxPermission'),
        // UC Integration use
        gcHostOrigin: _net.default.getCurrentWindowOrigin(),
        gcTargetEnv: this.get('application.runtime.name'),
        enableWorkitem: this._parseBooleanProp(config, 'properties.enableWorkitemFilter')
      }; // Only attempt to parse communicationTypeFilter when specifed to differentiate undefined/null from []

      if (!Ember.isNone(Ember.get(config, 'properties.communicationTypeFilter'))) {
        appProps.enabledCommunicationTypes = this._parseMultiValueProp(config, 'properties.communicationTypeFilter', [].concat(_toConsumableArray(_app.INTERAPPTION_COMMUNICATION_TYPE_FILTERS), [_app.NO_SUPPORTED_COMMUNICATION_TYPE_FILTER]));
      }

      if (!internallyHosted) {
        appProps.urlTemplate = Ember.get(config, 'properties.url');
        result = _app.default.create(Ember.getOwner(this).ownerInjection(), appProps);
      } else {
        appProps.subdomain = Ember.get(config, 'properties.subdomain');
        appProps.envDetails = this.get('application.envDetails');
        appProps.path = Ember.get(config, 'properties.path');
        appProps.query = Ember.get(config, 'properties.query');
        result = _internallyHostedApp.default.create(Ember.getOwner(this).ownerInjection(), appProps);
      } // Extract assets for each supported asset category


      _app.ASSET_CATEGORIES.forEach(function (currAssetCategory) {
        var currAssetCategoryCfg = {};
        var currVector = Ember.get(config, "advanced.".concat(currAssetCategory, ".").concat(VECTOR_IMG_KEY));

        if (currVector) {
          currAssetCategoryCfg.vector = currVector;
        }

        var currRasters = _this5._extractRasterImgs(config, "advanced.".concat(currAssetCategory));

        if (Object.keys(currRasters).length > 0) {
          currAssetCategoryCfg.raster = currRasters;
        }

        var icon = Ember.get(config, "advanced.".concat(currAssetCategory));

        if (icon && icon.guxIcon) {
          currAssetCategoryCfg.guxIcon = icon.guxIcon;
        }

        if (Object.keys(currAssetCategoryCfg).length > 0) {
          result.set("assets.".concat(currAssetCategory), currAssetCategoryCfg);
        } else if (config) {
          var advanced = Ember.get(config, "advanced");

          if (advanced) {
            var assetCategory = Ember.get(advanced, currAssetCategory);

            if (assetCategory) {
              var classNames = Ember.get(assetCategory, 'classNames');

              if (classNames) {
                var assets = Ember.get(result, 'assets');

                if (!Ember.get(assets, currAssetCategory)) {
                  Ember.set(assets, currAssetCategory, {
                    classNames: classNames
                  });
                } else {
                  Ember.set(assets, "".concat(currAssetCategory, ".classNames"), classNames);
                }
              }
            }
          }
        }
      });

      return result;
    },

    /**
     * Extract all images of pattern digit(s)xdigit(s) from the basePath
     */
    _extractRasterImgs: function _extractRasterImgs(config, basePath) {
      var result = {};
      var imgs = Ember.get(config, basePath);

      if (imgs && _typeof(imgs) === 'object') {
        for (var currImgKey in imgs) {
          if (currImgKey !== VECTOR_IMG_KEY) {
            var parseResult = RASTER_IMG_KEY_REG_EXP.exec(currImgKey);

            if (parseResult && parseResult[1] === parseResult[2]) {
              result["x".concat(parseResult[1])] = imgs[currImgKey];
            }
          }
        }
      }

      return result;
    },
    _isValidApp: function _isValidApp(app) {
      var appType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'App';

      try {
        this._validateApp(app);

        return true;
      } catch (e) {
        var logMessageAppType = appType === 'App' ? 'App' : 'Interapption';
        var logLevel;
        var logMessage;

        switch (e.message) {
          case 'invalidTypeId':
          case 'invalidId':
          case 'invalidName':
          case 'invalidDisplayType':
            logLevel = 'debug';
            logMessage = "".concat(logMessageAppType, " is being ignored due to a missing typeId, id, name, or displayType.");
            break;

          case 'invalidPath':
            logLevel = 'info';
            logMessage = "Internally Hosted ".concat(logMessageAppType, " is being ignored due to a missing/invalid path.");
            break;

          case 'invalidSubdomain':
            logLevel = 'info';
            logMessage = "Internally Hosted ".concat(logMessageAppType, " is being ignored due to an emptystring/invalid subdomain.");
            break;

          case 'invalidQuery':
            logLevel = 'info';
            logMessage = "Internally Hosted ".concat(logMessageAppType, " is being ignored due to an invalid query type.");
            break;

          case 'missingUrl':
            logLevel = 'debug';
            logMessage = "".concat(logMessageAppType, " is being ignored due to a missing url.");
            break;

          case 'invalidUrlProtocol':
            logLevel = 'error';
            logMessage = "".concat(logMessageAppType, " is being ignored due to an invalid protocol.");
            break;

          case 'reservedPureCloudDomain':
            logLevel = 'error';
            logMessage = "".concat(logMessageAppType, " is being ignored due to use of a PC reserved domain.");
            break;

          case 'malformedUrl':
            logLevel = 'error';
            logMessage = "".concat(logMessageAppType, " is being ignored due to an unparsable URL.");
            break;

          case 'unexpectedInterapptionProperty':
            logLevel = 'error';
            logMessage = "".concat(logMessageAppType, " is being ignored due to an unexpected Interaction Widget property.");
            break;

          default:
            logLevel = 'error';
            logMessage = "".concat(logMessageAppType, " is being ignored");
            break;
        }

        var fullLogMsg = null;

        if (appType === 'Interapption') {
          fullLogMsg = _apps2.default.buildInterapptionsLogMsg(logMessage);
        } else {
          fullLogMsg = _apps2.default.buildLogMsg(logMessage);
        }

        this.get("stashLogger.".concat(logLevel))(fullLogMsg, {
          appUrl: app.get('url'),
          remoteData: {
            appId: app.get('id')
          }
        });
        return false;
      }
    },
    _validateApp: function _validateApp(app) {
      if (Ember.typeOf(app.typeId) !== 'string' || app.typeId.trim().length === 0) {
        throw new Error('invalidTypeId');
      }

      if (Ember.typeOf(app.id) !== 'string' || app.id.trim().length === 0) {
        throw new Error('invalidId');
      }

      if (Ember.typeOf(app.name) !== 'string' || app.name.trim().length === 0) {
        throw new Error('invalidName');
      }

      if (Ember.typeOf(app.displayType) !== 'string' || app.displayType.trim().length === 0) {
        throw new Error('invalidDisplayType');
      }

      if (app instanceof _internallyHostedApp.default) {
        var internalAppPath = app.get('path');

        if (Ember.typeOf(internalAppPath) !== 'string' || Ember.isBlank(internalAppPath)) {
          throw new Error('invalidPath');
        } // subdomain can be null, undefined, or non-empty String


        var internalAppSubdomain = app.get('subdomain');
        var subdomainType = Ember.typeOf(internalAppSubdomain);

        if (!(subdomainType === 'null' || subdomainType === 'undefined' || subdomainType === 'string' && internalAppSubdomain.trim().length > 0)) {
          throw new Error('invalidSubdomain');
        } // query can be null, undefined, or a string (blank allowed)


        var internalAppQuery = app.get('query');

        if (['null', 'undefined', 'string'].indexOf(Ember.typeOf(internalAppQuery)) < 0) {
          throw new Error('invalidQuery');
        }
      }

      if (Ember.isBlank(app.get('url'))) {
        throw new Error('missingUrl');
      } // Enforce app URL protocol of http/https (http should primarily be used for development)


      if (APP_PROTOCOL_CHECK_REG_EXG.exec(app.get('url')) === null) {
        throw new Error('invalidUrlProtocol');
      }

      var externalUrl = null;

      try {
        // IMPORTANT: In addition to checking internal/external, this checks the validity of the url.  It must be run regardless of app type
        externalUrl = _net.default.isExternalDomain(app.get('url'));
      } catch (invalidUrl) {
        throw new Error('malformedUrl');
      }

      if (!externalUrl && !(app instanceof _internallyHostedApp.default) && this.get('externalUrlExemptIntegrationTypeIds').indexOf(app.get('typeId')) < 0) {
        // Disallow internal URLs on external apps due to sandbox concerns
        throw new Error('reservedPureCloudDomain');
      } // Interapptions validation


      if (app.displayType !== _app.DISPLAY_TYPES.INTERAPPTION) {
        ['interactionId', 'interactionType', 'enabledQueueIds', 'enabledCommunicationTypes', 'enableWorkitem'].forEach(function (currKey) {
          if (app.get(currKey) !== undefined && app.get(currKey) !== null) {
            throw new Error('unexpectedInterapptionProperty');
          }
        });
      }

      return true;
    },
    _isInternallyHostedApp: function _isInternallyHostedApp(integrationTypeId) {
      return Ember.get(_environment.default, 'purecloud-apps.internallyHostedIntegrationTypeIds').indexOf(integrationTypeId) >= 0;
    },
    _parseProp: function _parseProp(obj, prop) {
      var validItems = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var propVal = Ember.get(obj, prop);

      if (propVal && (validItems === null || validItems.indexOf(propVal) >= 0)) {
        return propVal;
      }
    },
    _parseBooleanProp: function _parseBooleanProp(obj, prop) {
      var propVal = Ember.get(obj, prop);

      if (typeof propVal === 'boolean') {
        return propVal;
      }

      return undefined;
    },
    _parseMultiValueProp: function _parseMultiValueProp(obj, prop) {
      var validItems = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var separator = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ',';
      var result = [];
      var propVal = Ember.get(obj, prop);

      if (propVal) {
        result = propVal.split(separator).reduce(function (validatedItems, curr) {
          if (curr) {
            var toCheck = curr.trim();

            if (toCheck.length > 0 && (validItems === null || validItems.indexOf(toCheck) >= 0)) {
              validatedItems.push(toCheck);
            }
          }

          return validatedItems;
        }, []);
      }

      return result;
    },
    // Storage for previous langTag regexp instances
    _langTagRegExps: {},

    /**
     * Leniently locate a langTag-specific property within the specified object and baseProp. The
     * provided langTag will searched in a padding space and case insensitive manor.
     *
     * @param {Object} obj - The base object to search
     * @param {String} baseProp - single or dot-separated list of hierarchical props identifying the base object to search
     * @param {String} langTag - the bcp47 langTag to search for within obj[baseProp]
     * @param {String} langTagRelProp - single or dot-separated list of hierarchical props identifying the langTag-relative property to return
     *
     * @returns the value of the prop if it is found for the current locale; undefined otherwise.
     */
    _parseLangTagProp: function _parseLangTagProp(obj, baseProp, langTag, langTagRelProp) {
      if (Ember.typeOf(langTag) !== 'string' || !langTag.trim()) {
        // Log and return undefined as this is an internal method. No need to throw and handle outside
        this.get('stashLogger').warn(_apps2.default.buildLogMsg('Missing/Invalid langTag provided to parse langTag specific prop', {
          remoteData: {
            langTag: langTag
          }
        }));
        return undefined;
      }

      var result = Ember.get(obj, "".concat(baseProp, ".").concat(langTag, ".").concat(langTagRelProp));

      if (typeof result === 'undefined') {
        // Check for lenient langTags
        var baseObj = Ember.get(obj, baseProp);

        if (baseObj) {
          var lenientLangTag = langTag.trim().toLowerCase();
          var langTagRegExp = this._langTagRegExps[lenientLangTag];

          if (!langTagRegExp) {
            langTagRegExp = new RegExp("^\\s*".concat(lenientLangTag, "\\s*$"), 'i');
            this._langTagRegExps[lenientLangTag] = langTagRegExp;
          }

          var langKey = Object.keys(baseObj).find(function (key) {
            return langTagRegExp.test(key);
          });

          if (Ember.typeOf(langKey) === 'string') {
            result = Ember.get(baseObj, "".concat(langKey, ".").concat(langTagRelProp));
          }
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});