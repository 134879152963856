define("web-directory/utils/acd-ts", ["exports", "web-directory/utils/types"], function (_exports, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COMMUNICATION_TYPE_SINGULAR = void 0;
  _exports.getQueueIdFromAppContext = getQueueIdFromAppContext;
  _exports.singularizeMediaType = singularizeMediaType;
  _exports.warnClosureAction = warnClosureAction;
  var COMMUNICATION_TYPE_SINGULAR = ['call', 'email', 'message', 'chat', 'callback'];
  _exports.COMMUNICATION_TYPE_SINGULAR = COMMUNICATION_TYPE_SINGULAR;

  /**
   * Issue warning that closure action was not provided to component.
   * @param {string} actionName The name of closure function that was not provided
   */
  function warnClosureAction(actionName) {
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var message = "ACD: ".concat(actionName, " closure action has not been set up for this component.");
    console.warn(message, {
      arguments: args
    });
  }

  function singularizeMediaType(type) {
    return type.substring(0, type.length - 1);
  }
  /**
   * Retrieves the queue ID from the given app context.
   *
   * This function extracts the queue ID based on the type of the app context.
   * For 'conversation' type, it attempts to retrieve the queue ID from the participant payload.
   * For 'workitem' type, it retrieves the queue ID directly from the workitem payload.
   * If the type is not recognized, it asserts an error.
   */


  function getQueueIdFromAppContext(_ref) {
    var type = _ref.type,
        payload = _ref.payload;

    switch (type) {
      case 'conversation':
        {
          return payload.get('participantForCurrentUser.queueId') || payload.get('participantForQueue.queueId');
        }

      case 'workitem':
        {
          return payload.workitem.queueId;
        }

      default:
        {
          (0, _types.assertNever)(type);
        }
    }
  }
});