define("web-directory/routes/custom-fields-orgs-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var isToggleEnabled = this.get('session.features')['ACT-851_custom-fields-ui'];

      if (isToggleEnabled) {
        return this.replaceWith('/admin/custom-fields-v2/external-orgs');
      }

      return this.transitionTo('custom-fields.data-models.external-orgs');
    }
  });

  _exports.default = _default;
});