define("web-directory/components/command-panel/panel-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hdpLk30l",
    "block": "{\"statements\":[[9,\"gux-tabs-advanced\",{\"attrs\":[[16,\"active-tab\",[26,[\"currentDrawerId\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n    \"],[9,\"gux-tab-advanced-list\",{\"attrs\":[[15,\"slot\",\"tab-list\"],[15,\"show-new-tab-button\",\"false\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n\"],[6,[\"each\"],[[28,[\"definition\",\"drawers\"]]],null,{\"statements\":[[0,\"            \"],[9,\"gux-tab-advanced\",{\"attrs\":[[16,\"tab-id\",[28,[\"drawer\",\"id\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n                \"],[1,[28,[\"drawer\",\"name\"]],false],[0,\"\\n            \"]]}],[0,\"\\n\"]],\"locals\":[\"drawer\"]},null],[0,\"    \"]]}],[0,\"\\n\"],[6,[\"each\"],[[28,[\"definition\",\"drawers\"]]],null,{\"statements\":[[0,\"        \"],[9,\"gux-tab-advanced-panel\",{\"attrs\":[[16,\"tab-id\",[28,[\"drawer\",\"id\"]],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n            \"],[1,[33,[\"component\"],[[28,[\"drawer\",\"layout\"]]],[[\"definition\"],[[28,[\"definition\"]]]]],false],[0,\"\\n        \"]]}],[0,\"\\n\"]],\"locals\":[\"drawer\"]},null]]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/command-panel/panel-navigation/template.hbs"
    }
  });

  _exports.default = _default;
});