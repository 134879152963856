define("web-directory/components/dropdown-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OgLgznqI",
    "block": "{\"statements\":[[11,\"button\",[]],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-purecloud\"],[16,\"onkeydown\",[33,[\"action\"],[[28,[null]],\"handleKeyDown\"],null],null],[5,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],\"toggleMenu\"],null]]],[13],[0,\"\\n    \"],[11,\"div\",[]],[13],[1,[26,[\"title\"]],false],[14],[0,\"\\n    \"],[11,\"i\",[]],[15,\"class\",\"ion-arrow-down-b\"],[13],[14],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"ul\",[]],[16,\"class\",[34,[\"dropdown-menu list-unstyled \",[33,[\"if\"],[[28,[\"showMenu\"]],\"show\"],null]]]],[16,\"onkeydown\",[33,[\"action\"],[[28,[null]],\"handleKeyDown\"],null],null],[13],[0,\"\\n    \"],[18,\"default\"],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/dropdown-menu/template.hbs"
    }
  });

  _exports.default = _default;
});