define("web-directory/components/station-picker-v2/component", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      run = Ember.run;
  var STATIONS_URI = 'api/v2/stations';

  var _default = Ember.Component.extend({
    classNames: ['station-picker-v2'],
    classNameBindings: ['userDropdown:station-picker-in-dropdown'],
    ajax: inject.service(),
    application: inject.service(),
    session: inject.service(),
    phone: inject.service(),
    store: inject.service(),
    throttleTime: 1000,
    nextUri: null,
    prefetchComplete: null,
    stations: null,
    currentRequest: null,
    pageAttempts: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.prefetch();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      run.cancel(this.get('throttleSearch'));
      Ember.$(this.get('scrollEventEl')).off();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var $stationList = this.$('.station-list');

      if ($stationList.length) {
        this.attachScrollListener();
      }

      this.$('.search-for-stations').trigger('focus');
    },
    defaultStationNotCurrentStation: computed('defaultStation.id', 'station.id', function () {
      var defaultStationId = this.get('defaultStation.id');
      var currentStationId = this.get('station.id');
      return defaultStationId !== currentStationId;
    }),
    defaultStationInUse: computed('defaultStation.associatedUser.id', 'session.person.guid', function () {
      var defaultStationUserId = this.get('defaultStation.associatedUser.id');
      return defaultStationUserId && defaultStationUserId !== this.get('session.person.guid');
    }),
    hasDefaultStation: computed.bool('defaultStation.id'),
    prefetch: function prefetch() {
      var _this = this;

      return this._doFetch().then(function () {
        _this.set('prefetchComplete', true);
      });
    },
    filterSearch: function filterSearch() {
      var searchValue = this.get('searchValue');

      this._doFetch({
        searchValue: searchValue
      });
    },
    fetchNext: function fetchNext() {
      var uri = this.get('nextUri');
      return this._doFetch({
        uri: uri,
        push: true
      });
    },
    _doFetch: function _doFetch(opts) {
      var _this2 = this;

      opts = opts || {};
      var _opts = opts,
          searchValue = _opts.searchValue,
          uri = _opts.uri,
          push = _opts.push;
      var baseUrl = this.get('application').pcV2Uri(STATIONS_URI);
      var queryParams = new URLSearchParams();
      var url;

      if (this.get('session.features.TC-351')) {
        queryParams.append('userSelectable', 'true');

        if (searchValue) {
          queryParams.append('name', searchValue);
        }
      } else if (searchValue) {
        queryParams.append('name', "*".concat(searchValue, "*"));
      } // If we have a URI, we're refetching the next set of results and should use that URI instead of building one.


      url = baseUrl + (!uri ? "?".concat(queryParams.toString()) : uri);

      if (!push) {
        this.set('stations', Ember.A());
      } // When the request resolves, if it doesn't match the current request, it means another request was made later,
      // so we can ignore the resuls to ensure we don't override latest with previous query results


      var currentRequest = _uuid.default.v4();

      this.set('currentRequest', currentRequest);
      return this.get('ajax').request(url).then(function (response) {
        var mappedEntities = [];

        if (_this2.get('currentRequest') !== currentRequest) {
          Ember.Logger.debug('[station-picker]', 'Request cancelled, tossing results.');
          return;
        }

        if (_this2.get('session.features.TC-351')) {
          // We don't need to filter the results ourselves with TC-351
          mappedEntities = response.entities;
        } else {
          mappedEntities = _this2._mapStations(response.entities);
        }

        _this2.setNextUri(response);

        var stations = _this2.get('stations');

        if (push) {
          // Append the next set of results.
          stations.pushObjects(mappedEntities);
        } else {
          _this2.set('stations', mappedEntities);
        }

        return _this2._maybeRefetch(mappedEntities);
      });
    },
    // When results come back, we filter out webrtc stations that the user can't actually use, so we may end up with an empty page.
    // If that happens, just fetch the next page automatically. Ensures we get at least one result on prefetch.
    // Limit this to 3 attempts so we don't recurse indefinitely forever
    _maybeRefetch: function _maybeRefetch(mappedEntities) {
      var pageAttempts = this.get('pageAttempts');

      if (pageAttempts < 3 && mappedEntities.length === 0) {
        this.set('pageAttempts', pageAttempts + 1);
        return this.fetchNext();
      } else {
        this.set('pageAttempts', 0);
      }
    },
    _mapStations: function _mapStations(entities) {
      var _this3 = this;

      // this is not returning station models, so the webrtc property needs adapted
      return entities.filter(function (e) {
        if (e.type !== 'inin_webrtc_softphone') {
          return true;
        }

        return e.lineAppearanceId === _this3.get('session.person.jid').replace('@', '+');
      }).map(function (e) {
        var userId = e.userId;

        if (userId) {
          _this3.get('store').find('person', userId).then(function (person) {
            return Ember.set(e, 'username', person.get('name'));
          });
        }

        Ember.set(e, 'persistentConnection', e.webRtcPersistentEnabled);
        var associated = e.status === 'ASSOCIATED' ? true : false;
        Ember.set(e, 'associated', associated);
        return e;
      });
    },
    setNextUri: function setNextUri(response) {
      // The API will return the next URI, we just need to trim it.
      if (response.nextUri) {
        var nextUri = response.nextUri.match(/(\?.*)$/)[1];
        this.set('nextUri', nextUri);
      } else {
        this.set('nextUri', null);
      }
    },
    shouldFetchNextBatch: function shouldFetchNextBatch() {
      var $el = this.$('.station-list');
      var offset = 50;
      var scrollHeight = $el.height() + $el.scrollTop();
      var isNext = this.get('nextUri');

      if ($el[0].scrollHeight - scrollHeight < offset && isNext) {
        this.fetchNext();
      }
    },
    attachScrollListener: function attachScrollListener() {
      var _this4 = this;

      var scrollEventEl = '.station-list';
      var $el = this.$(scrollEventEl);
      this.set('scrollEventEl', scrollEventEl);
      return $el.on('scroll', function () {
        run.throttle(_this4, 'shouldFetchNextBatch', 1000, false);
      });
    },
    selectStation: function selectStation(station) {
      var _this5 = this;

      if (!station || station.associated) {
        return false;
      }

      var stationId = Ember.get(station, 'id');
      var uri = this.get('application').pcV2Uri("/api/v2/stations/".concat(stationId));
      this.get('selectPhone')(uri).then(function () {
        _this5.get('closeStationPicker')();
      });
    },
    actions: {
      closeStationPicker: function closeStationPicker() {
        this.get('closeStationPicker')();
      },
      search: function search() {
        // TODO: handle other keyup events, down arrow, enter, and so on.
        this.set('searchValue', this.$('.search-for-stations').val());
        this.set('throttleSearch', run.throttle(this, 'filterSearch', this.get('throttleTime'), false));
      },
      selectStation: function selectStation(station) {
        this.selectStation(station);
      },
      useDefaultStation: function useDefaultStation(e) {
        e.preventDefault();
        var station = Ember.Object.create({
          id: this.get('defaultStation.id'),
          associated: this.get('defaultStationInUse')
        });
        this.selectStation(station);
      }
    }
  }).reopenClass({
    positionalParams: ['defaultStation', 'station']
  });

  _exports.default = _default;
});