define("web-directory/components/command-bar/component", ["exports", "web-directory/services/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['command-bar'],
    events: Ember.inject.service('events'),
    interaction: Ember.inject.service('interaction'),
    session: Ember.inject.service('session'),
    //@ts-ignore
    presence: Ember.inject.service('presence'),
    //@ts-ignore
    inbox: Ember.inject.service('inbox'),
    routerService: null,
    pageTitle: null,
    titleObserver: null,
    sidebarToggled: false,
    navIsOpen: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('routerService', Ember.getOwner(this).lookup('router:main'));
      this.set('pageTitle', window.document.title);
      var titleElem = document.querySelector('title');
      var titleObs = new MutationObserver(function () {
        _this.set('pageTitle', window.document.title);
      });
      var obsOptions = {
        subtree: true,
        characterData: true,
        childList: true
      };
      titleObs.observe(titleElem, obsOptions);
      this.set('titleObserver', titleObs);
    },
    destroy: function destroy() {
      if (this.get('titleObserver')) {
        this.get('titleObserver').disconnect();
      }
    },
    setPresence: function setPresence(presence) {
      // @ts-ignore
      this.get('presence').setUsersPresence(this.get('session.user'), presence);
    },
    toggleQueue: function toggleQueue() {
      // maybe even cruder
      // @ts-ignore
      if (this.get('interaction.isOnQueue')) {
        this.setPresence('available');
        return;
      }

      this.setPresence('on_queue'); // @ts-ignore

      this.routerService.transitionTo('agent-ui');
    },
    actions: {
      goBack: function goBack() {
        // this is crude, but ok for now
        window.history.back();
      },
      togglePanel: function togglePanel(panel) {
        this.get('events').triggerEvent(_events.TOGGLE_EVENT, {
          panel: panel
        });
      },
      navigateToSearch: function navigateToSearch() {
        var routerService = this.get('routerService'); // @ts-ignore

        routerService.transitionTo('search');
      },
      toggleOnQueue: function toggleOnQueue(evt) {
        // Allowing Enter Key and Space to toggle
        // in the event there is no key it's the onclick event being triggered
        if (evt.key === 'Enter' || evt.key === ' ' || !evt.key) {
          this.toggleQueue();
        }
      },
      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('sidebarToggled');
      }
    }
  });

  _exports.default = _default;
});