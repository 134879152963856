define("web-directory/components/preferences-chat/component", ["exports", "lodash", "web-directory/utils/collab-chat-constants"], function (_exports, _lodash, _collabChatConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;
  var preferenceDefaults = {
    soundsMuted: false,
    mediaExpanded: true,
    sortedAlpha: 'recent',
    displayDensity: 'comfortable',
    giphyKeyboard: 'collapsed'
  };

  var _default = Component.extend({
    classNames: ['chat-preferences'],
    session: inject.service(),
    preferences: inject.service(),
    intl: inject.service(),
    settings: Ember.inject.service(),
    chatFrame: Ember.inject.service(),
    useCollaborateChat: computed.readOnly('session.useCollaborateChat'),
    actions: {
      selectRosterOrder: function selectRosterOrder(value) {
        this.set('sortedAlpha', value);
        return this.communicateAndPersistPreference('sortedAlpha', value);
      },
      selectDisplayDensity: function selectDisplayDensity(value) {
        this.set('displayDensity', value);
        return this.communicateAndPersistPreference('displayDensity', value);
      },
      selectMediaExpanded: function selectMediaExpanded(value) {
        this.set('mediaExpanded', value);
        value = this.mediaToBoolean(value);
        return this.communicateAndPersistPreference('mediaExpanded', value);
      },
      changeSoundsMuted: function changeSoundsMuted() {
        this.toggleProperty('soundsMuted');
        return this.communicateAndPersistPreference('soundsMuted', this.get('soundsMuted'));
      },
      changeGithubEmbeds: function changeGithubEmbeds() {
        this.toggleProperty('showGithubEmbeds');
        return this.communicateAndPersistPreference('showGithubEmbeds', this.get('showGithubEmbeds'));
      },
      changeGiphyDefaultState: function changeGiphyDefaultState(value) {
        this.set('giphyKeyboard', value);
        return this.communicateAndPersistPreference('giphyKeyboard', value);
      },
      openSoundPreferences: function openSoundPreferences() {
        this.get('settings').openSoundPreference();
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('loading', true);

      this._loadPreferences().then(function () {
        Ember.run.scheduleOnce('afterRender', function () {
          Ember.trySet(_this, 'loading', false);
        });
      });
    },
    _loadPreferences: function _loadPreferences() {
      var _this2 = this;

      var preferencesService = this.get('preferences');
      var promises = [preferencesService.getPreference('chat.global').then(function (preferences) {
        preferences = preferences || {};
        /**
         * Settings api is returning bad preferences data so we are manually
         * deleting `showGithubEmbeds` to set it properly later on.
         **/

        if (_this2.get('useCollaborateChat')) {
          delete preferences.showGithubEmbeds;
        }

        _lodash.default.forOwn(preferenceDefaults, function (value, key) {
          if (!Object.prototype.hasOwnProperty.call(preferences, key)) {
            preferences[key] = value;
          }
        });

        if (typeof preferences.mediaExpanded === 'boolean') {
          preferences.mediaExpanded = _this2.booleanToMediaState(preferences.mediaExpanded);
        }

        if (!_this2.isDestroyed) {
          _this2.setProperties(preferences);
        }
      }), preferencesService.getPreference('widgets').then(function (settings) {
        var embed = null;

        if (settings && settings.githubRepo) {
          embed = settings.githubRepo.embed;
        }

        if (!_this2.isDestroyed) {
          _this2.set('showGithubEmbeds', embed);
        }
      })];
      return Ember.RSVP.all(promises);
    },
    mediaExpandedOptions: computed(function () {
      return [{
        value: 'mediaCollapsed',
        label: this.get('intl').t('preferences.chat.mediaCollapsed')
      }, {
        value: 'mediaExpanded',
        label: this.get('intl').t('preferences.chat.mediaExpanded')
      }];
    }),
    displayDensityOptions: computed(function () {
      return [{
        value: 'comfortable',
        label: this.get('intl').t('preferences.chat.displayDensity.comfortable')
      }, {
        value: 'compact',
        label: this.get('intl').t('preferences.chat.displayDensity.compact')
      }, {
        value: 'compact minimal',
        label: this.get('intl').t('preferences.chat.displayDensity.minimal')
      }];
    }),
    sortingOptions: computed(function () {
      return [{
        value: 'alpha',
        label: this.get('intl').t('chat.roster.settings.aToZ')
      }, {
        value: 'recent',
        label: this.get('intl').t('chat.roster.settings.recent')
      }];
    }),
    giphyKeyboardOptions: computed(function () {
      return [{
        value: 'collapsed',
        label: this.get('intl').t('preferences.chat.mediaCollapsed')
      }, {
        value: 'expanded',
        label: this.get('intl').t('preferences.chat.mediaExpanded')
      }, {
        value: 'rememberState',
        label: this.get('intl').t('preferences.chat.rememberState')
      }];
    }),
    notDesktop: computed(function () {
      return !this.get('applicationService.hostedContext').isHosted();
    }),
    showNotificationsSection: computed('notDesktop', function () {
      return this.get('notDesktop');
    }),
    persistPreference: function persistPreference(key, value) {
      var preferences = this.get('preferences');

      if (key === 'showGithubEmbeds') {
        return preferences.getPreference('widgets').then(function () {
          var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

          if (!prefs.githubRepo) {
            prefs.githubRepo = {};
          }

          prefs.githubRepo.embed = value;
          return preferences.setPreference('widgets', prefs);
        });
      } else {
        return preferences.getPreference('chat.global').then(function () {
          var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          prefs[key] = value;
          return preferences.setPreference('chat.global', prefs);
        });
      }
    },
    getPreferenceSettings: function getPreferenceSettings() {
      var preferences = this.getProperties('displayDensity', 'sortedAlpha', 'soundsMuted');
      preferences.mediaExpanded = this.get('mediaExpanded') === 'mediaExpanded';
      return preferences;
    },
    booleanToMediaState: function booleanToMediaState(value) {
      var state = value ? 'expanded' : 'collapsed';
      return "media".concat(_lodash.default.capitalize(state));
    },
    mediaToBoolean: function mediaToBoolean(value) {
      return value === 'mediaExpanded';
    },
    communicateAndPersistPreference: function communicateAndPersistPreference(preferenceKey, value) {
      this.persistPreference(preferenceKey, value); // Needed to set local global preference values for display
      // if collab chat is being used, notify it of preference update to keep in sync

      if (this.get('useCollaborateChat')) {
        return this.get('chatFrame').notifyCollaborateChatIFrame(_collabChatConstants.COLLABORATE_CHAT_EVENTS.publish.updateChatPreference, {
          value: value,
          preferenceKey: preferenceKey
        });
      }
    }
  });

  _exports.default = _default;
});