define("web-directory/routes/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    routeTitle: function routeTitle() {
      return this.get('intl').lookup('components.navMenu.search');
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var isToggleEnabled = this.get('session.features')['ATLAS-467_integrate-connections-ui'];

      if (isToggleEnabled) {
        var path = transition.intent.url || '';
        path = path.replace(/search/, 'search-v2');
        return this.replaceWith(path);
      }
    },
    model: function model() {
      var sidebar = this.get('sidebarService');

      if (sidebar) {
        sidebar.set('routeTitle', this.get('intl').lookup('components.navMenu.search'));
      }
    },
    afterModel: function afterModel() {
      var search = this.get('intl').lookup('components.navMenu.search');
      var name = this.get('session.org.name');
      this.set('routeTitle', "".concat(search, " - ").concat(name));
    }
  });

  _exports.default = _default;
});