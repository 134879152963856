define("web-directory/services/permissions", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkDonutPermission = checkDonutPermission;
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Service = Ember.Service;
  var WILDCARD = '*';

  function isPermissionInList(item, targetList) {
    var inList = item === WILDCARD || targetList === WILDCARD;

    if (!inList) {
      var listItems = targetList.split(',');
      inList = _lodash.default.includes(listItems, item);
    }

    return inList;
  }
  /**
   * Compare provided permission divisions against a secific division
   *
   * @item the division to look for
   * @targetList comma-delimited string representation of permission divisions
   */


  function shouldAllowBasedOnDivision(item, targetList) {
    // if the wildcard is present in permission list
    // if no divisions are returned from PAPI
    // if the requested division is the WILDCARD
    // if the requested division was an empty string
    // -- bypass the division check
    if (targetList.length === 0 || targetList === WILDCARD || item === WILDCARD || item.length === 0) {
      return true;
    }

    var divisions = targetList.split(',');
    return divisions.includes(item);
  }

  function isAllowedOrgPermission(allowedOrgPermissions, targetDomain, targetEntity, targetAction) {
    var domainPermissionMap = _lodash.default.head(_lodash.default.map(_lodash.default.filter(allowedOrgPermissions, {
      domain: targetDomain
    }), 'permissionMap'));

    domainPermissionMap = _lodash.default.flatten(_lodash.default.map(domainPermissionMap, function (value) {
      return value;
    }));
    return domainPermissionMap && domainPermissionMap.length && (targetEntity === WILDCARD || _lodash.default.find(domainPermissionMap, {
      entityType: targetEntity
    })) && (targetAction === WILDCARD || _lodash.default.find(domainPermissionMap, {
      action: targetAction
    }));
  }

  function checkDonutPermission(permissions, permissionValue, allowedOrgPermissions, targetDivision) {
    var isAllowed = false;

    if (!permissions) {
      permissions = [];
    } //change domain:* -> domain:*:*


    if (permissionValue.match(/^[a-zA-Z0-9]+:\*$/)) {
      permissionValue = permissionValue.replace('*', '*:*');
    }

    var permissionsToValidate = permissionValue.split(':'),
        targetDomain = permissionsToValidate[0],
        targetEntity = permissionsToValidate[1],
        targetAction = permissionsToValidate[2];

    if (isAllowedOrgPermission(allowedOrgPermissions, targetDomain, targetEntity, targetAction)) {
      permissions.forEach(function (permission) {
        var permissions = permission.split(':'),
            domain = permissions[0],
            entity = permissions[1],
            actionSet = permissions[2],
            divisions = permissions[3] || '';

        if (targetDomain === domain) {
          var matchesEntity = isPermissionInList(targetEntity, entity);
          var matchesAction = isPermissionInList(targetAction, actionSet);
          var matchesDivision = shouldAllowBasedOnDivision(targetDivision, divisions);

          if (matchesEntity && matchesAction && matchesDivision) {
            isAllowed = true;
          }
        }
      });
    }

    return isAllowed;
  }

  var _default = Service.extend(_logger.default, {
    session: inject.service(),
    adminAccess: inject.service(),
    permissions: computed.reads('session.permissions'),
    rolePermissions: computed.reads('session.roleNames'),
    //Deprecated
    allowedOrgPermissions: computed.reads('session.allowedOrgPermissions'),
    trusteeOrg: computed.reads('session.trusteeOrg'),
    hasUnifiedCommunications: computed('session.org.hasUnifiedCommunications', 'trusteeOrg', function () {
      return !!(this.get('session.org.hasUnifiedCommunications') || this.get('trusteeOrg.features.unifiedCommunications'));
    }),
    directoryPermissions: computed('permissions.[]', function () {
      var permissions = this.get('permissions');
      return _lodash.default.filter(permissions, function (permission) {
        return _lodash.default.includes(permission, '_');
      });
    }),
    donutPermissions: computed('permissions.[]', function () {
      var permissions = this.get('permissions');
      return _lodash.default.filter(permissions, function (permission) {
        return _lodash.default.includes(permission, ':');
      });
    }),
    hasSupervisorAccess: computed('rolePermissions', 'donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:queue:edit');
    }),
    validateDonutPermission: function validateDonutPermission(permission) {
      var division = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '*';
      var isAuthenticated = this.get('session.isAuthenticated');
      var logger = this.get('logger');

      if (!isAuthenticated) {
        logger.debug('attempted to check a permission before authenticated', {
          permission: permission
        });
      }

      if (typeof division !== 'string') {
        logger.error('Division argument for permission check must be a string.');
        return false;
      }

      return checkDonutPermission(this.get('donutPermissions'), permission, this.get('allowedOrgPermissions'), division.trim());
    },
    validateAllPermissions: function validateAllPermissions(permissionsList) {
      var _this = this;

      return (permissionsList || []).every(function (permission) {
        return _this.validateDonutPermission(permission);
      });
    },
    validateAnyPermissions: function validateAnyPermissions(permissionsList) {
      var _this2 = this;

      var permissionsSet = permissionsList || [];
      return permissionsSet.some(function (permission) {
        return _this2.validateDonutPermission(permission);
      });
    },
    canCreateGroups: computed('directoryPermissions.[]', 'donutPermissions.[]', function () {
      var permissions = this.get('directoryPermissions');

      var hasDirectoryPermissions = _lodash.default.includes(permissions, 'group_creation') || _lodash.default.includes(permissions, 'group_administration');

      var hasDonutPermission = this.validateDonutPermission('directory:group:add');
      return hasDirectoryPermissions || hasDonutPermission;
    }),
    canDeleteGroups: computed('directoryPermissions.[]', 'donutPermissions', function () {
      var permissions = this.get('directoryPermissions');

      var hasDirectoryPermissions = _lodash.default.includes(permissions, 'group_administration');

      var hasDonutPermission = this.validateDonutPermission('directory:group:edit');
      return hasDirectoryPermissions || hasDonutPermission;
    }),
    canInvitePeople: computed('directoryPermissions.[]', function () {
      var permissions = this.get('directoryPermissions');
      return _lodash.default.includes(permissions, 'person_administration');
    }),
    canGoOnQueue: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:agent:onqueue');
    }),
    canUseGiphy: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('chat:giphy:access');
    }),
    canViewTelemetry: computed('donutPermissions', function () {
      return this.validateDonutPermission('ui:networkPerformanceIndicators:view');
    }),
    canPark: computed('donutPermissions', 'session.useParkEmail', function () {
      if (this.get('session.useParkEmail')) {
        return this.validateDonutPermission('conversation:email:park');
      }

      return false;
    }),
    canViewInteractionDetails: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:conversationDetail:view') && this.validateDonutPermission('analytics:conversationAggregate:view') && (this.validateDonutPermission('conversation:communication:view') || this.validateDonutPermission('quality:evaluation:add') || this.validateDonutPermission('quality:calibration:view') || this.validateDonutPermission('quality:evaluation:editScore'));
    }),
    canViewExternalContactInteractionHistory: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:conversationDetail:view') || this.validateDonutPermission('externalContacts:conversation:viewAll');
    }),
    canActivateQueues: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:queue:join');
    }),
    canEditQueues: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:queue:edit');
    }),
    canScreenRecordingParticipate: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('recording:screenRecording:participate');
    }),
    canSecurePause: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:recording:pause');
    }),
    canViewCannedResponses: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('responses:library:view');
    }),
    canViewQueue: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:queue:view');
    }),
    canSearchQueue: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:queue:search');
    }),
    canViewWrapUpCode: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:wrapupCode:view');
    }),
    canViewEvaluationForms: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:evaluationForm:view');
    }),
    canViewSurveyForms: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:surveyForm:view');
    }),
    canScheduleCallback: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:callback:create');
    }),
    canViewCases: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('case:case:view');
    }),
    canViewQueueObservations: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:queueObservation:view');
    }),
    canViewQueuePerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:supervisorQueueDetails:view') && this.validateDonutPermission('analytics:conversationAggregate:view') && this.get('canViewQueue');
    }),
    canViewSurveysPerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:surveyForm:view') && this.validateDonutPermission('analytics:surveyAggregate:view');
    }),
    canViewSurveysPerformanceDetail: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:surveyForm:view') && this.validateDonutPermission('analytics:surveyAggregate:view');
    }),
    canViewSkillsPerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:supervisorPerformance:view') && this.validateDonutPermission('analytics:conversationAggregate:view') && this.get('canViewQueuePerformance');
    }),
    canViewWrapupPerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:supervisorWrapUpPerformance:view') && this.get('canViewQueuePerformance') && this.get('canViewWrapUpCode');
    }),
    canViewDnisPerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:supervisorDnisPerformance:view') && this.validateDonutPermission('analytics:conversationAggregate:view') && this.get('canViewQueuePerformance');
    }),
    canViewFlowPerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.get('canViewFlowAggregates') && this.get('canViewFlows') && this.get('canViewInsightConfigurations') && this.get('canSearchFlows');
    }),
    canViewOutcomePerformance: computed('donutPermisions', 'allowedOrgPermissions', function () {
      return this.get('canViewFlowAggregates') && this.get('canViewOutcomes') && this.get('canViewFlows') && this.get('canSearchFlows');
    }),
    canViewOutcomes: computed('donutPermissions', function () {
      return this.validateDonutPermission('architect:flowOutcome:view');
    }),
    canViewAgentDevelopment: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return (this.validateDonutPermission('coaching:appointment:view') || this.validateDonutPermission('learning:assignment:view')) && this.get('hasAgentSupervisorAccess');
    }),
    canViewAgentPerformance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.get('hasAgentSupervisorAccess') && this.validateDonutPermission('analytics:conversationAggregate:view');
    }),
    canViewAgentPerformanceMe: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:conversationAggregate:view');
    }),
    canViewAgentStatus: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.get('hasAgentSupervisorAccess') && this.validateDonutPermission('analytics:userAggregate:view');
    }),
    canViewAgentWrapup: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.get('hasAgentSupervisorAccess') && this.get('canViewWrapUpCode') && this.validateDonutPermission('ui:supervisorWrapUpPerformance:view');
    }),
    canViewAgentStatusMe: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:userAggregate:view');
    }),
    canViewAgentEvaluations: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.get('hasAgentSupervisorAccess') && this.validateDonutPermission('quality:evaluation:view');
    }),
    canViewAgentEvaluationsMe: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:evaluation:view');
    }),
    canViewQueueActivity: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:supervisorQueueDetails:view') && this.validateDonutPermission('analytics:queueObservation:view') && this.validateDonutPermission('analytics:conversationAggregate:view') && this.validateDonutPermission('analytics:conversationDetail:view') && this.get('canViewQueue');
    }),
    canViewAgentQueueActivity: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:queueObservation:view') && this.validateDonutPermission('analytics:conversationAggregate:view') && this.validateDonutPermission('ui:agentQueueActivity:view') && this.get('canViewQueue');
    }),
    canViewInsightConfigurations: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:insightConfigurations:view');
    }),
    canViewDashboardConfigurations: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:dashboardConfigurations:view');
    }),
    canEditDashboardConfigurations: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:dashboardConfigurations:edit');
    }),
    canMakePublicDashboards: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:dashboardConfigurations:publish');
    }),
    canViewSkillsAndLanguages: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:skill:manage');
    }),
    canViewAlerting: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('alerting:alert:*') || this.validateDonutPermission('alerting:rule:*');
    }),
    canViewAcdScreenShare: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('acdscreenshare:session:view');
    }),
    canViewAcdCoBrowseForCall: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('cobrowse:session:view') && this.validateDonutPermission('cobrowse:voice:escalate');
    }),
    canViewRecordings: computed('donutPermissions', 'allowedOrgPermissions', function () {
      if (this.get('session.features')['hideAdhocRecordingsUi']) {
        return this.validateDonutPermission('recording:recording:access');
      }

      return true;
    }),
    canViewAgentAssistance: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('chat:agentassistance:access');
    }),
    canViewAcdCoBrowseForChat: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('cobrowse:session:view') && this.validateDonutPermission('cobrowse:chat:escalate');
    }),
    canViewAcdCoBrowseForWebMessaging: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:cobrowse:add');
    }),
    canViewAcdCoBrowseV2ForVoice: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:cobrowseVoice:add');
    }),
    canViewAcdVideo: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('acdvideo:session:view');
    }),
    canViewAgentActivity: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:agentActivity:view') || this.validateDonutPermission('ui:supervisorActivity:view');
    }),
    canViewBusinessChat: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('chat:chat:access');
    }),
    canViewCollaborateVideo: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('video:video:access');
    }),
    canViewPublishedScripts: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('scripter:publishedScript:view');
    }),
    canViewAudits: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('audits:audit:view');
    }),
    hasAcdVoicemailAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('voicemail:acdVoicemail:view');
    }),
    hasVoicemailAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('voicemail:*:*');
    }),
    hasEvaluationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:evaluation:edit') || this.validateDonutPermission('quality:evaluation:editScore') || this.validateDonutPermission('quality:evaluation:view');
    }),
    hasEvaluationAggregateAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:evaluationAggregate:view');
    }),
    hasTimeOffRequestAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentTimeOffRequest:submit');
    }),
    hasSupervisorShiftTradeRequestAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:shiftTradeRequest:edit');
    }),
    hasLearningAssignmentViewAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('learning:assignment:view');
    }),
    hasCoachingParticipate: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('coaching:appointment:participate');
    }),
    hasAgentShiftTradeRequestAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentShiftTradeRequest:participate') || this.get('hasSupervisorShiftTradeRequestAccess');
    }),
    hasAgentAdherenceNotifyAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentSchedule:view') && this.validateDonutPermission('wfm:agentAdherence:notify');
    }),
    hasAgentAlternativeShiftAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentAlternativeShift:submit');
    }),
    hasShrinkageViewAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:shrinkage:view');
    }),
    hasAgentScheduleAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentSchedule:view');
    }),
    hasAgentScheduleNotificationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentScheduleNotification:view');
    }),
    hasAgentScheduleSyncAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:agentSchedule:sync');
    }),
    canViewAnAgentsSchedule: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return (this.validateDonutPermission('wfm:activityCode:administer') || this.validateDonutPermission('wfm:activityCode:view')) && (this.validateDonutPermission('wfm:schedule:administer') || this.validateDonutPermission('wfm:schedule:view')) && this.get('hasAgentSupervisorAccess');
    }),
    canViewAnAgentsScheduleMe: computed.bool('hasAgentScheduleAccess'),
    hasAgentSupervisorAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:supervisorAgentDetails:view') && this.get('canViewUsers');
    }),
    hasRelateAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:*:*');
    }),
    canEmailOnBehalfOfQueue: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:email:create');
    }),
    canForwardEmail: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:email:forward');
    }),
    canSendMessageOnBehalfOfQueue: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:message:create');
    }),
    canViewWebMessaging: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:message:view') || this.validateDonutPermission('conversation:webmessaging:view');
    }),
    hasExternalContactsViewContactAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:contact:view');
    }),
    hasExternalContactsAddContactAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:contact:add');
    }),
    hasExternalContactsEditContactAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:contact:edit');
    }),
    hasExternalContactsDeleteContactAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:contact:delete');
    }),
    hasExternalContactsViewOrganizationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:externalOrganization:view');
    }),
    hasExternalContactsAddOrganizationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:externalOrganization:add');
    }),
    hasExternalContactsEditOrganizationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:externalOrganization:edit');
    }),
    hasExternalContactsDeleteOrganizationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:externalOrganization:delete');
    }),
    hasExternalContactsExternalEntityImportAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:externalEntity:import');
    }),
    hasExternalContactsConversationAssociationPrivilege: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('externalContacts:conversation:associate');
    }),
    hasConversationAssociationPrivilege: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.get('hasExternalContactsConversationAssociationPrivilege');
    }),
    hasExternalContactsAddSchemaAccess: computed('donutPermissions', 'allowedOrgPermissions', 'session.features.relateDynamicSchema', function () {
      return this.get('session.features.relateDynamicSchema') && this.validateDonutPermission('externalContacts:customFields:add');
    }),
    hasExternalContactsEditSchemaAccess: computed('donutPermissions', 'allowedOrgPermissions', 'session.features.relateDynamicSchema', function () {
      return this.get('session.features.relateDynamicSchema') && this.validateDonutPermission('externalContacts:customFields:edit');
    }),
    hasExternalContactsViewSchemaAccess: computed('donutPermissions', 'allowedOrgPermissions', 'session.features.relateDynamicSchema', function () {
      return this.get('session.features.relateDynamicSchema') && this.validateDonutPermission('externalContacts:customFields:view');
    }),
    hasViewEvaluationAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('quality:evaluation:view');
    }),
    // Check permission to notify the adherence explanation
    hasAdherenceExplanationNotifyAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:adherenceExplanation:notify');
    }),
    // Check permission to view the adherence explanation
    hasAdherenceExplanationViewAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:adherenceExplanation:view');
    }),
    // Check permission to edit the adherence explanations
    hasAdherenceExplanationEditAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('wfm:adherenceExplanation:edit');
    }),
    // Checking against the donut permission: conversation:call:add or unified_communications
    // Eventually when unified_communications is removed, we can remove the check here
    phoneEnabled: computed('hasUnifiedCommunications', 'donutPermissions', 'directoryPermissions.[]', 'allowedOrgPermissions.[]', function () {
      var hasUnifiedCommunications = this.get('hasUnifiedCommunications');

      var legacyUnifiedCommunicationsPermission = _lodash.default.includes(this.get('directoryPermissions'), 'unified_communications');

      var canCall = this.validateDonutPermission('conversation:call:add');
      var phoneEnabled = hasUnifiedCommunications && (legacyUnifiedCommunicationsPermission || canCall);
      return phoneEnabled;
    }),
    canDisassociateSelf: computed('donutPermissions', function () {
      return this.validateDonutPermission('telephony:station:disassociateSelf');
    }),
    conferenceEnabled: true,
    canCallForward: computed('phoneEnabled', 'donutPermissions', 'allowedOrgPermissions.[]', function () {
      return this.validateDonutPermission('conversation:callForwarding:edit') && this.get('phoneEnabled');
    }),
    canRecord: computed('rolePermissions', 'donutPermisions', 'allowedOrgPermissions.[]', function () {
      var canRecord = this.validateDonutPermission('conversation:call:record');
      var isBusinessUserOrSupervisor = this.get('rolePermissions').indexOf('businessUser') > -1 || this.get('rolePermissions').indexOf('supervisor') > -1;
      return isBusinessUserOrSupervisor || canRecord;
    }),
    canFax: computed('donutPermissions', 'allowedOrgPermissions.[]', function () {
      return this.validateDonutPermission('conversation:fax:send');
    }),
    canDeleteFiles: computed('donutPermissions', function () {
      return this.validateDonutPermission('chat:file:delete');
    }),
    hasEngageAccess: computed('session.org.enabledFeatures.{purecloud,contactCenter}', function () {
      var hasContactCenter = this.get('session.org').getFieldValue('enabledFeatures.contactCenter');
      return hasContactCenter && hasContactCenter.value;
    }),
    canViewOutboundCampaigns: computed('donutPermissions', function () {
      return this.validateDonutPermission('outbound:campaign:view');
    }),
    canSearchOutboundCampaigns: computed('donutPermissions', function () {
      return this.validateDonutPermission('outbound:campaign:search');
    }),
    canViewContactLists: computed('donutPermissions', function () {
      return this.validateDonutPermission('outbound:contactList:view');
    }),
    canViewFlows: computed('donutPermissions', function () {
      return this.validateDonutPermission('architect:flow:view');
    }),
    canViewFlowAggregates: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:flowAggregate:view');
    }),
    canSearchFlows: computed('donutPermissions', function () {
      return this.validateDonutPermission('architect:flow:search');
    }),
    hasSavedFilterAccess: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:viewConfigurations:view') && this.validateDonutPermission('analytics:viewConfigurations:edit');
    }),
    canViewUsers: computed('donutPermissions', function () {
      return this.validateDonutPermission('directory:user:view');
    }),
    hasReportingExportAccess: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:dataExport:view');
    }),
    canCreateReportingExports: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:dataExport:add');
    }),
    canEditReportingExports: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:dataExport:edit');
    }),
    canDeleteReportingExports: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:dataExport:delete');
    }),
    canViewStaticLink: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:dataExportStaticLink:view');
    }),
    hasReportingAlertAccess: computed('donutPermissions', function () {
      return this.validateDonutPermission('alerting:alert:view');
    }),
    canEditReportingAlerts: computed('donutPermissions', function () {
      return this.validateDonutPermission('alerting:alert:edit');
    }),
    canEditReportingAlertRules: computed('donutPermissions', function () {
      return this.validateDonutPermission('alerting:rule:edit');
    }),
    canDeleteReportingAlerts: computed('donutPermissions', function () {
      return this.validateDonutPermission('alerting:alert:delete');
    }),
    canViewSupervisorStatusControls: computed('donutPermissions', function () {
      return this.validateDonutPermission('ui:supervisorStatusControl:view');
    }),
    canLogOffAgents: computed('donutPermissions', function () {
      return this.validateDonutPermission('oauth:token:delete');
    }),
    canEditProfiles: computed('donutPermissions', function () {
      return this.validateDonutPermission('directory:user:edit');
    }),
    canReadProfiles: computed('donutPermissions', function () {
      return this.validateDonutPermission('directory:user:view');
    }),
    canViewGroups: computed('donutPermissions', function () {
      return this.validateDonutPermission('directory:group:view');
    }),
    canEditGroups: computed('donutPermissions', function () {
      return this.validateDonutPermission('directory:group:edit');
    }),
    canEditSkillGroups: computed('donutPermissions', function () {
      return this.validateDonutPermission('routing:skillgroup:edit');
    }),
    canViewOAuthClients: computed('donutPermissions', function () {
      return this.validateDonutPermission('oauth:client:view');
    }),
    canDeleteParticipantFromGroup: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('chat:participant:delete');
    }),
    canViewZoomMeetings: computed('donutPermissions', function () {
      return this.validateDonutPermission('integration:zoomMeetings:view');
    }),
    canViewTeamsMeetings: computed('donutPermissions', function () {
      return this.validateDonutPermission('integration:microsoftTeamsMeetings:view');
    }),
    canViewJourneyAggregates: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:journeyAggregate:view');
    }),
    canViewActionMaps: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('journey:actionmap:view');
    }),
    canViewTopicTrends: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('analytics:speechAndTextAnalyticsAggregates:view') && this.validateDonutPermission('speechAndTextAnalytics:topic:view');
    }),
    canViewBotAggregates: computed('donutPermissions', function () {
      return this.validateDonutPermission('analytics:botAggregate:view');
    }),
    canViewDirectory: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('ui:myOrganization:view');
    }),
    canTargetUserSearchOutsideOfDivision: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:communication:target');
    }),
    canTargetQueueSearchOutsideOfDivision: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('conversation:communication:target');
    }),
    canEditGroupTelephonySettings: computed('donutPermissions', 'allowedOrgPermissions', 'session.features', function () {
      return this.validateDonutPermission('telephony:plugin:all');
    }),
    hasPredictiveRoutingAccess: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('routing:queue:view') && this.validateDonutPermission('routing:predictor:view');
    }),
    canViewIntegrationPresence: computed('donutPermissions', 'allowedOrgPermissions', 'session.features', function () {
      return this.validateDonutPermission('integration:presence:view');
    }),
    canEditOwnDirectRoutingSettings: computed('donutPermissions', function () {
      return this.validateDonutPermission('routing:directRoutingBackup:selfEdit');
    }),
    canViewOwnDirectRoutingSettings: computed('donutPermissions', function () {
      return this.validateDonutPermission('routing:directRoutingBackup:selfView');
    }),
    canDeleteOwnDirectRoutingSettings: computed('donutPermissions', function () {
      return this.validateDonutPermission('routing:directRoutingBackup:selfDelete');
    }),
    canViewWorkitem: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('workitems:workitem:view');
    }),
    canViewParkedEmails: computed('donutPermissions', 'session.features.acdParkEmail', function () {
      return this.validateDonutPermission('conversation:email:park') && this.get('session.useParkEmail');
    }),
    canViewJourneyAnalytics: computed('donutPermissions', function () {
      return this.validateDonutPermission('journey:views:view');
    }),
    canViewSummary: computed('donutPermissions', function () {
      return this.validateDonutPermission('conversation:summary:view');
    }),
    canViewDirectTransferToVoicemail: computed('donutPermissions', 'allowedOrgPermissions', function () {
      return this.validateDonutPermission('user:voicemail:transfer');
    }),
    canBlindTransfer: computed('donutPermissions', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.validateDonutPermission('conversation:communication:blindTransfer');
      }

      return true;
    }),
    canBlindTransferAgent: computed('donutPermissions', 'canBlindTransfer', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.get('canBlindTransfer') || this.validateDonutPermission('conversation:communication:blindTransferAgent');
      }

      return true;
    }),
    canBlindTransferQueue: computed('donutPermissions', 'canBlindTransfer', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.get('canBlindTransfer') || this.validateDonutPermission('conversation:communication:blindTransferQueue');
      }

      return true;
    }),
    canBlindTransferExternal: computed('donutPermissions', 'canBlindTransfer', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.get('canBlindTransfer') || this.validateDonutPermission('conversation:communication:blindTransferExternal');
      }

      return true;
    }),
    canConsultTransfer: computed('donutPermissions', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.validateDonutPermission('conversation:communication:consultTransfer');
      }

      return true;
    }),
    canConsultTransferAgent: computed('donutPermissions', 'canConsultTransfer', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.get('canConsultTransfer') || this.validateDonutPermission('conversation:communication:consultTransferAgent');
      }

      return true;
    }),
    canConsultTransferQueue: computed('donutPermissions', 'canConsultTransfer', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.get('canConsultTransfer') || this.validateDonutPermission('conversation:communication:consultTransferQueue');
      }

      return true;
    }),
    canConsultTransferExternal: computed('donutPermissions', 'canConsultTransfer', 'session.features.acdTransferPermissions', function () {
      if (this.get('session.features.acdTransferPermissions')) {
        return this.get('canConsultTransfer') || this.validateDonutPermission('conversation:communication:consultTransferExternal');
      }

      return true;
    }),
    canReceiveInteractionsAvailableOffQueue: computed('donutPermissions', function () {
      return this.validateDonutPermission('routing:conversation:acceptOffQueue');
    }),
    canViewCopilot: computed('donutPermissions', function () {
      return this.validateDonutPermission('assistants:copilot:view');
    }),
    canEnableMultipanelLab: computed('donutPermissions', function () {
      return this.validateDonutPermission('agentUI:multipanelLab:enable');
    })
  });

  _exports.default = _default;
});