define("web-directory/models/callv2", ["exports", "ember-data", "lodash/cloneDeep"], function (_exports, _emberData, _cloneDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var attr = _emberData.default.attr;

  function hasSipErrors(party) {
    var codes = Ember.get(party, 'sipResponseCodes');

    if (!codes || codes.length === 0) {
      return false;
    } // 487 is Voicemail Not Available, which is not treated as an error
    // 410 is a queue call that gets transfered to an agent for some reason


    return !!party.sipResponseCodes.filter(function (c) {
      return c >= 300 && c !== 487 && c !== 410;
    }).length;
  }

  var _default = _emberData.default.Model.extend({
    // required services
    session: Ember.inject.service('session'),
    addressformat: Ember.inject.service('addressformat'),
    application: Ember.inject.service('application'),
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service('intl'),

    /**
     * The conversation (with the same id as this call)
     * Fetched when we need to distinguish between participants (if there are 2 or more valid otherParties)
     * Raw object - Not the EPC model
     */
    conversation: null,
    //  The relevant part of conversation. Each participant in this list corresponds to participants on callv2, but has
    //  extra properties such as monitorParticipantId or coachedParticipantId
    conversationParticipants: Ember.computed('conversation.participants', function () {
      var conversationParties = this.get('conversation.participants') || [];
      return conversationParties;
    }),
    participants: attr(),
    startTime: attr('date'),
    direction: attr('string'),
    missedCall: attr('boolean'),
    wasConference: attr('boolean'),
    wasCallback: attr('boolean'),
    wasOutboundCampaign: attr('boolean'),

    /**
     * call went to voicemail
     */
    wentToVoicemail: attr('boolean'),
    callStart: Ember.computed.alias('startTime'),
    sentToVoicemail: Ember.computed('wentToVoicemail', 'voicemailParticipant', function () {
      // sent to my voicemail
      if (!this.get('wentToVoicemail')) {
        return false;
      }

      return this.get('voicemailParticipant.user.id') === this.get('myGuid');
    }),
    reachedVoicemail: Ember.computed.and('wentToVoicemail', 'isOutbound'),
    // went to other user's voicemail
    // my participant computeds
    myGuid: Ember.computed.reads('session.person.guid'),
    callEnd: Ember.computed.reads('myParticipant.endTime'),
    isOutbound: Ember.computed.equal('myParticipant.direction', 'outbound'),
    isMultiParty: Ember.computed.or('isOutboundCampaign', 'isIvrTransfer', 'isBlindTransfer', 'isConsultTransfer', 'wasConference'),
    myself: Ember.computed.reads('myParticipant'),
    // convenience for call-history-entity backward compat
    allParticipants: Ember.computed.union('internalParticipants', 'externalParticipants', 'stationParticipants', 'ivrParticipants', 'queueParticipants'),
    // an acd call is where any participant is an agent
    isAcdCall: Ember.computed('participants.[]', function () {
      return Ember.A(this.get('participants')).any(function (participant) {
        return participant && participant.purpose === 'agent';
      });
    }),
    // begin participant computed props: myParticipant, internalParticipants, externalParticipants, voicemailParticipant, otherParties
    myParticipant: Ember.computed('participants.[]', 'myGuid', function () {
      var nonVoicemail = this.get('participants').rejectBy('purpose', 'voicemail') || Ember.A([]);
      var me = nonVoicemail.findBy('user.id', this.get('myGuid'));

      if (!me) {
        me = nonVoicemail.findBy('purpose', 'station');
      }

      return me;
    }),
    // internalParticipants and externalParticipants and combined in allParticipants computed above.
    internalParticipants: Ember.computed('participants.[]', function () {
      return (this.get('participants') || []).filter(function (party) {
        return party.user !== undefined && party.monitoredCallId === undefined;
      });
    }),
    externalParticipants: Ember.computed('participants.[]', function () {
      var participants = this.get('participants').reject(function (p) {
        return Ember.get(p, 'dnis') === 'unknown' || hasSipErrors(p);
      });
      var externalParticipants = Ember.A([].concat(_toConsumableArray(participants.filterBy('purpose', 'external')), _toConsumableArray(participants.filterBy('purpose', 'customer')))); // the edge does dumb stuff like add participants with no error code when
      // the call gets redirected to another edge. See if there are two identical
      // participants where both didn't interact, and if so, filter one out.

      if (externalParticipants.length === 2 && externalParticipants.filterBy('didInteract').length < 2 && externalParticipants[0].ani === externalParticipants[0].ani) {
        externalParticipants = Ember.A([externalParticipants[0]]);
      }

      return externalParticipants;
    }),
    stationParticipants: Ember.computed('participants.[]', function () {
      return this.get('participants').reject(function (p) {
        return Ember.get(p, 'dnis') === 'unknown' || hasSipErrors(p);
      }).filterBy('purpose', 'station');
    }),
    voicemailParticipant: Ember.computed('participants.[]', function () {
      return this.get('participants').findBy('purpose', 'voicemail');
    }),
    ivrParticipants: Ember.computed('participants.[]', function () {
      return this.get('participants').filterBy('purpose', 'ivr');
    }),
    queueParticipants: Ember.computed('participants.[]', function () {
      return this.get('participants').filterBy('purpose', 'acd');
    }),
    // All participants that aren't the current user, or a monitor, coach, or barged participant
    otherParties: Ember.computed('allParticipants.[]', 'myGuid', 'conversationParticipants.[]', function () {
      var conversationParticipants = this.get('conversationParticipants');
      return this.get('allParticipants').rejectBy('user.id', this.get('myGuid')).reject(function (party) {
        return Ember.get(party, 'startTime') === Ember.get(party, 'endTime') || hasSipErrors(party);
      }).filter(function (party) {
        //  Only filter out monitors, coaches, or barged participants (if found from conversation.participants)
        var partyFromConversation = conversationParticipants.find(function (participant) {
          return participant.id === party.id;
        });

        if (partyFromConversation) {
          return !(Ember.get(partyFromConversation, 'monitoredParticipantId') || Ember.get(partyFromConversation, 'coachedParticipantId') || Ember.get(partyFromConversation, 'bargedParticipantId'));
        }

        return true;
      });
    }),
    callParties: Ember.computed('otherParties.[]', 'isGroupRing', 'conversationParticipants', function () {
      var _this = this;

      var otherParties = this.get('otherParties');
      var addressformat = this.get('addressformat'); // We dont display (all) group call participants in any call UIs

      if (this.get('isGroupRing')) {
        return [];
      }

      return otherParties.rejectBy('purpose', 'voicemail').map(function (p) {
        var party = (0, _cloneDeep.default)(p); // no side effects

        if (party.purpose === 'user' || party.purpose === 'agent') {
          var promise = _this.get('store').findRecord('person', party.user.id);

          promise.then(function (data) {
            Ember.set(party, 'name', data.get('name'));
          });
          return promise.catch(function () {
            return party;
          });
        }

        if (party.purpose === 'acd') {
          var _promise = _this.get('store').findRecord('queue', party.queue.id);

          _promise.then(function (data) {
            Ember.set(party, 'name', data.get('name'));
            Ember.set(party, 'address', "sip:".concat(data.get('id')));
          });

          return _promise.catch(function () {
            return party;
          });
        }

        if (party.purpose === 'ivr') {
          Ember.set(party, 'address', Ember.get(party, 'dnis'));
        } else if (party.direction === 'outbound') {
          var dnis = Ember.get(party, 'dnis');
          Ember.set(party, 'isOutbound', true); //  Sometimes in conference calls the address of a participant is not resolved correctly when accessed from the 'history' bulk endpoint
          //  In that case we try to fallback on the same participant taken fron the GET conversation/{conversationId} endpoint which may be resolved correctly

          if (party.address && party.address.startsWith('sip:')) {
            var conversationParticipants = _this.get('conversationParticipants');

            var participant = conversationParticipants.find(function (participant) {
              return participant.id === party.id;
            }); // If the participant address is also available from the more detailed 'conversationParticipants', use it

            if (participant) {
              Ember.set(party, 'address', Ember.get(participant, 'address'));
            } else {
              // Otherwise fallback to DNIS
              Ember.set(party, 'address', dnis);
            }
          } else {
            Ember.set(party, 'address', dnis);
          }
        } else {
          Ember.set(party, 'address', Ember.get(party, 'ani'));
        }

        Ember.set(party, 'address', addressformat.formatAddressForDisplay(Ember.get(party, 'address')));
        return Ember.RSVP.resolve(party);
      });
    }),
    initiator: Ember.computed('allParticipants.[]', function () {
      var all = this.get('allParticipants');
      var outboundParticipant = all.findBy('direction', 'outbound');

      if (outboundParticipant) {
        return outboundParticipant;
      }

      var external = this.get('externalParticipants');
      var inboundExternal = external.findBy('direction', 'inbound');

      if (inboundExternal) {
        return inboundExternal;
      } // Sometimes the initiator is indeterminate based on the data we get
      // but it's not really necessary for rendering the component


      return null;
    }),
    disconnectReason: Ember.computed('myParticipant.disconnectType', 'isOutbound', 'wentToVoicemail', function () {
      var intl = this.get('intl');
      var disconnectType = this.get('myParticipant.disconnectType') || 'unknown';
      var disconnectTypeKey = 'other';

      if (disconnectType === 'client') {
        disconnectTypeKey = 'you';
      } else if (disconnectType === 'endpoint') {
        disconnectTypeKey = 'otherParty';
      } else if (disconnectType === 'system') {
        disconnectTypeKey = 'system';
      } else if (disconnectType && disconnectType.indexOf('transfer') > -1) {
        if (!this.get('isOutbound') && this.get('wentToVoicemail')) {
          disconnectTypeKey = 'youDeclined';
        } else {
          disconnectTypeKey = 'transfer';
        }
      } else if (disconnectType === 'error') {
        disconnectTypeKey = 'error';
      }

      return intl.lookup("purecloud.disconnectReason.".concat(disconnectTypeKey));
    }),
    isIvrTransfer: Ember.computed('myParticipant.disconnectType', 'otherParties.[]', function () {
      return !!this.get('otherParties').filter(function (p) {
        var purpose = Ember.get(p, 'purpose');
        return purpose === 'ivr' || purpose === 'acd';
      }).length;
    }),
    isOutboundCampaign: Ember.computed.readOnly('wasOutboundCampaign'),
    isBlindTransfer: Ember.computed('sentToVoicemail', 'myParticipant.disconnectType', function () {
      // if I'm an agent and there were other agents, it's a transfer situation. We can't
      // distinguish between consult or blind based on analytics or conversation data
      if (this.get('myParticipant.purpose') === 'agent') {
        return this.get('participants').filterBy('purpose', 'agent').length > 1;
      }

      var participants = this.get('participants').filter(function (p) {
        var purpose = Ember.get(p, 'purpose');
        return purpose !== 'ivr' && purpose !== 'acd';
      });

      if (!participants.any(function (p) {
        return Ember.get(p, 'disconnectType') === 'transfer';
      })) {
        return false;
      }

      return !this.get('wentToVoicemail');
    }),
    isConsultTransfer: Ember.computed('isBlindTransfer', 'wasConference', 'callParties.[]', function () {
      // multiple parties, but not marked as a transfer or a conference means consult
      var parties = this.get('callParties').filter(function (p) {
        var purpose = Ember.get(p, 'purpose');
        return purpose !== 'ivr' && purpose !== 'acd';
      });
      return !this.get('isBlindTransfer') && !this.get('wasConference') && parties.length > 1;
    }),
    isPersonalVoicemail: Ember.computed('voicemailParticipant.dnis', function () {
      return this.get('voicemailParticipant') ? this.get('voicemailParticipant.dnis').indexOf('user=voicemailbox') > -1 : false;
    }),
    // This an outbound call initiated by the queue - NOT true for normal inbound/outbound ACD calls
    isQueueCall: Ember.computed('initiator', function () {
      var queueId = this.get('myParticipant.queue.id') || this.get('myParticipant.queueId');
      return !!(this.get('initiator.dnis') && queueId && this.get('initiator.dnis').indexOf(queueId > -1) && this.get('isOutbound')) && !this.get('isAcdCall');
    }),
    invalidNumber: Ember.computed('myParticipant.{disconnectType,dnis}', function () {
      return this.get('myParticipant.disconnectType') === 'error' && this.get('myParticipant.dnis') === 'Invalidnumber';
    }),
    groupRingParticipant: Ember.computed('participants.[]', function () {
      return this.get('participants').find(function (p) {
        return Ember.get(p, 'groupId') || Ember.get(p, 'group.id');
      });
    }),
    isGroupRing: Ember.computed.bool('groupRingParticipant'),
    groupRingId: Ember.computed('groupRingParticipant', function () {
      var participant = this.get('groupRingParticipant');

      if (!participant) {
        return undefined;
      }

      return Ember.get(participant, 'groupId') || Ember.get(participant, 'group.id');
    }),
    isOutboundGroupRing: Ember.computed('isOutbound', 'groupRingCaller.guid', 'isGroupRing', 'myGuid', function () {
      return !!(this.get('isOutbound') && this.get('isGroupRing') && this.get('groupRingCaller.guid') === this.get('myGuid'));
    }),
    groupRingGroup: Ember.computed('groupRingId', function () {
      if (this.get('groupRingId')) {
        return this.get('store').findRecord('group', this.get('groupRingId'), {
          reload: false,
          backgroundReload: false
        });
      }

      return Ember.RSVP.Promise.resolve(null);
    }),
    groupRingCaller: Ember.computed('isGroupRing', 'initiator', function () {
      var initiator = this.get('initiator');

      if (this.get('groupRingParticipant') && initiator) {
        if (!Ember.get(initiator, 'user')) {
          return Ember.RSVP.resolve(initiator);
        }

        return this.get('store').findRecord('person', Ember.get(initiator, 'user.id'), {
          reload: false,
          backgroundReload: false
        });
      }

      return Ember.RSVP.Promise.resolve(null);
    }),
    //  If there are more than 2 parties fetch the conversation individually to distinguish them
    loadConversation: function loadConversation() {
      var _this2 = this;

      var personParticipants = this.get('participants').filter(function (participant) {
        return ['user', 'agent', 'external', 'customer'].includes(participant.purpose.toLowerCase());
      });
      var promise = Ember.RSVP.Promise.resolve([]);

      if (!this.get('conversation') && personParticipants.length > 2) {
        var id = this.get('id');
        var url = this.get('application').pcV2Uri("api/v2/conversations/".concat(id));
        promise = this.get('ajax').request(url).then(function (res) {
          _this2.set('conversation', res);

          return !!res ? res.participants : [];
        }).catch(function () {
          return [];
        });
      }

      return promise;
    }
  });

  _exports.default = _default;
});