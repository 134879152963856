define("web-directory/services/engage", ["exports", "lodash", "ember-purecloud-components/mixins/logger", "web-directory/utils/presence-utils"], function (_exports, _lodash, _logger, _presenceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NOT_RESPONDING = 'NOT_RESPONDING';
  var ON_QUEUE = 'on_queue';
  var AVAILABLE = 'available';
  var DIRECTORY_ROUTE = /^#?directory:/i;
  var ANALYTICS_ROUTE = /^#\/?analytics/i;
  var ENGAGE_ROOT = '/contact-center/';
  var CM_ROUTE_REGEX = /^#\/engage\/contentManagement/;
  var DIRECT_NAV_LINKS = [/^#\/topics-definitions/, /^#\/quality\/admin\/encryptionKeys/, /^#\/quality\/admin\/evaluationForms/, /^#\/quality\/admin\/surveyForms/, /^#\/quality\/admin\/speech-and-text-analytics/, /^#\/quality\/admin\/reprocess-interactions/, /^#\/quality\/admin\/dictionary-management/, /^#\/quality\/admin\/sentiment-feedback/, /^#\/quality\/admin\/sta-category-definitions-ui/, /^#\/quality\/admin\/topic-miner/, /^#\/admin\/telephony\/extensions/, /^#\/admin\/telephony\/settings/];

  function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  function isDirectNavLink(href) {
    return !!DIRECT_NAV_LINKS.find(function (directRegex) {
      return directRegex.test(href);
    });
  } // This function sets up engage links to render with the #/engage/ prefix
  // but it also allows us to skip that for specific exceptions


  function setNavTreeLinkTo(navEntries) {
    navEntries.forEach(function (entry) {
      if (!entry.linkTo && !isDirectNavLink(entry.href)) {
        entry.linkTo = 'engage.embedded';
      } else {
        entry.direct = true; // use the href directly
      }

      if (entry.subLinks) {
        setNavTreeLinkTo(entry.subLinks);
      }
    });
  }

  var _default = Ember.Service.extend(Ember.Evented, _logger.default, {
    application: Ember.inject.service(),
    presenceSubscription: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notification: Ember.inject.service(),
    agentSchedule: Ember.inject.service(),
    queueService: Ember.inject.service('queue'),
    permissions: Ember.inject.service(),
    isEngageActive: false,
    isEngageInteracting: false,
    unreadAlertsCount: null,
    route: 'noop',
    navigationRoute: null,
    redirectRoute: null,
    navTree: null,
    breadcrumb: null,
    helpURL: null,
    title: '',
    url: ENGAGE_ROOT + '#noop',
    localEnv: null,
    location: Ember.computed('route', 'isEngageActive', 'localEnv', function () {
      var route = 'noop';
      var localEnv = this.get('localEnv');
      var locale = this.get('intl.isoRegionalLocale');
      var coreBuild = getParameterByName('engage-version');
      var location = "".concat(ENGAGE_ROOT);
      var componentBuilds = window.location.search.match(/([a-zA-Z]){1,}-component-version=[a-zA-Z0-9\-\_]{1,}/g);
      var componentParams = componentBuilds ? "&".concat(componentBuilds.join('&')) : '';

      if (this.get('isEngageActive')) {
        route = this.get('route');
      }

      if (route === 'interactions') {
        // Just go to the default route instead of interactions
        route = '';
      }

      this.logger.debug('Updating location to ' + ENGAGE_ROOT + '#' + route);

      if (coreBuild) {
        location = "".concat(location).concat(coreBuild, "/embedded.html?locale=").concat(locale);
      } else {
        location = "".concat(location, "embedded.html?locale=").concat(locale);
      }

      if (localEnv) {
        return "".concat(location, "&env=").concat(localEnv).concat(componentParams, "#").concat(route);
      }

      return "".concat(location).concat(componentParams, "#").concat(route);
    }),
    handleNavigation: function handleNavigation(navEvent) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        newWindow: false
      };

      if (options.newWindow) {
        window.open(navEvent);
        return;
      }

      if (this.isDirectoryRoute(navEvent)) {
        window.location.hash = navEvent.replace(DIRECTORY_ROUTE, '');
        return;
      } // if it's namespaced to analytics, make sure we bypass the embedded engage routing logic


      if (this.isAnalyticsRoute(navEvent)) {
        window.location.hash = navEvent;
        return;
      }

      this.set('navigationRoute', navEvent);
    },
    handleRedirect: function handleRedirect(navEvent) {
      this.propertyWillChange('redirectRoute');
      this.set('redirectRoute', navEvent);
      this.propertyDidChange('redirectRoute');
    },
    goOnQueue: function goOnQueue() {
      //Going on queue: set routing status to IDLE (make eligible for interactions)
      this.get('presenceSubscription').changeRoutingStatus('IDLE');
    },
    goOffQueue: function goOffQueue() {
      //Going off queue: set presence to AVAILABLE, unless they can receive interactions in the AVAILABLE presence then set presence to BUSY
      var newPresence = this.get('isAvailableAndCanReceiveInteractions') ? 'BUSY' : 'AVAILABLE';
      this.get('presenceSubscription').changePresence(newPresence);
    },
    selectQueues: function selectQueues() {
      window.location.hash = '#/engage/activateQueues';
      this.get('noActiveQueuesNotification').hide();
      this.set('noActiveQueuesNotification', null);
    },

    /**
     * Returns true if the passed in route is prepended with #directory: and should then bypass the engage routing altogether.
     * This is useful for situations where the engage state needs to be uninterrupted but the route needs to be changed
     * from within engage.  For instance an inner engage navhelper call to load the admin panel.  The route is not a valid
     * route in engage and attempting to load it will resolve to a noop if handled normally.
     *
     * By adding the "#directory:" convention the route following the token will be interpreted as a directory route
     * @param route
     * @returns {*|boolean}
     */
    isDirectoryRoute: function isDirectoryRoute(route) {
      return route && DIRECTORY_ROUTE.test(route);
    },
    isAnalyticsRoute: function isAnalyticsRoute(route) {
      return route && ANALYTICS_ROUTE.test(route);
    },
    notRespondingNotification: null,
    noActiveQueuesNotification: null,
    routingStatus: Ember.computed.reads('session.person.activities.routingStatus.status'),
    presence: Ember.computed.reads('session.person.presence'),
    isAvailableAndCanReceiveInteractions: Ember.computed('permissions.canReceiveInteractionsAvailableOffQueue', 'presence', 'session.features.agentAddressOnBehalfOfQueue', function () {
      var presence = this.get('presence');
      var canReceiveInteractionsAvailable = this.get('permissions.canReceiveInteractionsAvailableOffQueue');
      return presence === AVAILABLE && canReceiveInteractionsAvailable && this.get('session.features.agentAddressOnBehalfOfQueue');
    }),
    isNotResponding: Ember.computed('routingStatus', 'presence', 'isAvailableAndCanReceiveInteractions', function () {
      var routingStatus = this.get('routingStatus');
      var presence = this.get('presence');
      var isAvailableAndCanReceiveInteractions = this.get('isAvailableAndCanReceiveInteractions');
      return (presence === ON_QUEUE || isAvailableAndCanReceiveInteractions) && routingStatus === NOT_RESPONDING;
    }),
    isOnQueue: false,
    updateOnQueueStatus: Ember.observer('session.person.isOnQueue', function () {
      var oldValue = this.get('isOnQueue');
      var newValue = this.get('session.person.isOnQueue');

      if (oldValue !== newValue) {
        this.set('isOnQueue', newValue);
      }
    }).on('init'),
    handleOnQueue: Ember.on('init', Ember.observer('isOnQueue', _lodash.default.debounce(function () {
      var _this = this;

      var nonPurecloudSource = false;

      if (this.get('session.features.acdHybridToastNoActiveQueue')) {
        nonPurecloudSource = this.get('session.user.presence.source').toLowerCase() !== 'purecloud';
      }

      if (this.get('isOnQueue') && !nonPurecloudSource) {
        this.get('queueService').getMyQueues({
          joined: 'true'
        }).then(function (response) {
          if (response) {
            _this.handleNoActiveQueuesNotification(response.total < 1);
          }
        });
      } else {
        this.handleNoActiveQueuesNotification(false);
      }
    }, 250))),
    handleNoActiveQueuesNotification: function handleNoActiveQueuesNotification(show) {
      var notification = this.get('noActiveQueuesNotification');

      if (show && notification && notification.notification && notification.notification.shown) {
        // notification is already shown, so do nothing
        return;
      }

      if (show) {
        if (notification) {
          this.get('noActiveQueuesNotification').hide();
          this.set('noActiveQueuesNotification', null);
        }

        this.set('noActiveQueuesNotification', this.get('notification').info(null, this.get('intl').lookup('notification.purecloud.activeQueues.noQueues').toString(), {
          forceBrowserNotification: true,
          showCloseButton: true,
          hideAfter: false,
          messageStyle: 'pin-to-top',
          type: 'no-joined-queues',
          target: this,
          tag: 'no-joined-queues',
          actionHandlers: {
            selectQueues: this.get('intl').lookup('notification.purecloud.activeQueues.selectQueues').toString()
          }
        }));
      } else if (notification) {
        this.get('noActiveQueuesNotification').hide();
        this.set('noActiveQueuesNotification', null);
      }
    },
    disablePresenceSelector: function disablePresenceSelector() {
      var source = this.get('session.person.primaryPresence.source');

      if (!source) {
        return false;
      }

      return !(0, _presenceUtils.isDefaultPresence)(source);
    },
    handleNotRespondingState: Ember.on('init', Ember.observer('isNotResponding', 'intl.loaded', 'session.user.presence.source', 'isAvailableAndCanReceiveInteractions', function () {
      var isNotResponding = this.get('isNotResponding');
      var isAvailableAndCanReceiveInteractions = this.get('isAvailableAndCanReceiveInteractions');
      var notification = this.get('notRespondingNotification');
      var intl = this.get('intl');

      if (intl.get('loaded') && isNotResponding && notification && Ember.get(notification, 'notification.shown')) {
        //User is not responding but notification is already shown, so do nothing
        return;
      }

      if (isNotResponding && intl.get('loaded')) {
        //User is not responding, so show the notification
        if (notification) {
          notification.showBrowserNotification();
        } else {
          var options = {
            showNotificationEverywhere: true,
            showCloseButton: false,
            hideAfter: false,
            messageStyle: 'pin-to-top',
            type: 'not-responding-message',
            target: this,
            tag: 'not-responding-message'
          };

          if (!this.disablePresenceSelector()) {
            if (isAvailableAndCanReceiveInteractions) {
              options.actionHandlers = {
                goOnQueue: intl.lookup('notification.purecloud.notResponding.makeAvailable').toString(),
                goOffQueue: intl.lookup('notification.purecloud.notResponding.setToBusy').toString()
              };
            } else {
              options.actionHandlers = {
                goOnQueue: intl.lookup('notification.purecloud.notResponding.makeEligible').toString(),
                goOffQueue: intl.lookup('notification.purecloud.notResponding.goOffQueue').toString()
              };
            }
          }

          this.set('notRespondingNotification', this.get('notification').info(null, intl.lookup('notification.purecloud.notResponding.message').toString(), options, Ember.getOwner(this)));
        }
      } else if (notification) {
        //User is responding, so hide the notification
        notification.hide();
        this.set('notRespondingNotification', null);
      }
    })),
    registerNavEntries: function registerNavEntries(navEntries) {
      var outboundEntry = _lodash.default.find(navEntries, function (entry) {
        return entry.fromPlaceholder === 'outbound-component';
      });

      if (outboundEntry) {
        var outboundCampaigns = _lodash.default.remove(outboundEntry.subLinks, function (subLink) {
          return subLink.href.indexOf('outboundCampaigns') > -1;
        });

        if (outboundCampaigns.length > 0) {
          navEntries.push(outboundCampaigns[0]);
        }
      }

      setNavTreeLinkTo(navEntries);
      this.set('navTree', navEntries);
      this.trigger('navTreeUpdate');
    },
    setBreadcrumb: function setBreadcrumb(breadcrumbData) {
      if (breadcrumbData.length > 0 && CM_ROUTE_REGEX.test(breadcrumbData[0].hash)) {
        this.set('breadcrumb', null);
      } else {
        this.set('breadcrumb', breadcrumbData);
      }
    },
    setHelpURL: function setHelpURL(url) {
      this.set('helpURL', url);
    },
    checkEnv: Ember.on('init', function () {
      var envDetails = this.get('application.envDetails');

      if (envDetails && envDetails.local) {
        this.set('localEnv', envDetails.env);
      }
    })
  });

  _exports.default = _default;
});