define("web-directory/components/command-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/PPLRFyu",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"command-panel-wrapper\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"panelDefinitions\"]]],null,{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"currentPanel\"]],[28,[\"panel\",\"id\"]]],null]],null,{\"statements\":[[0,\"            \"],[11,\"hgroup\",[]],[13],[0,\"\\n                \"],[11,\"h1\",[]],[13],[0,\"\\n                    \"],[9,\"gux-icon\",{\"attrs\":[[16,\"icon-name\",[28,[\"panel\",\"icon\"]],null],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n                    \"],[1,[28,[\"panel\",\"name\"]],false],[0,\"\\n                \"],[14],[0,\"\\n\\n                \"],[9,\"gux-button-slot-beta\",{\"attrs\":[[15,\"class\",\"close-panel\"],[15,\"accent\",\"ghost\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n                    \"],[11,\"button\",[]],[15,\"type\",\"button\"],[5,[\"action\"],[[28,[null]],\"closePanel\"]],[13],[0,\"\\n                        \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"close\"],[16,\"screenreader-text\",[33,[\"t\"],[\"navBar.commandView.closePanel\"],null],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n                    \"],[14],[0,\"\\n                \"]]}],[0,\"\\n            \"],[14],[0,\"\\n\\n\"],[6,[\"if\"],[[28,[\"panel\",\"drawers\"]]],null,{\"statements\":[[0,\"                \"],[1,[33,[\"command-panel/panel-navigation\"],null,[[\"definition\",\"currentDrawerId\"],[[28,[\"panel\"]],[28,[\"currentDrawerId\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[11,\"section\",[]],[15,\"class\",\"panel-content\"],[13],[0,\"\\n                    \"],[1,[33,[\"component\"],[[28,[\"panel\",\"layout\"]]],[[\"definition\"],[[28,[\"panel\"]]]]],false],[0,\"\\n                \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null]],\"locals\":[\"panel\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/command-panel/template.hbs"
    }
  });

  _exports.default = _default;
});