define("web-directory/components/acd-interactions-panel/interaction-type-icon/component", ["exports", "web-directory/utils/supported-messaging-subtypes"], function (_exports, _supportedMessagingSubtypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed;

  var _default = Component.extend({
    classNames: ['interaction-icon'],
    classNameBindings: ['iconName', 'useNewDisconnectedConversationIcons:disconnected'],
    tagName: 'span',
    type: null,
    subtype: null,
    isDisconnected: null,
    isCampaign: null,
    acdDisconnectedConversationIconsFT: null,
    acdFacebookIconUpdateFT: null,
    acdTwitterXIconUpdateFT: null,
    useNewDisconnectedConversationIcons: computed.and('acdDisconnectedConversationIconsFT', 'isDisconnected'),
    accessibleTextKey: computed('type', 'subtype', 'isCampaign', 'acdFacebookIconUpdateFT', 'acdTwitterXIconUpdateFT', function () {
      var type = this.get('type');
      var subtype = this.get('subtype');

      if (type === 'callback' && this.get('isCampaign')) {
        type = 'preview';
      }

      var key = "purecloud.acdInteraction.interactionTypes.".concat(type);

      if ('message' === type) {
        if (_supportedMessagingSubtypes.MESSAGING_SUBTYPES.includes(subtype)) {
          if (subtype === 'facebook' && this.get('acdFacebookIconUpdateFT')) {
            key += "Types.".concat(subtype, "Meta");
          } else if (subtype === 'twitter' && this.get('acdTwitterXIconUpdateFT')) {
            key += "Types.".concat(subtype, "X");
          } else if (subtype === 'apple') {
            key += "Types.".concat(subtype);
          } else {
            key += "Types.".concat(subtype);
          }
        } else {
          key += "Types.unknown";
        }
      }

      return key;
    }),
    iconName: computed('type', 'subtype', 'isDisconnected', 'isCampaign', 'acdDisconnectedConversationIconsFT', 'acdFacebookIconUpdateFT', 'acdTwitterXIconUpdateFT', function () {
      var name;
      var type = this.get('subtype') || this.get('type');
      var isConnected = !this.get('isDisconnected');

      if (type === 'callback' && this.get('isCampaign')) {
        type = 'preview';
      }

      if (this.get('acdDisconnectedConversationIconsFT')) {
        switch (type) {
          case 'apple':
            name = 'custom/amb';
            break;

          case 'chat':
            name = 'roster-chat';
            break;

          case 'callback':
            name = 'phone-callback';
            break;

          case 'preview':
            name = 'phone-outbound';
            break;

          case 'call':
            name = 'phone';
            break;

          case 'email':
            name = 'roster-email';
            break;

          case 'sms':
            name = 'legacy/roster-sms';
            break;

          case 'facebook':
            name = this.get('acdFacebookIconUpdateFT') ? 'facebook' : isConnected ? 'roster-messenger' : 'roster-messenger-disable';
            break;

          case 'instagram':
            name = 'instagram';
            break;

          case 'twitter':
            name = this.get('acdTwitterXIconUpdateFT') ? 'fa/x-twitter' : isConnected ? 'roster-twitter' : 'roster-twitter-disable';
            break;

          case 'wechat':
            name = 'wechat';
            break;

          case 'whatsapp':
            name = 'whatsapp';
            break;

          case 'webmessaging':
            name = 'chat';
            break;

          case 'workitem':
            name = 'fa/clipboard-list-regular';
            break;

          default:
            // legacy fallback
            name = 'comment-ellipsis';
            break;
        }
      } else {
        switch (type) {
          case 'chat':
            name = isConnected ? 'roster-chat' : 'roster-chat-disable';
            break;

          case 'callback':
            name = 'phone-callback';
            break;

          case 'preview':
            name = isConnected ? 'phone-outbound' : 'roster-phone-disable';
            break;

          case 'call':
            name = isConnected ? 'phone' : 'legacy/phone-disable';
            break;

          case 'email':
            name = isConnected ? 'roster-email' : 'roster-email-disable';
            break;

          case 'sms':
            name = isConnected ? 'legacy/roster-sms' : 'legacy/roster-sms-disable';
            break;

          case 'line':
            name = isConnected ? 'roster-line' : 'roster-line-disable';
            break;

          case 'facebook':
            name = this.get('acdFacebookIconUpdateFT') ? 'facebook' : isConnected ? 'roster-messenger' : 'roster-messenger-disable';
            break;

          case 'instagram':
            name = isConnected ? 'roster-instagram' : 'roster-instagram-disable';
            break;

          case 'twitter':
            name = this.get('acdTwitterXIconUpdateFT') ? 'fa/x-twitter' : isConnected ? 'roster-twitter' : 'roster-twitter-disable';
            break;

          case 'wechat':
            name = isConnected ? 'roster-wechat' : 'roster-wechat-disable';
            break;

          case 'whatsapp':
            name = isConnected ? 'roster-whatsapp' : 'roster-whatsapp-disable';
            break;

          case 'webmessaging':
            name = isConnected ? 'chat' : 'chat-disable';
            break;

          case 'workitem':
            name = isConnected ? 'fa/clipboard-list-regular' : 'custom/clipboard-list-slash-regular';
            break;

          default:
            // legacy fallback
            name = isConnected ? 'comment-ellipsis' : 'comment-ellipsis-off';
            break;
        }
      }

      return name;
    })
  });

  _exports.default = _default;
});