define("web-directory/components/tags-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FysogGNv",
    "block": "{\"statements\":[[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"tags-input__search-icon\"],[15,\"icon-name\",\"search\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\\n\"],[6,[\"each\"],[[28,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"tags-input__tag\"],[15,\"removable\",\"true\"],[15,\"accent\",\"default\"],[13],[0,\"\\n        \"],[11,\"p\",[]],[15,\"class\",\"tags-input__tag-text\"],[16,\"title\",[28,[\"tag\",\"text\"]],null],[13],[0,\"\\n            \"],[1,[28,[\"tag\",\"text\"]],false],[0,\"\\n        \"],[14],[0,\"\\n\\n        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[16,\"class\",[34,[\"tags-input__tag-remove \",[33,[\"concat\"],[\"tag-remove-\",[28,[\"index\"]]],null]]]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onRemoveTag\",[28,[\"index\"]]],null],null],[16,\"onkeydown\",[33,[\"action\"],[[28,[null]],\"onTagKeyUp\",[28,[\"index\"]]],null],null],[16,\"aria-label\",[33,[\"concat\"],[[33,[\"t\"],[\"common.remove\"],null],\" \",[28,[\"tag\",\"text\"]]],null],null],[13],[0,\"\\n            \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"tags-input__tag-remove-icon\"],[15,\"icon-name\",\"close\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[\"tag\",\"index\"]},null],[0,\"\\n\"],[11,\"label\",[]],[15,\"class\",\"sr-only\"],[16,\"for\",[26,[\"inputId\"]],null],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[[28,[\"label\"]]],null],false],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"input\",[]],[15,\"class\",\"tags-input__input\"],[16,\"value\",[26,[\"searchValue\"]],null],[16,\"id\",[26,[\"inputId\"]],null],[16,\"placeholder\",[26,[\"placeholder\"]],null],[15,\"type\",\"text\"],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/tags-input/template.hbs"
    }
  });

  _exports.default = _default;
});