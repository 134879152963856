define("web-directory/components/region-chooser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3llPJfH2",
    "block": "{\"statements\":[[11,\"label\",[]],[15,\"for\",\"region-chooser-dropdown\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"components.region.choose\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"p\",[]],[15,\"class\",\"extra\"],[13],[0,\"\\n    \"],[1,[33,[\"t\"],[\"components.region.extraNote\"],null],false],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[9,\"gux-dropdown\",{\"attrs\":[[15,\"id\",\"region-chooser-dropdown\"],[15,\"classNames\",\"form-control\"],[16,\"value\",[26,[\"desktopRegionId\"]],null],[16,\"onchange\",[33,[\"action\"],[[28,[null]],\"setRegionId\"],null],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n    \"],[9,\"gux-listbox\",{\"attrs\":[],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n\"],[6,[\"each\"],[[28,[\"regions\"]]],null,{\"statements\":[[0,\"            \"],[9,\"gux-option\",{\"attrs\":[[16,\"value\",[28,[\"region\",\"desktopId\"]],null],[16,\"selected\",[33,[\"if\"],[[33,[\"eq\"],[[28,[\"region\",\"desktopId\"]],[28,[\"desktopRegionId\"]]],null],\"true\"],null],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n                \"],[1,[28,[\"region\",\"label\"]],false],[0,\"\\n            \"]]}],[0,\"\\n\"]],\"locals\":[\"region\"]},null],[0,\"    \"]]}],[0,\"\\n\"]]}],[0,\"\\n\\n\"],[11,\"div\",[]],[15,\"class\",\"submit\"],[13],[0,\"\\n    \"],[9,\"gux-button\",{\"attrs\":[[15,\"type\",\"button\"],[15,\"accent\",\"primary\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[28,[\"chooseRegion\"]]],null],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n        \"],[1,[33,[\"t\"],[\"common.choose\"],null],false],[0,\"\\n    \"]]}],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/region-chooser/template.hbs"
    }
  });

  _exports.default = _default;
});