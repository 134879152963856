define("web-directory/components/acd-interactions-panel/interaction-workitem-frame-router/component", ["exports", "web-directory/components/frame-router/component", "web-directory/services/acdworkitem"], function (_exports, _component, _acdworkitem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CLIENT_WORKITEM_INTERACTION_UPDATED_EVENT = _exports.CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT = void 0;
  var LOCALHOST = 'https://localhost:4800';
  var APP_URL = 'task-management-agent-ui/#/workitems/';
  var WORKITEMS_VERSION = 'workitemsMainStageVersion';
  var CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT = 'workitemInteractionChanged';
  _exports.CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT = CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT;
  var CLIENT_WORKITEM_INTERACTION_UPDATED_EVENT = 'workitemInteractionUpdated';
  _exports.CLIENT_WORKITEM_INTERACTION_UPDATED_EVENT = CLIENT_WORKITEM_INTERACTION_UPDATED_EVENT;

  var _default = _component.default.extend({
    classNames: ['taskmanagement-main-stage-frame-router'],
    acdworkitem: Ember.inject.service('acdworkitem'),
    intlService: Ember.inject.service('intl'),
    logger: Ember.inject.service('stash-logger'),
    session: Ember.inject.service('session'),
    frame: null,
    workitem: null,
    route: '/',
    didInsertElement: function didInsertElement() {
      var workitem = this.get('workitem');

      if (workitem && workitem.workitem) {
        try {
          this.setupFrames();
        } catch (e) {
          this.get('logger').error('Interaction Workitem Frame Router - Unable to initialize.');
        }
      }

      this._bindCreateEvents();
    },

    /**
     * Change the route if workitem changed or trigger an event to notify the attribute change in the same workitem.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var workitem = this.get('workitem');
      var frame = this.get('frame');

      if (frame && workitem) {
        this.publishToClient(CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT, workitem);
      }
    },

    /**
     * Cleanup on destroy.
     */
    willDestroyElement: function willDestroyElement() {
      this._unbindCreateEvents();

      this._super.apply(this, arguments);
    },

    /**
     * Initializes this host frame with the client including
     * the environmental data required by the client.
     */
    setupFrames: function setupFrames() {
      var _this = this;

      var workitem = this.get('workitem');

      var appVersion = this._getTaskManagementTargetBranch();

      var localUrl = "".concat(LOCALHOST, "/").concat(APP_URL);
      var appUrl = "".concat(window.location.origin, "/").concat(APP_URL);

      if (workitem) {
        this.set('route', "detail");
      }

      if (appVersion === 'local') {
        appUrl = localUrl;
      } else if (appVersion) {
        appUrl = appUrl.replace('/#/', "/".concat(appVersion, "/#/"));
      }

      var frameConfig = {
        locale: this.get('intlService').get('isoLocale'),
        hostRootUrl: window.location.origin
      };
      this.element.setupFrames({
        'task-management': {
          url: appUrl,
          assignedRoute: ''
        }
      }, frameConfig);
      this.element.messaging.addListener(CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT, function () {
        var workitem = _this.get('workitem');

        if (workitem) {
          _this.publishToClient(CLIENT_WORKITEM_INTERACTION_CHANGED_EVENT, workitem);
        }
      });
      this.setupToasts();
      this.set('frame', this.element);
    },

    /**
     * Publish a message to the client.
     *
     * @param topic to publish on.
     * @param payload data to publish.
     */
    publishToClient: function publishToClient(topic, payload) {
      this.element.publish({
        topic: topic,
        payload: payload
      });
    },

    /**
     * Create workitems event bindings.
     */
    _bindCreateEvents: function _bindCreateEvents() {
      this.get('acdworkitem').on(_acdworkitem.WORKITEM_INTERACTION_CHANGED_EVENT, this, this.publishWorkitemInteractionToClient);
    },

    /**
     * @WillDestroyElement
     * Unbind workitems event bindings.
     */
    _unbindCreateEvents: function _unbindCreateEvents() {
      this.get('acdworkitem').off(_acdworkitem.WORKITEM_INTERACTION_CHANGED_EVENT, this, this.publishWorkitemInteractionToClient);
    },

    /**
     * Publish a message to the client subscribed to selected workitem changes.
     *
     * @param workitemInteraction WorkitemInteraction
     */
    publishWorkitemInteractionToClient: function publishWorkitemInteractionToClient(workitemInteraction) {
      var workitem = this.get('workitem');

      if (workitem && workitem.id === workitemInteraction.id && workitemInteraction.workitem.state === 'connected') {
        this.publishToClient(CLIENT_WORKITEM_INTERACTION_UPDATED_EVENT, workitemInteraction);
      }
    },

    /**
     * Returns branch name from query params if present otherwise null.
     */
    _getTaskManagementTargetBranch: function _getTaskManagementTargetBranch() {
      // window.location.hash to support old time machine links that were initially formatted incorrectly
      var queryParams = new URLSearchParams(window.location.search ? window.location.search : window.location.hash.split('?')[1]);
      return queryParams.get(WORKITEMS_VERSION);
    }
  });

  _exports.default = _default;
});