define("web-directory/components/entity-picture-content/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imageMappings = _exports.default = void 0;
  var computed = Ember.computed;
  var imageMappings = {
    person: {
      profile: 'uploads.images',
      profileInstructionText: 'images.profile',
      informal: 'images.informal',
      activeProfilePictureSection: 'images'
    },
    group: {
      profile: 'images.profilePictures',
      profileInstructionText: 'images.activeProfilePicture',
      informal: 'images.gallery',
      activeProfilePictureSection: 'images'
    }
  };
  _exports.imageMappings = imageMappings;

  var _default = Ember.Component.extend({
    classNames: ['entity-picture-content'],
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    permissions: Ember.inject.service(),
    notification: Ember.inject.service(),
    chatFrame: Ember.inject.service(),
    attrs: {
      open: function open() {},
      close: function close() {},
      entity: null,
      askToDelete: false
    },
    type: 'profile',
    // or 'informal'
    askToDelete: false,
    showUploadModal: false,
    actions: {
      cancel: function cancel() {
        this.attrs.close();
      },
      uploadPicture: function uploadPicture() {
        this.attrs.close();
        this.set('showUploadModal', true);
      },
      confirmUpload: function confirmUpload() {
        var type = this.get('type');
        var entity = this.get('entity');
        entity.notifyPropertyChange('uploadedProfilePictures');
        entity.notifyPropertyChange('uploadedInformalPhotos');
        var pictures = entity.get('uploadedProfilePictures');
        var picture = pictures[pictures.length - 1];

        if (!picture) {
          return;
        }

        if (type === 'profile') {
          this.send('selectUploadedImage', type, picture, false);
        }

        this.send('cancelUploadModal');
      },
      selectUploadedImage: function selectUploadedImage(type, image, active) {
        var _this = this;

        var entity = this.get('entity');
        var entityJid = entity.get('jid');
        var tag = 'profile-success';
        var edit = this.get('canEditProfilePicture');
        var section = entity.get('fieldConfig.images');

        if (!edit || active) {
          return;
        }

        type = type || this.get('type');

        if (entity.type === 'person' && this.get('session.features.PLUG-74_user-image-upload')) {
          // When adding and remove images using the public API it updates the group.
          // Each time it updates the group its version number increases, we go out and get the group
          // to make sure we have the latest version.  Once venue is release we will not
          // need to do this since it doesn't require a version like directory did.
          entity.reload().then(function (user) {
            section['version'] = user.version;

            if (type === 'profile') {
              entity.setProfilePicture(image);
              section = entity.get('fieldConfig.images');
            }

            return section;
          }).then(function (updatedSection) {
            entity.saveSection(updatedSection);
          }).then(function () {
            _this.get('chatFrame').confirmUploadImage(image, entityJid);

            _this.get('notification').info(_this.get('intl').lookup('notification.titles.profileSave'), _this.get('intl').lookup('notification.fieldSection.saved'), {
              forceBrowserNotification: true,
              hideAfter: 5,
              tag: tag
            });
          }).catch(function (err) {
            if (err && (err.errors || err.payload)) {
              var errors = err.errors || err.payload;

              var error = _lodash.default.head(errors);

              if (error && error.payload && error.payload.code === 'VERSION_MISMATCH') {
                return;
              }
            }

            _this.get('notification').error(_this.get('intl').lookup('notification.titles.profileSaveError'), _this.get('intl').lookup('notification.fieldSection.error'), {
              forceBrowserNotification: true,
              hideAfter: 5
            });

            entity.reload();
          }).finally(function () {
            _this.get('notification').info(_this.get('intl').lookup('notification.titles.profileSave'), _this.get('intl').lookup('notification.fieldSection.saved'), {
              forceBrowserNotification: true,
              hideAfter: 5,
              tag: tag
            });
          });
        } else {
          if (type === 'profile') {
            entity.setProfilePicture(image);
          }

          return entity.saveSection(section).then(function () {
            _this.get('chatFrame').confirmUploadImage(image, entityJid);

            _this.get('notification').info(_this.get('intl').lookup('notification.titles.profileSave'), _this.get('intl').lookup('notification.fieldSection.saved'), {
              forceBrowserNotification: true,
              hideAfter: 5,
              tag: tag
            });
          }).catch(function (err) {
            if (err && (err.errors || err.payload)) {
              var errors = err.errors || err.payload;
              var error = errors.firstObject;

              if (error && error.payload && error.payload.code === 'VERSION_MISMATCH') {
                return;
              }
            }

            _this.get('notification').error(_this.get('intl').lookup('notification.titles.profileSaveError'), _this.get('intl').lookup('notification.fieldSection.error'), {
              forceBrowserNotification: true,
              hideAfter: 5
            });

            entity.reload();
          }).finally(function () {
            _this.get('notification').info(_this.get('intl').lookup('notification.titles.profileSave'), _this.get('intl').lookup('notification.fieldSection.saved'), {
              forceBrowserNotification: true,
              hideAfter: 5,
              tag: tag
            });
          });
        }
      },
      deleteUploadedImage: function deleteUploadedImage(type, image, active) {
        var _this2 = this;

        var entity = this.get('entity');
        var entityType = entity.get('type');
        var promise = Ember.RSVP.resolve();

        if (active) {
          var images = entity.get('uploadedImages');

          if (!_lodash.default.isEmpty(images)) {
            var newImage = images[0];

            if (newImage) {
              var profilePictureSection = entity.get("fieldConfig.".concat(imageMappings[entityType].activeProfilePictureSection));
              entity.setProfilePicture(newImage);
              promise = entity.saveSection(profilePictureSection);
            }
          }
        }

        var fieldPath = imageMappings[entityType][type];
        var path = "entity.".concat(fieldPath);
        this.get(path).removeObject(image);

        if (entityType === 'person' && this.get('session.features.PLUG-74_user-image-upload')) {
          return this.entity.reload();
        }

        promise.then(function () {
          var sectionKey = fieldPath.split('.')[0];
          var section = entity.get("fieldConfig.".concat(sectionKey));
          return entity.saveSection(section);
        }).catch(function () {
          return _this2.get(path).addObject(image);
        });
      },
      cancelUploadModal: function cancelUploadModal() {
        this.set('showUploadModal', false);
        this.attrs.open();
      },
      cancelDeleteModal: function cancelDeleteModal() {
        this.set('askToDelete', false);
        this.set('showing', false);
      },
      changeType: function changeType(type) {
        this.set('type', type);
      },
      doneDeleting: function doneDeleting() {
        this.set('askToDelete', false);
        this.set('showing', true);
      }
    },
    _showing: false,
    showing: computed('canEditProfilePicture', {
      get: function get() {
        return this._showing;
      },
      set: function set(key, value) {
        this._showing = value;
        return value;
      }
    }),
    canUploadPicture: Ember.computed('session.user.isAdmin', 'entity.{readWritePermissions,type}', 'entity.uploadedImages.[]', 'entity.fieldConfig.uploads.images.maxRepeat', 'type', function () {
      if (this.get('session.user.isAdmin')) {
        return true;
      }

      if (this.get('entity.type') === 'group' && this.get('entity').isPersonOwner) {
        return this.get('entity').isPersonOwner(this.get('session.person'));
      }

      return this.hasAccessToPicture('uploads.images');
    }),
    canEditProfilePicture: Ember.computed('session.{user.isAdmin,features,person}', 'entity.{readWritePermissions,id}', 'profile.canEditProfilePictures', function () {
      if (this.get('session.user.isAdmin')) {
        return true;
      }

      if (this.get('entity.type') === 'group' && this.get('entity').isPersonOwner) {
        return this.get('entity').isPersonOwner(this.get('session.person'));
      } // Profile can be null, but if its set and this call is true, then lets return true


      if (this.get('profile.canEditProfilePictures')) {
        return true;
      }

      return this.get('session.person.id') === this.get('entity.id');
    }),
    canEditInformalPicture: Ember.computed('session.{user.isAdmin,features}', 'entity.{readWritePermissions,id}', 'profile.canEditProfilePictures', function () {
      if (this.get('session.user.isAdmin')) {
        return true;
      }

      if (this.get('entity.type') === 'group' && this.get('entity').isPersonOwner) {
        return this.get('entity').isPersonOwner(this.get('session.person'));
      } // Profile can be null, but if its set and this call is true, then lets return true


      if (this.get('profile.canEditProfilePictures')) {
        return true;
      }

      return this.get('session.person.id') === this.get('entity.id');
    }),
    canEditAgentImage: Ember.computed('entity.{readWritePermissions,id}', 'entity.fieldConfig.agent.profileImage', 'permissions.hasEngageAccess', function () {
      var field = this.get('entity.fieldConfig.agent.profileImage');

      if (_lodash.default.isEmpty(field)) {
        return false;
      }

      return this.hasAccessToPicture('agent.profileImage') && this.get('permissions.hasEngageAccess');
    }),
    hasAccessToPicture: function hasAccessToPicture(path) {
      var isSelf = this.get('session.person.id') === this.get('entity.id');
      var canEditProfiles = this.get('permissions.canEditProfiles');

      if (path === 'agent.profileImage') {
        return canEditProfiles;
      }

      if (path === 'uploads.images') {
        return isSelf || canEditProfiles;
      }

      return isSelf;
    }
  });

  _exports.default = _default;
});