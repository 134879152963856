define("web-directory/utils/sound-recorder", ["exports", "howler-but-with-updates", "web-directory/utils/testing"], function (_exports, _howlerButWithUpdates, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Howl = _howlerButWithUpdates.default.Howl; // Recorder from: https://github.com/chris-rudmin/Recorderjs

  var _window = window,
      MediaRecorder = _window.MediaRecorder;

  var _default = Ember.Object.extend(Ember.Evented, {
    ajax: Ember.inject.service(),
    application: Ember.inject.service(),
    recording: false,
    blob: null,
    recorder: null,
    deferred: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (_testing.isTesting) return;
      this.setupRecorder();
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      if (this.recorder) {
        this.recorder.clearStream();
        this.recorder.audioContext.suspend();
      }
    },
    mimeType: Ember.computed(function () {
      var mimeTypes = ['audio/mp3', 'audio/ogg', 'audio/wav', 'audio/webm'];
      return mimeTypes.find(function (mimeType) {
        return MediaRecorder.isTypeSupported(mimeType);
      });
    }),
    format: Ember.computed('mimeType', function () {
      // Forcing OGG for now
      // return _.last(this.get('mimeType').split('/'));
      return 'ogg';
    }),
    blobURL: Ember.computed('blob', function () {
      var blob = this.get('blob');

      if (blob) {
        return this.getBlobUrl(blob);
      }

      return null;
    }),
    setupRecorder: function setupRecorder() {
      var _this = this;

      this.deferred = Ember.RSVP.defer();
      this.loadEncoder().then(function (encoderPath) {
        _this.recorder = new window.Recorder({
          encoderPath: encoderPath
        });
        var context = _this.recorder.audioContext;

        if (context && context.state === 'suspended') {
          return context.resume();
        }
      }).then(function () {
        _this.recorder.addEventListener('streamReady', _this._streamReady.bind(_this));

        _this.recorder.addEventListener('stop', _this._stoppedRecording.bind(_this));

        _this.recorder.addEventListener('dataAvailable', _this._onDataAvailable.bind(_this));

        _this.deferred.resolve();
      });
    },
    reset: function reset() {
      this.set('blob', null);
    },
    startRecording: function startRecording() {
      var _this2 = this;

      this.deferred.promise.then(function () {
        _this2.recorder.initStream();

        _this2.set('blob', null);

        _this2.set('chunks', []);
      });
    },
    resumeRecording: function resumeRecording() {
      this.recorder.resume();
    },
    pauseRecording: function pauseRecording() {
      this.recorder.pause();
    },
    stopRecording: function stopRecording() {
      this.recorder.stop();
    },
    createHowlFromBlob: function createHowlFromBlob(blob) {
      var blobUrl = URL.createObjectURL(blob);
      return new Howl({
        src: [blobUrl],
        loop: false,
        format: [this.get('format')],
        html5: true
      });
    },
    getBlobUrl: function getBlobUrl(blob) {
      return URL.createObjectURL(blob);
    },
    loadEncoder: function loadEncoder() {
      var _this3 = this;

      return this._loadSoundRecorder().then(function () {
        return _this3._loadWorker();
      });
    },
    _onDataAvailable: function _onDataAvailable(event) {
      var blob = new Blob([event.detail], {
        type: 'audio/ogg'
      });
      this.set('blob', blob);
      this.trigger('blobready');
    },
    _streamReady: function _streamReady() {
      this.set('recording', true);
      this.recorder.start();
    },
    _stoppedRecording: function _stoppedRecording() {
      this.set('recording', false);
    },
    _loadSoundRecorder: function _loadSoundRecorder() {
      if (window.Recorder) {
        return Ember.RSVP.resolve();
      }

      var path = "".concat(this.get('application.urlprefix'), "assets/recorder.min.js");
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.getScript(path, function success() {
          resolve();
        });
      });
    },
    _loadWorker: function _loadWorker() {
      var path = 'assets/workers/encoderWorker.min.js';
      return Ember.$.get(path).then(function (contents) {
        var blob = new Blob([contents]);
        return window.URL.createObjectURL(blob);
      });
    }
  });

  _exports.default = _default;
});