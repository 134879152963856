define("web-directory/mixins/conversation-uri-builder", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BASE_URI_V2 = 'api/v2/conversations';
  var inject = Ember.inject,
      Mixin = Ember.Mixin;

  var _default = Mixin.create({
    session: inject.service(),
    application: inject.service(),
    acdNormalizedMessages: Ember.computed.bool('session.features.acdNormalizedMessages'),
    pcUri: function pcUri() {
      var application = this.get('application');
      return application.pcV2Uri.apply(application, arguments);
    },
    baseUri: function baseUri() {
      var application = this.get('application');
      return application.pcV2Uri(BASE_URI_V2);
    },
    callsUri: function callsUri(id) {
      return "".concat(this.communicationUri(id, 'calls'));
    },
    createCommunicationUri: function createCommunicationUri(mediaType) {
      return "".concat(this.baseUri(), "/").concat(mediaType);
    },
    communicationUri: function communicationUri(id, mediaType) {
      return !_lodash.default.isEmpty(mediaType) ? "".concat(this.baseUri(), "/").concat(mediaType, "/").concat(id) : "".concat(this.baseUri(), "/").concat(id);
    },
    communicationsUri: function communicationsUri(id, participantId, mediaType, mediaId) {
      return "".concat(this.participantUri(id, participantId, mediaType), "/communications/").concat(mediaId);
    },
    communicationsByTypeUri: function communicationsByTypeUri(mediaType) {
      return this.baseUri() + '?communicationType=' + mediaType;
    },
    consultUri: function consultUri(id, participantId, mediaType, entity) {
      if (this.get('session.features.acdTransferPermissions') && entity) {
        entity = entity.toLowerCase() === 'person' ? 'agent' : entity;
        return this.participantUri(id, participantId, mediaType) + '/consult' + "/".concat(entity);
      }

      return this.participantUri(id, participantId, mediaType) + '/consult';
    },
    disconnectMediaTypeUri: function disconnectMediaTypeUri(id, mediaId, participantId, mediaType) {
      var uri = "".concat(BASE_URI_V2, "/").concat(mediaType, "/").concat(id, "/participants/").concat(participantId, "/communications/").concat(mediaId);
      return this.get('application').pcV2Uri(uri);
    },
    getConversationUri: function getConversationUri(id) {
      return this.communicationUri(id);
    },
    mediaRequestUri: function mediaRequestUri(id) {
      return "".concat(this.communicationUri(id, 'chats'), "/mediarequests");
    },
    monitorUri: function monitorUri(id, participantId, mediaType) {
      return this.participantUri(id, participantId, mediaType) + '/monitor';
    },
    participantUri: function participantUri(id, participantId, mediaType) {
      return this.participantsUri(id, mediaType) + participantId;
    },
    participantsUri: function participantsUri(id, mediaType) {
      return this.communicationUri(id, mediaType) + '/participants/';
    },
    participantDetailsUri: function participantDetailsUri(id, participantId) {
      return this.participantUri(id, participantId) + '/details';
    },
    participantWrapupUri: function participantWrapupUri(id, participantId, mediaType) {
      return this.participantUri(id, participantId, mediaType) + '/wrapup';
    },
    sessionShareCodeUri: function sessionShareCodeUri(id, participantId, mediaType) {
      return this.participantUri(id, participantId, mediaType) + '/codes';
    },
    expireSessionShareCodeUri: function expireSessionShareCodeUri(id, participantId, securityKey) {
      return this.participantUri(id, participantId) + '/codes/' + securityKey;
    },
    participantWrapupCodesUri: function participantWrapupCodesUri(id, participantId, mediaType) {
      return this.participantUri(id, participantId, mediaType) + '/wrapupcodes';
    },
    participantCommunicationWrapupUri: function participantCommunicationWrapupUri(id, participantId, mediaType, communicationId) {
      return this.participantUri(id, participantId, mediaType) + '/communications/' + communicationId + '/wrapup';
    },
    replaceUri: function replaceUri(id, participantId, query, mediaType, entity) {
      if (query) {
        return this.participantUri(id, participantId, mediaType) + '/replace' + this.getQueryStr(query);
      } else {
        if (this.get('session.features.acdTransferPermissions') && entity) {
          entity = entity.toLowerCase() === 'person' ? 'agent' : entity;
          return this.participantUri(id, participantId, mediaType) + '/replace' + "/".concat(entity);
        }

        return this.participantUri(id, participantId, mediaType) + '/replace';
      }
    },
    scheduleCallbackUri: function scheduleCallbackUri(id, participantId) {
      return "".concat(this.participantUri(id, participantId), "/callbacks");
    },
    tagsUri: function tagsUri(id) {
      //Are we using this?
      return this.communicationUri(id) + '/tags';
    },
    wrapUpUri: function wrapUpUri(id, participantId) {
      return this.participantUri(id, participantId) + '/wrapup';
    },
    wrapUpCodesUri: function wrapUpCodesUri(id) {
      return this.communicationUri(id) + '/wrapupCodes';
    },
    messagesUri: function messagesUri(id, mediaType) {
      return "".concat(this.communicationUri(id, mediaType), "/messages");
    },
    sendChatMessageUri: function sendChatMessageUri(conversationId, communicationId) {
      return "".concat(this.communicationUri(conversationId, 'chats'), "/communications/").concat(communicationId, "/messages");
    },
    sendTypingUri: function sendTypingUri(conversationId, communicationId) {
      return "".concat(this.communicationUri(conversationId, 'chats'), "/communications/").concat(communicationId, "/typing");
    },
    sendMessagingTypingUri: function sendMessagingTypingUri(conversationId, communicationId) {
      return "".concat(this.communicationUri(conversationId, 'messages'), "/communications/").concat(communicationId, "/typing");
    },
    draftUri: function draftUri(id, mediaType, query) {
      if (query) {
        return "".concat(this.messagesUri(id, mediaType), "/draft").concat(this.getQueryStr(query));
      } else {
        return "".concat(this.messagesUri(id, mediaType), "/draft");
      }
    },
    draftAttachmentUri: function draftAttachmentUri(id, attachmentId, mediaType) {
      return "".concat(this.draftUri(id, mediaType), "/attachments/").concat(attachmentId);
    },
    smsUri: function smsUri(id) {
      return "".concat(this.communicationUri(id, 'messages'), "/messages");
    },
    smsHistoryUri: function smsHistoryUri(id) {
      return this.getConversationUri(id);
    },
    smsMessageDetailUri: function smsMessageDetailUri(id, messageId) {
      // api/v2/conversations/messages/{conversationId}/messages/{messageId}/details
      return "".concat(this.smsUri(id), "/").concat(messageId, "/details/?useNormalizedMessage=").concat(this.get('acdNormalizedMessages'));
    },
    smsMessageBatchUri: function smsMessageBatchUri(id) {
      return "".concat(this.smsUri(id), "/bulk?useNormalizedMessage=").concat(this.get('acdNormalizedMessages'));
    },
    sendSMSMessageUri: function sendSMSMessageUri(id, communicationId) {
      return "".concat(this.communicationUri(id, 'messages'), "/communications/").concat(communicationId, "/messages?useNormalizedMessage=").concat(this.get('acdNormalizedMessages'));
    },
    createMediaAttachmentUri: function createMediaAttachmentUri(id, communicationId) {
      return "".concat(this.communicationUri(id, 'messages'), "/communications/").concat(communicationId, "/messages/media");
    },
    getMediaAttachmentUri: function getMediaAttachmentUri(id, communicationId, mediaId) {
      return "".concat(this.communicationUri(id, 'messages'), "/communications/").concat(communicationId, "/messages/media/").concat(mediaId);
    },
    copyMediaAttachmentUri: function copyMediaAttachmentUri(conversationId) {
      // api/v2/conversations/emails/{conversationId}/messages/draft/attachments/copy
      return "".concat(this.communicationUri(conversationId, 'emails'), "/messages/draft/attachments/copy");
    },
    requestACWUri: function requestACWUri(interactionId, participantId, agentCommunicationId) {
      // api/v2/conversations/aftercallwork/{conversationId}/participants/{participantId}/communications/{communicationId}
      return "".concat(this.communicationUri(''), "aftercallwork/").concat(interactionId, "/participants/").concat(participantId, "/communications/").concat(agentCommunicationId);
    },
    getSupportedContentUri: function getSupportedContentUri(subType, id) {
      return "".concat(this.baseUri(), "/messaging/integrations/").concat(subType, "/").concat(id, "?expand=supportedContent");
    },
    getDeploymentConfigUri: function getDeploymentConfigUri(deploymentId) {
      var getSupportedContent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (getSupportedContent) {
        return "".concat(this.pcUri(), "api/v2/webdeployments/deployments/").concat(deploymentId, "/configurations?expand=supportedContent");
      }

      return "".concat(this.pcUri(), "api/v2/webdeployments/deployments/").concat(deploymentId, "/configurations?type=active");
    },
    getIntegrationConfigUri: function getIntegrationConfigUri(subMessageType, integrationId) {
      return "".concat(this.baseUri(), "/messaging/integrations/").concat(subMessageType, "/").concat(integrationId, "?expand=messagingSetting");
    },
    getConversationSummaryFeedbackUri: function getConversationSummaryFeedbackUri(conversationId, summaryId) {
      return "".concat(this.baseUri(), "/").concat(conversationId, "/summaries/").concat(summaryId, "/feedback");
    },
    getQueryStr: function getQueryStr(parameters) {
      var query = '';
      var arrayParameterCollection = {};
      var params;

      if (parameters && !_lodash.default.isEmpty(parameters)) {
        // Get all parameters that are simple key-value pairs and where the value is
        // NOT an array
        params = _lodash.default.reduce(parameters, function (result, value, key) {
          if (Array.isArray(parameters[key])) {
            arrayParameterCollection[key] = value;
          } else {
            result[key] = value;
          }

          return result;
        }, {}); // serialize the parameters

        query = '?' + $.param(params);

        _lodash.default.forIn(arrayParameterCollection, function (arr, key) {
          // convert the array values into query parameters that will result in:
          //   ?key=value1&key=value2&...
          _lodash.default.each(arr, function (item) {
            if (query === '?') {
              query += key + '=' + item;
            } else {
              query += '&' + key + '=' + item;
            }
          });
        });
      }

      return query;
    }
  });

  _exports.default = _default;
});