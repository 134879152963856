define("web-directory/components/volt-frame-router/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aQrlJFkU",
    "block": "{\"statements\":[[9,\"frame-router\",{\"attrs\":[[16,\"route\",[26,[\"route\"]],null],[15,\"frame-id\",\"volt-frame\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/volt-frame-router/template.hbs"
    }
  });

  _exports.default = _default;
});