define("web-directory/components/multi-select-target-dropdown-v2/component", ["exports", "ember-purecloud-components/utils/email", "web-directory/utils/acd", "web-directory/utils/safeSet"], function (_exports, _email, _acd, _safeSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The default value for max tags to be applied. Configurable in the component.
   */
  var MAX_TAGS = 50;

  var _default = Ember.Component.extend({
    classNames: ['multi-select-target-dropdown-v2'],
    classNameBindings: ['hideTargetDropdown:hide-target-dropdown'],

    /**
     * @attribute
     *
     * The currently authenticated user information
     */
    authUser: null,

    /**
     * @attribute
     *
     * the type of interaction this is in context of
     */
    interactionType: null,

    /**
     * @attribute
     *
     * the placeholder text shown on the input
     */
    placeholder: null,

    /**
     * @attribute
     *
     * the label text shown on the input for the screenreader
     */
    label: null,

    /**
     * @attribute
     *
     * `true` if the user should be autofocused into the tags input
     */
    autofocus: false,

    /**
     * @attribute
     *
     * the filters to be included in this target selector
     */
    includeFilters: [],

    /**
     * @attribute
     *
     * The currently selected value of the component. This value should be passed
     * in and stored by the parent. We do not want to mutate this value from within the
     * component itself.
     */
    value: Ember.A([]),

    /**
     * @attribute
     *
     * `true` if the target dropdown logic should be supressed and the dropdown be hidden.
     * This should be used if the user has been tasked with added a recipient, but shouldn't
     * use the systems internal data to create the recipient, but rather just leveraging the input
     */
    hideTargetDropdown: false,

    /**
     * @attribute
     *
     * the max amount of tags that can be applied to the selector
     */
    maxTags: MAX_TAGS,

    /**
     * @attribute this value can be mutated within this component
     *
     * @example 2-way data binding
     * searchValue=(mut searchValue)
     *
     * the value of the main input. if updated by parent, it will automatically up the input
     * in the template and will search once input has been focused.
     */
    searchValue: '',

    /**
     * @attribute
     *
     * class names passed in to be applied to the input tag
     */
    inputClasses: '',

    /**
     * `true` if the target dropdown is open
     */
    open: false,

    /**
     * Reference to the input element in the template created after the bootstrap
     * selector component has been initialized
     */
    input: null,

    /**
     * Maps the current value of the component into an array of id's or unique values to pass
     * to the target dropdown in order to prevent the ability of selecting the same participant
     * twice.
     */
    valueIds: Ember.computed.map('value', function (tag) {
      var _tag$data$id, _tag$data;

      return (_tag$data$id = (_tag$data = tag.data) === null || _tag$data === void 0 ? void 0 : _tag$data.id) !== null && _tag$data$id !== void 0 ? _tag$data$id : tag.value;
    }),

    /**
     * A mock interaction object to set the type of for target selection context
     */
    mockInteraction: Ember.computed('interactionType', function () {
      return {
        type: this.get('interactionType')
      };
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('hideTargetDropdown')) {
        this.hideOnClickOutside(this.element);
        this.hideOnKeyOutside(this.element);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.removeEventListener('click', this.outsideClickListener);
      this.$(this.element).off('keydown', this.outsideKeyListener);
    },

    /**
     * @listener
     *
     * reference to the listener for when the element has been clicked outside of.
     */
    outsideClickListener: function outsideClickListener(event) {
      return (0, _acd.warnClosureAction)('outsideClickListener', event);
    },

    /**
     * @listener
     *
     * reference to the listener for when the element has been clicked outside of.
     */
    outsideKeyListener: function outsideKeyListener() {
      return (0, _acd.warnClosureAction)('outsideKeyListener', event);
    },

    /**
     * Listeners to determine if the user has clicked outside of the element being
     * passed through. If the element has been clicked outside of, then the dropdown
     * will close.
     *
     * @param element the element to check against
     */
    hideOnClickOutside: function hideOnClickOutside(element) {
      var _this = this;

      if (element) {
        this.set('outsideClickListener', function (event) {
          return _this.checkFocusState(event.target);
        });
        document.addEventListener('click', this.outsideClickListener);
      }
    },

    /**
     * We use `keydown` because we want to auto close the dropdown if the user tabs out
     * of the element. `keypress` doesn't support all of the keys such as `tab`.
     * `keydown` also supports holding the key down for a duration.
     *
     * @param element the element to apply the keydown event unto.
     */
    hideOnKeyOutside: function hideOnKeyOutside(element) {
      var _this2 = this;

      if (element) {
        this.set('outsideKeyListener', function () {
          return _this2.checkFocusState();
        });
        this.$(this.element).on('keydown', this.outsideKeyListener);
      }
    },

    /**
     * Checks the current focus state of this element based on the element to check against (i.e.
     * click target). If no element is provided, then we will check against the
     * `document.activeElement` instead.
     *
     * @param element the element to check the focus out state against
     */
    checkFocusState: function checkFocusState() {
      var _this3 = this;

      var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // setTimeout is required for accessing `activeElement`. Otherwise it will show the
      // activeElement is the document body in between switching focused elements.
      setTimeout(function () {
        var _element;

        element = (_element = element) !== null && _element !== void 0 ? _element : document.activeElement;

        if (!_this3.element.contains(element)) {
          _this3.set('open', false);
        }
      });
    },

    /**
     * Let the component determine if the popover should be open or closed based on the
     * current state of the `searchValue`.
     */
    determineOpen: function determineOpen() {
      (0, _safeSet.default)(this, 'open', !!this.get('searchValue'));
    },

    /**
     * Updates the `searchValue` property and any other additional logic that may need
     * to go along with that in one isolated place.
     *
     * @param value the value to update the search property with
     */
    updateSearch: function updateSearch(value) {
      this.set('searchValue', value); // if there is a value we should wait a short time to debounce to make sure
      // we delay the opening until after user input is finished

      if (value) {
        Ember.run.debounce(this, this.determineOpen, 500);
      } else {
        // if the input has been removed we can hide the popover immediately.
        this.set('open', false);
      }
    },

    /**
     * Adds the raw text input to the value array to be passed into the tags input.
     *
     * @param text the raw text of the tag to add
     */
    _addTag: function _addTag(text) {
      var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (!text) {
        this.submit();
        return;
      }

      if (this.get('value').length < this.get('maxTags')) {
        switch (this.get('interactionType')) {
          case 'email':
            if ((0, _email.isValidEmailAddress)(text)) {
              this.targetSelected({
                email: text
              });

              if (reset) {
                this.updateSearch('');
              }
            }

            break;

          default:
            if (text) {
              this.targetSelected({
                phoneNumber: text
              });

              if (reset) {
                this.updateSearch('');
              }
            }

            break;
        }
      }
    },

    /**
     * Quick way to bulk add tags
     *
     * @param value the value of tags to add
     */
    _addTags: function _addTags(value) {
      var _this4 = this;

      value.forEach(function (item) {
        return _this4._addTag(item, false);
      });
      this.updateSearch('');
    },

    /**
     * Invoked when the user inputs data into the main input of the component.
     *
     * @param event the event data provided by the input
     */
    _inputChange: function _inputChange(event) {
      var _ref = event.target,
          value = _ref.value;
      this.updateSearch(value);
    },

    /**
     * Invoked when the user focused the input
     */
    _focusInput: function _focusInput() {
      if (this.get('searchValue')) {
        this.set('open', true);
      }
    },

    /**
     * @action
     *
     * Handles when the user presses the enter key while the search input is empty
     */
    submit: function submit() {
      return (0, _acd.warnClosureAction)('submit', null);
    },

    /**
     * @action
     *
     * Handles the removal of a target
     *
     * @param target the target to remove from the value
     */
    targetRemoved: function targetRemoved(index) {
      return (0, _acd.warnClosureAction)('targetRemoved', {
        index: index
      });
    },

    /**
     * @action passed from the parent
     *
     * Handles the selection of a target
     *
     * @param target the selected data corresponding to the target
     */
    targetSelected: function targetSelected(target) {
      return (0, _acd.warnClosureAction)('targetSelected', target);
    },
    actions: {
      /**
       * Invoked when the user selects data from within the target selector component
       *
       * @param target the data that was selected
       */
      onTargetSelected: function onTargetSelected(target) {
        this.set('open', false);
        this.targetSelected(target);
        this.updateSearch('');
      },
      onAddTag: function onAddTag(value) {
        this._addTag(value);
      },
      onSubmit: function onSubmit() {
        this.submit();
      },
      onBulkAddTags: function onBulkAddTags(values) {
        this._addTags(values);
      },
      onRemoveTag: function onRemoveTag(index) {
        this.targetRemoved(index);
      },
      onInputFocus: function onInputFocus() {
        this._focusInput();
      },
      onInputChange: function onInputChange(event) {
        this._inputChange(event);
      }
    }
  });

  _exports.default = _default;
});