define("web-directory/components/application-links/component", ["exports", "lodash", "ember-purecloud-components/utils/code-utils", "web-directory/models/app", "web-directory/models/app-pool", "web-directory/utils/menu"], function (_exports, _lodash, _codeUtils, _app, _appPool, _menu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      getOwner = Ember.getOwner,
      observer = Ember.observer,
      run = Ember.run,
      typeOf = Ember.typeOf;
  var GENERAL_APP_FEATURE_CATEGORY = 'GENERAL';
  var PERFORMANCE_NAV_ORDER = ['#/analytics/dashboards', 'dashboard', 'dashboard/agent', '#/analytics/queues/activity/me', '#/analytics/queues/activity', '#/analytics/queues/performance', '#/analytics/agents/performance/me', '#/analytics/agents/development/me', '#/analytics/agents/schedule/me', '#/analytics/agents/performance', '#/analytics/agents/development', 'agents', '#/analytics/interactions', '#/analytics/content', '#/analytics/dnis/performance', '#/analytics/skills/performance', '#/analytics/wrapup/performance', '#/analytics/flows/performance', '#/analytics/outcomes/performance', '#/analytics/quality/surveys/performance', 'outbound/dashboards/outboundCampaigns', 'dashboards/outboundCampaigns', 'admin/scheduledCallbacks', '#/analytics/outbound/scheduledCallbacks', '#/analytics/journey/actionMaps', '#/admin/wfm/intradayMonitoring', '#/admin/wfm/adherence/realtime', '#/admin/wfm/adherence/historicalV2/historical-adherence', '#/admin/wfm/shrinkage/historical', '#/analytics/api-usage', '#/analytics/topic/trends', '#/analytics/bots/performance', '#/analytics/predictive-routing/queues'];
  /**
   * Maps path prefixes to top-level menu-items (by className).
   * You can specify [] to opt out of path-based lookups.
   * See utils/menu:getActiveTopLevelMenuItem for more details
   */

  var menuItemActivePathPrefixesMap = {
    'menu-search': ['/person/', '/group/'],
    'menu-content-management': ['/content-management/'],
    'menu-performance-ddl': ['/analytics/', '/engage/dashboard/'],
    'menu-partners': ['/partners/'],
    'menu-admin': ['/admin/', '/engage/telephonyAdmin/', '/engage/admin/', '/engage/outbound/admin/', '/admin/outbound/admin/', '/engage/quality/admin/', '/quality/admin/', '/topics-definitions/', '/engage/wfm/admin/']
  };

  var _default = Ember.Component.extend({
    classNames: ['application-links'],
    classNameBindings: ['application.showTinyNavMenu:mobile-links', 'session.user.isTrustee'],
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    apps: Ember.inject.service(),
    adminAccess: Ember.inject.service(),
    adminui: Ember.inject.service('adminui'),
    sidebar: Ember.inject.service(),
    engage: Ember.inject.service(),
    permissions: Ember.inject.service(),
    documents: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: null,
    // Used to trigger active helper updates for the menu
    currentURL: computed.readOnly('router.currentURL'),
    showTinyNavDropdown: false,
    canSeeReports: false,
    appMenuItemsByFeatureCategory: null,
    activityLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.activity');
    }),
    searchLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.directory');
    }),
    myOrganizationLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.myOrganization');
    }),
    documentsLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.documents');
    }),
    tasksLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.tasks');
    }),
    reportsLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.reports');
    }),
    journeyAnalyticsLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.journeyAnalytics');
    }),
    adminLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.admin');
    }),
    performanceLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.performance');
    }),
    appsLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.apps');
    }),
    relateLinkTitle: computed(function () {
      return this.get('intl').t('components.navMenu.externalContacts');
    }),
    actions: {
      leafItemClick: function leafItemClick(node) {
        this.closeMenus();
        this.get('sidebar').ensureRouteVisibility();

        if (node && node.action && typeof node.action === 'function') {
          try {
            node.action();
          } catch (e) {// Empty error handling
          }
        }
      },
      leftItemClickEmpty: function leftItemClickEmpty() {
        this.closeMenus();
        this.get('sidebar').ensureRouteVisibility();
      },
      onDropdownChange: function onDropdownChange(topLevelMenuItem, isOpen) {
        if (isOpen) {
          this.set('openSubMenuClassName', topLevelMenuItem.className);
        } else {
          this.set('openSubMenuClassName', null);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      try {
        var router = getOwner(this).lookup('router:main');

        if (!router) {
          Ember.Logger.error('Located empty router service.  Active menu marking disabled.', {
            remoteData: {
              type: typeOf(router)
            }
          });
        }

        this.set('router', router || null);
      } catch (e) {
        Ember.Logger.error('Failed to lookup router service.  Active menu marking disabled.', {
          thrownError: e
        });
        this.set('router', null);
      } // Unlikely the app will be ready at this point, the observer will handle apps env changes


      this._buildAppMenuItems();

      var standaloneAppPool = this.get('apps.standaloneAppPool');

      if (standaloneAppPool) {
        standaloneAppPool.on(_appPool.APPS_RELOADED_EVENT, this, this._buildAppMenuItems);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var standaloneAppPool = this.get('apps.standaloneAppPool');

      if (standaloneAppPool) {
        standaloneAppPool.off(_appPool.APPS_RELOADED_EVENT, this, this._buildAppMenuItems);
      }
    },
    _appsEnvChanged: observer('apps.appsSupported', 'session.isAuthenticated', function () {
      this._buildAppMenuItems();
    }),
    _buildAppMenuItems: function _buildAppMenuItems() {
      // Only fetch when feature is enabled and wait for authentication for orgId, etc
      if (this.get('apps.appsSupported') && this.get('session.isAuthenticated')) {
        run.once(this, function () {
          var _this2 = this;

          this.get('apps.standaloneAppPool').findAllApps().then(function (standaloneApps) {
            var appMenuItemsByCategory = {};

            if (standaloneApps && standaloneApps.length > 0) {
              standaloneApps.sort(function (menuItem1, menuItem2) {
                return menuItem1.get('name').localeCompare(menuItem2.get('name'));
              }).reduce(function (result, currApp) {
                var currCategory = currApp.get('featureCategory') || GENERAL_APP_FEATURE_CATEGORY;
                var currAppEntry = {
                  className: "menu-app-".concat(currApp.get('id')),
                  label: currApp.get('name'),
                  route: {
                    name: 'third-party.app-embed',
                    segment: currApp.get('id')
                  }
                };

                if (Object.prototype.hasOwnProperty.call(result, currCategory)) {
                  result[currCategory].push(currAppEntry);
                } else {
                  result[currCategory] = [currAppEntry];
                }

                return result;
              }, appMenuItemsByCategory);
            }

            _this2.set('appMenuItemsByFeatureCategory', appMenuItemsByCategory);
          }).catch(function (reason) {
            Ember.Logger.error('Failed to fetch standalone apps for menu', reason);

            _this2.set('appMenuItemsByFeatureCategory', null);
          });
        });
      } else {
        this.set('appMenuItemsByFeatureCategory', null);
      }
    },

    /**
     * Attempt to locate one and only one top-level menu item to mark as active.
     * This implementation is decent (espeically with the path prefixes map); however,
     * there are edge cases.  An even more robust approach might be to pass down
     * more of the route structure (embedded engage/admin) with this menu to convey
     * proper hierarchy.  But, for now, the existing hierarchy and path prefixes
     * should suffice.
     *
     * Note: currentURL triggers recalculation and is required (even though it's not directly consumed)
     */
    activeTopLevelMenuItem: Ember.computed('menu', 'router', 'currentURL', function () {
      var menu = this.get('menu');
      var router = this.get('router');

      if (!menu || !router) {
        return null;
      }

      var menuItemBasePathsMap = Object.entries(menuItemActivePathPrefixesMap).reduce(function (acc, _ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            className = _ref2[0],
            basePaths = _ref2[1];

        var topLevelMatch = menu.find(function (curr) {
          return curr.className === className;
        });

        if (topLevelMatch) {
          acc.set(topLevelMatch, basePaths);
        }

        return acc;
      }, new Map());
      return (0, _menu.getActiveTopLevelMenuItem)(menu, router, {
        menuItemBasePathsMap: menuItemBasePathsMap
      });
    }),
    menu: Ember.computed('session.person.{isAdmin,isContentUser}', 'permissions', 'adminAccess.userHasAdminAccess', 'adminui.adminEntries', 'navEntries', 'canSeeReports', 'appMenuItemsByFeatureCategory', 'documentsSegment', 'permissions.canViewDirectory', 'router', 'currentURL', function () {
      var _this3 = this;

      var adminEntries = this.get('adminui.adminEntries');
      var appMenuItemsByFeatureCategory = this.get('appMenuItemsByFeatureCategory');
      var useNewActivityRoute = this.get('permissions.canViewAgentActivity');
      var router = this.get('router');
      if (!router) return [];
      var currentURL = this.get('currentURL');
      var result = [{
        className: 'menu-activity',
        iconClassNames: ['pc', 'pc-no-activity'],
        label: this.get('activityLinkTitle'),
        route: useNewActivityRoute ? 'center-stage-frame-agentHome' : 'timeline'
      }];
      var directoryMenuItems = [];
      var pcDirectoryMenuItem;

      if (this.get('permissions.canViewDirectory')) {
        pcDirectoryMenuItem = {
          className: 'menu-search',
          iconClassNames: ['pc-moon', 'pc-directory'],
          label: this.get('searchLinkTitle'),
          route: 'search'
        };
        directoryMenuItems.push(pcDirectoryMenuItem);
      }

      if (this.get('permissions.hasExternalContactsViewContactAccess') || this.get('permissions.hasExternalContactsViewOrganizationAccess')) {
        var linkData = {
          className: 'menu-relate',
          iconClassNames: ['pc-moon', 'pc-relate'],
          label: this.get('relateLinkTitle'),
          route: 'center-stage-frame-contactsAndOrganizationsUI'
        };
        directoryMenuItems.push(linkData);
      }

      var directoryAppMenuItems = appMenuItemsByFeatureCategory ? appMenuItemsByFeatureCategory[_app.FEATURE_CATEGORIES.DIRECTORY] : [];

      if (!_lodash.default.isEmpty(directoryAppMenuItems)) {
        directoryMenuItems = directoryMenuItems.concat(directoryAppMenuItems);
      }

      if (directoryMenuItems.length > 1) {
        // Change the title of the internal PureCloud directory menu item
        if (!!pcDirectoryMenuItem) {
          pcDirectoryMenuItem.label = this.get('myOrganizationLinkTitle');
        }

        result.push({
          className: 'menu-search',
          iconClassNames: ['pc-moon', 'pc-directory'],
          label: this.get('searchLinkTitle'),
          open: false,
          children: directoryMenuItems
        });
      } else if (!!directoryMenuItems[0]) {
        // Push the internal PureCloud directory menu item as the top-level item
        result.push(directoryMenuItems[0]);
      }

      if (this.get('session.person.isContentUser')) {
        result.push({
          className: 'menu-content-management',
          iconClassNames: ['pc', 'pc-content'],
          label: this.get('documentsLinkTitle'),
          route: {
            name: 'content-management',
            segment: this.get('documentsSegment')
          }
        });
      }

      if (this.get('permissions').validateDonutPermission('affiliateOrganization:clients:view')) {
        result.push({
          className: 'menu-partners',
          iconClassNames: ['pc', 'pc-tasks'],
          label: this.get('intl').t('components.navMenu.partners.partners'),
          route: 'partners.clients'
        });
      }

      var perfNav = this.get('navEntries.performanceNav');

      if (perfNav) {
        var perfNavEntry = {
          className: 'menu-performance-ddl',
          iconClassNames: ['pc', 'pc-performance'],
          label: this.get('performanceLinkTitle'),
          open: false,
          children: []
        };
        var isNewAgentsViewEnabled = this.get('permissions.canViewAgentPerformance'); // Add all embedded engage nav items

        perfNavEntry.children = perfNav.filter(function (currPerfNavItem) {
          return !(currPerfNavItem.path === 'agents' && isNewAgentsViewEnabled) && !(currPerfNavItem.path === 'queues') && !(currPerfNavItem.path === 'admin/scheduledCallbacks');
        }).map(function (currPerfNavItem) {
          return {
            className: currPerfNavItem.className,
            label: currPerfNavItem.text,
            route: {
              name: currPerfNavItem.name || 'engage.embedded',
              segment: currPerfNavItem.path
            }
          };
        });
        perfNavEntry.children.push({
          className: _codeUtils.default.generateClassName('workspace', 'menu-'),
          label: this.get('intl').t('components.navMenu.workspace'),
          url: '#/analytics',
          target: '_top'
        });

        if (Array.isArray(adminEntries)) {
          var wfmAdminEntry = adminEntries.find(function (e) {
            return e.id === 'WFM';
          });

          if (wfmAdminEntry && Array.isArray(wfmAdminEntry.subLinks)) {
            wfmAdminEntry.subLinks.filter(function (wfmAdminEntrySubLink) {
              var isIntraday = wfmAdminEntrySubLink.id === 'WFM_INTRADAY';
              var isRTA = wfmAdminEntrySubLink.id === 'WFM_REALTIMEADHERENCE';
              var isHA = wfmAdminEntrySubLink.id === 'WFM_HISTORICALADHERENCEV2';
              var isHS = wfmAdminEntrySubLink.id === 'WFM_HISTORICALSHRINKAGE';

              if (_this3.get('permissions.hasShrinkageViewAccess')) {
                return isIntraday || isRTA || isHA || isHS;
              }

              return isIntraday || isRTA || isHA;
            }).forEach(function (wfmLink) {
              perfNavEntry.children.push({
                className: _codeUtils.default.generateClassName(wfmLink.id, 'menu-'),
                label: wfmLink.text,
                url: "#/admin/".concat(wfmLink.href),
                target: '_top'
              });
            });
          }
        }

        perfNavEntry.children = _lodash.default.sortBy(perfNavEntry.children, function (navObj) {
          return _lodash.default.findIndex(PERFORMANCE_NAV_ORDER, function (path) {
            var route = navObj.route;
            var url = navObj.url;
            var isRouteMatch = route && (path === route.segment || path === route);
            var isUrlMatch = path === url;
            return isRouteMatch || isUrlMatch;
          });
        });
        var perfAppMenuItems = appMenuItemsByFeatureCategory ? appMenuItemsByFeatureCategory[_app.FEATURE_CATEGORIES.CONTACT_CENTER_INSIGHTS] : [];

        if (!_lodash.default.isEmpty(perfAppMenuItems)) {
          perfNavEntry.children = perfNavEntry.children.concat(perfAppMenuItems);
        }

        if (perfNavEntry.children.length > 0) {
          result.push(perfNavEntry);
        }
      }

      if (this.get('canSeeReports')) {
        result.push({
          className: 'menu-reports',
          iconClassNames: ['pc', 'pc-reports'],
          label: this.get('reportsLinkTitle'),
          route: {
            name: 'engage.embedded',
            segment: 'reports'
          }
        });
      }

      if (this.get('permissions.canViewJourneyAnalytics')) {
        result.push({
          className: 'menu-journey-analytics',
          iconClassNames: ['pc', 'pc-journey-analytics'],
          label: this.get('journeyAnalyticsLinkTitle'),
          url: '/journey-management',
          target: '_blank'
        });
      }

      var generalAppMenuItems = appMenuItemsByFeatureCategory ? appMenuItemsByFeatureCategory[GENERAL_APP_FEATURE_CATEGORY] : [];

      if (!_lodash.default.isEmpty(generalAppMenuItems)) {
        result.push({
          className: 'menu-apps-ddl',
          label: this.get('appsLinkTitle'),
          open: false,
          children: generalAppMenuItems
        });
      }

      if (this.get('adminAccess.userHasAdminAccess')) {
        result.push({
          className: 'menu-admin',
          label: this.get('adminLinkTitle'),
          route: {
            name: 'admin',
            segment: 'welcomeV2'
          }
        });
      } // Restore the previously open menu item, if any


      var prevOpenSubMenuClassName = this.get('openSubMenuClassName');

      if (prevOpenSubMenuClassName) {
        _lodash.default.forEach(result, function (curr) {
          if (curr.className === prevOpenSubMenuClassName) {
            curr.open = true;
            return false;
          }
        });
      }

      return emberifyMenu.call(this, result, currentURL);
    }),
    navEntries: Ember.computed('engage.navTree', 'session.person.isEngageUser', function () {
      var _this4 = this;

      var navEntries = this.get('engage.navTree');
      var performanceNav = [];
      var adminNav = [];

      if (!navEntries) {
        navEntries = [];
      }

      if (this.get('permissions.canViewOutboundCampaigns')) {
        var textKey = 'outbound.primaryNavigation.dashboards.outboundCampaigns';

        if (!navEntries.some(function (item) {
          return item.textKey == textKey;
        })) {
          navEntries.push({
            text: this.get('intl').t('components.navMenu.outboundCampaigns'),
            textKey: textKey,
            href: 'dashboards/outboundCampaigns',
            roles: [{
              any: ['perm:outbound:campaign:view']
            }],
            ignoreDivisions: true,
            routeName: 'center-stage-frame-outbound.internal'
          });
        }
      } // Reset visible flags


      this.set('canSeeReports', false);

      _lodash.default.each(navEntries, function (entry) {
        var navObj = {
          text: entry.text,
          className: _codeUtils.default.generateClassName(entry.text, 'menu-')
        };

        if (entry.href) {
          navObj.path = entry.href.replace('#', '');
        } else if (entry.subLinks && entry.subLinks.length > 0) {
          navObj.path = entry.subLinks[0].href.replace('#', '');
        }

        if (entry.routeName) {
          navObj.name = entry.routeName;
        } // Ignore report component


        if (entry.fromPlaceholder === 'reports-component') {
          _this4.set('canSeeReports', true);

          return;
        } // Do not show alerts in this context


        if (entry.fromPlaceholder === 'alerting-component') {
          return;
        }

        if (entry.href === '#admin/scheduledCallbacks') {
          performanceNav.push(navObj);
          return;
        }

        var match = new RegExp('architect').test(entry.href);

        if (match) {
          navObj.path = entry.href;
          navObj.isArchitect = adminNav.push(navObj);
        } else if (isPerformanceEntry(entry)) {
          performanceNav.push(navObj);
        } else {
          adminNav.push(navObj);
        }
      });

      return {
        performanceNav: performanceNav,
        adminNav: _lodash.default.sortBy(adminNav, 'text')
      };
    }),
    documentsSegment: Ember.computed('documents.lastSegment', function () {
      var lastSegment = this.get('documents.lastSegment');

      if (lastSegment) {
        return lastSegment;
      } else {
        return 'dashboard';
      }
    }),
    closeMenus: function closeMenus() {
      this.set('showTinyNavDropdown', false);
      var menu = this.get('menu');

      _lodash.default.forEach(menu, function (curr) {
        if (curr.get('open')) {
          curr.set('open', false);
        }
      });

      this.set('openSubMenuClassName', null);
    },
    handleShowTinyNavMenu: Ember.on('init', observer('application.showTinyNavMenu', function () {
      if (!this.get('application.showTinyNavMenu')) {
        this.closeMenus();
      }
    }))
  });

  _exports.default = _default;

  function ensureRouteURl(src, currentURL) {
    var isCurrentURL = false;

    if (src.url) {
      if (src.url.replace(/^#/, '') == currentURL) {
        isCurrentURL = true;
      }

      src.isCurrentURL = isCurrentURL;
      return;
    }

    var router = this.get('router');
    if (!router || !router.generate) return;
    var url;

    if (typeof src.route === 'string') {
      url = router.generate(src.route);
    }

    if (_typeof(src.route) === 'object') {
      url = router.generate(src.route.name, src.route.segment);
    }

    if (url) {
      if (url.replace(/^#/, '') == currentURL) {
        isCurrentURL = true;
      }

      src.url = url;
      src.isCurrentURL = isCurrentURL;
    }
  }
  /**
   * Convert the raw js menu array of objects into Ember objects for easy use in components
   *  @param {array} src The raw js array of objects
   *  @returns {array} An ember-wrapped array of ember-wrapped objects
   */


  function emberifyMenu(src, currentURL) {
    var _this = this;

    if (Array.isArray(src)) {
      return new Ember.A(_lodash.default.map(src, function (curr) {
        return emberifyMenu.call(_this, curr, currentURL);
      }));
    }

    if (src.children) {
      src.children = Ember.A(_lodash.default.map(src.children, function (currChild) {
        return emberifyMenu.call(_this, currChild, currentURL);
      }));
    } else {
      ensureRouteURl.call(_this, src, currentURL);
    }

    return Ember.Object.create(src);
  }
  /**
   * Evaluate a nav entry object to determine if
   * it should be grouped in the performance category.
   *
   * @param {object} entry
   * @returns {boolean}
   */


  function isPerformanceEntry(entry) {
    // Components that should be pushed into the
    // performance navigation
    var performanceComponents = ['queues-component', 'agents-component']; // Core paths that should be pushed into the
    // performance navigation

    var performancePaths = ['#dashboard', '#agentDashboard', '#admin/scheduledCallbacks', '#outbound/dashboards/outboundCampaigns', 'dashboards/outboundCampaigns'];

    if (entry.fromPlaceholder && !_lodash.default.includes(performanceComponents, entry.fromPlaceholder)) {
      return false;
    } else if (entry.fromPlaceholder === undefined && !_lodash.default.includes(performancePaths, entry.href)) {
      return false;
    }

    return true;
  }
});