define("web-directory/components/acd-interactions-panel/interaction-outbound/component", ["exports", "web-directory/utils/acd-ts"], function (_exports, _acdTs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-outbound'],
    classNameBindings: ['showDialpad:dialpad-open:dialpad-closed'],

    /**
     * @attribute
     *
     * `true` if the dialpad is currently open
     */
    showDialpad: false,

    /**
     * @attribute
     */
    emergencyLocationOn: false,

    /**
     * @attribute
     */
    emergencyLocationData: null,

    /**
     * @attribute
     */
    outboundMedia: null,

    /**
     * @attribute
     * @feature-toggle
     */
    acdOutboundWhatsApp: false,

    /**
     * Determines what tab is currently being viewed
     */
    activeTab: 'call',

    /**
     * @action
     *
     * Invoked when on of the interaction components requests a self close
     */
    actionClose: function actionClose() {
      return (0, _acdTs.warnClosureAction)('actionClose');
    },

    /**
     * Changes the currently active tab in the view to the new value
     *
     * @param tab the tab to update the view to
     */
    _setActiveTab: function _setActiveTab(tab) {
      this.set('activeTab', tab);
    },
    actions: {
      onSetActiveTab: function onSetActiveTab(tab) {
        this._setActiveTab(tab);
      },
      onClose: function onClose() {
        this.actionClose();
      }
    }
  });

  _exports.default = _default;
});