define("web-directory/components/entity-mini/entity-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['entity-details'],
    // Child of entity-mini display only all attributes are inherited
    entityIsPerson: false,
    entityIsContact: false,
    entityIsMe: false,
    showDepartment: true,
    showOrganization: false,
    showPresence: false,
    isPresenceLabelToggleEnabled: false,
    subscribeToPresence: false,
    canContactEntity: false,
    useInitials: false,
    isEntityLocationToggleEnabled: false,
    isClickable: false,
    entity: null,
    entityLocationData: null,
    presenceLabelData: null
  });

  _exports.default = _default;
});