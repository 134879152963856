define("web-directory/authorizers/directory", ["exports", "web-directory/utils/net", "web-directory/config/environment", "ember-simple-auth/authorizers/base", "lodash", "@purecloud/pc-ui-auth/dist/host", "ember-ajax/errors", "web-directory/services/ajax"], function (_exports, _net, _environment, _base, _lodash, _host, _errors, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var HASH_LENGTH_LIMIT = 4976;
  var SEMVER_PARSER = /^([^\+]+)\+(\d+)-(.+)-([a-zA-Z0-9]+)$/;

  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    _authData: null,
    _ajaxPrefilters: null,
    _ajaxErrors: null,
    appContextHeaderTokens: Ember.computed('application.semver', 'application.hostedContext.isHostedProp', 'application.isPopout', function () {
      // This is similar to our service name, directory-webui, with the `-`
      // replaced by `_` since PAPI enums use java identifiers and can't include dashes.
      var headerTokens = ['directory_webui']; // web-dir version

      var semver = this.get('application.semver');

      if (typeof semver === 'string') {
        var semverParseResult = SEMVER_PARSER.exec(semver.trim());

        if (semverParseResult && semverParseResult.length === 5) {
          // Production build semver format
          var _semverParseResult = _slicedToArray(semverParseResult, 5),
              pkgVersion = _semverParseResult[1],
              buildNumber = _semverParseResult[2],
              gitBranch = _semverParseResult[3],
              gitCommit = _semverParseResult[4]; // Reduce the size of the release details to keep the overall header length down
          // packageVersion-gitBranch[0,24)-buildNumber-gitCommit[0,8)
          // 25 branch chars accounts for our standard branch prefixes, jira teams/numbers, and a bit of desc
          // 8 chars of git sha should be more than sufficient


          headerTokens.push([pkgVersion, gitBranch.slice(0, 25), buildNumber, gitCommit.slice(0, 8)].map(function (curr) {
            return curr.trim().replaceAll('-', '_');
          }).filter(function (curr) {
            return curr;
          }).join('-'));
        } else {
          // Dev/Unparsable semver - just use it as is and truncated to 25 chars
          headerTokens.push(semver.trim().slice(0, 25));
        }
      } // Desktop vs Browser


      var isHosted = this.get('application.hostedContext.isHostedProp');
      headerTokens.push(isHosted ? 'desktop' : 'browser'); // Popout (PEF, chat, etc)
      // Popout is not a 1:1 mapping to PEF, but, it is one of the cases that manifests as isPopout

      if (this.get('application.isPopout')) {
        headerTokens.push('popout');
      }

      return headerTokens;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._ajaxPrefilters = $.Callbacks();
      Ember.$.ajaxPrefilter('+*', this._ajaxPrefilters.fire);
      this._ajaxErrors = $.Callbacks();
      Ember.$(document).ajaxError(this._ajaxErrors.fire);
    },
    authorize: function authorize(authData, fnToAuthorize) {
      if (authData && authData.access_token) {
        fnToAuthorize(this._buildHeaders(authData));
      }
    },
    authorizeAllDomainRequests: function authorizeAllDomainRequests(authData) {
      var _this = this;

      this.set('_authData', authData);

      this._ajaxPrefilters.add(this._addHeadersPrefilter.bind(this));

      this._ajaxErrors.add(Ember.run.bind(this, '_handleUnauthorizedRequests'));

      _host.default.onClient401(function (msg) {
        if (msg.origin === window.origin) {
          _this._handleClientUnauthorizedError(msg.client);
        } else {
          _this.get('logger').warn('Received unexpected cross-orgin 401 notification from iframe client', {
            client: msg.client,
            origin: msg.origin
          });

          _this._handleClientUnauthorizedError(msg.client);
        }
      });
    },
    deauthorizeAllDomainRequests: function deauthorizeAllDomainRequests() {
      this.set('_authData', null);

      this._ajaxPrefilters.empty();

      this._ajaxErrors.empty();
    },
    _addHeadersPrefilter: function _addHeadersPrefilter(options, original, jqXHR) {
      if (!options.crossDomain || this._isWhitelistedUrl(options.url) || this._isAbsPathOfProxiedEnv(options.url)) {
        var excludeAuthHeaderForFax = this.isFaxUpload(options.url);

        var headers = this._buildHeaders(this.get('_authData'));

        if (excludeAuthHeaderForFax && headers.Authorization) {
          // fax upload using Darth service has its own authentication headers
          delete headers.Authorization;
        }

        _lodash.default.forEach(headers, function (headerValue, headerName) {
          jqXHR.setRequestHeader(headerName, headerValue);
        });
      }

      if (this._isGcPublicApiUrl(options.url)) {
        var headerTokens = _toConsumableArray(this.get('appContextHeaderTokens')); // App Subsection
        // Read the `original` options param which are the unmodified options from the caller


        if (original && _typeof(original) === 'object' && Object.prototype.hasOwnProperty.call(original, _ajax.REQUEST_TELEMETRY_APP_SUBSECTIONS)) {
          var subsection = original[_ajax.REQUEST_TELEMETRY_APP_SUBSECTIONS]; // Delete this prop so it doesn't get sent with the request

          delete original[_ajax.REQUEST_TELEMETRY_APP_SUBSECTIONS];
          var subsectionTokens = Array.isArray(subsection) ? subsection : typeof subsection === 'string' ? [subsection] : null;

          if (!subsectionTokens || subsectionTokens.some(function (curr) {
            return typeof curr !== 'string';
          })) {
            // log and ignore when encountering any invalid subsection types
            this.get('logger').error('Received invalid subsection for Genesys-App header', {
              remoteData: {
                type: _typeof(subsection),
                subsection: subsection
              }
            });
          } else {
            // This check is strictly for dev logging
            // We drop slashes when building the final header value
            if (subsectionTokens.some(function (currToken) {
              return currToken.includes('/');
            })) {
              this.get('logger').warn('Dropping invalid "/" char found in Genesys-App header subsection', {
                remoteData: {
                  type: _typeof(subsection),
                  subsection: subsection
                }
              });
            }

            headerTokens.push.apply(headerTokens, _toConsumableArray(subsectionTokens));
          }
        } // Escape `/` with `_` as slashes are our token separators


        jqXHR.setRequestHeader('Genesys-App', headerTokens.map(function (curr) {
          return curr.trim().replaceAll('/', '_');
        }).join('/'));
      }
    },
    _isWhitelistedUrl: function _isWhitelistedUrl(url) {
      return !!url && url.indexOf(this.get('application.purecloudV2BaseUri')) === 0;
    },
    _isGcPublicApiUrl: function _isGcPublicApiUrl(url) {
      if (!url) {
        return false;
      }

      var pubApiBase = this.get('application.purecloudV2BaseUri');
      var pubApiProxyBase = this.get('application.purecloudBaseUri');
      return [pubApiBase, pubApiProxyBase].some(function (currBase) {
        return currBase && typeof currBase === 'string' && url.indexOf(currBase) === 0;
      });
    },
    isFaxUpload: function isFaxUpload(url) {
      return !!url && url.indexOf('fax-uploads') > -1;
    },

    /**
     * Returns true if:
     * The current environment is development
     * The current environment is proxied to a PC deployed env
     * The provided url is on or a sub-domain of the PC TLD.
     *
     * Currently, this utility doesn't require that the provided url be remote.
     * This allows you to be serving locally off of `localhost.mypurcloud.com:4300`
     * and append the auth token to a `localhost:4300/sca` url.
     */
    _isAbsPathOfProxiedEnv: function _isAbsPathOfProxiedEnv(url) {
      if (!url) {
        return false;
      }

      var result = false;
      var envDetails = this.get('application.envDetails');

      if (_environment.default.environment === 'development' && envDetails && envDetails.local) {
        // Currently proxying.  Test if url is our tgt env
        try {
          var tgtEnvDetails = _net.default.getEnvDetails(url);

          result = envDetails.env === tgtEnvDetails.env;
        } catch (unknownEnv) {
          result = false;
        }
      }

      return result;
    },
    _handleUnauthorizedRequests: function _handleUnauthorizedRequests(event, jqXHR, settings, thrownError) {
      var _this2 = this;

      /*
       * isAuthenticated/logoutInProgress checks avoid handling expected 401s and logging false positives in sumo.
       *
       * Ex:
       *   Attempting restore with an expired token
       *   Ajax request failing after logout requested
       */
      if (jqXHR.status === 401 && !jqXHR.skipPurecloudUnauthorizedCheck && !!this.get('session.isAuthenticated') && !this.get('session.logoutInProgress') && (settings.crossDomain === false || this._isWhitelistedUrl(settings.url) || this._isAbsPathOfProxiedEnv(settings.url))) {
        /*
         * Attempt to log this 401 immediately.  If it succeeds, we have a service that is throwing a
         * 401 that is out of sync with public-api wrt authentication.  The log will make it to
         * sumo and will not be a false positive.  If it fails due to rate limiting, we're reloading
         * unconditionally at the moment, so no need to buffer it.
         */
        this._sendXhrErrorDetails(settings, thrownError, jqXHR).finally(function () {
          // Request a new token (remember me) or logout
          _this2.get('session').invalidate({
            unauthorized: true
          });
        });
      }
    },
    _buildHeaders: function _buildHeaders(authData) {
      /* eslint-disable dot-notation */
      var hash = window.location.hash;
      hash = hash.length > HASH_LENGTH_LIMIT ? hash.slice(0, HASH_LENGTH_LIMIT) : hash;
      var headers = {
        'ININ-Client-Path': hash
      };

      if (authData && authData.access_token) {
        headers['Authorization'] = "bearer ".concat(authData.access_token);
      }
      /* eslint-enable dot-notation */


      return headers;
    },
    _handleClientUnauthorizedError: function _handleClientUnauthorizedError(clientId) {
      var _this3 = this;

      var msg = "".concat(clientId, " reported invalid 401 event. Not logout will be triggered.");
      return this._sendErrorMessage({
        message: msg
      }).catch(function (error) {
        // Checking if the session is actually invalid.
        if ((0, _errors.isUnauthorizedError)(error)) {
          _this3.get('session').invalidate({
            unauthorized: true
          });
        }
      });
    },
    _sendXhrErrorDetails: function _sendXhrErrorDetails(settings) {
      var thrownError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var xhr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var message = '401 encountered while trying to access URL. Automatically requesting new token for user. Check local machine logs.'; // eslint-disable-next-line no-console

      console.error(message, {
        settings: settings,
        thrownError: thrownError
      }); // Don't log to sumo in dev mode

      if (this.get('application.runtime.local')) {
        return Ember.RSVP.resolve();
      }

      var correlationId = xhr.getResponseHeader('inin-correlation-id');
      var data = JSON.stringify({
        message: message,
        correlationId: correlationId
      });
      return this._sendErrorMessage(data);
    },
    _sendErrorMessage: function _sendErrorMessage(messageData) {
      var url = this.get('application').pcV2Uri('api/v2/diagnostics/trace');
      return this.get('ajax').post(url, {
        beforeSend: function beforeSend(jqXHR) {
          // Add a flag to this request to bypass infinite 401 checking
          jqXHR.skipPurecloudUnauthorizedCheck = true;
        },
        dataType: 'text',
        data: JSON.stringify({
          app: {
            appId: 'collaborate-ui',
            appVersion: this.get('version')
          },
          traces: [{
            level: 'ERROR',
            time: new Date().toString(),
            message: messageData,
            topic: 'collaborate-ui.service:session'
          }]
        })
      });
    }
  });

  _exports.default = _default;
});