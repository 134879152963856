define("web-directory/components/agent-greeting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    session: inject.service(),
    currentUserId: computed.readOnly('session.person.guid'),
    frameUrl: computed('currentUserId', function () {
      return '/agent-ui-settings-v2/#/' + this.get('currentUserId') + '/agent-greeting';
    })
  });

  _exports.default = _default;
});