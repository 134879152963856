define("web-directory/components/participant-controls/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var on = Ember.on,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNames: ['participant-controls-container'],
    shouldAttach: false,
    tetherBoundingBox: null,
    tetherDropDownMenu: null,
    tetherClass: null,
    onMenuSelector: false,
    onMenu: false,
    setupTetherDropDown: on('didInsertElement', function () {
      var _this = this;

      if (!this.get('shouldAttach')) {
        return;
      }

      var hasParentBox = this.$().parents(".".concat(this.get('tetherBoundingBox'))).length;
      var hasBoxMember = this.get('tetherBoundingBox');

      if (!(hasParentBox || hasBoxMember)) {
        Ember.Logger.error('tetherBoundingBox property on dropDownComponent was not found');
        return;
      }

      this.set('tetherClass', _lodash.default.uniqueId('tether_'));
      Ember.run.scheduleOnce('afterRender', function () {
        var menu = _this.$('.menu');

        var target = _this.$('.menu-selector');

        try {
          _this.set('tetherDropDownMenu', new window.Tether({
            element: menu,
            target: target,
            attachment: 'middle left',
            targetAttachment: 'middle right',
            classPrefix: _this.get('tetherClass'),
            constraints: [{
              to: _this.$().parents(".".concat(_this.get('tetherBoundingBox')))[0],
              attachment: 'together'
            }]
          }));
        } catch (error) {
          var message = 'component: participant-controls    Unable to attach tethered dropdown to participant';

          _this.logger.error(message, {
            error: error
          });
        }

        _this.$().hover(function () {
          _this.toggleProperty('onMenuSelector', function () {
            setTimeout(function () {
              _this.toggleProperty('onMenuSelector');
            }, 200);
          });
        });

        Ember.$(".".concat(_this.get('tetherClass'), "-element")).hover(function () {
          _this.toggleProperty('onMenu', function () {
            setTimeout(function () {
              _this.toggleProperty('onMenu');
            }, 200);
          });
        });
      });
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var menu = this.get('tetherDropDownMenu');

      if (menu) {
        menu.destroy();
        var element = menu.element;

        if (element) {
          element.remove();
        }
      }

      this.$().off('mouseenter mouseleave');
      Ember.$(".".concat(this.get('tetherClass'), "-element")).off('mouseenter mouseleave');
    },
    showTetherDropDowns: observer('onMenuSelector', 'onMenu', function () {
      var tether = Ember.$(".".concat(this.get('tetherClass'), "-element"));

      if (this.get('onMenuSelector') || this.get('onMenu')) {
        tether.show();
        this.get('tetherDropDownMenu').position();
      } else {
        tether.hide();
      }
    })
  });

  _exports.default = _default;
});