define("web-directory/components/acd-interactions-panel/interaction-wrapup-codes-copilot/interaction-wrapup-codes-copilot-wrapup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['interaction-wrapup-codes-copilot-wrapup'],
    wrapupcodes: [],
    selectedWrapupCode: null,
    wrapupCodeText: Ember.computed.readOnly('selectedWrapupCode.name'),
    isCopilotWrapupEnabled: false,
    showWrapupList: false,
    suggestedWrapupCodes: [],
    isFeedbackSelected: false,
    isReadabilityImprovementEnabled: false,
    handleWrapupCodeSelect: function handleWrapupCodeSelect(_code) {},
    handleFilter: function handleFilter(_val) {},
    feedbackInputChange: function feedbackInputChange(_feedbackType, _val) {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var wrapupSelector = this.element.querySelector('.wrapup-selector');
      wrapupSelector === null || wrapupSelector === void 0 ? void 0 : wrapupSelector.addEventListener('focusin', this.handleFocus.bind(this));
      wrapupSelector === null || wrapupSelector === void 0 ? void 0 : wrapupSelector.addEventListener('focusout', this.handleBlur.bind(this));
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.bindGuxDelete();
    },
    bindGuxDelete: function bindGuxDelete() {
      var _this = this;

      var wrarUpTagId = '#wrap-up-tag';
      this.$(wrarUpTagId).off('guxdelete').on('guxdelete', function () {
        _this.handleWrapupCodeSelect(null);
      });
    },
    unBindGuxDelete: function unBindGuxDelete() {
      var wrarUpTagId = '#wrap-up-tag';
      this.$(wrarUpTagId).off('guxdelete');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unBindGuxDelete();
    },
    handleBlur: function handleBlur() {
      if (!this.hasFocusInWrapupSelector()) {
        this.set('showWrapupList', false);
      }
    },
    handleFocus: function handleFocus() {
      this.set('showWrapupList', true);
    },
    hasFocusInWrapupSelector: function hasFocusInWrapupSelector() {
      var wrapupSelector = this.element.querySelector('.wrapup-selector');
      return wrapupSelector === null || wrapupSelector === void 0 ? void 0 : wrapupSelector.contains(document.activeElement);
    },
    actions: {
      selectCode: function selectCode(code) {
        this.handleWrapupCodeSelect(code);
        this.handleFilter('');
        this.set('showWrapupList', false);
      },
      handleInputClick: function handleInputClick() {
        this.set('showWrapupList', true);
      },
      handleFilter: function handleFilter(val) {
        this.handleFilter(val);
      },
      handleKeyDown: function handleKeyDown(index, event) {
        var focusReachedFirstItem = index - 1 < 0;
        var focusReachedLastItem = index + 1 > this.wrapupcodes.length - 1;

        if (event.code === 'Escape') {
          this.set('showWrapupList', false);
        } else if (event.code === 'ArrowUp') {
          if (focusReachedFirstItem) {
            var _document$getElementB;

            (_document$getElementB = document.getElementById('wrapup-item-' + (this.wrapupcodes.length - 1).toString())) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.focus();
          } else {
            var _document$getElementB2;

            (_document$getElementB2 = document.getElementById('wrapup-item-' + (index - 1).toString())) === null || _document$getElementB2 === void 0 ? void 0 : _document$getElementB2.focus();
          }
        } else if (event.code === 'ArrowDown') {
          if (focusReachedLastItem) {
            var _document$getElementB3;

            (_document$getElementB3 = document.getElementById('wrapup-item-0')) === null || _document$getElementB3 === void 0 ? void 0 : _document$getElementB3.focus();
          } else {
            var _document$getElementB4;

            (_document$getElementB4 = document.getElementById('wrapup-item-' + (index + 1).toString())) === null || _document$getElementB4 === void 0 ? void 0 : _document$getElementB4.focus();
          }
        } else if (event.code === 'Enter' || event.code === 'Space' || event.code === 'Tab') {
          var _document$getElementB5;

          (_document$getElementB5 = document.getElementById('wrapup-item-' + index.toString())) === null || _document$getElementB5 === void 0 ? void 0 : _document$getElementB5.dispatchEvent(new Event('mousedown'));
        }
      },
      handleWrapupKeyDown: function handleWrapupKeyDown(event) {
        if (event.code === 'Escape' || event.code === 'Tab') {
          this.set('showWrapupList', false);
        } else if (event.code === 'ArrowUp') {
          var indexAfterLastItem = this.get('wrapupcodes').length;
          this.actions.handleKeyDown.call(this, indexAfterLastItem, event);
        } else if (event.code === 'ArrowDown') {
          var indexBeforeFirstItem = -1;
          this.actions.handleKeyDown.call(this, indexBeforeFirstItem, event);
        }
      },
      handleFeedbackInputChange: function handleFeedbackInputChange(_feedbackType, _val) {
        this.feedbackInputChange(_feedbackType, _val);
      }
    }
  });

  _exports.default = _default;
});