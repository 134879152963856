define("web-directory/templates/nav-system-v2/agent-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fXXT7NRw",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"agent-ui-wrapper \",[33,[\"if\"],[[28,[\"isAgentRoute\"]],\"active\"],null]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"session\",\"useMultipanel\"]]],null,{\"statements\":[[0,\"        \"],[1,[33,[\"acd-interactions-panel-v2\"],null,[[\"active\",\"isPopout\",\"onQueue\",\"selectedInteraction\",\"requestedInteraction\",\"activeInteractions\",\"activeWorkitems\",\"panelsOverrideObject\",\"emergencyLocationData\",\"emergencyLocationOn\",\"streamingConnected\"],[[28,[\"sidebar\",\"showAcdInteractions\"]],[28,[\"application\",\"isPopout\"]],[28,[\"session\",\"person\",\"isOnQueue\"]],[28,[\"interaction\",\"selectedInteraction\"]],[28,[\"interaction\",\"requestedInteraction\"]],[28,[\"interaction\",\"activeInteractions\"]],[28,[\"acdworkitem\",\"activeWorkitems\"]],[28,[\"panelsOverrideObject\"]],[28,[\"emergencyLocation\",\"emergencyLocationData\"]],[28,[\"emergencyLocationOn\"]],[28,[\"streaming\",\"connectedV2\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"        \"],[1,[33,[\"acd-interactions-panel\"],null,[[\"active\",\"isPopout\",\"onQueue\",\"selectedInteraction\",\"requestedInteraction\",\"activeInteractions\",\"activeWorkitems\",\"panelsOverrideObject\",\"emergencyLocationData\",\"emergencyLocationOn\",\"streamingConnected\"],[[28,[\"sidebar\",\"showAcdInteractions\"]],[28,[\"application\",\"isPopout\"]],[28,[\"session\",\"person\",\"isOnQueue\"]],[28,[\"interaction\",\"selectedInteraction\"]],[28,[\"interaction\",\"requestedInteraction\"]],[28,[\"interaction\",\"activeInteractions\"]],[28,[\"acdworkitem\",\"activeWorkitems\"]],[28,[\"panelsOverrideObject\"]],[28,[\"emergencyLocation\",\"emergencyLocationData\"]],[28,[\"emergencyLocationOn\"]],[28,[\"streaming\",\"connectedV2\"]]]]],false],[0,\"\\n\"]],\"locals\":[]}],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/templates/nav-system-v2/agent-ui.hbs"
    }
  });

  _exports.default = _default;
});