define("web-directory/components/command-nav/navMenu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NavMenu = void 0;
  var NavMenu = [{
    title: 'navBar.commandView.agent.title',
    icon: 'agent',
    subMenu: [{
      title: 'navBar.commandView.agent.workspace',
      link: '#/agent-ui' // action: () => {
      //     // @ts-ignore
      //     this.get('sidebar').set('showAcdInteractions', true);
      // }

    }, {
      title: 'navBar.commandView.agent.activity',
      link: '#/activity'
    }, {
      title: 'navBar.commandView.agent.taskManagement',
      subMenu: [{
        title: 'navBar.commandView.agent.workitem',
        link: '#/agent/task-management/workitems'
      }, {
        title: 'navBar.commandView.agent.parkedConversations',
        link: '#/agent/task-management/parked-conversations'
      }]
    }, {
      title: 'navBar.commandView.agent.workspaceSettings',
      subMenu: [{
        title: 'navBar.commandView.agent.panelManager',
        link: '#/admin/contact-center/panel-settings',
        reqs: {
          features: 'agentUIDefaultPanel3129',
          permissions: ['agentUI:defaultPanels:view']
        }
      }, {
        title: 'navBar.commandView.agent.assistants',
        link: '#/admin/assistants/list'
      }, {
        title: 'navBar.commandView.agent.abandonIntervals',
        link: '#/engage/queues/performance'
      }, {
        title: 'navBar.commandView.agent.cannedResponses',
        link: '#/admin/responseManagement/responses'
      }, {
        title: 'navBar.commandView.agent.concurrent',
        link: 'WIP'
      }, {
        title: 'navBar.commandView.agent.responseAssets',
        link: '#/admin/responseManagement/assets'
      }, {
        title: 'navBar.commandView.agent.scripts',
        link: '#/admin/scripts'
      }, {
        title: 'navBar.commandView.agent.scriptTemplates',
        link: '#/admin/scriptTemplates'
      }, {
        title: 'navBar.commandView.agent.wrapup',
        link: '#/admin/organization/_wrapupCodesV2'
      }, {
        title: 'navBar.commandView.agent.wrapupMappings',
        link: '#/admin/outbound/admin/wrapupMappings'
      }, {
        title: 'navBar.commandView.agent.utilization',
        link: '#/admin/contact-center/utilization'
      }]
    }]
  }, {
    title: 'navBar.commandView.analytics.analytics',
    icon: 'graph-line',
    subMenu: [{
      title: 'navBar.commandView.analytics.contactCenter.title',
      subMenu: [{
        title: 'navBar.commandView.analytics.contactCenter.agentPerformance',
        link: '#/analytics/agent/performance'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.agentStatus',
        link: '#/analytics/agent/status'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.campaignPerformance',
        link: '#/analytics/campaigns/performance'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.dnisPerformance',
        link: '#/analytics/dnis/performance'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.interactions',
        link: '#/analytics/interactions'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.queueActivity',
        link: '#/analytics/queues/activity'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.queuePerformance',
        link: '#/analytics/queues/performance'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.scheduledCallbacks',
        link: '#/analytics/outbound/scheduledCallbacks'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.skillsPerformance',
        link: '#/analytics/skills/performance'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.workItemPerformance',
        link: '#/analytics/workitems/performance'
      }, {
        title: 'navBar.commandView.analytics.contactCenter.wrapupPerformance',
        link: '#/analytics/wrapup/performance'
      }]
    }, {
      title: 'navBar.commandView.analytics.dashboards',
      link: '#/analytics/dashboards'
    }, {
      title: 'navBar.commandView.analytics.employeeEngagement.title',
      subMenu: [{
        title: 'navBar.commandView.analytics.employeeEngagement.agentDevelopment',
        link: '#/analytics/agent/development'
      }, {
        title: 'navBar.commandView.analytics.employeeEngagement.agentEvaluation',
        link: '#/analytics/agents/evaluations'
      }, {
        title: 'navBar.commandView.analytics.employeeEngagement.leaderboards',
        link: '#/analytics/agents/leaderboard'
      }, {
        title: 'navBar.commandView.analytics.my.development',
        link: '#/analytics/agents/development/me'
      }, {
        title: 'navBar.commandView.analytics.my.scorecard',
        link: '#/analytics/agents/scorecard/me'
      }, {
        title: 'navBar.commandView.analytics.employeeEngagement.surveyPerformance',
        link: '#/analytics/quality/surveys/performance'
      }]
    }, {
      title: 'navBar.commandView.analytics.flows.title',
      subMenu: [{
        title: 'navBar.commandView.analytics.flows.destination',
        link: '#/analytics/flows/destinations'
      }, {
        title: 'navBar.commandView.analytics.flows.outcomePerformance',
        link: '#/analytics/outcomes/performance'
      }, {
        title: 'navBar.commandView.analytics.flows.performance',
        link: '#/analytics/flows/performance'
      }]
    }, {
      title: 'navBar.commandView.analytics.journey.title',
      subMenu: [{
        title: 'navBar.commandView.analytics.journey.actionMap',
        link: '#/analytics/journey/actionMaps'
      }, {
        title: 'navBar.commandView.analytics.journey.outcome',
        link: '#/analytics/journey/outcomes'
      }, {
        title: 'navBar.commandView.analytics.journey.segment',
        link: '#/analytics/journey/segments'
      }]
    }, {
      title: 'navBar.commandView.analytics.my.performance',
      subMenu: [{
        title: 'navBar.commandView.analytics.my.development',
        link: '#/analytics/agents/development/me'
      }, {
        title: 'navBar.commandView.analytics.my.evaluations',
        link: '#/analytics/agents/evaluations/me'
      }, {
        title: 'navBar.commandView.analytics.my.interactions',
        link: '#/analytics/agents/interactions/me'
      }, {
        title: 'navBar.commandView.analytics.my.performance',
        link: '#/analytics/agents/performance/me'
      }, {
        title: 'navBar.commandView.analytics.my.queuesActivity',
        link: '#/analytics/queues/activity/me'
      }, {
        title: 'navBar.commandView.analytics.my.schedule',
        link: '#/agents/agent-schedule/me'
      }, {
        title: 'navBar.commandView.analytics.my.scorecard',
        link: '#/analytics/agents/scorecard/me'
      }, {
        title: 'navBar.commandView.analytics.my.status',
        link: '#/analytics/agents/status/me'
      }]
    }, {
      title: 'navBar.commandView.analytics.other.title',
      subMenu: [{
        title: 'navBar.commandView.analytics.other.agentAssist',
        link: '#/analytics/agent-assist/performance'
      }, {
        title: 'navBar.commandView.analytics.other.alertRules',
        link: '#/analytics/alerting/rules'
      }, {
        title: 'navBar.commandView.analytics.other.apiUsage',
        link: '#/analytics/api-usage'
      }, {
        title: 'navBar.commandView.analytics.other.botPerformance',
        link: '#/analytics/bots/performance'
      }, {
        title: 'navBar.commandView.analytics.other.dataActions',
        link: '#/analytics/data-actions/performance'
      }, {
        title: 'navBar.commandView.analytics.other.knowledge',
        link: '#/analytics/knowledge/performance'
      }, {
        title: 'navBar.commandView.analytics.other.scheduledExports',
        link: '#/analytics/scheduledExports'
      }]
    }, {
      title: 'navBar.commandView.analytics.speechAndText.title',
      subMenu: [{
        title: 'navBar.commandView.analytics.speechAndText.agent',
        link: '#/analytics/agents/topics'
      }, {
        title: 'navBar.commandView.analytics.speechAndText.flow',
        link: '#/analytics/flows/topics'
      }, {
        title: 'navBar.commandView.analytics.speechAndText.queue',
        link: '#/analytics/queues/topics'
      }, {
        title: 'navBar.commandView.analytics.speechAndText.trends',
        link: '#/analytics/topic/trends'
      }]
    }]
  }, {
    title: 'navBar.commandView.accountManagement.title',
    icon: 'building',
    subMenu: [{
      title: 'navBar.commandView.accountManagement.subscription',
      link: '#/admin/billing/summary'
    }, {
      title: 'navBar.commandView.accountManagement.appFoundry',
      icon: 'external-link',
      link: 'https://appfoundry.genesys.com/filter/genesyscloud'
    }, {
      title: 'navBar.commandView.accountManagement.organization',
      subMenu: [{
        title: 'navBar.commandView.accountManagement.locations',
        link: '#/directory/locations'
      }, {
        title: 'navBar.commandView.accountManagement.orgSettings',
        link: '#/admin/organization'
      }]
    }]
  }, {
    title: 'navBar.commandView.people.title',
    icon: 'users',
    subMenu: [{
      title: 'navBar.commandView.people.directory',
      link: '#/admin/directory/people'
    }, {
      title: 'navBar.commandView.people.orgDirectory',
      link: '#/search'
    }, {
      title: 'navBar.commandView.people.rolesPerms',
      link: '#/admin/people-permissions/roles'
    }, {
      title: 'navBar.commandView.people.divisions',
      link: '#/admin/people-permissions/divisions'
    }, {
      title: 'navBar.commandView.people.groups',
      link: '#/admin/groups'
    }, {
      title: 'navBar.commandView.people.workTeams',
      link: '#/admin/teams'
    }, {
      title: 'navBar.commandView.people.acdSkillsLangs',
      link: '#/admin/directory/acdSkills'
    }, {
      title: 'navBar.commandView.people.profileFields',
      link: '#/admin/people-permissions/fields'
    }, {
      title: 'navBar.commandView.people.external',
      link: '#/contactsAndOrganizations'
    }, {
      title: 'navBar.commandView.people.customFields',
      link: '#/admin/custom-fields/contacts'
    }]
  }, {
    title: 'navBar.commandView.channels.title',
    icon: 'view-all',
    subMenu: [{
      title: 'navBar.commandView.channels.messenger.title',
      subMenu: [{
        title: 'navBar.commandView.channels.messenger.configs',
        link: '#/admin/messenger/configurations'
      }, {
        title: 'navBar.commandView.channels.messenger.deployments',
        link: '#/admin/messenger/deployments'
      }]
    }, {
      title: 'navBar.commandView.channels.domains',
      link: '#/admin/organization/email/domains'
    }, {
      title: 'navBar.commandView.channels.social.title',
      subMenu: [{
        title: 'navBar.commandView.channels.social.platformIntegration',
        link: '#/admin/messaging/platforms'
      }, {
        title: 'navBar.commandView.channels.social.platformConfig',
        link: '#/admin/messaging/platform-configs'
      }]
    }, {
      title: 'navBar.commandView.channels.taskManagement.title',
      subMenu: [{
        title: 'navBar.commandView.channels.taskManagement.workBins',
        link: '#/admin/workbins'
      }, {
        title: 'navBar.commandView.channels.taskManagement.workTypes',
        link: '#/admin/worktypes'
      }, {
        title: 'navBar.commandView.channels.taskManagement.triggers',
        link: '#/admin/triggers'
      }]
    }, {
      title: 'navBar.commandView.channels.outreach.title',
      subMenu: [{
        title: 'navBar.commandView.channels.outreach.liveNow',
        link: '#/admin/live-now'
      }, {
        title: 'navBar.commandView.channels.outreach.segments',
        link: '#/admin/journey/segment'
      }, {
        title: 'navBar.commandView.channels.outreach.outcomes',
        link: '#/admin/journey/outcome'
      }, {
        title: 'navBar.commandView.channels.outreach.actionMaps',
        link: '#/admin/embed/engagement/action-map'
      }, {
        title: 'navBar.commandView.channels.outreach.actionLibrary',
        link: '#/admin/embed/library/action-template'
      }, {
        title: 'navBar.commandView.channels.outreach.settings',
        link: '#/admin/embed/global-settings'
      }, {
        title: 'navBar.commandView.channels.outreach.visitorActivity',
        link: '#/admin/embed/analytics/visitor-activity'
      }, {
        title: 'navBar.commandView.channels.outreach.actionMapReport',
        link: '#/admin/embed/analytics/action-map'
      }]
    }, {
      title: 'navBar.commandView.channels.outboundTelephony.title',
      subMenu: [{
        title: 'navBar.commandView.channels.outboundTelephony.callAnalysis',
        link: '#/admin/outbound/admin/callResponses'
      }, {
        title: 'navBar.commandView.channels.outboundTelephony.campaignManagement',
        link: '#/admin/outbound/admin/campaignManagement'
      }, {
        title: 'navBar.commandView.channels.outboundTelephony.timeSets',
        link: '#/admin/outbound/admin/timeSets'
      }, {
        title: 'navBar.commandView.channels.outboundTelephony.eventViewer',
        link: '#/admin/outbound/admin/eventViewer'
      }, {
        title: 'navBar.commandView.channels.outboundTelephony.listManagement',
        link: '#/admin/outbound/admin/lists'
      }, {
        title: 'navBar.commandView.channels.outboundTelephony.ruleManagement',
        link: '#/admin/outbound/admin/ruleManagement'
      }, {
        title: 'navBar.commandView.channels.outboundTelephony.settings',
        link: '#/admin/outbound/admin/outboundSettings'
      }]
    }, {
      title: 'navBar.commandView.channels.voiceNumber.title',
      subMenu: [{
        title: 'navBar.commandView.channels.voiceNumber.certificate',
        link: '#/admin/telephony/certificate-authorities'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.contracts',
        link: '#/admin/telecom/contracts'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.didNumbers',
        link: '#/admin/telephony/did'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.edges',
        link: '#/admin/telephony/edges'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.edgeGroups',
        link: '#/admin/telephony/edge-groups'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.extensions',
        link: '#/admin/telephony/extensions'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.metrics',
        link: '#/admin/telephony/metrics'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.numberManagement',
        link: '#/admin/telecom/numbers'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.numberInventory',
        link: '#/admin/telephony/phone-management'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.phoneManagement',
        link: '#/admin/telephony/phone-management'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.ratesheets',
        link: '#/admin/telecom/ratesheets'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.reports',
        link: '#/admin/telecom/reports'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.schemas',
        link: '#/admin/telecom/schemas'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.providers',
        link: '#/admin/telecom/carriers'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.sites',
        link: '#/admin/telephony/sites'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.smsNumber',
        link: '#/admin/messaging/sms-numbers'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.topology',
        link: '#/admin/telephony/topology'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.trunks',
        link: '#/admin/telephony/trunks'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.voiceAnalytics',
        link: '#/admin/telecom/analytics'
      }, {
        title: 'navBar.commandView.channels.voiceNumber.globalSettings',
        link: '#/admin/telephony/settings'
      }]
    }, {
      title: 'navBar.commandView.channels.settings.title',
      subMenu: [{
        title: 'navBar.commandView.channels.settings.supportedProfiles',
        link: '#/admin/messaging/supported-content-profiles'
      }, {
        title: 'navBar.commandView.channels.settings.timeline',
        link: '#/admin/messaging/threading-timeline'
      }]
    }]
  }, {
    title: 'navBar.commandView.routing.title',
    icon: 'fa/route-regular',
    subMenu: [{
      title: 'navBar.commandView.routing.call',
      link: '#/admin/routing/ivrs'
    }, {
      title: 'navBar.commandView.routing.customKPIs',
      link: '#/admin/predictive-routing/custom-kpis'
    }, {
      title: 'navBar.commandView.routing.emergencyGroups',
      link: '#/admin/routing/emergency-groups'
    }, {
      title: 'navBar.commandView.routing.message',
      link: '#/admin/routing/sms'
    }, {
      title: 'navBar.commandView.routing.predictive',
      link: '#/analytics/predictive-routing/queues'
    }, {
      title: 'navBar.commandView.routing.queues',
      link: '#/admin/admin/organization/_queuesV2'
    }, {
      title: 'navBar.commandView.routing.operatingSchedules',
      link: '#/admin/routing/scheduling'
    }]
  }, {
    title: 'navBar.commandView.knowledge.title',
    icon: 'fa/book-regular',
    subMenu: [{
      title: 'navBar.commandView.knowledge.articles',
      link: '#/admin/knowledge/knowledgeBases/articles'
    }, {
      title: 'navBar.commandView.knowledge.categories',
      link: 'admin/knowledge/knowledgeBases/categories'
    }, {
      title: 'navBar.commandView.knowledge.importExport',
      link: 'WIP'
    }, {
      title: 'navBar.commandView.knowledge.optimizer',
      link: 'admin/knowledge/optimizer'
    }, {
      title: 'navBar.commandView.knowledge.documents',
      link: '#/content-management/dashboard'
    }]
  }, {
    title: 'navBar.commandView.flowBuilder.title',
    icon: 'customer-journey',
    subMenu: [{
      title: 'navBar.commandView.flowBuilder.architect',
      icon: 'external-link',
      link: 'https://apps.inindca.com/architect'
    }, {
      title: 'navBar.commandView.flowBuilder.options',
      subMenu: [{
        title: 'navBar.commandView.flowBuilder.dataTables',
        link: '#/admin/routing/datatables'
      }, {
        title: 'navBar.commandView.flowBuilder.outcomes',
        link: '#/admin/routing/flowoutcomes'
      }, {
        title: 'navBar.commandView.flowBuilder.milestones',
        link: '#/admin/routing/flowmilestones'
      }]
    }]
  }, {
    title: 'navBar.commandView.workforceManagement.title',
    icon: 'calendar',
    subMenu: [{
      title: 'navBar.commandView.workforceManagement.forecasts',
      link: '#/admin/wfm/forecasts'
    }, {
      title: 'navBar.commandView.workforceManagement.gamification.title',
      subMenu: [{
        title: 'navBar.commandView.workforceManagement.gamification.profiles',
        link: '#/admin/performance/gamification-profiles'
      }, {
        title: 'navBar.commandView.workforceManagement.gamification.settings',
        link: '#/admin/performance/gamification-settings'
      }]
    }, {
      title: 'navBar.commandView.workforceManagement.performanceEngagement.title',
      subMenu: [{
        title: 'navBar.commandView.workforceManagement.performanceEngagement.development',
        link: '#/admin/performance/modules'
      }, {
        title: 'navBar.commandView.workforceManagement.performanceEngagement.external',
        link: '#/admin/performance/external-metric-definitions'
      }]
    }, {
      title: 'navBar.commandView.workforceManagement.quality.title',
      subMenu: [{
        title: 'navBar.commandView.workforceManagement.quality.keys',
        link: '#/admin/quality/encryptionKeys'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.evaluators',
        link: '#/admin/quality/evaluators'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.evaluationForms',
        link: '#/admin/quality/evaluationForms'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.policies',
        link: '#/admin/quality/policies/mediaRetention'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.programs',
        link: '#/topics-definitions/programs'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.recordingManagement',
        link: '#/admin/quality/recordingManagement'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.sentiment',
        link: '#/quality/admin/sentiment-feedback'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.speechAnalytics',
        link: '#/quality/admin/speech-and-text-analytics'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.surveys',
        link: '#/admin/quality/surveyForms'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.topics',
        link: '#/topics-definitions/topics'
      }, {
        title: 'navBar.commandView.workforceManagement.quality.miner',
        link: '#/quality/admin/topic-miner'
      }]
    }, {
      title: 'navBar.commandView.workforceManagement.scheduling.title',
      subMenu: [{
        title: 'navBar.commandView.workforceManagement.scheduling.schedules',
        link: '#/admin/wfm/schedules'
      }, {
        title: 'navBar.commandView.workforceManagement.scheduling.shiftTrades',
        link: '#/admin/wfm/shiftTrades'
      }, {
        title: 'navBar.commandView.workforceManagement.scheduling.limits',
        link: '#/admin/wfm/timeOffLimits'
      }, {
        title: 'navBar.commandView.workforceManagement.scheduling.plans',
        link: '#/admin/wfm/timeOffPlans'
      }, {
        title: 'navBar.commandView.workforceManagement.scheduling.requests',
        link: '#/admin/wfm/timeOffRequests'
      }]
    }, {
      title: 'navBar.commandView.workforceManagement.settings.title',
      subMenu: [{
        title: 'navBar.commandView.workforceManagement.settings.agents',
        link: '#/admin/wfm/agents'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.activityCodes',
        link: '#/admin/wfm/activityCodes'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.businessUnits',
        link: '#/admin/wfm/businessUnits'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.managementUnits',
        link: '#/admin/wfm/managementUnits'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.goalTemplates',
        link: '#/admin/wfm/serviceGoalTemplates'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.planningGroups',
        link: '#/admin/wfm/planningGroups'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.workPlans',
        link: '#/admin/wfm/workPlans'
      }, {
        title: 'navBar.commandView.workforceManagement.settings.planRotations',
        link: '#/admin/wfm/workPlanRotations'
      }]
    }]
  }, {
    title: 'navBar.commandView.itIntegrations.title',
    icon: 'server',
    subMenu: [{
      title: 'navBar.commandView.itIntegrations.integrations.title',
      subMenu: [{
        title: 'navBar.commandView.itIntegrations.integrations.actions',
        link: '#/admin/integrations/actions'
      }, {
        title: 'navBar.commandView.itIntegrations.integrations.authorizedApps',
        link: '#/admin/integrations/authorized-apps'
      }, {
        title: 'navBar.commandView.itIntegrations.integrations.authorizedDivisions',
        link: 'WIP'
      }, {
        title: 'navBar.commandView.itIntegrations.integrations.oAuth',
        link: '#/admin/access-management/oauth-clients'
      }, {
        title: 'navBar.commandView.itIntegrations.integrations.orgIntegrations',
        link: '#/admin/integrations/apps'
      }, {
        title: 'navBar.commandView.itIntegrations.integrations.sso',
        link: '#/admin/identity/sso'
      }]
    }, {
      title: 'navBar.commandView.itIntegrations.troubleshooting.title',
      subMenu: [{
        title: 'navBar.commandView.itIntegrations.troubleshooting.auditViewer',
        link: '#/admin/troubleshooting/auditviewer'
      }, {
        title: 'navBar.commandView.itIntegrations.troubleshooting.apiUsage',
        link: '#/analytics/api-usage'
      }, {
        title: 'navBar.commandView.itIntegrations.troubleshooting.disconnect',
        link: '#/admin/routing/disconnectInteractions'
      }, {
        title: 'navBar.commandView.itIntegrations.troubleshooting.logCapture',
        link: '#/admin/troubleshooting/logcapture'
      }, {
        title: 'navBar.commandView.itIntegrations.troubleshooting.platformUsage',
        link: '#/admin/platformusage/api'
      }]
    }]
  }];
  _exports.NavMenu = NavMenu;
});