define("web-directory/components/command-nav/component", ["exports", "web-directory/services/events", "boon-js", "web-directory/components/command-nav/navMenu"], function (_exports, _events, _boonJs, _navMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['command-nav'],
    events: Ember.inject.service('events'),
    permissions: Ember.inject.service('permissions'),
    session: Ember.inject.service('session'),
    routerService: null,
    navIsOpen: null,
    selectedMenu: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('routerService', Ember.getOwner(this).lookup('router:main'));
    },
    checkMenuPermissions: function checkMenuPermissions(menu) {
      var _this = this;

      var output = [];
      menu.forEach(function (menuItem) {
        var _menuItem$reqs$featur, _menuItem$reqs;

        var featureEval = (0, _boonJs.getEvaluator)((_menuItem$reqs$featur = (_menuItem$reqs = menuItem.reqs) === null || _menuItem$reqs === void 0 ? void 0 : _menuItem$reqs.features) !== null && _menuItem$reqs$featur !== void 0 ? _menuItem$reqs$featur : 'true'); // const permissionEval = getEvaluator(menuItem.reqs?.permissions ?? 'true');

        if (!menuItem.reqs || // @ts-ignore
        featureEval(_this.get('session.features')) && // @ts-ignore
        _this.get('permissions').validateAllPermissions(menuItem.reqs.permissions)) {
          if (menuItem.subMenu) {
            menuItem.subMenu = _this.checkMenuPermissions(menuItem.subMenu);
          }

          output.push(menuItem);
        }
      });
      return output;
    },
    menuStructure: Ember.computed('session.{feature,person}', 'permissions', function () {
      return this.checkMenuPermissions(_navMenu.NavMenu);
    }),
    actions: {
      closeNav: function closeNav() {
        this.get('events').triggerEvent(_events.TOGGLE_NAV_EVENT, {
          open: false
        });
      },
      toggleNav: function toggleNav() {
        // @ts-ignore
        this.get('events').triggerEvent(_events.TOGGLE_NAV_EVENT, {
          open: !this.get('navIsOpen')
        });
      },
      // @ts-ignore
      selectMenu: function selectMenu(menu) {
        if (this.get('selectedMenu') == menu) {
          this.set('selectedMenu', null);
        } else {
          this.set('selectedMenu', menu);
        }
      },
      // @ts-ignore
      handleLinkAction: function handleLinkAction(action) {
        // @ts-ignore
        this.set('selectedMenu', null);

        if (action) {
          action();
        }

        this.get('events').triggerEvent(_events.TOGGLE_NAV_EVENT, {
          open: !this.get('navIsOpen')
        });
      }
    }
  });

  _exports.default = _default;
});