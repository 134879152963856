define("web-directory/components/command-subnav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['command-subnav', 'nav-item'],
    classNameBindings: ['subMenuOpen:open:closed'],
    menu: null,
    subMenuOpen: false,
    actions: {
      // @ts-ignore
      toggleSubMenu: function toggleSubMenu() {
        this.toggleProperty('subMenuOpen');
      },
      handleLinkAction: function handleLinkAction(action) {
        this.sendAction('handleLinkAction', action);
      }
    }
  });

  _exports.default = _default;
});