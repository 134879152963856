define("web-directory/initializers/service-discovery", ["exports", "ember-ajax", "web-directory/services/application", "web-directory/utils/testing"], function (_exports, _emberAjax, _application, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'service-discovery',
    initialize: function initialize(application) {
      application.deferReadiness();

      if (_testing.isTesting) {
        _application.default.reopen({
          authUri: null
        });
      } else {
        (0, _emberAjax.default)('/services/discovery.properties', {
          method: 'GET',
          crossDomain: true,
          dataType: 'text',
          contentType: 'text/plain'
        }).then(function (properties) {
          var instanceProperties = {};
          var authUriRegex = /^auth\.uri\=(.*)$/gim;
          var authUriResults = authUriRegex.exec(properties);
          instanceProperties.authUri = authUriResults ? authUriResults[1] : null;
          var apiUriRegex = /^api\.uri\=(.*)$/gim;
          var apiUriResults = apiUriRegex.exec(properties);
          var apiUri = apiUriResults && apiUriResults[1];

          if (apiUri) {
            instanceProperties.purecloudV2BaseUri = apiUri;
          }

          var cobrowseUriRegex = /^cobrowse\.uri\=(.*)$/gim;
          var cobrowseUriResults = cobrowseUriRegex.exec(properties);
          var cobrowseUri = cobrowseUriResults && cobrowseUriResults[1];

          if (cobrowseUri) {
            instanceProperties.cobrowseUri = cobrowseUri;
          }

          var purecloudUriRegex = /^purecloud\.uri\=(.*)$/gim;
          var purecloudUriResults = purecloudUriRegex.exec(properties);
          var purecloudUri = purecloudUriResults && purecloudUriResults[1];

          if (purecloudUri) {
            instanceProperties.purecloudAppsUri = purecloudUri;
          }

          var streamingServiceUriRegex = /^streaming\.uri=(.*)$/gim;
          var streamingServiceUriResults = streamingServiceUriRegex.exec(properties);
          var streamingServiceUri = streamingServiceUriResults && streamingServiceUriResults[1];

          if (streamingServiceUri) {
            instanceProperties.streamingServiceUri = streamingServiceUri;
          }

          _application.default.reopen(instanceProperties);

          application.advanceReadiness();
        }).catch(function (error) {
          Ember.Logger.error("Couldn't reach service discovery.", error);
          application.advanceReadiness();

          _application.default.reopen({
            authUri: null
          });
        });
      }
    }
  };
  _exports.default = _default;
});