define("web-directory/components/agent-greeting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w9AXD0Lu",
    "block": "{\"statements\":[[11,\"iframe\",[]],[15,\"class\",\"agent-greeting\"],[15,\"frameborder\",\"0\"],[16,\"title\",[33,[\"t\"],[\"components.agentGreeting.title\"],null],null],[15,\"sandbox\",\"allow-popups-to-escape-sandbox allow-scripts allow-same-origin allow-modals allow-forms allow-popups allow-top-navigation-by-user-activation\"],[16,\"src\",[26,[\"frameUrl\"]],null],[13],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/agent-greeting/template.hbs"
    }
  });

  _exports.default = _default;
});