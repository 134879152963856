define("web-directory/instance-initializers/set-application-service-values", ["exports", "web-directory/utils/testing", "web-directory/utils/hosted-context", "web-directory/utils/net"], function (_exports, _testing, _hostedContext, _net) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'set-application-service-values',
    initialize: function initialize(instance) {
      var application = instance.lookup('application:main');
      var service = instance.lookup('service:application');
      service.setProperties(application.getProperties('urlprefix', 'semver', 'version', 'context'));

      var context = _hostedContext.default.create(instance.ownerInjection());

      service.set('hostedContext', context);
      service.set('store', instance.lookup('service:store'));

      if (_testing.isTesting) {
        // Runtime will not resolve in testing mode, so anything that uses it will need to mock it in the tests.
        service.set('runtime', null);
      } else {
        try {
          var runtime = (0, _net.parseRuntimeCustom)();
          if (runtime) service.set('runtime', (0, _net.parseRuntimeCustom)());else {
            throw new Error('Failed to parse URL into a known runtime environment. Check URL and utils/net.js');
          }
        } catch (error) {
          Ember.Logger.error(error); // Setting the errorState on the applicaiton service will route to the maintenance mode page.

          service.set('errorState', {
            type: 'invalidEnv'
          });
          service.set('runtime', null);
        }
      }

      application.set('applicationService', service);
    }
  };
  _exports.default = _default;
});