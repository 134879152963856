define("web-directory/components/command-subnav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1oTlWMMb",
    "block": "{\"statements\":[[9,\"gux-button-slot-beta\",{\"attrs\":[[15,\"accent\",\"ghost\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n    \"],[11,\"button\",[]],[15,\"type\",\"button\"],[16,\"aria-controls\",[28,[\"menu\",\"id\"]],null],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"toggleSubMenu\"],null],null],[16,\"aria-expanded\",[33,[\"if\"],[[28,[\"subMenuOpen\"]],\"true\",\"false\"],null],null],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"link-title\"],[13],[1,[33,[\"t\"],[[28,[\"menu\",\"title\"]]],null],false],[14],[0,\"\\n        \"],[9,\"gux-icon\",{\"attrs\":[[16,\"icon-name\",[33,[\"if\"],[[28,[\"subMenuOpen\"]],\"chevron-up\",\"chevron-down\"],null],null],[15,\"decorative\",\"true\"],[15,\"size\",\"inherit\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n    \"],[14],[0,\"\\n\"]]}],[0,\"\\n\\n\"],[11,\"ul\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"subMenuOpen\"]],\"\",\"hidden\"],null]]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"subMenuOpen\"]]],null,{\"statements\":[[6,[\"each\"],[[28,[\"menu\",\"subMenu\"]]],null,{\"statements\":[[0,\"            \"],[11,\"li\",[]],[13],[0,\"\\n                \"],[11,\"a\",[]],[15,\"class\",\"nav-item nav-link\"],[16,\"href\",[28,[\"subItem\",\"link\"]],null],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"handleLinkAction\",[28,[\"subItem\",\"action\"]]],null],null],[13],[0,\"\\n                    \"],[11,\"div\",[]],[15,\"class\",\"sublink-title\"],[13],[1,[33,[\"t\"],[[28,[\"subItem\",\"title\"]]],null],false],[14],[0,\"\\n                \"],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[\"subItem\"]},null]],\"locals\":[]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/command-subnav/template.hbs"
    }
  });

  _exports.default = _default;
});