define("web-directory/components/acd-interactions-panel/interaction-card-v2/chat-card/component", ["exports", "web-directory/components/acd-interactions-panel/interaction-card-v2/base-card/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTL_KEY = {
    UNREAD: 'purecloud.acdInteraction.roster.cards.unreadCount',
    LAST_MSG_CUSTOMER: 'purecloud.acdInteraction.roster.cards.lastMessageWasCustomer',
    TYPING: 'purecloud.acdInteraction.roster.cards.typing'
  };

  var _default = _component.default.extend({
    classNames: ['acd-interaction-card-v2-chat'],

    /**
     * @Attribute
     * Conversation represented by card
     */
    interaction: null,

    /**
     * @Attribute
     * Array of conversation guids currently flagged as typing
     */
    isTypingInteractions: null,

    /**
     * Primary roster card state
     */
    state: Ember.computed('inWrapup', 'unreadMessageCount', 'lastMessageWasCustomer', 'isAttendedTransferring', function () {
      if (this.get('isAttendedTransferring')) {
        return {
          loading: true,
          class: 'transferring-state'
        };
      } else if (this.get('inWrapup')) {
        return {
          icon: 'badge-check',
          class: 'wrapup-state'
        };
      } else if (this.get('unreadMessageCount')) {
        return {
          icon: 'badge-amount',
          textKey: INTL_KEY.UNREAD,
          unreadCount: this.get('unreadMessageCount'),
          class: 'alert-state msg-count'
        };
      } else if (this.get('lastMessageWasCustomer')) {
        return {
          icon: 'badge-amount',
          textKey: INTL_KEY.LAST_MSG_CUSTOMER,
          class: 'alert-state last-message-was-customer alert-state-abbreviated'
        };
      }

      return null;
    }),

    /**
     * Supplemental roster card state
     */
    supplementalState: Ember.computed('inWrapup', 'isTyping', 'lastMessageText', function () {
      if (this.get('inWrapup')) {
        return null;
      } else if (this.get('isTyping')) {
        return {
          textKey: INTL_KEY.TYPING,
          class: '',
          elapsed: this.get('lastMessageTime')
        };
      } else if (this.get('lastMessageText')) {
        return {
          text: this.get('lastMessageText'),
          class: '',
          elapsed: this.get('lastMessageTime')
        };
      }

      return null;
    }),

    /**
     * Flag for chat provider (v2 vs v1)
     */
    isV2Provider: Ember.computed.readOnly('interaction.isV2Provider'),

    /**
     * Flag indicating if the last message was inbound or outbound
     */
    lastMessageWasCustomer: Ember.computed.readOnly('interaction.lastMessageWasCustomer'),

    /**
     * Text of the last message sent or received.  Will be undefined if we it cannot be parsed from
     * message objects.
     */
    lastMessageText: Ember.computed.readOnly('interaction.lastMessageText'),

    /**
     * Time object from last message object. Read from mixins/acd.js
     */
    lastMessageTime: Ember.computed.readOnly('interaction.lastMessageTime'),

    /**
     * Count of the number of messaage marked as unread, formatted as a string since we
     * write anything >9 as 9+.
     */
    unreadMessageCount: Ember.computed('interaction.messages.@each.unread', 'interaction.room.messages.@each.unread', function () {
      var messages;

      if (this.get('isV2Provider')) {
        messages = this.get('interaction.messages') || [];
      } else {
        messages = this.get('interaction.room.messages') || [];
      }

      var cnt = messages.filter(function (m) {
        return Ember.get(m, 'unread');
      }).length;
      return cnt > 9 ? '9+' : cnt;
    }),

    /**
     * True/false if interaction customer is flagged as typing
     */
    isTyping: Ember.computed('isTypingInteractions.[]', 'interaction.id', function () {
      var typingInteractions = this.get('isTypingInteractions') || [];
      var id = this.get('interaction.id');
      return typingInteractions.indexOf(id) > -1;
    })
  });

  _exports.default = _default;
});