define("web-directory/components/command-panel-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3y7TdeVP",
    "block": "{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"definition\",\"activeDrawerId\"]],\"help-current-page\"],null]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"app-embed\"],null,[[\"appConfig\"],[[28,[\"embedConfig\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"definition\",\"activeDrawerId\"]],\"help-release-notes\"],null]],null,{\"statements\":[[0,\"    \"],[1,[33,[\"app-embed\"],null,[[\"appConfig\"],[[28,[\"releaseEmbedConfig\"]]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"definition\",\"activeDrawerId\"]],\"help-resources\"],null]],null,{\"statements\":[[0,\"    \"],[11,\"p\",[]],[13],[0,\"Resource list here.\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]}]],\"locals\":[]}],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/command-panel-help/template.hbs"
    }
  });

  _exports.default = _default;
});