define("web-directory/components/application-preferences/component", ["exports", "lodash", "web-directory/utils/focus-management"], function (_exports, _lodash, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Component = Ember.Component,
      computed = Ember.computed,
      inject = Ember.inject;

  var _default = Component.extend({
    classNames: ['preferences'],
    settings: inject.service(),
    permissions: inject.service(),
    activePreference: 'notifications',
    defaultPreference: 'notifications',
    init: function init() {
      this._super.apply(this, arguments);

      var focusTarget = 'a:first';
      (0, _focusManagement.doFocus)(this, focusTarget);

      if (!this.get('settings').canOpenPreference('notifications')) {
        this.set('activePreference', 'chat');
        this.set('defaultPreference', 'chat');
      }

      this.get('settings').on('openPreference', this, this.openPreference);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('settings').off('openPreference', this, this.openPreference);

      this._super.apply(this, arguments);
    },
    actions: {
      changeTab: function changeTab(preferenceKey) {
        this.openPreference(preferenceKey);
      }
    },
    tabs: computed('activePreference', 'defaultPreference', 'settings.{commonPreferences,customPreferences,commonPreferencesSortOrder}', 'permissions.canViewBusinessChat', function () {
      var order = this.get('settings.commonPreferencesSortOrder');
      var common = this.get('settings.commonPreferences').sort(function (prefA, prefB) {
        return order.indexOf(prefA.key) - order.indexOf(prefB.key);
      });
      var custom = this.get('settings.customPreferences').sort(function (prefA, prefB) {
        return prefA.name.localeCompare(prefB.name);
      });

      var tabs = _lodash.default.cloneDeep([].concat(_toConsumableArray(common), _toConsumableArray(custom))); // fix SafeString garble


      tabs.forEach(function (tab) {
        tab.isActive = false;

        if (tab.name && tab.name.string) {
          tab.name = tab.name.string;
        }
      });
      var activePreference = this.get('activePreference');
      var defaultPreference = this.get('defaultPreference');
      var preference = tabs.find(function (preference) {
        return preference.key === activePreference;
      }) || tabs.find(function (preference) {
        return preference.key === defaultPreference;
      });
      preference.isActive = true;

      if (!this.get('permissions.canViewBusinessChat')) {
        tabs = tabs.reject(function (tab) {
          return tab.key === 'chat';
        });
      }

      if (!this.get('permissions.phoneEnabled')) {
        tabs = tabs.reject(function (tab) {
          return tab.key === 'pin';
        });
      }

      return tabs;
    }),
    currentTab: computed('tabs', function () {
      return this.get('tabs').find(function (tab) {
        return tab.isActive;
      });
    }),
    openPreference: function openPreference(preferenceKey) {
      this.set('activePreference', preferenceKey);
    }
  });

  _exports.default = _default;
});