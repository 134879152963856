define("web-directory/components/acd-interactions-panel/interaction-card-v2/email-card/component", ["exports", "web-directory/components/acd-interactions-panel/interaction-card-v2/base-card/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _component.default.extend({
    classNames: ['acd-interaction-card-v2-email'],

    /**
     * @Attribute
     * Conversation represented by card
     */
    interaction: null,

    /**
     * @Attribute
     * Email Draft Object containing updates
     */
    draftUpdate: null,

    /**
     * Primary roster card state
     */
    state: Ember.computed('inWrapup', 'isEditingDraft', 'isAttendedTransferring', function () {
      if (this.get('isAttendedTransferring')) {
        return {
          loading: true,
          class: 'transferring-state'
        };
      } else if (this.get('inWrapup')) {
        return {
          icon: 'badge-check',
          class: 'wrapup-state'
        };
      } else if (this.get('isEditingDraft')) {
        return {
          icon: 'badge-edit',
          class: 'draft-state'
        };
      }

      return null;
    }),

    /**
     * Supplemental roster card state
     */
    supplementalState: Ember.computed('inWrapup', 'subject', 'lastReceived', function () {
      if (this.get('inWrapup')) {
        return null;
      }

      var subject = this.get('subject');
      var elapsed = this.get('lastReceived') || null;
      var textKey = 'purecloud.acdInteraction.roster.cards.email.noSubject';
      var cssClass = '';

      if (!!subject) {
        return {
          class: cssClass,
          elapsed: elapsed,
          text: subject
        };
      }

      return {
        class: cssClass,
        elapsed: elapsed,
        textKey: textKey
      };
    }),

    /**
     * Flag indicating that the draft is in edit mode
     */
    isEditingDraft: Ember.computed('useEmailDraftEvent', 'draftUpdate.state', 'interaction.{hasMessages,isResponding,draft.state}', 'acdEmailPreviewPerformance', function () {
      if (this.get('useEmailDraftEvent')) {
        var state = (this.get('draftUpdate.state') || '').toLowerCase();
        return state === 'edited' || state === 'ready';
      } else if (this.get('acdEmailPreviewPerformance')) {
        var _state = (this.get('interaction.draft.state') || '').toLowerCase();

        return _state === 'edited' || _state === 'ready';
      }

      if (!this.get('interaction.hasMessages')) {
        return true;
      }

      return this.get('interaction.isResponding');
    }),

    /**
     * The subject of the last message recieved or draft
     */
    subject: Ember.computed('interaction.messages.[]', 'interaction.draft.subject', 'useEmailDraftEvent', 'draftUpdate.subject', 'interaction.participantForCustomer.emails.firstObject.subject', function () {
      if (this.get('useEmailDraftEvent')) {
        return this.get('draftUpdate.subject') || this.get('interaction.participantForCustomer.emails.firstObject.subject');
      }

      var subject = this.get('interaction.messages.lastObject.subject');
      var draftSubject = this.get('interaction.draft.subject');
      return subject || draftSubject;
    }),

    /**
     * The moment object of the oldest message timestamp
     */
    lastReceived: Ember.computed('interaction.messages.[]', function () {
      var messages = this.get('interaction.messages') || [];

      if (!messages || messages.length < 1) {
        return null;
      }

      var times = messages.map(function (m) {
        return new Date(Ember.get(m, 'time')).valueOf();
      });
      var oldest = Math.max.apply(Math, _toConsumableArray(times));
      return new Date(oldest);
    })
  });

  _exports.default = _default;
});