define("web-directory/components/tags-input/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q9w9kL1O",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"tags-input-label\"],[13],[0,\"\\n    \"],[11,\"p\",[]],[15,\"class\",\"tags-input__tag\"],[13],[0,\"\\n        \"],[9,\"gux-truncate-beta\",{\"attrs\":[[15,\"max-lines\",\"1\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n            \"],[18,\"default\"],[0,\"\\n        \"]]}],[0,\"\\n    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/tags-input/label/template.hbs"
    }
  });

  _exports.default = _default;
});