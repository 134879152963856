define("web-directory/services/help", ["exports", "web-directory/utils/newrelic"], function (_exports, _newrelic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Evented = Ember.Evented,
      inject = Ember.inject;
  var FULL_URL_REG_EXP = /^.*:\/\/.*$/;
  var LOCALE_REDIRECT_REGEX = /\/redirect\/[^/]+\//;
  var HELP_PANEL_NAME = 'help';
  var NO_OP = 'noop';

  var _default = Ember.Service.extend(Evented, {
    PC_HELP_FQDN: 'help.mypurecloud.com',
    EVENT_NAMES: {
      SHOW_RC_ARTIFACT: 'showRcArtifact',
      OPEN_CONTEXTUAL_HELP: 'openContextualHelp'
    },
    sidebar: inject.service(),
    application: inject.service(),
    engage: inject.service(),
    relate: inject.service(),
    adminui: inject.service(),
    centerStageFrame: inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.generateContextualHelpUrl();
    },
    previousContext: null,
    contextualHelpUrl: '#',
    contextualHelpAnchor: undefined,
    observeRoutes: Ember.observer('application.currentRouteName', 'sidebar.{communicationPanel,communicationSidebarExpanded}', 'engage.{isEngageActive,route,helpURL}', 'relate.{isRelateActive,route,helpURL}', 'adminui.currentRouteName', 'centerStageFrame.helpPath', function () {
      var _this = this;

      Ember.run.debounce(this, function () {
        return _this.generateContextualHelpUrl();
      }, 500);
    }),
    generateContextualHelpUrl: function generateContextualHelpUrl() {
      var locale = this.get('intl.isoLocale');
      var contextualHelpUrl = '#'; //TEMP: Redirect all locales to en-US until Resource Center supports other locales (PCDWEBK-1883)

      locale = 'en-US';
      var route = this.get('application.currentRouteName');
      var routePrefix = 'directory.';

      if (this.get('sidebar.communicationSidebarExpanded')) {
        var panel = this.get('sidebar.communicationPanel');

        if (panel === 'help' && this.get('previousContext')) {
          contextualHelpUrl = this.get('previousContext');
        } // map expanded panels to redirect routes because they don't have application routes


        if (panel === 'webrtc') {
          route = 'videoPanel';
        } else if (panel === 'conversations') {
          route = 'chatPanel';
        } else {
          route = 'sidebarPanel';
        }
      } else if (this.get('engage.isEngageActive') && this.get('engage.route') !== NO_OP) {
        if (this.get('engage.helpURL')) {
          contextualHelpUrl = this.get('engage.helpURL');
        }

        route = this.get('engage.route');
        routePrefix = '';
      } else if (this.get('relate.isRelateActive') && this.get('relate.route') !== NO_OP) {
        // deprecated with changeover to engine below.
        routePrefix = 'directory.relate.';
        route = this.get('relate.route');
      } else if (route === 'admin') {
        routePrefix = 'admin.';
        route = this.get('adminui.currentRouteName').replace(/\//g, '.');
        route = route.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, 'id'); // uuid

        route = route.replace(/[0-9a-f]{24}/g, 'id'); // mongo id

        route = route.replace(/[0-9a-f]{30}/g, 'id'); // bridge id

        route = route.replace(/-[0-9a-zA-Z]+==-/g, 'id'); // connector id
      } else if (route && route.startsWith('center-stage-frame-outbound.internal')) {
        // Used for outbound-ui
        routePrefix = '';
        route = this.get('centerStageFrame.helpPath');
      } else if (route && route.startsWith('center-stage-frame-quality.internal')) {
        // used for Quality Admin pages
        routePrefix = '';
        route = this.get('centerStageFrame.helpPath');
      } else if (route && route.startsWith('center-stage-frame')) {
        if (route.endsWith('messageRouting')) {
          routePrefix = '';
        }

        route = this.get('centerStageFrame.helpPath');
      } else {
        // used for ember-engine-contacts. The route is
        // passed back unchanged if there is no contacts engine relevant path.
        route = this.get('relate').getEngineHelp(route);
      }

      if (contextualHelpUrl === '#') {
        contextualHelpUrl = "https://help.mypurecloud.com/redirect/".concat(locale, "/").concat(routePrefix).concat(route);
      } // ACE-1926, only support for en-US locales currently


      contextualHelpUrl = contextualHelpUrl.replace(LOCALE_REDIRECT_REGEX, "/redirect/".concat(locale, "/"));
      var evtPayload = {
        route: route,
        routePrefix: routePrefix,
        contextualHelpUrl: contextualHelpUrl
      };
      (0, _newrelic.addPageAction)('contextualHelpRouteChange', evtPayload);
      this.set('previousContext', contextualHelpUrl);
      this.set('contextualHelpUrl', contextualHelpUrl);
    },

    /**
     * Open the help panel and the Resource Center URL referenced by the relative path
     *
     * @param relPath A non-empty, relative path. Paths will be encoded.  Query parms
     * are allowed but should be pre-encoded.  Hash params will be removed.
     *
     * @throws Error if the relPath is invalid:
     * <ul>
     *   <li>Empty (empty strings, empty paths and query strings)</li>
     *   <li>Not a string</li>
     *   <li>Leads with protocol</li>
     *   <li>Path navigation</li>
     * </ul>
     */
    showResourceCenterArtifact: function showResourceCenterArtifact(relPath) {
      var embededArtifactUrl = this.buildResourceCenterArtifactUrl(relPath);
      var externalArtifactUrl = this.buildResourceCenterArtifactUrl(relPath, {
        useSimplifiedTheme: false
      });
      this.get('sidebar').openPanel(HELP_PANEL_NAME);
      this.trigger(this.EVENT_NAMES.SHOW_RC_ARTIFACT, {
        requestedRelPath: relPath,
        externalArtifactUrl: externalArtifactUrl,
        embededArtifactUrl: embededArtifactUrl
      });
    },

    /**
     * Open the help panel to the contextual help tab and navigate to an anchor
     * on the page if provided.
     *
     * @param anchor A non-empty anchor to be added to the relative path or undefined.
     */
    openContextualHelpTab: function openContextualHelpTab(anchor) {
      this.get('sidebar').openPanel(HELP_PANEL_NAME);

      if (anchor) {
        this.set('contextualHelpAnchor', anchor);
      }

      this.trigger(this.EVENT_NAMES.OPEN_CONTEXTUAL_HELP);
    },

    /**
     * Build the absolute resource center url from the provided rel Path.
     *
     * @param relPath A non-empty, relative path. Paths will be encoded.  Query parms
     * are allowed and should be encoded.  Hash params will be removed.
     *
     * @param options.useSimplifiedTheme boolean indicating if the simplified theme
     * option should be added to the generated url; defaults to true
     *
     * @returns A full url to the resource center artifact. Paths will be encoded by this call.  Query parms
     * are allowed but should be pre-encoded.  Hash params will be removed.
     *
     * @throws Error if the relPath is invalid:
     * <ul>
     *   <li>Empty (empty strings, empty paths and query strings)</li>
     *   <li>Not a string</li>
     *   <li>Leads with protocol</li>
     *   <li>Path navigation</li>
     * </ul>
     */
    buildResourceCenterArtifactUrl: function buildResourceCenterArtifactUrl(relPath) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$useSimplifiedThe = _ref.useSimplifiedTheme,
          useSimplifiedTheme = _ref$useSimplifiedThe === void 0 ? true : _ref$useSimplifiedThe;

      if (!relPath || typeof relPath !== 'string' || relPath.trim().length === 0) {
        throwBadRelPath(relPath, 'Empty relPath or invalid type');
      }

      var toCheck = relPath.trim();

      if (toCheck.match(FULL_URL_REG_EXP)) {
        throwBadRelPath(relPath, 'Full URL Attempted');
      }

      var path = '';
      var query = null;
      var hashIndex = toCheck.indexOf('#');

      if (hashIndex >= 0) {
        toCheck = toCheck.slice(0, hashIndex);
      }

      var queryIndex = toCheck.indexOf('?');

      if (queryIndex >= 0) {
        query = toCheck.slice(queryIndex + 1);
        path = toCheck.slice(0, queryIndex);
      } else {
        path = toCheck;
      }

      if (path.length === 0 && !query) {
        throwBadRelPath(relPath, 'Insufficient path');
      } else if (path.length > 0) {
        path = path.split('/').map(function (pathSegment) {
          if (pathSegment === '.' || pathSegment === '..') {
            throwBadRelPath(relPath, 'Path Navigation Detected');
          }

          return encodeURIComponent(pathSegment);
        }).join('/');

        if (path.charAt(0) !== '/') {
          path = "/".concat(path);
        }
      }

      var result = "https://".concat(this.PC_HELP_FQDN).concat(path);

      if (query) {
        result += "?".concat(query);
      }

      if (useSimplifiedTheme) {
        result += "".concat(query ? '&' : '?', "theme=simplified");
      }

      return result;
    }
  });

  _exports.default = _default;

  function throwBadRelPath(relPath, internalMsg) {
    var err = new Error('service:help - Invalid Artifact Relative Path', relPath);
    err.internalMsg = internalMsg;
    throw err;
  }
});