define("web-directory/components/command-panel-help/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['command-panel-help'],
    // @ts-ignore
    help: Ember.inject.service('help'),
    embedConfig: Ember.computed('contextualHelpUrl', 'contextualHelpAnchor', function () {
      return {
        id: 'help-panel-embed',
        url: this.get('contextualHelpAnchor') ? "".concat(this.get('contextualHelpUrl'), "?theme=simplified").concat(this.get('contextualHelpAnchor')) : "".concat(this.get('contextualHelpUrl'), "?theme=simplified"),
        sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups', 'allow-forms']
      };
    }),
    releaseEmbedConfig: Ember.computed(function () {
      return {
        id: 'help-panel-emebd',
        url: 'https://help.mypurecloud.com/release-notes-home/?theme=simplified',
        sandbox: ['allow-same-origin', 'allow-scripts', 'allow-popups', 'allow-forms']
      };
    }),
    contextualHelpUrl: Ember.computed.reads('help.contextualHelpUrl'),
    contextualHelpAnchor: Ember.computed.readOnly('help.contextualHelpAnchor')
  });

  _exports.default = _default;
});