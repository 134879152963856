define("web-directory/utils/transform-panels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformationDependentFeatureToggles = _exports.transformPanelSetting = _exports.transformPanelId = _exports.standardTransformationFtComputedDepKey = _exports.buildTransformationFtComputedDepKey = _exports.addNativeProvider = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Native Panels will eventually be replaced by providers and gadgets
  var NATIVE_PANELS = ['details', 'scripts', 'responses', 'notes', 'scheduleCallback', 'wrapup'];
  var SCRIPTS_GADGET_PANEL_ID = 'scripts&scriptsProvider';
  var deprecatedConversationSummaryPanelIds = ['interaction-details', 'agent-conversation-summary'].map(function (currPanelId) {
    return "".concat(currPanelId, "&conversationSummaryInterapptionsProvider");
  });
  var CONVERSATION_DETAILS_GADGET_PANEL_ID = 'conversation-details&conversationDetailsInterapptionsProvider';
  var CANNED_RESPONSES_GADGET_PANEL_ID = 'responses&cannedResponsesProvider'; // Assuming the provider and app id for Schedule Callbacks

  var SCHEDULE_CALLBACK_GADGET_PANEL_ID = 'scheduleCallback&scheduleCallbackProvider';
  var NOTES_GADGET_PANEL_ID = 'notesGadget&notesGadgetProvider';
  var WRAPUP_GADGET_PANEL_ID = 'wrapupCodesGadget&wrapupCodesGadgetProvider';
  var transformationMaps = {
    // Note: Conversation Summary will be replaced by Conversation Details before going GA
    // CS options were never shipped to AUiB and CS used two different panel ids which complicates the transform
    // As such, we're only supporting between details and conversation-details
    details: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === 'details' && panelSetting.type.toLowerCase() === 'builtin';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltInInterapption',
          panelId: CONVERSATION_DETAILS_GADGET_PANEL_ID
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return ['details', addNativeProvider('details')].concat(_toConsumableArray(deprecatedConversationSummaryPanelIds)).includes(appDefId);
      },
      transformAppDefId: function transformAppDefId() {
        return CONVERSATION_DETAILS_GADGET_PANEL_ID;
      },
      dependentFeatureToggles: ['conversationSummary3256', 'PURE-3256_useConversationDetails'],
      active: function active(features) {
        return features.conversationSummary3256 && features['PURE-3256_useConversationDetails'];
      }
    },
    reverseDetails: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === CONVERSATION_DETAILS_GADGET_PANEL_ID && panelSetting.type.toLowerCase() === 'builtininterapption';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltIn',
          panelId: 'details'
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return [].concat(_toConsumableArray(deprecatedConversationSummaryPanelIds), [CONVERSATION_DETAILS_GADGET_PANEL_ID]).includes(appDefId);
      },
      transformAppDefId: function transformAppDefId() {
        return 'details';
      },
      active: function active(features) {
        return !transformationMaps.details.active(features);
      }
    },
    notes: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === 'notes' && panelSetting.type.toLowerCase() === 'builtin';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltInInterapption',
          panelId: NOTES_GADGET_PANEL_ID
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === 'notes' || appDefId === addNativeProvider('notes');
      },
      transformAppDefId: function transformAppDefId() {
        return NOTES_GADGET_PANEL_ID;
      },
      dependentFeatureToggles: ['auraACWGadgets'],
      active: function active(features) {
        return features['auraACWGadgets'];
      }
    },
    reverseNotes: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === NOTES_GADGET_PANEL_ID && panelSetting.type.toLowerCase() === 'builtininterapption';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltIn',
          panelId: 'notes'
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === NOTES_GADGET_PANEL_ID;
      },
      transformAppDefId: function transformAppDefId() {
        return 'notes';
      },
      active: function active(features) {
        return !transformationMaps.notes.active(features);
      }
    },
    wrapup: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === 'wrapup' && panelSetting.type.toLowerCase() === 'builtin';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltInInterapption',
          panelId: WRAPUP_GADGET_PANEL_ID
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === 'wrapup' || appDefId === addNativeProvider('wrapup');
      },
      transformAppDefId: function transformAppDefId() {
        return WRAPUP_GADGET_PANEL_ID;
      },
      dependentFeatureToggles: ['auraACWGadgets'],
      active: function active(features) {
        return features['auraACWGadgets'];
      }
    },
    reverseWrapup: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === WRAPUP_GADGET_PANEL_ID && panelSetting.type.toLowerCase() === 'builtininterapption';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltIn',
          panelId: 'wrapup'
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === WRAPUP_GADGET_PANEL_ID;
      },
      transformAppDefId: function transformAppDefId() {
        return 'wrapup';
      },
      active: function active(features) {
        return !transformationMaps.wrapup.active(features);
      }
    },
    responses: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === 'responses' && panelSetting.type.toLowerCase() === 'builtin';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltInInterapption',
          panelId: CANNED_RESPONSES_GADGET_PANEL_ID
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === 'responses' || appDefId == addNativeProvider('responses');
      },
      transformAppDefId: function transformAppDefId() {
        return CANNED_RESPONSES_GADGET_PANEL_ID;
      },
      dependentFeatureToggles: ['PURE-5421_CannedResponse_Gadget'],
      active: function active(features) {
        return features['PURE-5421_CannedResponse_Gadget'];
      }
    },
    reverseResponses: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === CANNED_RESPONSES_GADGET_PANEL_ID && panelSetting.type.toLowerCase() === 'builtininterapption';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltIn',
          panelId: 'responses'
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === CANNED_RESPONSES_GADGET_PANEL_ID;
      },
      transformAppDefId: function transformAppDefId() {
        return 'responses';
      },
      active: function active(features) {
        return !transformationMaps.responses.active(features);
      }
    },
    scheduleCallback: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === 'scheduleCallback' && panelSetting.type.toLowerCase() === 'builtin';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltInInterapption',
          panelId: SCHEDULE_CALLBACK_GADGET_PANEL_ID
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === 'scheduleCallback' || appDefId === addNativeProvider('scheduleCallback');
      },
      transformAppDefId: function transformAppDefId() {
        return SCHEDULE_CALLBACK_GADGET_PANEL_ID;
      },
      dependentFeatureToggles: ['scheduleCallbackGadget'],
      // assumption for the ft
      active: function active(features) {
        return features['scheduleCallbackGadget']; // assumption for the ft
      }
    },
    reverseScheduleCallback: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === SCHEDULE_CALLBACK_GADGET_PANEL_ID && panelSetting.type.toLowerCase() === 'builtininterapption';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltIn',
          panelId: 'scheduleCallback'
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === SCHEDULE_CALLBACK_GADGET_PANEL_ID;
      },
      transformAppDefId: function transformAppDefId() {
        return 'scheduleCallback';
      },
      active: function active(features) {
        return !transformationMaps.scheduleCallback.active(features);
      }
    },
    scripts: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === 'scripts' && panelSetting.type.toLowerCase() === 'builtin';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltInInterapption',
          panelId: SCRIPTS_GADGET_PANEL_ID
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === 'scripts' || appDefId === addNativeProvider('scripts');
      },
      transformAppDefId: function transformAppDefId() {
        return SCRIPTS_GADGET_PANEL_ID;
      },
      dependentFeatureToggles: ['PURE-6186_Scripter_Gadget_GCUI'],
      // assumption for the ft
      active: function active(features) {
        // Here, we do not have to check for workitem scripter FT
        // PEF does not support workitems and therefore we only need to transform query params for conversations
        // The Workitem scripter PM setting will use the built-in provider panel setting from the get-go
        // The workitem scritper panel will use the built-in provider panelId from the get-go
        // legacy scripter panel ids in layout models only need to be transformed for conversations;
        //   again because workitem scripter panelIds will use provider from the get-go
        return features['PURE-6186_Scripter_Gadget_GCUI'];
      }
    },
    reverseScripts: {
      shouldTransformPanelSetting: function shouldTransformPanelSetting(panelSetting) {
        return panelSetting.panelId === SCRIPTS_GADGET_PANEL_ID && panelSetting.type.toLowerCase() === 'builtininterapption';
      },
      transformPanelSetting: function transformPanelSetting() {
        return {
          type: 'BuiltIn',
          panelId: 'scripts'
        };
      },
      shouldTransformAppDefId: function shouldTransformAppDefId(appDefId) {
        return appDefId === SCRIPTS_GADGET_PANEL_ID;
      },
      transformAppDefId: function transformAppDefId() {
        return 'scripts';
      },
      active: function active(features) {
        // This logic isn't perfect. It won't guard against the case where scripter is converted
        // to a gadget in conversation contexts and needed to rollback to the native panel.
        // However, we wouldn't update PM to use built-in providers for contextual panels until the gadget is fully GA'd and stable.
        // Thus, the impact would only be that users having to manually reopen the script panel from a persisted MP layout.
        // If it's decided this isn't sufficient, the proper solution would be to also provide a 'conversation' | 'workitem'
        // context to the transformer.
        return !features['PURE-6186_Scripter_Gadget_GCUI'] && !features['PURE-4948_scripter_workItem'];
      }
    }
  };

  var transformationDependentFeatureToggles = _toConsumableArray(new Set(Object.values(transformationMaps).flatMap(function (_ref) {
    var dependentFeatureToggles = _ref.dependentFeatureToggles;
    return dependentFeatureToggles !== null && dependentFeatureToggles !== void 0 ? dependentFeatureToggles : [];
  })));

  _exports.transformationDependentFeatureToggles = transformationDependentFeatureToggles;

  var buildTransformationFtComputedDepKey = function buildTransformationFtComputedDepKey(injectionPrefix) {
    return "".concat(injectionPrefix, ".{").concat(transformationDependentFeatureToggles.join(','), "}");
  };

  _exports.buildTransformationFtComputedDepKey = buildTransformationFtComputedDepKey;
  var standardTransformationFtComputedDepKey = buildTransformationFtComputedDepKey('session.features');
  _exports.standardTransformationFtComputedDepKey = standardTransformationFtComputedDepKey;

  var transformPanelSetting = function transformPanelSetting(panelSetting, features) {
    var requiredTransform = Object.values(transformationMaps).find(function (transform) {
      return transform.active(features) && transform.shouldTransformPanelSetting(panelSetting);
    });

    if (requiredTransform) {
      return requiredTransform.transformPanelSetting();
    }

    return panelSetting;
  };
  /**
   * Transforms the provided panelId to the target panelId based on the enable state of various transformers.
   * If no applicable transformer is found, the original panelId is returned. This
   * transformation will work for native src panels with and without a native provider, but note that
   * transformations back to native panels will not include the native provider.  If the native provider is needed,
   * callers should call `addNativeProvider` on the returned panelId.
   *
   * @param panelId The panelId (with or without native provider) to transform, when applicable
   * @param features The feature flags to use when determining which transformer to use
   * @returns A transformed panelId if applicable, otherwise the original panelId.  Native panelIds will not include the native provider.
   */


  _exports.transformPanelSetting = transformPanelSetting;

  var transformPanelId = function transformPanelId(panelId, features) {
    // Normalize native panel ids to not include the native provider for searching transformers
    var srcNativePanelMatch = findNativePanel(panelId);
    var toSearch = srcNativePanelMatch ? srcNativePanelMatch.panelId : panelId;
    var requiredTransform = Object.values(transformationMaps).find(function (transform) {
      return transform.active(features) && transform.shouldTransformAppDefId(toSearch);
    });

    if (requiredTransform) {
      return requiredTransform.transformAppDefId();
    }

    return panelId;
  };
  /**
   * Appends the native provider to the end of the provided panelId if it represents a native panel.
   * This function is idempotent and safe to call on non-native panelIds as well as panelIds
   * which may already include the native provider.
   *
   * eg.
   * scheduleCallback -> scheduleCallback&native
   * scheduleCallback&native -> scheuldeCallback&native
   * nonNativePanelId -> nonNativePanelId
   * nonNativePanelId&nonNativeProviderId -> nonNativePanelId&nonNativeProviderId
   */


  _exports.transformPanelId = transformPanelId;

  var addNativeProvider = function addNativeProvider(panelId) {
    var nativePanel = findNativePanel(panelId);

    if (nativePanel) {
      return "".concat(nativePanel.panelId, "&native");
    }

    return panelId;
  };

  _exports.addNativeProvider = addNativeProvider;

  var findNativePanel = function findNativePanel(panelId) {
    var nativePanel = NATIVE_PANELS.find(function (currNativePanel) {
      return panelId === currNativePanel || panelId === "".concat(currNativePanel, "&native");
    });

    if (!nativePanel) {
      return;
    }

    return {
      panelId: nativePanel,
      includedNativeProvider: panelId.endsWith('&native')
    };
  };
});