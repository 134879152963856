define("web-directory/components/acd-interactions-panel/interaction-card-v2/component", ["exports", "web-directory/utils/acd", "web-directory/utils/safeSet"], function (_exports, _acd, _safeSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['acd-interaction-card-v2'],
    classNameBindings: ['isAlerting', 'isSelected'],

    /**
     * @attribute
     * acdShowMonitoringIndicator ft
     */
    acdAnnounceMonitoringFt: false,

    /**
     * @attribute
     * acdAnnounceMonitoringDigital ft
     */
    acdAnnounceMonitoringDigitalFt: false,

    /**
     * @Attribute
     * digital monitoring ft
     */
    dgiMonFt: false,

    /**
     * @attribute
     * useEmailDraftEvent ft
     */
    useEmailDraftEvent: false,

    /**
     * @attribute
     * Conversation represented by card
     */
    interaction: null,

    /**
     * @attribute
     * Currently selection conversation
     */
    selectedInteraction: null,

    /**
     * @attribute
     * Workitem represented by card
     */
    workitem: null,

    /**
     * @attribute
     * Id of the interaction's (conversation, workitem) queue
     */
    interactionQueueId: '',

    /**
     * @attribute
     * Draft type object containing draft updates
     */
    draftUpdateHash: null,

    /**
     * 'name' of agent being monitored
     */
    mcbParticipantName: null,

    /**
     * @private
     * Id of the last fetched interaction's (conversation, workitem) queue
     */
    _prevInteractionQueueId: '',

    /**
     * `true` if the current user participant is on monitor.
     */
    iAmOnMonitor: Ember.computed('interaction.participants.@each.{monitoredParticipantId}', 'acdAnnounceMonitoringFt', function () {
      var _this$get, _participants$find;

      if (this.get('interaction.type') !== 'call' || !this.get('acdAnnounceMonitoringFt')) {
        return false;
      }

      var participants = (_this$get = this.get('interaction.participants')) !== null && _this$get !== void 0 ? _this$get : [];
      var currUserParticipantId = this.get('interaction.participantForCurrentUser.id');
      var monitoredParticipantId = (_participants$find = participants.find(function (p) {
        var _p$calls, _p$calls$;

        return p.monitoredParticipantId && !((_p$calls = p.calls) !== null && _p$calls !== void 0 && (_p$calls$ = _p$calls[0]) !== null && _p$calls$ !== void 0 && _p$calls$.disconnectType);
      })) === null || _participants$find === void 0 ? void 0 : _participants$find.monitoredParticipantId;
      return currUserParticipantId === monitoredParticipantId;
    }),

    /**
     * `true` if the current user participant monitor - digital only
     */
    iAmOnMonitorDigital: Ember.computed('interaction.participants.@each.{monitoredParticipantId}', 'acdAnnounceMonitoringDigitalFt', function () {
      var _this$get2, _participants$find2;

      if (this.get('interaction.type') !== 'message' || !this.get('acdAnnounceMonitoringDigitalFt') || !this.get('dgiMonFt')) {
        return false;
      }

      var participants = (_this$get2 = this.get('interaction.participants')) !== null && _this$get2 !== void 0 ? _this$get2 : [];
      var currUserParticipantId = this.get('interaction.participantForCurrentUser.id');
      var monitoredParticipantId = (_participants$find2 = participants.find(function (p) {
        var _p$messages, _p$messages$;

        return p.monitoredParticipantId && !((_p$messages = p.messages) !== null && _p$messages !== void 0 && (_p$messages$ = _p$messages[0]) !== null && _p$messages$ !== void 0 && _p$messages$.disconnectType);
      })) === null || _participants$find2 === void 0 ? void 0 : _participants$find2.monitoredParticipantId;
      return currUserParticipantId === monitoredParticipantId;
    }),

    /**
     * `true` if the current user participant is on coach.
     */
    iAmOnCoach: Ember.computed('interaction.participants.@each.{coachedParticipantId}', function () {
      var _participants$find3;

      var participants = this.get('interaction.participants') || [];
      var currUserParticipantId = this.get('interaction.participantForCurrentUser.id');
      var coachedParticipantId = (_participants$find3 = participants.find(function (p) {
        var _p$calls2, _p$calls2$;

        return p.coachedParticipantId && !((_p$calls2 = p.calls) !== null && _p$calls2 !== void 0 && (_p$calls2$ = _p$calls2[0]) !== null && _p$calls2$ !== void 0 && _p$calls2$.disconnectType);
      })) === null || _participants$find3 === void 0 ? void 0 : _participants$find3.coachedParticipantId;
      return currUserParticipantId === coachedParticipantId;
    }),

    /**
     * `true` if the current user participant is on barge-in
     */
    iAmOnBargeIn: Ember.computed('interaction.participants.@each.{bargedParticipantId}', function () {
      var _this$get3, _this$get3$find;

      var currUserParticipantId = this.get('interaction.participantForCurrentUser.id');
      var bargedParticipantId = (_this$get3 = this.get('interaction.participants')) === null || _this$get3 === void 0 ? void 0 : (_this$get3$find = _this$get3.find(function (p) {
        var _p$calls3, _p$calls3$;

        return p.bargedParticipantId && !((_p$calls3 = p.calls) !== null && _p$calls3 !== void 0 && (_p$calls3$ = _p$calls3[0]) !== null && _p$calls3$ !== void 0 && _p$calls3$.disconnectType);
      })) === null || _this$get3$find === void 0 ? void 0 : _this$get3$find.bargedParticipantId;
      return currUserParticipantId === bargedParticipantId;
    }),

    /**
     * Flag indicating if conversation is currently selected
     */
    isSelected: Ember.computed('interaction.id', 'selectedInteraction.id', 'selectedWorkitem.id', function () {
      var selectedInteraction = this.get('interaction.id') === this.get('selectedInteraction.id');
      var selectedWorkitem = this.get('interaction.id') === this.get('selectedWorkitem.id');
      return selectedInteraction || selectedWorkitem;
    }),

    /**
     * Flag indicating if interaction is a workitem
     */
    isWorkitem: Ember.computed.bool('interaction.workitem'),

    /**
     * Flag indicating if workitem is alerting
     */
    isAlertingWorkitem: Ember.computed.equal('interaction.workitem.assignmentState', 'Alerting'),

    /**
     * Alerting conversation or workitem interaction
     */
    isAlerting: Ember.computed.or('interaction.isAlerting', 'isAlertingWorkitem'),

    /**
     * `true` if the user is monitoring an agent
     */
    myselfIsMonitorV2: Ember.computed.readOnly('interaction.myselfIsMonitorV2'),
    draftUpdate: Ember.computed('draftUpdateHash', 'draftUpdateHash.@each.conversationId', 'interaction.id', function () {
      var _findBy, _this$get4;

      if (!this.get('draftUpdateHash')) return {};
      return (_findBy = ((_this$get4 = this.get('draftUpdateHash')) !== null && _this$get4 !== void 0 ? _this$get4 : Ember.A([])).findBy('conversationId', this.get('interaction.id'))) !== null && _findBy !== void 0 ? _findBy : {};
    }),
    mcbedParticipantId: Ember.computed('interaction.{myselfIsMonitor,myselfIsCoach,myselfIsBarged}', 'myselfIsMonitorV2', 'iAmOnBargeIn', 'iAmOnCoach', 'iAmOnMonitor', 'iAmOnMonitorDigital', function () {
      var _currentUser$messages, _currentUser$messages2, _currentUser$calls, _currentUser$calls$;

      var interaction = this.get('interaction');
      if (!interaction) return null;
      var currentUser = Ember.get(interaction, 'participantForCurrentUser');
      if (!currentUser) return null;

      if ((this.get('myselfIsMonitorV2') || this.get('iAmOnMonitorDigital')) && !((_currentUser$messages = currentUser.messages) !== null && _currentUser$messages !== void 0 && (_currentUser$messages2 = _currentUser$messages[0]) !== null && _currentUser$messages2 !== void 0 && _currentUser$messages2.disconnectType)) {
        var _Ember$get$find$id, _Ember$get, _Ember$get$find;

        if (currentUser.monitoredParticipantId) {
          return currentUser.monitoredParticipantId;
        }

        return (_Ember$get$find$id = (_Ember$get = Ember.get(interaction, 'participants')) === null || _Ember$get === void 0 ? void 0 : (_Ember$get$find = _Ember$get.find(function (p) {
          var _p$messages2, _p$messages2$;

          return (p.monitoredParticipantId || p.coachedParticipantId || p.bargedParticipantId) && !((_p$messages2 = p.messages) !== null && _p$messages2 !== void 0 && (_p$messages2$ = _p$messages2[0]) !== null && _p$messages2$ !== void 0 && _p$messages2$.disconnectType);
        })) === null || _Ember$get$find === void 0 ? void 0 : _Ember$get$find.id) !== null && _Ember$get$find$id !== void 0 ? _Ember$get$find$id : null;
      }

      if ((Ember.get(interaction, 'myselfIsBarged') || Ember.get(interaction, 'myselfIsCoach') || Ember.get(interaction, 'myselfIsMonitor')) && !((_currentUser$calls = currentUser.calls) !== null && _currentUser$calls !== void 0 && (_currentUser$calls$ = _currentUser$calls[0]) !== null && _currentUser$calls$ !== void 0 && _currentUser$calls$.disconnectType)) {
        return currentUser.monitoredParticipantId || currentUser.coachedParticipantId || currentUser.bargedParticipantId;
      }

      if (this.get('iAmOnBargeIn') || this.get('iAmOnCoach') || this.get('iAmOnMonitor')) {
        var _Ember$get$find$id2, _Ember$get2, _Ember$get2$find;

        return (_Ember$get$find$id2 = (_Ember$get2 = Ember.get(interaction, 'participants')) === null || _Ember$get2 === void 0 ? void 0 : (_Ember$get2$find = _Ember$get2.find(function (p) {
          var _p$calls4, _p$calls4$;

          return (p.monitoredParticipantId || p.coachedParticipantId || p.bargedParticipantId) && !((_p$calls4 = p.calls) !== null && _p$calls4 !== void 0 && (_p$calls4$ = _p$calls4[0]) !== null && _p$calls4$ !== void 0 && _p$calls4$.disconnectType);
        })) === null || _Ember$get2$find === void 0 ? void 0 : _Ember$get2$find.id) !== null && _Ember$get$find$id2 !== void 0 ? _Ember$get$find$id2 : null;
      }

      return null;
    }),

    /**
     * @action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionLoadParticipantName: function actionLoadParticipantName(interaction, participantId) {
      (0, _acd.warnClosureAction)('actionLoadParticipantName', {
        interaction: interaction,
        participantId: participantId
      });
      return Ember.RSVP.Promise.resolve('');
    },

    /**
     * @action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionSelectInteraction: function actionSelectInteraction(interaction) {
      return (0, _acd.warnClosureAction)('actionSelectInteraction', {
        interaction: interaction
      });
    },

    /**
     * @action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionSelectWorkitemInteraction: function actionSelectWorkitemInteraction(workitemInteraction) {
      return (0, _acd.warnClosureAction)('actionSelectWorkitemInteraction', {
        workitemInteraction: workitemInteraction
      });
    },

    /**
     * @action
     * Closure action passed to the component to be invoked at the parent level
     */
    actionLoadQueue: function actionLoadQueue(queueId) {
      (0, _acd.warnClosureAction)('actionLoadQueue', {
        queueId: queueId
      });
      return Ember.RSVP.Promise.resolve('');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var interaction = this.get('interaction');

      if (!this.get('isWorkitem')) {
        if (!!this.get('mcbedParticipantId')) {
          this.actionLoadParticipantName(interaction, this.get('mcbedParticipantId')).then(function (name) {
            (0, _safeSet.default)(_this, 'mcbParticipantName', name);
          });
        }
      }

      if (this.get('myselfIsMonitorV2') || this.get('iAmOnMonitorDigital')) {
        this.actionLoadParticipantName(interaction, this.get('mcbedParticipantId')).then(function (name) {
          (0, _safeSet.default)(_this, 'mcbParticipantName', name);
        });
      }

      var interactionQueueId = this.get('interactionQueueId');

      if (interactionQueueId !== this.get('_prevInteractionQueueId')) {
        this.set('_prevInteractionQueueId', interactionQueueId); // actionLoadQueue will unconditionally return a string;
        // including unknown fallback text for falsey/invalid queueIds and queue fetch failures

        this.actionLoadQueue(interactionQueueId).then(function (queueName) {
          (0, _safeSet.default)(_this, 'queueName', queueName);
        });
      }
    },
    invokeItemSelection: function invokeItemSelection(interaction) {
      if (this.get('isWorkitem')) {
        return this.actionSelectWorkitemInteraction(interaction);
      }

      return this.actionSelectInteraction(interaction);
    },
    _selectItem: function _selectItem() {
      var interaction = this.get('interaction');

      if (interaction) {
        Ember.run.throttle(this, this.invokeItemSelection, interaction, 500);
      }
    },
    actions: {
      onSelectItem: function onSelectItem() {
        this._selectItem();
      }
    }
  });

  _exports.default = _default;
});