define("web-directory/utils/testing", ["exports", "web-directory/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isTesting = void 0;

  /**
   * Determines if the app is currently running in a testing mode or not based on the ENV config used
   * at build time.
   *
   * @returns boolean detailing if the environment is in testing mode or not
   */
  var isTesting = _environment.default.environment === 'test';
  _exports.isTesting = isTesting;
});