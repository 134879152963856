define("web-directory/components/acd-interactions-panel-v2/interaction-header/header-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * List of class names to be applied to the component during render
     */
    classNames: ['interaction-header-group'],

    /**
     * List of dynamic class names to be applied to component affected by
     * their corresponding property.
     */
    classNameBindings: ['hidden'],

    /**
     * `true` if the there is content detected from within the group.
     * defaults to true to avoid any millisecond rendering oddities
     */
    hidden: true,

    /**
     * reference to a lightweight observer detecting when the children of the element
     * have been updated so we can track visibility based on element children
     */
    mutationObserver: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.element) {
        this.determineVisibility();
        var observer = new MutationObserver(function () {
          return _this.determineVisibility();
        });
        observer.observe(this.element, {
          characterData: false,
          childList: true,
          attributes: false
        });
        this.set('mutationObserver', observer);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var observer = this.get('mutationObserver');

      if (observer) {
        observer.disconnect();
      }
    },

    /**
     * Determines if this element should be hidden or not based on the elements
     * children length.
     *
     * @param element the element to track the children of
     */
    determineVisibility: function determineVisibility() {
      var _this2 = this;

      Ember.run(function () {
        _this2.set('hidden', _this2.element ? !_this2.element.children.length : true);
      });
    }
  });

  _exports.default = _default;
});