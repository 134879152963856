define("web-directory/components/webrtc-panel/component", ["exports", "lodash", "uuid"], function (_exports, _lodash, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      observer = Ember.observer,
      inject = Ember.inject,
      on = Ember.on;

  var _default = Component.extend({
    classNames: ['chat-panel'],
    intl: inject.service(),
    volt: inject.service(),
    chat: inject.service(),
    chatFrame: inject.service(),
    webrtc: inject.service(),
    interaction: inject.service(),
    session: inject.service(),
    presenceSubscription: inject.service(),
    sidebar: inject.service(),
    batchDataRequests: inject.service(),
    mediaProviders: inject.service(),
    canEmbedZoomClient: false,
    addingParticipants: false,
    participant: null,
    participants: null,
    useCollaborateChat: computed.readOnly('session.useCollaborateChat'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('participants')) {
        this.set('participants', []);
      }
    },
    clearParticipants: function clearParticipants() {
      this.get('participants').clear();
    },
    selfInParticipantsList: function selfInParticipantsList() {
      var participants = this.get('participants');
      var selfId = this.get('session.person.id');

      for (var i = 0; i < participants.length; i++) {
        var participantIsSelf = participants[i].id === selfId;

        if (participantIsSelf) {
          return true;
        }
      }

      return false;
    },
    makeCallViaWebDirectory: function makeCallViaWebDirectory(participants, chat) {
      var _this = this;

      var activateRoom = function activateRoom(room) {
        chat.loadRoomHistory(room);
        chat.activateRoom(room);
        chat.changeActiveRoom(room);
        Ember.run.scheduleOnce('afterRender', function () {
          room.set('showingVideo', true);
        });
      };

      if (this.get('useCollaborateChat')) {
        this.get('sidebar').openPanel('conversations');
        return this.createRoomUsingCollabChat(participants, chat);
      }

      if (participants.length > 1) {
        var jidId = "videoconference-".concat(_uuid.default.v4());
        chat.joinRoom(jidId).then(function (room) {
          chat.setRoomSubject(room, _this.get('intl').lookup('ewc.defaultTitle').toString(), function () {
            var people = participants.filterBy('entity').mapBy('entity');
            people.forEach(function (person) {
              chat.inviteToRoom(room, person);
            });
            activateRoom(room);
          });
        });
      } else if (participants.length === 1) {
        var entity = participants[0].entity;

        if (entity && entity.get('jid')) {
          chat.getChatRoom(entity.get('jid')).then(function (room) {
            _this.get('volt').prepareOneToOne(room.get('jid'));

            return activateRoom(room);
          });
        }
      }
    },
    createRoomUsingCollabChat: function createRoomUsingCollabChat(participants, chat) {
      var _this2 = this;

      var chatFrame = this.get('chatFrame');

      if (participants.length > 1) {
        var people = participants.filterBy('entity').mapBy('entity');
        return chatFrame.notifyCollaborateChat('collaborate-chat-host-asked-to-start-video-conference', {
          participants: people.map(function (person) {
            return person.get('jid');
          })
        });
      } else if (participants.length === 1) {
        var entity = participants[0].entity;

        if (entity && entity.get('jid')) {
          chat.getChatRoom(entity.get('jid')).then(function (room) {
            // volt service preps the one to one
            _this2.get('volt').prepareOneToOne(room.get('jid'));

            chatFrame.notifyCollaborateChat('collaborate-chat-host-started-one-to-one', {
              jid: room.get('jid')
            }); // web-directory will need to track the room change and the showingVideo state

            chat.trackRoomChange(room);
            Ember.run.scheduleOnce('afterRender', function () {
              room.set('showingVideo', true);
            });
          });
        }
      }
    },
    videoProviders: computed('session.user', function () {
      return this.get('mediaProviders').getVideoProviders(this.get('session.user'));
    }),
    showChatPanelConversation: computed('interaction.activeRealtimeInteractions.[]', 'webrtc.activeRoom.{showingVideo,isAcdRoom}', 'webrtcPanelOpen', function () {
      var hasActiveRealtimeInteractions = this.get('interaction.activeRealtimeInteractions.length');
      var showingVideo = this.get('webrtc.activeRoom.showingVideo');
      var isAcdRoom = this.get('webrtc.activeRoom.isAcdRoom');
      return !hasActiveRealtimeInteractions && showingVideo && !isAcdRoom;
    }),
    activeRoom: computed('webrtc.activeRoom.showingVideo', 'webrtc.activeRoom.isAcdRoom', 'webrtcPanelOpen', {
      get: function get() {
        if (this.get('webrtc.activeRoom.isAcdRoom') || !this.get('webrtc.activeRoom.showingVideo')) {
          var roomJid = "".concat(_uuid.default.v4(), "@conference.").concat(this.get('session.org.shortName'), ".orgspan.com");
          var pendingRoom = this.get('chat').newRoom(roomJid);
          pendingRoom.pendingRoom = true;
          pendingRoom.subject = this.get('videoChatLabel');
          this.get('batchDataRequests').getChatUser(this.get('session.person.jid')).then(function (person) {
            pendingRoom.addParticipant(person);
          });
          return pendingRoom;
        } else {
          return this.get('webrtc.activeRoom');
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isPoppedOut: computed('activeRoom.isPopout', function () {
      return this.get('activeRoom.isPopout');
    }),
    pendingRoomObserver: observer('activeRoom', function () {
      var activeRoom = this.get('activeRoom');

      if (activeRoom.get('pendingRoom')) {
        Ember.run.schedule('afterRender', this, function () {
          this.get('webrtc').trigger('activeChatRoomChange');
        });
      }
    }),
    connected: computed('chat.connected', 'activeRoom.isPending', function () {
      return this.get('chat.connected') || !this.get('activeRoom.isPending') && this.get('activeRoom.videoConversation');
    }),
    videoChatLabel: computed(function () {
      return this.get('intl').lookup('ewc.defaultTitle').toString();
    }),
    webrtcPanelOpen: computed.reads('sidebar.showWebrtc'),
    isConferenceCall: computed('participants.[]', function () {
      if (this.get('participants.length')) {
        this.get('presenceSubscription').autoSubscribe(_lodash.default.map(this.get('participants'), 'entity'), this);
      }

      return this.get('participants.length') > 1 && !this.get('addingParticipants');
    }),
    firstParticipantAvailable: computed('participants.firstObject.entity.isOfflineOrBusy', function () {
      var target = this.get('participants.firstObject.entity');
      return target && !target.get('isOfflineOrBusy');
    }),
    defaultParticipant: on('init', function () {
      this.get('webrtc').on('activeChatRoomChange', this, this.clearParticipants);
      this.set('participant', Ember.Object.create({
        value: null,
        entity: null
      }));
    }),
    cleanup: on('willDestroyElement', function () {
      this.get('webrtc').off('activeChatRoomChange', this, this.clearParticipants);
    }),
    disableVideoCall: computed('disabledMessage', function () {
      return !!this.get('disabledMessage');
    }),
    disabledMessage: computed('participants.[]', 'webrtc.activeBridge', 'firstParticipantAvailable', function () {
      var intl = this.get('intl');

      if (!this.get('isConferenceCall') && this.get('participants.length') && !this.get('firstParticipantAvailable')) {
        var name = this.get('participants.firstObject.entity.name');

        if (this.get('participants.firstObject.entity.isOffline')) {
          return intl.lookup('ewc.userOffline', {
            name: name
          });
        }

        if (this.get('participants.firstObject.entity.isBusy')) {
          return intl.lookup('ewc.userBusy', {
            name: name
          });
        }
      }

      if (this.selfInParticipantsList()) {
        return intl.lookup('chat.video.cannotAddSelf');
      }

      return '';
    }),
    shouldUseV2: computed('webrtc.activeRoom', function () {
      return this.get('webrtc').isV2(this.get('webrtc.activeRoom'));
    }),
    enableMediaProviderChooser: computed('videoProviders.[]', 'participants.[]', 'mediaProviders.enabled', 'canEmbedZoomClient', function () {
      if (!this.get('canEmbedZoomClient')) {
        return false;
      }

      if (this.get('participants.length') === 0 || !this.get('mediaProviders.enabled')) {
        return false;
      }

      var providers = this.get('videoProviders');

      if (providers.length === 0) {
        return false;
      }

      return !!providers.find(function (provider) {
        return provider.id !== 'purecloud';
      });
    }),
    actions: {
      startVideo: function startVideo(provider) {
        // Default behavior
        if (provider.get('id') === 'purecloud') {
          this.send('makeCall');
          return;
        }

        var participants = this.get('participants');
        var entities = participants.map(function (participant) {
          return participant.entity;
        });
        provider.action({
          participants: entities,
          sendNotification: true,
          allowMultipleMeetings: true
        });
      },
      makeCall: function makeCall() {
        var participants = this.get('participants');
        var chat = this.get('chat');
        this.makeCallViaWebDirectory(participants, chat);
      }
    }
  });

  _exports.default = _default;
});