define("web-directory/adapters/externalcontacts/contact", ["exports", "web-directory/adapters/purecloud", "web-directory/utils/newrelic", "ember-purecloud-components/mixins/logger", "web-directory/mixins/relate/relate-features"], function (_exports, _purecloud, _newrelic, _logger, _relateFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var STRICT = true;
  var EXPAND = 'externalOrganization,externalDataSources,division';
  var PHONE_LOOKUP_EXPAND = 'contacts.externalOrganization,externalDataSources';
  var TIME_OUT = 15000; // public API takes 10 seconds to time out - but adding a little more than 10 as fudge factor

  var QUERY_MAX_LENGTH = 256;
  var ORIGINATOR = 'web-directory';

  var _default = _purecloud.default.extend(_logger.default, _relateFeatures.default, {
    logger: inject.service('stash-logger'),
    application: inject.service(),
    relate: inject.service(),
    addressformat: inject.service(),
    permissions: inject.service(),
    namespace: 'api/v2',
    defaultSerializer: 'externalcontacts/contact',
    pathForType: function pathForType() {
      return 'externalcontacts/contacts';
    },
    cannotViewExternalContacts: computed.not('permissions.hasExternalContactsViewContactAccess'),
    createRecord: function createRecord(store, type, record) {
      this.get('relate').clearCachedAdapter();
      var data = Object.assign(store.serializerFor(type.modelName).serialize(record, {
        includeId: true
      }), {
        originator: ORIGINATOR
      });
      return this.ajax(this.buildURL(type.modelName), 'POST', {
        data: data
      });
    },
    findRecord: function findRecord(store, type, id) {
      var urlAndModel = this.buildURL(type.modelName);
      var params = "?".concat(Ember.$.param({
        expand: EXPAND
      }));
      return this.ajax("".concat(urlAndModel, "/").concat(id).concat(params), 'GET', {
        timeout: TIME_OUT
      });
    },
    deleteRecord: function deleteRecord() {
      var returnValue = this._super.apply(this, arguments);

      this.get('relate').clearCachedAdapter();
      return returnValue;
    },
    query: function query(store, type, queryParams) {
      var addressFormat = this.get('addressformat');
      var searchTerm = queryParams.searchTerm,
          pageNumber = queryParams.pageNumber,
          pageSize = queryParams.pageSize,
          expandField = queryParams.expandField,
          sortOrder = queryParams.sortOrder,
          originator = queryParams.originator,
          subType = queryParams.subType;
      var hasValidSearchTerm = searchTerm && typeof searchTerm === 'string';
      var isValidPhoneNumber = addressFormat.isValidNumber(searchTerm, null, STRICT); // only attempt to phone format search strings that look like phone numbers and contain only phone number like characters

      var containsOnlyPhoneNumberChars = /^(\+|\d|\)|\(|\.|-|\s)+$/gim.test(searchTerm); // only mutate search if not wrapped in ""

      var isExactSearch = /^"(.*)"$/.test(searchTerm);

      if (isExactSearch) {
        // Log exact search occurrences for tracking usage
        (0, _newrelic.addPageAction)('externalContactExactSearch', {
          userId: this.get('session.user.id'),
          orgId: this.get('session.org.guid')
        });
      } else if (containsOnlyPhoneNumberChars && isValidPhoneNumber) {
        searchTerm = addressFormat.formatAddress(searchTerm);
      }

      var data = {};

      if (expandField) {
        data.expand = expandField;
      }

      if (originator) {
        data.originator = originator;
      }

      if (subType) {
        data.subType = subType;
      }

      var url = this.buildURL(type.modelName);

      if (this.get('cannotViewExternalContacts')) {
        // Logging a failure of the permissions check here is unnecessary because this is merely the ultimate
        // backstop to catch unpermitted external contaxts service calls. It's the responsibility of the caller
        // to handle the response and either log and/or alert the user.  See RELATE-1776
        return Ember.RSVP.resolve({});
      }

      if (queryParams.useLookupService) {
        // Use the "reverse whitepages" search endpoint
        url = this.get('application').pcUri("api/v2/externalcontacts/reversewhitepageslookup");
        data.lookupVal = searchTerm;

        if (expandField) {
          data.expand = PHONE_LOOKUP_EXPAND;
        }

        if (originator) {
          data.originator = originator;
        }
      } else {
        if (hasValidSearchTerm) {
          var term = encodeURI(searchTerm.replace('tel:', ''));

          try {
            if (term.length > QUERY_MAX_LENGTH) {
              throw new Error('Query length limit encountered');
            }
          } catch (error) {
            this.get('logger').error("ACD: Attempted to send query larger than limit. From: ".concat(originator, " "), error);
          }

          data.q = decodeURI(term.substr(0, QUERY_MAX_LENGTH)); // remove characters beyond limit
        }

        if (pageNumber) {
          data.pageNumber = pageNumber;
        }

        if (pageSize) {
          data.pageSize = pageSize;
        }

        if (sortOrder) {
          data.sortOrder = sortOrder;
        }

        if (originator) {
          data.originator = originator;
        }
      }

      if (queryParams.bypassCache) {
        return this.get('relate').findMatchingExternalContacts(searchTerm, pageNumber, pageSize, expandField, originator);
      }

      return this.get('relate').getCachedAdapter({
        url: url,
        data: data,
        TIME_OUT: TIME_OUT
      }, this);
    }
  });

  _exports.default = _default;
});