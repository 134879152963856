define("web-directory/app-providers/email-gadget-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    // Attributes
    id: 'emailGadgetProvider',
    type: 'programmatic',
    group: 'conversation',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    // Derived
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var runtimeEnv = this.get('application.runtime');

      if (runtimeEnv.local) {
        return "https://apps.".concat(runtimeEnv.publicDomainName);
      }

      return window.location.origin;
    }),
    // Methods
    getAppConfigs: function getAppConfigs(interaction) {
      var appDefs = [];
      var urlParams = new URLSearchParams(window.location.search);
      var emailGadgetVersion = urlParams.get('emailGadgetVersion');
      var gadgetOrigin = this.get('gadgetTargetOrigin');
      var local = emailGadgetVersion === 'local';
      var url = emailGadgetVersion ? local ? 'https://localhost:3000/email-gadget.html' : "".concat(gadgetOrigin, "/email-gadget/").concat(emailGadgetVersion, "/email-gadget.html") : "".concat(gadgetOrigin, "/email-gadget/email-gadget.html");

      if (interaction && interaction.type === 'conversation' && Ember.typeOf(interaction.payload) === 'instance') {
        if (interaction.payload.get('type') === 'email' && interaction.payload.get('isInternalProvider')) {
          appDefs.push(buildEmailGadgetDef(url, this.get('intl').t('purecloud.email')));
        }
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.bool)('true');
  _exports.boolExpr = boolExpr;

  var buildEmailGadgetDef = function buildEmailGadgetDef(url, displayName) {
    return {
      id: 'emailConversationGadget',
      displayName: displayName,
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['roster-email']
      },
      type: 'iframe',
      src: new URL(url),
      sandbox: ['allow-scripts', 'allow-downloads', 'allow-same-origin', 'allow-popups', 'allow-modals'],
      trustedDataPassing: true,
      startBehavior: 'lazy',
      lifecycleConfig: {
        bootstrap: true,
        bootstrapTimeoutMillis: 15000,
        focus: true,
        blur: true,
        stop: true
      }
    };
  };
});