define("web-directory/components/contextual-help/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* PURE-761: This component has pretty much replaced by the {{help-panel}} sidebar component.
   * However UX still wants the upper right contextual dropdown menu for going onQueue
   * and the Add User button.
   */
  var ContextualHelpComponent = Ember.Component.extend({
    classNames: ['contextual-help'],
    session: Ember.inject.service(),
    streaming: Ember.inject.service(),
    application: Ember.inject.service(),
    showDropdown: false,
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('showDropdown');
      },
      showAdminModal: function showAdminModal() {
        this.attrs.showAdminModal('/admin/#/directory/people/add');
      }
    },
    showAdminAddUser: Ember.computed('application.showTinyNavMenu', 'application.session.person.isAdmin', function () {
      return this.get('application.showTinyNavMenu') && this.get('application.session.person.isAdmin');
    }),
    showQueueToggle: Ember.computed('application.session.person.isEngageAgent', 'application.showTinyNavMenu', 'application.session.hasTrusteeAccess', function () {
      return this.get('application.showTinyNavMenu') && this.get('application.session.person.isEngageAgent') && !this.get('application.session.hasTrusteeAccess');
    }),
    click: function click(event) {
      var _this = this;

      var target = Ember.$(event.target);
      var isOutsideLink = target.parent('li').hasClass('help-with-page-link') || target.parent('li').hasClass('resource-center-link');

      if (target.is('a') && isOutsideLink) {
        Ember.run.next(function () {
          _this.send('toggleDropdown');
        });
      }
    }
  });
  var _default = ContextualHelpComponent;
  _exports.default = _default;
});