define("web-directory/models/conversation-legacy", ["exports", "ember-data", "lodash", "web-directory/mixins/external-contacts", "web-directory/models/person", "web-directory/utils/dates", "web-directory/utils/conversation"], function (_exports, _emberData, _lodash, _externalContacts, _person, _dates, _conversation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      get = Ember.get,
      inject = Ember.inject,
      observer = Ember.observer,
      run = Ember.run;

  function maybeLowerCase(str) {
    return str && str.toLowerCase();
  }

  var _default = Ember.Object.extend(_externalContacts.default, {
    application: inject.service(),
    intl: inject.service(),
    session: inject.service(),
    chat: inject.service(),
    phone: inject.service(),
    interaction: inject.service(),
    addressformat: inject.service(),
    permissions: inject.service(),
    names: inject.service(),
    store: inject.service(),
    id: null,
    conversationId: null,
    participants: null,
    maxParticipants: 0,
    selfUri: null,
    acwNotes: null,
    selectedWrapupCode: null,
    conversationIsAConsult: false,
    conversationInitiator: null,
    lastPartyTalkingTo: null,
    attemptingToCall: null,
    webrtcSession: null,
    hasDisplayedDesktopNotification: false,
    acceptedByClient: false,
    willDestroy: function willDestroy() {
      run.cancel(this.get('_recomputePartiesTimer'));

      this._super.apply(this, arguments);
    },
    dialingTime: computed('isDialing', 'isContacting', 'participants.[]', function () {
      if (this.get('isContacting')) {
        return (0, _dates.default)();
      } else if (this.get('isDialing') && this.get('participants.length') > 1) {
        return (0, _dates.default)();
      }
    }),
    otherPartyJid: null,
    setOtherPartyJid: observer('onCall', 'otherPartyModel', 'otherParty', function () {
      var _this = this;

      var promise = Ember.RSVP.resolve(this.get('otherPartyModel'));
      promise.then(function (person) {
        var isOtherPartyPerson = person instanceof _person.default;

        if (person && isOtherPartyPerson && _this.get('onCall') && _this.get('otherParty') && _this.get('otherParty.purpose') !== 'customer') {
          _this.set('otherPartyJid', person.get('jid'));
        } else {
          _this.set('otherPartyJid', null);
        }
      });
    }).on('init'),
    startTime: computed('myself.connectedTime', 'isDialing', 'dialingTime', function () {
      var connectedTime = this.get('myself.connectedTime');

      if (this.get('isDialing') && connectedTime === undefined) {
        return this.get('dialingTime');
      }

      if (!this.get('onCall')) {
        return null;
      }

      if (connectedTime) {
        return connectedTime;
      }
    }),
    connectedDuration: computed('myself.{endTime,connectedTime}', function () {
      var connectedTime = this.get('myself.connectedTime');
      var endTime = this.get('myself.endTime') || (0, _dates.default)();

      if (connectedTime && endTime) {
        return (0, _dates.default)(endTime).diff((0, _dates.default)(connectedTime));
      }

      return null;
    }),
    iAmInitiatorOfConsult: computed('conversationInitiator', 'conversationIsAConsult', 'myself', function () {
      return this.get('conversationInitiator.userId') === this.get('myGuid');
    }),
    otherPartyThatIsNotTheInitiator: computed('participants.[]', 'conversationInitiator', 'myGuid', function () {
      var _this2 = this;

      var initiator = this.get('conversationInitiator');

      var otherParties = _lodash.default.reject(this.get('participants'), function (party) {
        var partyId = party && party.userId;
        var initiatorId = initiator && initiator.userId;
        return partyId === initiatorId || partyId === _this2.get('myGuid');
      });

      return _lodash.default.last(otherParties);
    }),
    otherParticipantsThatAreNotTheInitiator: computed('participants.[]', 'conversationInitiator', 'myGuid', function () {
      var _this3 = this;

      var initiator = this.get('conversationInitiator');

      var otherParties = _lodash.default.reject(this.get('participants'), function (party) {
        var partyId = party && party.userId;
        var initiatorId = initiator && initiator.userId;
        return partyId === initiatorId || partyId === _this3.get('myGuid');
      });

      return _lodash.default.map(otherParties, function (participant) {
        var name = participant.name || participant.aniName;
        var model = {
          name: name
        };
        return Object.assign(participant, {
          model: model
        });
      });
    }),
    otherPartyThatIsNotTheInitiatorModel: computed('otherPartyThatIsNotTheInitiator', function () {
      var guid = (0, _conversation.userGuidFromParticipant)(this.get('otherPartyThatIsNotTheInitiator'));

      if (guid) {
        return this.get('application.store').find('person', guid);
      }

      return this.get('otherPartyThatIsNotTheInitiator');
    }),
    setLastPartyTalkingTo: observer('connectedParties.[]', function () {
      var _this4 = this;

      var connectedParties = _lodash.default.reject(this.get('connectedParties'), function (party) {
        return party && party.userId === _this4.get('myGuid');
      });

      if (connectedParties.length > 0) {
        return this.set('lastPartyTalkingTo', _lodash.default.head(connectedParties));
      }
    }),
    lastPartyTalkingToModel: computed('lastPartyTalkingTo', function () {
      var guid = (0, _conversation.userGuidFromParticipant)(this.get('lastPartyTalkingTo'));

      if (guid) {
        return this.get('application.store').find('person', guid);
      }

      return this.get('lastPartyTalkingTo');
    }),
    consultOtherParty: computed('participants.[]', 'onCall', 'lastDisconnectedPartyModel', 'lastPartyTalkingToModel', 'otherParties.[]', 'conversationIsAConsult', 'conversationInitiator', 'sortedDisconnectedParties', 'connectedParties', 'iAmInitiatorOfConsult', 'initiatorModel', 'otherPartyThatIsNotTheInitiator', function () {
      var _this5 = this;

      var initiatorModel = this.get('initiatorModel');

      var initiator = _lodash.default.find(this.get('participants'), function (party) {
        return party && party.userId === _this5.get('conversationInitiator.userId');
      });

      var initiatorCall = _lodash.default.head(initiator && initiator.calls);

      var initiatorConnected = initiatorCall && initiatorCall.state.toLowerCase() === 'connected';
      var otherPartyThatIsNotTheInitiatorCall = this.get('otherPartyThatIsNotTheInitiator').calls[0];
      var otherPartyThatIsNotTheInitiatorConnected = otherPartyThatIsNotTheInitiatorCall.state.toLowerCase() === 'connected';

      if (this.get('iAmInitiatorOfConsult')) {
        if (this.get('onCall')) {
          return _lodash.default.head(this.get('otherParties'));
        }

        if (this.get('connectedParties.length') < 1) {
          return this.get('lastDisconnectedPartyModel');
        }

        return _lodash.default.head(this.get('connectedParties'));
      } else {
        if (initiatorConnected) {
          return initiatorModel;
        }

        if (otherPartyThatIsNotTheInitiatorConnected) {
          return this.get('otherPartyThatIsNotTheInitiatorModel');
        }

        if (this.get('otherPartyModel') === undefined) {
          return this.get('lastPartyTalkingToModel');
        }

        return this.get('otherPartyModel');
      }
    }),
    consultOtherPartyModel: computed('consultOtherParty', 'participants.[]', 'onCall', function () {
      var guid = (0, _conversation.userGuidFromParticipant)(this.get('consultOtherParty'));

      if (guid) {
        return this.get('application.store').find('person', guid);
      }

      return this.get('consultOtherParty');
    }),
    participantModels: computed('participants.[]', function () {
      var _this6 = this;

      var participants = _lodash.default.reject(this.get('participants'), function (participant) {
        var guid = (0, _conversation.userGuidFromParticipant)(participant);
        var communication = _lodash.default.head(participant.calls) || _lodash.default.head(participant.callbacks) || {};
        var isTerminated = maybeLowerCase(communication.state) !== 'terminated';
        var isNotSelf = _this6.get('application.session.person.guid') !== guid;
        return isTerminated && isNotSelf;
      });

      return _lodash.default.map(participants, function (participant) {
        var guid = (0, _conversation.userGuidFromParticipant)(participant);
        var name = participant && participant.name;
        var model = guid ? _this6.get('application.store').find('person', guid) : {
          name: name
        };
        return Object.assign(participant, {
          model: model
        });
      });
    }),
    otherPartyModel: computed('otherParty', function () {
      var otherParty = this.get('otherParty');
      var guid = (0, _conversation.userGuidFromParticipant)(otherParty);
      var store = this.get('application.store');

      if (!guid) {
        return otherParty;
      } // running phone service test in isolation always threw an Ember Error:
      // No model was found for 'person'


      var personFromStore;

      try {
        personFromStore = store.findRecord('person', guid, {
          reload: false,
          backgroundReload: false
        });

        if (personFromStore.catch) {
          personFromStore.catch(function () {
            return otherParty;
          });
        }
      } catch (e) {
        personFromStore = otherParty;
      }

      return personFromStore;
    }),
    otherPartyNumber: computed('otherParty.address', function () {
      var number = this.get('otherParty.address');

      if (number && 'user' !== this.get('otherParty.purpose')) {
        return this.get('addressformat').formatAddressForDisplay(number, null, {
          doLookup: true
        });
      }
    }),
    otherParties: computed('participants.[]', function () {
      var _this7 = this;

      var otherParties = _lodash.default.filter(this.get('participants'), function (participant) {
        var guid = (0, _conversation.userGuidFromParticipant)(participant);
        var communication = _lodash.default.head(participant.calls) || _lodash.default.head(participant.callbacks) || {};
        var purpose = maybeLowerCase(participant.purpose);
        var state = maybeLowerCase(communication.state);
        var notTerminatedOrDisconnected = state !== 'terminated' && state !== 'disconnected';
        var isSelf = _this7.get('myGuid') === guid;
        var isMonitor = !!participant.monitoredParticipantId;
        var isCoach = !!participant.coachedParticipantId;
        var isBarge = !!participant.bargedParticipantId;

        if (notTerminatedOrDisconnected && !isMonitor && !isCoach && !isBarge) {
          return !isSelf || isSelf && purpose !== 'user';
        }

        return false;
      });

      return otherParties;
    }),
    disconnectedParties: computed('participants.[]', 'participants.@each.endTime', function () {
      var _this8 = this;

      return _lodash.default.filter(this.get('participants'), function (participant) {
        var communication = _lodash.default.head(participant.calls) || _lodash.default.head(participant.callbacks) || {};
        var isDisconnected = maybeLowerCase(communication.state) === 'disconnected' || maybeLowerCase(communication.state) === 'terminated';
        return isDisconnected && participant.userId !== _this8.get('myGuid') && participant.purpose !== 'ivr' && participant.purpose !== 'group';
      });
    }),
    sortedDisconnectedParties: computed.sort('disconnectedParties', function (a, b) {
      return (0, _dates.default)(b.endTime).valueOf() - (0, _dates.default)(a.endTime).valueOf();
    }),
    lastDisconnectedPartyModel: computed('sortedDisconnectedParties', function () {
      var last = _lodash.default.last(this.get('sortedDisconnectedParties'));

      var guid = (0, _conversation.userGuidFromParticipant)(last);

      if (guid) {
        return this.get('application.store').find('person', guid);
      }
    }),
    selfIsDisconnectedOrTerminated: function selfIsDisconnectedOrTerminated() {
      var myself = this.get('myself');

      if (!myself) {
        return false;
      }

      var communication = _lodash.default.head(myself.calls) || _lodash.default.head(myself.callbacks) || {};

      if (!communication) {
        return false;
      }

      var myState = maybeLowerCase(communication.state);
      return myState === 'disconnected' || myState === 'terminated';
    },
    otherParty: computed('otherParties.[]', 'connectedParties.[]', 'onConsult', function () {
      var _this9 = this;

      var useDisconnectedParties = this.selfIsDisconnectedOrTerminated();
      var parties = useDisconnectedParties ? this.get('disconnectedParties') : this.get('connectedParties');
      parties = parties.reject(function (p) {
        return p.purpose === 'ivr' || p.purpose === 'group' || p.userId === _this9.get('myGuid') || !!p.coachedParticipantId || !!p.monitoredParticipantId || !!p.bargedParticipantId;
      } // Both ACD and Communicate should not display monitor/coached or barged in users
      );

      if (parties.length > 0) {
        return parties.get('lastObject');
      }

      var otherParties = this.get('otherParties').reject(function (p) {
        return p.purpose === 'ivr' || p.purpose === 'group';
      });
      return otherParties.get('lastObject');
    }),
    allParties: computed('participants.[]', function () {
      return _lodash.default.filter(this.get('participants'), function (participant) {
        var communication = _lodash.default.head(participant.calls) || _lodash.default.head(participant.callbacks) || {};
        return communication.state && communication.state !== 'terminated';
      });
    }),
    connectedParties: computed('participants.[]', function () {
      return _lodash.default.filter(this.get('participants'), function (participant) {
        var communication = _lodash.default.head(participant.calls) || _lodash.default.head(participant.callbacks) || {};
        return maybeLowerCase(communication.state) === 'connected';
      });
    }),
    connectedOnConsult: computed('connectedParties.[]', function () {
      return _lodash.default.reject(this.get('connectedParties'), function (participant) {
        var communication = _lodash.default.head(participant.calls) || _lodash.default.head(participant.callbacks) || {};
        return communication.confined === 'true';
      });
    }),
    callParties: computed('onConference', 'onConsult', 'allParties.[]', 'otherParties.[]', 'otherParty', function () {
      if (!this.get('onConference')) {
        return this.get('onConsult') ? this.get('otherParties') : [this.get('otherParty')];
      }

      var firstCall = function firstCall(party) {
        return _lodash.default.head(party.calls) || _lodash.default.head(party.callbacks);
      };

      var isDisconnected = function isDisconnected(call) {
        return call && (call.state.toLowerCase() === 'disconnected' || call.state.toLowerCase() === 'terminated');
      };

      var sortedParties = this.get('allParties').sort(function (a, b) {
        var state = 'disconnected';
        var callStateA = _lodash.default.head(a.calls).state.toLowerCase() === state ? 0 : 1;
        var callStateB = _lodash.default.head(b.calls).state.toLowerCase() === state ? 0 : 1;
        return callStateA - callStateB;
      });

      var parties = _lodash.default.reject(sortedParties, function (party) {
        var disconnected = isDisconnected(firstCall(party));
        return disconnected && (0, _dates.default)().isAfter((0, _dates.default)(party.endTime).add(20, 'seconds'));
      });

      var arePartiesDisconnected = _lodash.default.some(parties, function (party) {
        return isDisconnected(firstCall(party));
      });

      if (arePartiesDisconnected) {
        var timer = run.debounce(this, this.recomputeParties, 12000);
        this.set('_recomputePartiesTimer', timer);
      }

      return parties;
    }),
    otherDisconnectedParty: computed('participants.[]', 'conversationIsAConsult', 'iAmInitiatorOfConsult', 'lastPartyTalkingToModel', 'sortedDisconnectedParties', function () {
      var _this10 = this;

      var isConsult = this.get('conversationIsAConsult');

      if (isConsult && !this.get('iAmInitiatorOfConsult')) {
        return this.get('lastPartyTalkingToModel');
      }

      var otherParties = _lodash.default.filter(this.get('participants'), function (party) {
        var guid = (0, _conversation.userGuidFromParticipant)(party);

        var isSelf = guid === _this10.get('myGuid');

        var isOngoing = party.endTime === undefined;
        var isGroup = party.purpose === 'group';
        var isStation = party.purpose === 'station';
        var isIvr = party.purpose === 'ivr';
        return !isSelf && (isConsult ? isOngoing : true) && !isGroup && !isStation && !isIvr;
      });

      if (otherParties.length > 1) {
        /**
         * When group ring call ends, there's a period where the endTime properties are set on some
         * of the participants but not all. This can cause the display to be incorrect.
         */
        var otherPartiesNoEndTime = otherParties.filter(function (otherParty) {
          return !Ember.get(otherParty, 'endTime');
        });
        return _lodash.default.head(otherPartiesNoEndTime);
      }

      return _lodash.default.head(otherParties);
    }),
    otherDisconnectedPartyModel: computed('otherDisconnectedParty', function () {
      var participant = this.get('otherDisconnectedParty');

      if (participant) {
        if (get(participant, 'purpose') === 'acd') {
          return this.get('application.store').find('queue', participant.queueId);
        }

        var guid = (0, _conversation.userGuidFromParticipant)(participant);
        return guid ? this.get('application.store').find('person', guid) : participant;
      }
    }),
    recomputeParties: function recomputeParties() {
      return this.notifyPropertyChange('callParties');
    },
    initiator: computed('participants.[]', 'otherParties.[]', 'onConsult', 'participants.@each.consultParticipantId', function () {
      return _lodash.default.find(this.get('participants'), function (party) {
        var initiator = get(party, 'attributes.consultInitiator');
        return initiator === 'true' || initiator === true;
      });
    }),
    initiatorModel: computed('conversationInitiator', 'initiator', function () {
      var participant = this.get('conversationInitiator');
      var guid = (0, _conversation.userGuidFromParticipant)(participant);
      return guid ? this.get('application.store').find('person', guid) : participant;
    }),
    isDisconnectedQueueCall: computed('otherDisconnectedParty', function () {
      return this.get('otherDisconnectedParty.purpose') === 'acd';
    }),
    setConsultInitiator: observer('initiator', 'conversationIsAConsult', function () {
      var initiator = this.get('initiator');

      if (!_lodash.default.isEmpty(initiator) && this.get('conversationIsAConsult')) {
        return this.set('conversationInitiator', initiator);
      }
    }),
    nonInitiatorNumber: computed('otherParties.[]', 'onConsult', 'myself', function () {
      var myConsultId = this.get('myself.consultParticipantId');

      var consultParty = _lodash.default.filter(this.get('otherParties'), function (party) {
        return party.id === myConsultId;
      });

      var nonInitiator = _lodash.default.last(consultParty);

      return this.get('addressformat').formatAddressForDisplay(nonInitiator.address);
    }),
    hasActiveAgent: computed('participants.@each.purpose', 'myGuid', function () {
      var _this11 = this;

      // Check to see if the current user has an agent participant
      // that is not currently wrapped up.
      return _lodash.default.some(this.get('participants'), function (participant) {
        var isMe = _this11.get('myGuid') === (0, _conversation.userGuidFromParticipant)(participant);
        var isAgent = participant.purpose.toLowerCase() === 'agent';
        var needsWrapup = !!participant.wrapupRequired && participant.wrapup === undefined;
        return isMe && isAgent && needsWrapup;
      });
    }),
    myGuid: computed.reads('session.person.guid'),
    myself: computed('participants.[]', 'myGuid', 'hasActiveAgent', function () {
      var _this12 = this;

      var hasActiveAgent = this.get('hasActiveAgent');
      return _lodash.default.findLast(this.get('participants'), function (participant) {
        var isMe = _this12.get('myGuid') === (0, _conversation.userGuidFromParticipant)(participant);
        var purpose = (participant.purpose || '').toLowerCase();
        var isAgent = purpose === 'agent';
        var isUser = purpose === 'user';
        var needsWrapup = !!participant.wrapupRequired && participant.wrapup === undefined;
        var isAlerting = false;

        try {
          var state = participant.calls[0].state.toLowerCase();
          isAlerting = state === 'alerting';
        } catch (e) {
          isAlerting = false;
        } // If the user has an unwrapped up agent participant, set that as their
        // current participant.


        if (hasActiveAgent) {
          return isMe && isAgent && (needsWrapup || isAlerting);
        } // Otherwise, we just use the last participant for the user
        // to represent their state.


        return isMe && (isAgent || isUser);
      });
    }),
    myselfIsMonitor: computed.bool('myself.monitoredParticipantId'),
    myselfIsCoach: computed.bool('myself.coachedParticipantId'),
    myselfIsBarged: computed('myself.bargedParticipantId', function () {
      var bargedParticipantId = this.get('myself.bargedParticipantId');
      var bargedParticipant = (this.get('participants') || []).find(function (participant) {
        return participant.id === bargedParticipantId;
      }) || {};
      var disconnectType = get(bargedParticipant, 'calls.firstObject.disconnectType');
      return bargedParticipantId && !disconnectType;
    }),
    myselfIsMCB: computed.or('myselfIsMonitor', 'myselfIsCoach', 'myselfIsBarged'),
    monitoredParticipant: computed('myselfIsMonitor', 'myself.monitoredParticipantId', 'allParties', function () {
      if (this.get('myselfIsMonitor')) {
        return this.get('allParties').findBy('id', this.get('myself.monitoredParticipantId'));
      }

      return null;
    }),
    coachedParticipant: computed('myselfIsCoach', 'myself.coachedParticipantId', 'allParties', function () {
      if (this.get('myselfIsCoach')) {
        return this.get('allParties').findBy('id', this.get('myself.coachedParticipantId'));
      }

      return null;
    }),
    bargedParticipant: computed('myselfIsBarged', 'myself.bargedParticipantId', 'allParties', function () {
      if (this.get('myselfIsBarged')) {
        return this.get('allParties').findBy('id', this.get('myself.bargedParticipantId'));
      }

      return null;
    }),
    monitoredParticipantPerson: computed('monitoredParticipant', function () {
      var monitoredParticipant = this.get('monitoredParticipant');
      var guid = (0, _conversation.userGuidFromParticipant)(monitoredParticipant);
      var store = this.get('application.store');

      if (!guid) {
        return monitoredParticipant;
      }

      var personFromStore;

      try {
        personFromStore = store.findRecord('person', guid, {
          reload: false,
          backgroundReload: false
        });

        if (personFromStore.catch) {
          personFromStore.catch(function () {
            return monitoredParticipant;
          });
        }
      } catch (e) {
        personFromStore = monitoredParticipant;
      }

      return personFromStore;
    }),
    coachedParticipantPerson: computed('coachedParticipant', function () {
      var coachedParticipant = this.get('coachedParticipant');
      var guid = (0, _conversation.userGuidFromParticipant)(coachedParticipant);
      var store = this.get('application.store');

      if (!guid) {
        return coachedParticipant;
      }

      var personFromStore;

      try {
        personFromStore = store.findRecord('person', guid, {
          reload: false,
          backgroundReload: false
        });

        if (personFromStore.catch) {
          personFromStore.catch(function () {
            return coachedParticipant;
          });
        }
      } catch (e) {
        personFromStore = coachedParticipant;
      }

      return personFromStore;
    }),
    bargedParticipantPerson: computed('bargedParticipant', function () {
      var bargedParticipant = this.get('bargedParticipant');
      var guid = (0, _conversation.userGuidFromParticipant)(bargedParticipant);
      var store = this.get('application.store');

      if (!guid) {
        return bargedParticipant;
      }

      var personFromStore;

      try {
        personFromStore = store.findRecord('person', guid, {
          reload: false,
          backgroundReload: false
        });

        if (personFromStore.catch) {
          personFromStore.catch(function () {
            return bargedParticipant;
          });
        }
      } catch (e) {
        personFromStore = bargedParticipant;
      }

      return personFromStore;
    }),
    isAgentCall: computed('participants.[]', 'myself', 'myselfIsMCB', function () {
      var isSelfMCB = this.get('myselfIsMCB'); // Otherwise, if there is another agent on the call, I want to view this conversation in ACD.

      var participants = this.get('participants') || [];
      return participants.some(function (user) {
        return maybeLowerCase(user.purpose) === 'agent';
      }) || isSelfMCB;
    }),
    isAfterCallWorkCompleted: computed('myself.wrapup', 'myself.wrapupRequired', function () {
      var result = this.get('myself.wrapup') || !this.get('myself.wrapupRequired');
      return result;
    }),
    isAfterCallWorkForcedTimeout: computed('myself.{wrapupPrompt,wrapupTimeoutMs}', function () {
      var prompt = maybeLowerCase(this.get('myself.wrapupPrompt'));
      return this.get('myself.wrapupTimeoutMs') && prompt === 'forcedtimeout';
    }),
    isAfterCallWorkTimeout: computed('myself.{wrapupPrompt,wrapupTimeoutMs}', function () {
      var prompt = maybeLowerCase(this.get('myself.wrapupPrompt'));
      return this.get('myself.wrapupTimeoutMs') && prompt === 'timeout';
    }),
    canUseCommunicationACW: computed.readOnly('interaction.orgLevelConversationsSettings.communicationBasedACW'),
    isWrapupPromptAgentRequested: computed('myself.{wrapupPrompt,wrapupTimeoutMs}', 'canUseCommunicationACW', function () {
      if (this.get('canUseCommunicationACW')) {
        var prompt = maybeLowerCase(this.get('myself.wrapupPrompt'));
        return !!this.get('myself.wrapupTimeoutMs') && prompt === 'agentrequested';
      } else {
        return false;
      }
    }),
    isNonDiscretionaryCompletion: computed.or('isWrapupPromptAgentRequested', 'isAfterCallWorkForcedTimeout', 'isAfterCallWorkTimeout'),
    isAfterCallWorkMandatory: computed('myself.wrapupPrompt', 'isNonDiscretionaryCompletion', function () {
      var prompt = maybeLowerCase(this.get('myself.wrapupPrompt'));
      return prompt === 'mandatory' || this.get('isNonDiscretionaryCompletion');
    }),
    callState: computed.reads('myself.calls.firstObject'),
    peerDisconnectedCall: computed('callState.disconnectType', function () {
      var disconnectType = maybeLowerCase(this.get('callState.disconnectType'));
      return disconnectType === 'peer';
    }),
    myselfDisconnectedCall: computed('callState.disconnectType', function () {
      var disconnectType = maybeLowerCase(this.get('callState.disconnectType'));
      return disconnectType === 'client';
    }),
    errorInfo: computed.reads('callState.errorInfo'),
    errorInfoMessage: computed.reads('errorInfo.message'),
    isIncoming: computed('callState.state', function () {
      return maybeLowerCase(this.get('callState.state')) === 'alerting';
    }),
    isDialing: computed('callState.state', function () {
      var state = maybeLowerCase(this.get('callState.state'));
      return state === 'alerting' || state === 'contacting' || state === 'dialing';
    }),
    isRinging: computed('callState.state', function () {
      var state = maybeLowerCase(this.get('callState.state'));
      return state === 'alerting';
    }),
    isDialingOrContacting: computed('callState.state', function () {
      var state = maybeLowerCase(this.get('callState.state'));
      return state === 'contacting' || state === 'dialing';
    }),
    isContacting: computed('callState.state', function () {
      var state = maybeLowerCase(this.get('callState.state'));
      return state === 'contacting';
    }),
    justMyselfOnCall: computed('participants.[]', 'myGuid', 'isContacting', function () {
      var iAmContacting = this.get('participants.firstObject.userId') === this.get('myGuid') && this.get('isContacting');
      return this.get('participants').length === 1 && iAmContacting;
    }),
    theOtherPartyWhileContacting: computed('justMyselfOnCall', 'attemptingToCall', function () {
      if (!this.get('justMyselfOnCall')) {
        return;
      }

      var address = this.get('attemptingToCall');

      if (address) {
        var userId = Ember.get(address, 'userId');
        var calls = [{
          state: 'ALERTING'
        }];
        return {
          userId: userId,
          address: address,
          calls: calls
        };
      }
    }),
    isOutbound: computed('callState.direction', function () {
      var direction = this.get('callState.direction');
      return maybeLowerCase(direction) === 'outbound';
    }),
    isMissed: computed('callState.segments', 'isOutbound', function () {
      var segments = this.get('callState.segments');

      if (_lodash.default.size(segments) === 1 && !this.get('isOutbound')) {
        var segment = _lodash.default.last(segments);

        var type = segment.type,
            howEnded = segment.howEnded;
        var lowType = type.toLowerCase();
        var endedWithDisconnect = maybeLowerCase(howEnded) === 'disconnect';
        return (lowType === 'dialing' || lowType === 'alert') && endedWithDisconnect;
      }

      return false;
    }),
    isActive: computed('phone.activeCall', function () {
      return this.get('id') === this.get('phone.activeCall.id');
    }),
    isHeld: computed.and('callState.held', 'onCall'),
    isConfined: computed.and('callState.confined', 'onCall', 'onConsult'),
    isMuted: computed.and('callState.muted', 'onCall'),
    isRecording: computed.and('callState.recording', 'onCall'),
    isDisconnected: computed.not('onCall'),
    wentToVoicemail: computed('allParties.@each.purpose', function () {
      var parties = this.get('allParties');

      if (!parties) {
        return false;
      }

      var voicemail = parties.findBy('purpose', 'Voicemail') || parties.findBy('purpose', 'voicemail');
      return !_lodash.default.isEmpty(voicemail);
    }),
    voicemailParties: computed('wentToVoicemail', function () {
      return _lodash.default.filter(this.get('allParties'), function (party) {
        return party && party.purpose === 'voicemail';
      });
    }),
    onCall: computed('callState', function () {
      var state = maybeLowerCase(this.get('callState.state'));
      return state !== 'disconnected' && state !== 'terminated';
    }),
    isConnected: computed('callState.state', function () {
      var state = maybeLowerCase(this.get('callState.state'));
      return state === 'connected';
    }),
    onConsult: computed('otherParties.@each.calls', function () {
      var others = this.get('otherParties');
      var consultParties = others.filter(function (other) {
        return !!other.consultParticipantId;
      });

      if (consultParties.length !== 2) {
        return false;
      }

      var found = _lodash.default.find(others, function (other) {
        return other.consultParticipantId;
      });

      return !_lodash.default.isEmpty(found);
    }),
    markCallAsConsult: observer('onConsult', 'participants.@each.consultParticipantId', 'participants.[]', function () {
      var consult = _lodash.default.find(this.get('participants'), function (participant) {
        return participant.consultParticipantId;
      });

      if (this.get('onConsult') || consult) {
        return this.set('conversationIsAConsult', true);
      }
    }),

    /**
     * @deprecated use `isActiveConference` from the interaction model instead
     */
    onConference: computed('maxParticipants', function () {
      return this.get('maxParticipants') > 0;
    }),
    canUpdateConsult: computed('onConsult', 'participants.@each.attributes', function () {
      var person = this.get('application.session.person');

      var initiator = _lodash.default.find(this.get('participants'), function (participant) {
        var initiator = participant.attributes.consultInitiator;
        return initiator === 'true' || initiator === true;
      });

      return get(initiator, 'userId') === person.get('guid');
    }),
    groupRingId: computed('participants.@each.purpose', function () {
      // grab most recent group participant with endTime
      // if no endTime, then that group participant is most recent
      var mostRecentEndTime = null;
      var groupParticipant = null;

      var _iterator = _createForOfIteratorHelper(this.get('participants')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var participant = _step.value;
          var groupId = get(participant, 'groupId') || get(participant, 'group.id');

          if (groupId) {
            var currentTimestamp = (0, _dates.default)(get(participant, 'endTime')); // Compare with the current most recent timestamp

            if (mostRecentEndTime === null || currentTimestamp.isAfter(mostRecentEndTime)) {
              mostRecentEndTime = currentTimestamp;
              groupParticipant = participant;
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (groupParticipant) {
        return groupParticipant.groupId;
      }

      return null;
    }),
    isGroupRing: computed('groupRingId', function () {
      return !!this.get('groupRingId');
    }),
    groupRingGroup: computed('groupRingId', function () {
      var groupId = this.get('groupRingId');

      if (groupId) {
        var promise = this.get('application.store').findRecord('group', groupId, {
          reload: false,
          backgroundReload: false
        });
        return _emberData.default.PromiseObject.create({
          promise: promise
        });
      }
    }),
    _groupRingCaller: computed('isGroupRing', 'participants', 'connectedParties.@each.purpose', function () {
      if (!this.get('isGroupRing')) {
        return null;
      } // We are going to mimic the callv2 behavior, for consistency


      var participants = this.get('participants');
      var outboundParticipant = participants.findBy('calls.firstObject.direction', 'outbound');

      if (outboundParticipant) {
        return outboundParticipant;
      }

      var externalParticipants = participants.reject(function (p) {
        return Ember.get(p, 'dnis') === 'unknown';
      }).filter(function (p) {
        return p.purpose === 'external' || p.purpose === 'customer';
      });
      var inboundExternal = externalParticipants.findBy('calls.firstObject.direction', 'inbound');

      if (inboundExternal) {
        return inboundExternal;
      } // Sometimes the initiator is indeterminate based on the data we get
      // but it's not really necessary for rendering the component


      return null;
    }),
    isOutboundGroupRing: computed('isOutbound', '_groupRingCaller', 'myGuid', function () {
      return this.get('isOutbound') && this.get('_groupRingCaller.userId') === this.get('myGuid');
    }),
    groupRingCaller: computed('_groupRingCaller', function () {
      var actualCaller = this.get('_groupRingCaller');
      var caller = actualCaller;

      if (caller && caller.userId) {
        var promise = this.get('store').findRecord('person', caller.userId, {
          reload: false,
          backgroundReload: false
        });
        caller = _emberData.default.PromiseObject.create({
          promise: promise
        });
      }

      return caller;
    }),
    connectedToStationAndNotConference: computed('participants.[]', 'onConference', function () {
      var participants = this.get('participants');
      var anotherPartyIsStation = !!participants.find(function (p) {
        var isStation = Ember.get(p, 'purpose') === 'station';

        if (!isStation) {
          return false;
        }

        var isStandaloneStation = Ember.get(p, 'name');

        if (isStandaloneStation) {
          return false;
        }

        return Ember.get(p, 'calls.firstObject.disconnectType') !== 'transfer.forward';
      });
      var anotherPartyIsVoicemail = !!participants.findBy('purpose', 'voicemail');
      return anotherPartyIsStation && !this.get('onConference') && !anotherPartyIsVoicemail;
    }),
    _doesParticipantHaveActiveSessionShare: function _doesParticipantHaveActiveSessionShare(participant) {
      var screenShares = Ember.get(participant, 'screenShares') || Ember.get(participant, 'screenshares');
      var cobrowsesessions = Ember.get(participant, 'cobrowsesessions');
      var videos = Ember.get(participant, 'videos');
      var allMedia = [];

      if (screenShares) {
        allMedia = allMedia.concat(screenShares);
      }

      if (cobrowsesessions) {
        allMedia = allMedia.concat(cobrowsesessions);
      }

      if (videos) {
        allMedia = allMedia.concat(videos);
      }

      var connectedMedia = _lodash.default.find(allMedia, function (media) {
        return media.state.toLowerCase() === 'connected';
      });

      return !!connectedMedia;
    },
    agentParticipants: computed('participants.[]', 'myGuid', function () {
      var _this13 = this;

      return _lodash.default.filter(this.get('participants'), function (participant) {
        var guid = (0, _conversation.userGuidFromParticipant)(participant);
        var purpose = participant.purpose.toLowerCase();
        return _this13.get('myGuid') === guid && (purpose === 'agent' || purpose === 'user');
      });
    }),
    conversationHasActiveSessionShare: computed('agentParticipants.[]', function () {
      var _this14 = this;

      var hasActiveMedia = _lodash.default.some(this.get('agentParticipants'), function (participant) {
        return _this14._doesParticipantHaveActiveSessionShare(participant);
      });

      return hasActiveMedia;
    })
  });

  _exports.default = _default;
});