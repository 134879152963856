define("web-directory/components/acd-interactions-panel/interaction-card-v2/workitem-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['statusCategory'],
    acdworkitem: Ember.inject.service('acdworkitem'),
    session: Ember.inject.service('session'),

    /**
     * @Attribute
     * WorkitemInteraction represented by card
     */
    workitemInteraction: null,
    statusCategory: Ember.computed('workitemInteraction.workitem.statusCategory', function () {
      return this.get('workitemInteraction.workitem.statusCategory').toLowerCase();
    })
  });

  _exports.default = _default;
});