define("web-directory/utils/newrelic-legacy", ["exports", "ember-purecloud-components/utils/newrelic-legacy"], function (_exports, _newrelicLegacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _newrelicLegacy.default;
    }
  });
});