define("web-directory/components/fax-panel/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component,
      inject = Ember.inject,
      run = Ember.run;
  var MIN_WINDOW_WIDTH_FOR_SEARCH = 1100;

  var _default = Component.extend(_logger.default, {
    classNames: 'fax-panel',
    application: inject.service(),
    fax: inject.service(),
    addressformat: inject.service(),
    intl: inject.service(),
    notification: inject.service(),
    session: inject.service(),
    addresses: [],
    includeCoverSheet: true,
    showFileTypeError: false,
    showRequiredFaxNumberError: false,
    notes: '',
    faxParty: computed('participants.[]', function () {
      return this.get('participants').get('firstObject');
    }),
    invalidFileTypeMessage: computed(function () {
      return this.get('intl').t('purecloud.fax.error.invalidFileTypeWord');
    }),
    attachInstructions: computed(function () {
      return this.get('intl').t('purecloud.fax.attachInstructionsWord');
    }),
    observeFaxDocumentData: observer('fax.documentData', function () {
      var _this = this;

      var documentData = this.get('fax.documentData');

      if (documentData && documentData.documentId) {
        this.setFileInput(documentData);
      }

      this.set('fax.documentData', null);
      Ember.run.later(function () {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
          _this.set('fax.isSelectingDocument', false);
        }
      }, 500);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.attachFileListeners(); // because toggle-switch calculates width, it doesn't work on hidden elements.
      // so we have to override with our own width

      var debounceTimerId = run.debounce(this, function () {
        this.$('.toggle-switch-container').removeAttr('style');
      }, 500);
      this.set('debounceTimer', debounceTimerId);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var $hiddenFileInput = this.$('.hidden-file-input');
      $hiddenFileInput.off();
      this.$('.fax-number-input > input').off();
      run.cancel(this.get('debounceTimer'));
    },
    supportedTypes: ['image/tiff', 'application/pdf', 'application/vnd.oasis.opendocument.text', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    isSupportedType: function isSupportedType(type) {
      return _lodash.default.includes(this.get('supportedTypes'), type);
    },
    attachFileListeners: function attachFileListeners() {
      var _this2 = this;

      var $hiddenFileInput = this.$('.hidden-file-input');
      $hiddenFileInput.on('change', function (e) {
        Ember.run(function () {
          _this2.resetFiles();

          var file = _lodash.default.head(e.target.files); // only one file for now.


          if (e.target.files.length > 0) {
            run.next(_this2, _this2.setFileInput, file);
            e.target.value = null; // set to null so we pick up the next change event
          }
        });
      });
      var $faxNumberInput = this.$('.fax-number-input > input');
      $faxNumberInput.on('keyup', function (e) {
        Ember.run(function () {
          var isEmpty = e.target.value === '';

          _this2.set('showRequiredFaxNumberError', isEmpty);
        });
      }); //this.observeFaxDocumentData();
    },
    numberIsEmpty: computed.empty('fax.address'),
    isFileAttached: computed.bool('attachedFile'),
    noFilesAttached: computed('attachedFile', function () {
      // OMG a File object will return Empty even though it really holds data.
      return _lodash.default.isEmpty(this.get('attachedFile.name'));
    }),
    // Create the new FormData object and append the file.
    setFileInput: function setFileInput(file) {
      if (file === undefined) {
        this.logger.log('FAX undefined file set as file input');
        return;
      }

      if (!this.isSupportedType(file.type)) {
        this.set('showFileTypeError', true);
        this.logger.log('FAX bad file type set as file input - ' + file.type);
        return;
      }

      var faxForm = new FormData();
      faxForm.append('name', file.name);
      faxForm.append('file', file);
      this.set('showFileTypeError', false);
      this.set('faxForm', faxForm);
      this.set('attachedFile', file);
      this.set('documentId', file.documentId);
      this.set('fileName', file.name);
      this.set('contentType', file.type);
      this.set('name', "fax - ".concat(file.lastModifiedDate));
    },
    validateFax: function validateFax() {
      var isValid = true;
      var currentFileType = this.get('contentType');
      this.set('showFileTypeError', false);
      this.set('showRequiredFaxNumberError', false);

      if (!this.isSupportedType(currentFileType)) {
        this.set('showFileTypeError', true);
        isValid = false;
      }

      if (this.get('numberIsEmpty')) {
        this.set('showRequiredFaxNumberError', true);
        isValid = false;
      }

      return isValid;
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
    },
    // here we have to manually add the dropped file to the input.
    drop: function drop(event) {
      event.preventDefault();
      var DOCUMENT_DATA = 'documentdata';
      this.set('isDragging', false);
      var file = event.dataTransfer.files[0];

      if (!file && event.dataTransfer.types.indexOf(DOCUMENT_DATA) !== -1) {
        var documentData = event.dataTransfer.getData(DOCUMENT_DATA);
        file = JSON.parse(documentData);
      }

      this.resetFiles();
      this.setFileInput(file);
    },
    dragEnter: function dragEnter(event) {
      event.preventDefault();
      this.set('isDragging', true);
    },
    resetFiles: function resetFiles() {
      this.set('faxForm', null);
      this.set('attachedFile', null);
      this.set('documentId', null);
      this.set('fileName', null);
      this.set('contentType', null);
      this.set('name', null);
    },
    resetFormState: function resetFormState() {
      this.set('addresses', []);
      this.set('faxing', false);
      this.set('includeCoverSheet', true);
      this.set('notes', null);
      this.set('fax.address', '');
      this.set('showRequiredFaxNumberError', false);
      this.$('.bootstrap-tagsinput > input').val('');
    },
    actions: {
      initiateFax: function initiateFax() {
        var _this3 = this;

        if (this.get('noStationSelected')) {
          var message = this.get('intl').t('purecloud.fax.error.stationRequiredToSendFax');
          this.get('notification').error(null, message);
          this.logger.log('FAX Fax attempted with no station selected');
          return false;
        }

        if (this.validateFax()) {
          this.set('faxing', true);
          var faxAddress = this.get('fax.address');
          var addresses = [this.get('addressformat').formatAddress(faxAddress)];
          var documentId = this.get('documentId');
          var fileName = this.get('fileName');
          var faxForm = this.get('faxForm');
          var notes = this.get('includeCoverSheet') ? this.get('notes') : null;
          var documentName = "Fax - ".concat(moment().format('L LTS'), " ").concat(fileName);
          this.get('fax').makeFaxRequest(addresses, documentName, documentId, this.get('contentType'), notes).then(function (fax) {
            _this3.get('fax').updateActiveFaxes({
              id: fax.id,
              _faxAddress: addresses[0],
              _purpose: 'fax',
              fileName: documentName,
              documentId: documentId
            });

            if (documentId) {
              return fax;
            }

            return _this3.get('fax').makeUploadRequestWithHeaders(fax.uploadDestinationUri, faxForm, fax.headers);
          }).catch(function (e) {
            var title = _this3.get('intl').t('purecloud.fax.error.requestFailed');

            var message = e.responseJSON ? e.responseJSON.message : _this3.get('intl').t('purecloud.fax.error.unknown').toString();

            _this3.get('notification').error(title, message, {
              tag: 'call-error'
            });

            _this3.logger.error('FAX: Failed to fax - ', {
              message: message,
              error: e
            });
          }).finally(function () {
            _this3.resetFiles();

            _this3.resetFormState();

            _this3.get('fax').getActiveFaxes();
          });
        }
      },
      toggleCoverSheet: function toggleCoverSheet() {
        this.toggleProperty('includeCoverSheet');
      },
      removeFile: function removeFile() {
        this.resetFiles();
      },
      chooseDocument: function chooseDocument() {
        this.set('fax.isSelectingDocument', true);
        this.bubbleAction({
          action: 'transitionToRoute',
          actionContext: ['content-management', 'search/results?mode=fax']
        });

        if (this.$(window).innerWidth() < MIN_WINDOW_WIDTH_FOR_SEARCH) {
          this.bubbleAction({
            action: 'closePanel'
          });
        }
      },
      chooseFile: function chooseFile() {
        // We are using the label to handle the click event and hiding the file input because it is hard to style.
        // http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
        // Then we have to trigger a click event on the input so that the user's file dialog will appear.
        this.$('#choose-file').trigger('click');
      },
      openStationPicker: function openStationPicker() {
        if (this.get('openStationPicker')) {
          this.get('openStationPicker')();
        }
      }
    }
  }).reopenClass({
    positionalParams: ['noStationSelected']
  });

  _exports.default = _default;
});