define("web-directory/mixins/searchV2", ["exports", "lodash", "web-directory/utils/external-contact-messaging", "web-directory/utils/testing"], function (_exports, _lodash, _externalContactMessaging, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEOUT = _testing.isTesting ? 0 : 300;

  var PrepareResults = function PrepareResults(context, results) {
    var parsedResults;

    if (!Array.isArray(results)) {
      parsedResults = results.get('content');
      parsedResults = parsedResults.map(function (x) {
        return x.getRecord();
      });
    } else {
      parsedResults = results;
    }

    if (parsedResults.length < 0 && parsedResults === undefined && parsedResults === null) {
      context.set('retrySearch', true);
      return;
    }

    var entities = _lodash.default.flatten(parsedResults);

    var people = entities.filterBy('type', 'person');

    if (people.length > 0) {
      context.get('presenceSubscription').autoSubscribe(people, context, 'search');
    }

    return entities;
  };

  var performSearch = function performSearch() {
    var _this = this;

    var value;

    if (this.get('isDestroyed') || this.get('isDestroying')) {
      return;
    }

    if (this.get('searchQuery.length') > 0) {
      value = this.get('searchQuery');
    } else if (this.get('searchOnEmpty')) {
      value = '*';
    } else {
      if (this._state !== 'inDOM') {
        return;
      }

      this.get('presenceSubscription').autoSubscribe(null, this, 'search');
      this.set('searchResults', []);
      return;
    }

    var userPending = this.get('userPending');
    var queuePending = this.get('queuePending');
    var relatePending = this.get('relatePending');
    var queuePermission = this.get('permissions.canViewQueue');
    var relatePermission = this.get('permissions.hasExternalContactsViewContactAccess');
    var searchRelate = this.get('searchRelate');
    var searchQueues = this.get('searchQueue');
    var searchUsers = this.get('searchUser');
    var userRequests = null;

    if (searchUsers && !userPending) {
      this.set('userPending', true);
      var request = this.get('store').query('public-api-user', {
        query: [{
          value: value,
          type: 'QUERY_STRING'
        }],
        sortBy: 'name',
        sortOrder: 'ASC'
      });
      userRequests = request.then(function (entities) {
        var resultArray = entities.content || [];
        var userIds = resultArray.map(function (entry) {
          return entry.id;
        });
        return _this.get('store').findMany('person', userIds);
      });
      userRequests.catch(function () {
        return _this.set('userSearchFailed', true);
      });
      userRequests.then(function (res) {
        var entities = new PrepareResults(_this, res);

        _this.set('userResults', entities);
      });
      userRequests.finally(function () {
        _this.set('userPending', false);

        _this.get('retrySearch', false);
      });
    }

    if (searchQueues && queuePermission && !queuePending) {
      this.set('queuePending', true);
      var queueSearchData = {
        name: "**".concat(value, "**"),
        pageNumber: 1,
        pageSize: this.get('searchLimit')
      };
      var divisionAgnostic = this.get('divisionAgnostic');

      if (divisionAgnostic) {
        queueSearchData.divisionAgnostic = true;
      }

      var queueRequests = this.get('store').query('queue', queueSearchData);
      queueRequests.catch(function () {
        return _this.set('queueSearchFailed', true);
      });
      queueRequests.then(function (res) {
        var entities = new PrepareResults(_this, res);

        _this.set('queueResults', entities);
      });
      queueRequests.finally(function () {
        _this.set('queuePending', false);

        _this.get('retrySearch', false);
      });
    }

    if (searchRelate && relatePermission && !relatePending) {
      this.set('relatePending', true);
      var relateRequests = this.get('store').query('externalcontacts/contact', {
        searchTerm: "".concat(value),
        pageSize: this.get('searchLimit'),
        pageNumber: 1,
        expandField: 'externalOrganization,division',
        originator: this.get('componentClassName')
      });
      relateRequests.catch(function () {
        return _this.set('relateSearchFailed', true);
      });
      relateRequests.then(function (res) {
        var entities = new PrepareResults(_this, res);

        _this.set('relateResults', entities);
      });
      relateRequests.finally(function () {
        _this.set('relatePending', false);

        _this.get('retrySearch', false);
      });
    }
  };

  var SearchMixin = Ember.Mixin.create({
    componentClassName: Ember.computed(function () {
      return _externalContactMessaging.componentClassName.call(this);
    }),
    presenceSubscription: Ember.inject.service('presence-subscription'),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    searchQuery: null,
    searchOnEmpty: false,
    retrySearch: false,
    searchUser: false,
    searchRelate: false,
    searchQueue: false,
    userSearchFailed: false,
    queueSearchFailed: false,
    relateSearchFailed: false,
    searchLimit: 25,
    userPending: false,
    queuePending: false,
    relatePending: false,
    userResults: null,
    queueResults: null,
    relateResults: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('userResults', []);
      this.set('relateResults', []);
      this.set('queueResults', []);
    },
    performSearch: Ember.observer('searchQuery', 'entityFilter', function () {
      this.debouncedSearch();
    }),
    performReload: Ember.observer('retrySearch', function () {
      if (this.get('retrySearch')) {
        this.set('userResults', []);
        this.set('relateResults', []);
        this.set('queueResults', []);
        this.debouncedSearch();
      }
    }),
    debouncedSearch: _lodash.default.debounce(performSearch, TIMEOUT, {
      leading: false,
      trailing: true
    })
  });
  var _default = SearchMixin;
  _exports.default = _default;
});