define("web-directory/components/navigation-menu/component", ["exports", "web-directory/utils/focus-management"], function (_exports, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['navigation-menu'],
    sidebar: Ember.inject.service(),
    session: Ember.inject.service(),
    phone: Ember.inject.service(),
    menuOpen: false,
    doFocusMethod: function doFocusMethod(focusTarget) {
      (0, _focusManagement.doFocus)(Ember, focusTarget);
    },
    hasAlertingAcdInteraction: function hasAlertingAcdInteraction() {
      return Ember.$('.acd-interaction-card-v2.is-alerting').length;
    },
    hasAlertingNonAcdInteraction: function hasAlertingNonAcdInteraction() {
      return this.get('phone.incomingNonAcdConversations').length;
    },
    callsPanelActive: function callsPanelActive() {
      return this.get('sidebar.showCallControls');
    },
    hasSelectedInteraction: function hasSelectedInteraction() {
      return Ember.$('.acd-interaction-card-v2.is-selected').length;
    },
    hasActivePresence: function hasActivePresence() {
      return Ember.$('.presence-selector .presence-entry.active').length;
    },
    actions: {
      logout: function logout() {
        this.triggerAction({
          action: 'logout'
        });
      },
      skipToAlerting: function skipToAlerting() {
        var _this = this;

        if (this.hasAlertingAcdInteraction()) {
          var focusTarget = '.acd-interaction-card-v2.is-alerting';

          if (!this.get('sidebar.showAcdInteractions')) {
            this.get('sidebar').togglePanel('acdInteractions');
          }

          this.doFocusMethod(focusTarget);
        } else if (this.hasAlertingNonAcdInteraction()) {
          var _focusTarget = '.incoming-controls .answer-call';

          if (!this.callsPanelActive()) {
            Ember.run.next(function () {
              _this.$('.action-item.call a').trigger('click');

              _this.doFocusMethod(_focusTarget);
            });
          } else {
            this.doFocusMethod(_focusTarget);
          }
        }
      },
      skipToNotification: function skipToNotification() {
        var focusTarget = 'body > ul.messenger';
        var container = document.querySelector(focusTarget);

        if (container) {
          container.setAttribute('tabindex', '-1');
          container.focus();
        }
      },
      skipToMain: function skipToMain() {
        var focusTarget;

        if (this.get('sidebar.sidebarCollapsed')) {
          focusTarget = '#orgspan';
        } else {
          focusTarget = '#skip-link-main-target';
        }

        this.doFocusMethod(focusTarget);
      },
      skipToInteractions: function skipToInteractions() {
        var focusTarget = '.acd-interaction-card-v2.is-alerting';

        if (!this.hasAlertingAcdInteraction()) {
          focusTarget = '.acd-interaction-card-v2.is-selected';

          if (!this.hasSelectedInteraction()) {
            focusTarget = 'button.new-outbound:first';
          }
        }

        if (!this.get('sidebar.showAcdInteractions')) {
          this.get('sidebar').togglePanel('acdInteractions');
        }

        this.doFocusMethod(focusTarget);
      },
      skipToPresenceSelector: function skipToPresenceSelector() {
        var _this2 = this;

        var focusTarget = '.presence-selector .presence-entry.active';

        if (!this.hasActivePresence()) {
          focusTarget = '.presence-selector .presence-entry:first';
        }

        Ember.run.next(function () {
          _this2.$('#toggleUserDropdownTarget').trigger('click');

          _this2.doFocusMethod(focusTarget);
        });
      }
    },
    click: function click(event) {
      var target = $(event.target);
      var isMenu = target.parents('.menu').length === 1;
      var isAction = target.parents('.actions').length === 1;
      var isItem = target.is('a') || target.parent().is('a');

      if ((isMenu || isAction) && isItem) {
        this.set('menuOpen', false);
      }

      if (isMenu && !isAction && isItem) {
        this.get('sidebar').ensureRouteVisibility();
      }
    }
  });

  _exports.default = _default;
});