define("web-directory/routes/person/detail", ["exports", "web-directory/mixins/model-route"], function (_exports, _modelRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isGuid(id) {
    if (!id) {
      return false;
    }

    return !!id.match(/^[a-z0-9-]{36}$/i);
  }

  var _default = Ember.Route.extend(_modelRoute.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    routeTitle: 'Person Profile',
    actions: {
      willTransition: function willTransition(transition) {
        var editing = this.get('controller.editing') && this.get('controller.editingSection');

        if (editing) {
          var msg = this.get('intl').lookup('notification.person.navigate').toString();

          if (!window.confirm(msg)) {
            transition.abort();
            return false;
          }

          this.set('controller.editing', false);
          this.set('controller.editingSection', false);
        }

        return true;
      }
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var result = this._super(transition);

      var id = transition.params['person.detail'].person_id;

      if (transition.isActive === false) {
        // Route canceled for auth
        return;
      }

      if (this.get('session.user.isSuper') || this.get('session.user.isTrustee')) {
        if (transition.params['person.detail'].person_id === this.get('session.person.id')) {
          transition.abort();
        }

        return;
      }

      var isToggleEnabled = this.get('session.features')['ATLAS-467_integrate-connections-ui'];

      if (isToggleEnabled) {
        return this.replaceWith("/person-v2/".concat(id));
      }

      return Ember.RSVP.resolve(result).then(function () {
        var id = transition.params['person.detail'].person_id;
        var person;

        if (!isGuid(id)) {
          // We've got a mongoid, so we need to translate that to something useable...
          var orgName = _this.get('session.org.shortName');

          var jid = "".concat(id, "@").concat(orgName, ".orgspan.com");
          return _this.get('store').findMany('person', [jid]).then(function (res) {
            if (res && res[0]) {
              transition.abort();

              _this.replaceWith('person.detail', res[0].get('id'));
            }
          }).catch(function () {
            return _this.transitionTo('person.detail', _this.get('session.person.id'));
          });
        }

        if (id === _this.get('session.person.id')) {
          person = _this.get('session.person');
        } else {
          person = _this.store.recordForId('person', id);
        }

        if (person && !person.get('isEmpty') && !person.get('isSaving') && !person.get('isLoading') && !person.get('isReloading')) {
          return person.reload().then(function (p) {
            p.rollbackAttributes();
          });
        } else {
          return true;
        }
      });
    },
    model: function model(params) {
      var _this2 = this;

      // We have a guid by this point due to the beforeModel hook performing a transition for us
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.store.findRecord('public-api-user', params.person_id, {
          adapterOptions: {
            expand: 'authorization'
          }
        }).then(function (user) {
          if (params.person_id === _this2.get('session.person.id')) {
            resolve({
              user: user,
              person: _this2.get('session.person')
            });
            return;
          }

          user.get('profile').then(function (profile) {
            resolve({
              user: user,
              person: profile
            });
          });
        }).catch(reject);
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (this.get('session.person.id') !== model.person.get('id')) {
        controller.set('editing', false);
      } else {
        controller.set('editing', true);
      }

      this.set('routeTitle', model.person.get('name'));
    }
  });

  _exports.default = _default;
});