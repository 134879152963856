define("web-directory/services/emergency-location", ["exports", "web-directory/utils/dates", "web-directory/utils/emergency-location-util", "web-directory/utils/testing"], function (_exports, _dates, _emergencyLocationUtil, _testing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var POLL_INTERVAL = 1200000; //20min

  var _default = Ember.Service.extend({
    session: inject.service(),
    ajax: Ember.inject.service(),
    application: Ember.inject.service(),
    intl: inject.service(),
    logger: inject.service('stash-logger'),
    addressformat: inject.service(),
    eLocation: null,
    isAuthenticated: computed.reads('session.isAuthenticated'),
    isEligibleForE911: false,
    eLocationInlineWarning: null,
    primaryVoice: null,
    emergencyLocationData: computed('eLocation', 'isEligibleForE911', 'eLocationInlineWarning', 'primaryVoice', function () {
      return {
        eLocation: this.get('eLocation'),
        eLocationInlineWarning: this.get('eLocationInlineWarning'),
        primaryVoice: this.get('primaryVoice'),
        isEligibleForE911: this.get('isEligibleForE911')
      };
    }),

    /**
     * Gets emergency location
     * @param  {string} phoneNumber the e164 formatted phone number paired with the emergency location being retrieved
     * @return {promise} promise wraps object containing address location
     */
    getEmergencyLocation: function getEmergencyLocation(phoneNumber) {
      phoneNumber = !!phoneNumber ? phoneNumber : this.get('primaryVoice.number');
      phoneNumber = this.get('addressformat').formatAddress(phoneNumber);
      var url = this.get('application').pcV2Uri("api/v2/carrierservices/integrations/emergencylocations/me");
      var options = {
        data: {
          phoneNumber: phoneNumber
        }
      };
      return this.get('ajax').request(url, options);
    },

    /**
     * Fetches and loads primary contact information for user and sets primary number in service
     * @param  {string} userId Optional userId for whom the primary contact information is fetched.
     * @return {promise} returns promise containing number and also sets primaryVoice in this service
     * @return {promise rejected} returns error message to display in notification
     */
    loadUserPrimaryNumber: function loadUserPrimaryNumber(userId) {
      var _this = this;

      var url = this.get('application').pcV2Uri("api/v2/users/me");

      if (userId) {
        url = this.get('application').pcV2Uri("api/v2/users/".concat(userId));
      }

      return this.get('ajax').request(url).then(function (data) {
        var number = null;

        if (data && data.primaryContactInfo) {
          var primaryPhoneObject = data.primaryContactInfo.findBy('mediaType', 'PHONE');

          if (primaryPhoneObject && (primaryPhoneObject.address || primaryPhoneObject.display)) {
            number = _this.get('addressformat').formatAddress(primaryPhoneObject.address || primaryPhoneObject.display);

            _this.set('primaryVoice', {
              number: number,
              display: _this.get('addressformat').formatAddressForDisplay(number)
            });

            return number;
          }
        }

        _this.set('primaryVoice', {
          number: number,
          display: ''
        });

        _this.set('eLocationInlineWarning', _this.get('intl').lookup('emergencyLocation.errors.primaryNumberNotFound'));

        return Ember.RSVP.reject({
          err: 'No primary number found',
          softError: _this.get('intl').lookup('emergencyLocation.errors.primaryNumberNotFound')
        });
      }).catch(function (err) {
        if (err.softError) {
          return Ember.RSVP.reject(err);
        }

        _this.set('eLocationInlineWarning', _this.get('intl').lookup('emergencyLocation.errors.primaryNumberLookup'));

        return Ember.RSVP.reject({
          err: err,
          hardError: _this.get('intl').lookup('emergencyLocation.errors.primaryNumberLookup')
        });
      });
    },

    /**
     * Fetches e911 eligibility status for a given phone number and sets isEligibleForE911 service property
     * @param  {string} phoneNumber e164 formatted phone number that is checked for e911 eligibility
     * @return {promise resolved} returns promise containing eligibility status
     * @return {promise rejected} returns error message to display in notification
     */
    loadELocationEligibility: function loadELocationEligibility(phoneNumber) {
      var _this2 = this;

      var url = this.get('application').pcV2Uri('api/v2/carrierservices/integrations/emergencylocations/verifyphonenumber');
      var options = {
        data: {
          phoneNumber: phoneNumber
        }
      };
      return this.get('ajax').post(url, options).then(function (data) {
        if (data && data.supported) {
          _this2.set('isEligibleForE911', true);

          return data;
        }

        _this2.set('isEligibleForE911', false);

        return Ember.RSVP.resolve({
          supported: false
        });
      }).catch(function (err) {
        _this2.set('isEligibleForE911', false);

        return Ember.RSVP.reject({
          err: err,
          softError: _this2.get('intl').lookup('emergencyLocation.errors.e911IsSupported')
        });
      });
    },

    /**
     *  Resets the eLocation property to an emty address.
     */
    clearElocation: function clearElocation() {
      var emptyAddress = {
        id: '',
        address: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zipcode: '',
          country: ''
        },
        did: '',
        source: ''
      };
      this.set('eLocation', emptyAddress);
    },

    /**
     * Refreshes the eLocation property containing the emergency location
     * @param  {string} phoneNumber the e164 formatted phone number paired with the emergency location being retrieved
     * @return {promise resolved} returns promise containing emergency address
     * @return {promise rejected} returns error message to display in notification
     */
    refreshEmergencyLocation: function refreshEmergencyLocation(phoneNumber) {
      var _this3 = this;

      return this.getEmergencyLocation(phoneNumber).then(function (result) {
        _this3.set('isEligibleForE911', true);

        _this3.set('eLocation', result);
      }).catch(function (err) {
        _this3.set('isEligibleForE911', false);

        _this3.clearElocation();

        var defaultMessage = _this3.get('intl').lookup('emergencyLocation.errors.e911LocationLookup', {
          number: _this3.get('primaryVoice.display')
        });

        var errorMessage = _this3.get('intl').lookup(_emergencyLocationUtil.default.getErrorString(err, defaultMessage));

        return Ember.RSVP.reject({
          err: err,
          softError: errorMessage
        });
      });
    },

    /**
     * Refreshes all emergency contact information properties:
     *     1 - eLocation
     *     2 - primaryVoice
     *     3 - isEligibleForE911
     * @return {promise} returns promise
     */
    refreshAllEmergencylocationInfo: function refreshAllEmergencylocationInfo() {
      var _this4 = this;

      this.set('eLocationInlineWarning', null);

      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return Ember.RSVP.resolve();
      }

      if (!this.get('isAuthenticated')) {
        return Ember.RSVP.resolve();
      }

      return this.loadUserPrimaryNumber().then(function (number) {
        if (number) {
          return _this4.refreshEmergencyLocation(number);
        }

        _this4.clearElocation();

        throw {
          err: 'Number not supported.',
          softError: _this4.get('intl').lookup('emergencyLocation.errors.e911NotEligible')
        };
      }).catch(function (errObject) {
        var messageBody = errObject.softError;

        if (messageBody && !_this4.get('eLocationInlineWarning')) {
          _this4.set('eLocationInlineWarning', messageBody);
        }

        return Ember.RSVP.reject(errObject);
      });
    },

    /**
     * Begins polling to refresh all eLocation data
     */
    startPolling: function startPolling() {
      var _this5 = this;

      if (_testing.isTesting) return;
      var service = this;

      var pollingIntervalInMinutes = _dates.default.duration(POLL_INTERVAL).minutes();

      var pollCounter = 0;
      var pollingIntervalId = setInterval(function () {
        _this5.get('logger').info("E911UI: Polling count: ".concat(pollCounter++, " - Polling time: ").concat((0, _dates.default)().format(), " - Polling every ").concat(pollingIntervalInMinutes, "min."));

        service._refreshAllEmergencylocationInfo();
      }, POLL_INTERVAL);
      this.set('pollingIntervalId', pollingIntervalId);
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('session.features.emergencyLocation')) {
        this.refreshAllEmergencylocationInfo().catch(function () {});
        this.startPolling();
      }
    },

    /**
     * Wrapper function to put in runloop
     */
    _refreshAllEmergencylocationInfo: function _refreshAllEmergencylocationInfo() {
      var _this6 = this;

      Ember.run.later(function () {
        _this6.refreshAllEmergencylocationInfo().catch(function () {});
      }, 100);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      clearInterval(this.get('pollingIntervalId'));
    },

    /**
     * Sets eLocation on backend
     * @param  {object} newAddress object
     * @param  {string} number number to pair with newAddress
     * @return {promise} returns promise
     */
    setElocation: function setElocation(newAddress, number) {
      var _this7 = this;

      var url = this.get('application').pcV2Uri("api/v2/carrierservices/integrations/emergencylocations/me");
      var options = {
        data: {
          did: number,
          address: newAddress,
          source: 'User'
        }
      };
      return this.get('ajax').post(url, options).catch(function (err) {
        var errorMessage = _this7.get('intl').lookup(_emergencyLocationUtil.default.getErrorString(err));

        return Ember.RSVP.reject({
          err: err,
          hardError: errorMessage
        });
      });
    },

    /**
     * Saves eLocation property on this service
     * @param  {object} newAddress object
     * @param  {string} number number to pair with newAddress
     * @return {promise} returns promise
     */
    saveElocation: function saveElocation(newAddress) {
      var _this8 = this;

      if (!this.get('session.features.emergencyLocation')) {
        return;
      }

      this.set('eLocationInlineWarning', null);
      return this.loadUserPrimaryNumber().then(function (number) {
        return _this8.setElocation(newAddress, number);
      }).then(function () {
        return _this8.refreshAllEmergencylocationInfo();
      });
    }
  });

  _exports.default = _default;
});