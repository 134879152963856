define("web-directory/components/acd-interactions-panel-v2/interaction-panel-sizer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Zjl28qY",
    "block": "{\"statements\":[[11,\"button\",[]],[15,\"type\",\"button\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onToggleTooltip\"],null],null],[15,\"class\",\"panel-resizer\"],[16,\"aria-label\",[33,[\"t\"],[\"purecloud.acdInteraction.panelResizer\"],null],null],[16,\"title\",[33,[\"t\"],[\"purecloud.acdInteraction.panelResizer\"],null],null],[13],[0,\"\\n    \"],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"menu-kebab-vertical\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[16,\"class\",[34,[\"sizing-tooltip \",[33,[\"if\"],[[33,[\"not\"],[[28,[\"showTooltip\"]]],null],\"hide\"],null]]]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"options\"]]],null,{\"statements\":[[0,\"        \"],[11,\"button\",[]],[15,\"type\",\"button\"],[16,\"class\",[34,[\"interactions-panel-sizing-choice clickable \",[33,[\"if\"],[[33,[\"eq\"],[[28,[\"currentPanelSize\"]],[28,[\"option\",\"size\"]]],null],\"selected\"],null]]]],[16,\"data-size\",[28,[\"option\",\"size\"]],null],[16,\"aria-label\",[33,[\"t\"],[[28,[\"option\",\"label\"]]],null],null],[16,\"title\",[33,[\"t\"],[[28,[\"option\",\"label\"]]],null],null],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"onSelectPanelSize\",[28,[\"option\",\"size\"]]],null],null],[13],[14],[0,\"\\n\"]],\"locals\":[\"option\"]},null],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/acd-interactions-panel-v2/interaction-panel-sizer/template.hbs"
    }
  });

  _exports.default = _default;
});