define("web-directory/components/entity-contact/component", ["exports", "ember-data", "lodash", "web-directory/mixins/promise-loading", "web-directory/mixins/flag", "ember-purecloud-components/mixins/logger"], function (_exports, _emberData, _lodash, _promiseLoading, _flag, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EntityContactComponent = Ember.Component.extend(_promiseLoading.default, _flag.default, _logger.default, {
    classNames: ['entity-contact'],
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    favorites: Ember.inject.service(),
    notification: Ember.inject.service(),
    addressformat: Ember.inject.service(),
    intl: Ember.inject.service(),
    phone: Ember.inject.service(),
    showPhone: false,
    showChat: false,
    showEmail: false,
    showFavorite: false,
    makingCall: false,
    shouldTether: false,
    contact: null,
    phoneNumber: null,
    email: null,
    callId: null,
    shouldDisplayDropdown: true,
    showDropdown: false,
    callConnecting: false,
    flaggingSupportEnabled: false,
    isRedial: false,
    tether: null,
    actions: {
      toggleFavorite: function toggleFavorite() {
        var _this = this;

        this.get('person').then(function (person) {
          _this.get('favorites').toggleFavorite(person);
        });
      },
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('showDropdown');

        if (this.get('showDropdown')) {
          var tether = this.get('tether');

          if (this.get('shouldTether') && !_lodash.default.isEmpty(tether)) {
            Ember.run.scheduleOnce('afterRender', tether, tether.position);
          }
        }
      },
      makeCall: function makeCall(address) {
        var _this2 = this;

        if (this.get('session.features.digitalFirstPURE2942') && !this.get('permissions.phoneEnabled')) {
          return;
        }

        if (!this.get('makingCall') && !this.get('showPhoneSpinner')) {
          this.set('showDropdown', false);
          this.set('makingCall', true);
          this.set('callConnecting', true);
          this.get('person').then(function (person) {
            if (address) {
              // Prefer action supplied phone number entry to makeCall.
              // User has clicked dropdown and selected a specific number.
              return _this2.get('phone').makeCall(address, person);
            } else if (!_lodash.default.isEmpty(person) && !(_this2.get('isExternalContact') || _this2.get('isGroup'))) {
              // Or call person by their guid
              // Calling a person back at their preferred number.
              return _this2.get('phone').makeCall(person);
            } else {
              // Otherwise call person by specified phone number to the component.
              // (call-history-entry, active-call-entry components)
              return _this2.get('phone').makeCall(_this2.get('phoneNumber'), person);
            }
          }).then(function (call) {
            return _this2.set('callId', call.id);
          }).catch(function () {
            return _this2.set('callConnecting', false);
          }).finally(function () {
            _this2.set('makingCall', false);
          });
        }
      },
      recordRecentContact: function recordRecentContact(type, value) {
        if (type === 'email') {
          window.top.location = 'mailto:' + value.data.value;
        } else if (type === 'voice' && !this.get('phone.enabled')) {
          if (this.get('session.features.digitalFirstPURE2942')) {
            return;
          }

          window.top.location = 'tel:' + value.data.rel.e164;
        }
      },
      sendToProfile: function sendToProfile() {
        var _this3 = this;

        this.get('person').then(function (person) {
          _this3.bubbleAction({
            action: 'transitionToEntityDetail',
            actionContext: [person]
          });

          _this3.set('showDropdown', false);
        });
      },
      flagInteraction: function flagInteraction(action) {
        this.flagInteractionAction(action);
      }
    },
    entityData: Ember.computed('contact.{id,name,isFavorite,type}', function () {
      return {
        id: this.get('contact.id'),
        name: this.get('contact.name'),
        isFavorite: this.get('contact.isFavorite'),
        type: this.get('contact.type')
      };
    }),
    isEntityFavoriteDecompEnabled: Ember.computed.readOnly('session.features.epcDecompEntityFavorite'),
    callConnected: Ember.observer('phone.activeCall', function () {
      var activeCall = this.get('phone.activeCall');

      if (activeCall) {
        var activeCallId = Ember.get(activeCall, 'id');
        var componentCallIsActiveCall = activeCallId === this.get('callId');

        if (componentCallIsActiveCall) {
          this.set('callConnecting', false);
        }
      }
    }),
    showPhoneSpinner: Ember.computed('makingCall', 'callConnecting', function () {
      return this.get('makingCall') || this.get('callConnecting');
    }),
    disablePhoneButton: Ember.computed.or('showPhoneSpinner', 'phone.webrtcPhoneWithIssues'),
    phoneNumberDetails: Ember.computed('phoneNumber', 'person', function () {
      if (this.get('person.name')) {
        return this.get('person.name');
      }

      return this.get('addressformat').formatAddressForDisplay(this.get('phoneNumber'));
    }),
    phoneServiceEnabled: Ember.computed('phone.{enabled,phoneDisabled,noStationSelected}', function () {
      return this.get('phone.enabled') && !this.get('phone.noStationSelected') && !this.get('phone.phoneDisabled');
    }),
    phoneEnabled: Ember.computed('showPhone', 'isExternalContact', 'phoneNumber', 'phone.phoneDisabled', function () {
      if (this.get('phone.phoneDisabled') || this.get('personIsDeleted')) {
        return false;
      }

      return this.get('showPhone') || (this.get('isExternalContact') || this.get('isGroup')) && !!this.get('phoneNumber');
    }),
    chatEnabled: Ember.computed('showChat', 'isExternalContact', 'permissions.canViewBusinessChat', function () {
      return this.get('permissions.canViewBusinessChat') && this.get('showChat') && !this.get('isExternalContact');
    }),
    emailEnabled: Ember.computed('showEmail', 'isExternalContact', 'email', function () {
      return this.get('showEmail') || this.get('isExternalContact') && !!this.get('email');
    }),
    favoriteEnabled: Ember.computed('showFavorite', 'isExternalContact', 'favorites.enabled', function () {
      return this.get('showFavorite') && !this.get('isExternalContact') && this.get('favorites.enabled');
    }),
    isExternal: Ember.computed('contact.participantType', function () {
      if (this.get('contact.type') === 'person' || this.get('isExternalContact')) {
        return false;
      }

      return !this.get('contact.purpose') || this.get('contact.purpose').toLowerCase() === 'external' || _lodash.default.isEmpty(this.get('contact.userId'));
    }),
    isExternalContact: Ember.computed('contact.type', function () {
      return this.get('contact.type') === 'externalContact';
    }),
    isGroup: Ember.computed('contact.type', function () {
      return this.get('contact.type') === 'group';
    }),
    setExternalContactInfo: Ember.on('didInsertElement', function () {
      if (this.get('isExternalContact')) {
        var phone = this.get('contact.contactInfo.phone');

        if (phone) {
          this.set('phoneNumber', "tel:".concat(phone[0].rel.e164));
        }

        var email = this.get('contact.contactInfo.email');

        if (email) {
          this.set('email', "mailto:".concat(email[0].value));
        }
      }
    }),
    setupGroupPhoneNumber: Ember.on('didInsertElement', function () {
      if (this.get('isGroup')) {
        Ember.run.scheduleOnce('afterRender', this, this.setGroupNumber);
      }
    }),
    enableFavoriteEvent: Ember.on('didInsertElement', function () {
      var _this4 = this;

      this.$().on('favoriteEvent', function () {
        _this4.get('person').then(function (person) {
          _this4.get('favorites').toggleFavorite(person);
        });
      });
    }),
    person: Ember.computed('contact.{userId,type,id}', function () {
      var type = this.get('contact.type');
      var id = this.get('contact.id');
      var userId = this.get('contact.userId');

      if (type && id) {
        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(this.get('contact'))
        });
      } else if (userId) {
        // This fall through will not be needed after public api v3 -- this looks up a user by GUID
        return this.store.find('person', userId);
      }

      return Ember.RSVP.resolve({});
    }),
    personIsDeleted: Ember.computed.equal('person.state', 'deleted'),
    hideChat: Ember.computed('contact.{id,userId}', 'session.person.id', function () {
      var id = this.get('contact.id') || this.get('contact.userId');
      return id === this.get('session.person.id');
    }),
    jid: Ember.computed('person.jid', 'contact.jid', function () {
      if (this.get('contact.type') === 'group') {
        return this.get('contact.jid');
      }

      return this.get('person.jid');
    }),
    phones: Ember.computed('person', 'isExternalContact', function () {
      var _this5 = this;

      if (this.get('isExternalContact')) {
        return this.get('contact.contactInfo.phone');
      } else {
        var promise = this.get('person').then(function (person) {
          return _this5.getContactFieldValuesByType(person, 'voice');
        });
        return _emberData.default.PromiseArray.create({
          promise: promise
        });
      }
    }),
    emails: Ember.computed('person', 'isExternalContact', function () {
      var _this6 = this;

      if (this.get('isExternalContact')) {
        return this.get('contact.contactInfo.email');
      } else {
        var promise = this.get('person').then(function (person) {
          _this6.getContactFieldValuesByType(person, 'email');
        });
        return _emberData.default.PromiseArray.create({
          promise: promise
        });
      }
    }),
    displayDropdown: Ember.computed('shouldDisplayDropdown', 'phones', 'emails', 'isExternalContact', function () {
      var hasEmail = !_lodash.default.isEmpty(this.get('emails'));
      var hasPhone = !_lodash.default.isEmpty(this.get('phones'));
      var canDisplay = this.get('shouldDisplayDropdown');
      var isContact = this.get('isExternalContact');
      return canDisplay && (!isContact || hasEmail || hasPhone);
    }),
    callTitle: Ember.computed(function () {
      return this.get('intl').t('tooltip.contactIcon.phone').toString();
    }),
    attachListeners: Ember.on('didInsertElement', function () {
      var id = this.get('elementId');
      Ember.$(window).on('click.' + id, Ember.run.bind(this, this.detectOutsideClick));
    }),
    setupTether: Ember.on('didInsertElement', function () {
      if (!this.get('shouldTether')) {
        return;
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        var tether = new window.Tether({
          element: this.$('.dropdown-menu')[0],
          target: this.$('.show-more')[0],
          attachment: 'top right',
          targetAttachment: 'bottom right',
          constraints: [{
            to: 'scrollParent',
            attachment: 'together',
            pin: true
          }]
        });
        this.set('tether', tether);
      });
    }),
    removeTether: function removeTether() {
      var tether = this.get('tether');

      if (tether) {
        tether.destroy();

        if (tether.element) {
          tether.element.remove();
        }
      }
    },
    removeListeners: function removeListeners() {
      Ember.$(window).off(".".concat(this.get('elementId')));
    },
    willDestroyElement: function willDestroyElement() {
      this.removeListeners();
      this.removeTether();

      this._super.apply(this, arguments);
    },
    detectOutsideClick: function detectOutsideClick(event) {
      var id = this.get('elementId');
      var target = Ember.$(event.target);

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (id && !target.parents('#' + id).length) {
        Ember.run.once(this, this.set, 'showDropdown', false);
      }
    },
    getContactFieldValuesByType: function getContactFieldValuesByType(person, type) {
      if (!person.getContactFieldValuesByType) {
        return [];
      }

      var items = person.getContactFieldValuesByType(type).map(function (item) {
        return {
          data: item
        };
      });
      var primary = person.getFieldValue("primaryContactInfo.".concat(type), true);

      if (!_lodash.default.isEmpty(primary)) {
        var primaryItem = _lodash.default.find(items, function (item) {
          return Ember.get(item.data, '_id') === Ember.get(primary, 'value.fieldId');
        });

        if (primaryItem) {
          primaryItem.primary = true;
        }
      }

      return _lodash.default.sortBy(items, function (item) {
        return item.primary;
      });
    },
    notifyError: function notifyError(key) {
      this.get('notification').show({
        target: this.get('phone.applicationController'),
        tag: 'callHistory',
        message: this.get('intl').t(key),
        type: 'error',
        hideAfter: false,
        actionHandlers: {
          reportError: this.get('intl').t('components.navMenu.feedback.contactSupport').toString()
        }
      });
    },
    setGroupNumber: function setGroupNumber() {
      var data = this.get('contact').getFieldValue('contactInfo.groupNumber');

      if (!_lodash.default.isEmpty(data)) {
        var number = this.get('addressformat').format(data);
        this.set('phoneNumber', "tel:".concat(number));
      }
    }
  });
  var _default = EntityContactComponent;
  _exports.default = _default;
});