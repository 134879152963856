define("web-directory/components/dial-pad/component", ["exports", "ember-purecloud-components/mixins/logger", "lodash/isEmpty", "lodash/uniqBy", "web-directory/utils/acd", "web-directory/utils/focus-management"], function (_exports, _logger, _isEmpty, _uniqBy, _acd, _focusManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DIALPAD_NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'];
  var DTMF_DELAY_MS = 500;

  function mapDialPadNumbers(numbers) {
    return numbers.map(function (num) {
      return {
        val: num,
        pressed: false
      };
    });
  }
  /**
   * provide a letter and return a number
   * used for key input to get dtmf number
   */


  var LETTER_TO_DIALPAD_NUMBER = {
    a: 2,
    b: 2,
    c: 2,
    d: 3,
    e: 3,
    f: 3,
    g: 4,
    h: 4,
    i: 4,
    j: 5,
    k: 5,
    l: 5,
    m: 6,
    n: 6,
    o: 6,
    p: 7,
    q: 7,
    r: 7,
    s: 7,
    t: 8,
    u: 8,
    v: 8,
    w: 9,
    x: 9,
    y: 9,
    z: 9,
    '+': 0
  };

  var _default = Ember.Component.extend(_logger.default, {
    layoutName: 'purecloud/panels/dialpad',
    classNames: ['dialpad'],
    phone: Ember.inject.service('phone'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    permissions: Ember.inject.service('permissions'),
    interaction: Ember.inject.service('interaction'),
    sidebar: Ember.inject.service('sidebar'),
    queueService: Ember.inject.service('queue'),
    webrtc: Ember.inject.service('webrtc'),
    addressformat: Ember.inject.service('addressformat'),

    /**
     * @attribute
     *
     * `true` if the call input should be visible
     */
    showCallInput: true,

    /**
     * @attribute
     */
    sendDtmf: false,

    /**
     * @attribute
     */
    showDialpadToggle: false,

    /**
     * @attribute
     */
    showTitle: false,

    /**
     * @attribute
     */
    disableCallOnBehalfOfQueue: false,

    /**
     * @attribute
     */
    addingParticipants: false,

    /**
     * @attribute
     */
    isAcdDTMF: false,

    /**
     * @attribute
     *
     * The currently selected queue to create the conversation with
     */
    queue: null,

    /**
     * @attribute
     *
     * `true` to show the dialpad in the DOM
     */
    showDialpad: false,

    /**
     * @attribute
     *
     * array of participants to use for creating the conversation with
     */
    participants: Ember.A([]),

    /**
     * @attribute
     */
    showStationPicker: false,

    /**
     * @attribute
     *
     * `true` if the user can make a call in the dialpad component. This will render
     * the phone button that invokes the `makeCall` logic.
     */
    showMakeCallButton: true,

    /**
     * @attribute
     *
     * `true` if the target dropdown logic should be supressed and the dropdown be hidden.
     * This should be used if the user has been tasked with added a recipient, but shouldn't
     * use the systems internal data to create the recipient, but rather just leveraging the input
     */
    hideTargetDropdown: false,

    /**
     * Array of all recipients mapped for the multi select component to easily consume it for
     * quick display
     */
    participantTags: Ember.computed.map('participants', function (participant) {
      return {
        value: participant.value,
        text: participant.name,
        data: {
          id: participant.id
        }
      };
    }),

    /**
     * Array of available filters to showcase in the queue target selector component.
     * We only want to showcase queue options.
     */
    queueFilter: ['queue'],
    value: null,
    dialpadValue: null,
    _cachedDialpadValue: null,
    isKeydownReset: true,
    numberInputMode: false,

    /**
     * @attribute
     */
    phoneNumbersCollection: [],
    addedPhoneNumbers: Ember.A([]),
    hasInvalidNumber: false,
    allowInvalidNumbers: false,
    queuedDTMF: null,
    dtmfTimer: null,
    numbers: mapDialPadNumbers(DIALPAD_NUMBERS),

    /**
     * the current value being searched in the search input
     */
    searchValue: '',

    /**
     * The current authenticated user from the session service
     */
    authUser: Ember.computed.readOnly('session.publicApiUser'),

    /**
     * @permission
     */
    canViewQueues: Ember.computed.readOnly('permissions.canViewQueue'),

    /**
     * @feature-toggle
     */
    newTagsInput: Ember.computed.readOnly('session.features.acdTagsInput'),
    participant: Ember.computed(function () {
      return Ember.Object.create({
        value: null,
        entity: null
      });
    }),
    multipleQueueParticipants: Ember.computed('participants.@each', function () {
      var participants = this.get('participants') || [];
      return participants.filter(function (p) {
        return Ember.get(p, 'entity.type') === 'queue';
      }).length > 1;
    }),
    phoneDisabled: Ember.computed('phone.{phoneDisabled,station.webRtcRequireMediaHelper}', function () {
      return this.get('phone.phoneDisabled') && !this.get('phone.station.webRtcRequireMediaHelper');
    }),
    isConferenceCall: Ember.computed('participants.[]', function () {
      return this.get('participants.length') > 1 && !this.get('addingParticipants');
    }),
    selfInParticipantList: Ember.computed('participants.[]', function () {
      var selfId = this.get('session.person.id');
      var participants = this.get('participants') || [];
      return participants.filter(function (p) {
        return p.id === selfId;
      }).length;
    }),
    enableCallOnBehalfOfQueue: Ember.computed('permissions.canGoOnQueue', 'sidebar.showAcdInteractions', 'isAcdDTMF', 'numberInputMode', 'disableCallOnBehalfOfQueue', function () {
      if (this.get('disableCallOnBehalfOfQueue')) {
        return false;
      }

      return this.get('permissions.canGoOnQueue') && this.get('sidebar.showAcdInteractions') && !this.get('isAcdDTMF') && !this.get('numberInputMode');
    }),
    disableAddBtn: Ember.computed('numberInputMode', 'hasInvalidNumber', 'addedPhoneNumbers.length', function () {
      return this.get('numberInputMode') && (this.get('hasInvalidNumber') || this.get('addedPhoneNumbers.length') === 0);
    }),
    displayNumberWarning: Ember.computed('participants.[]', 'searchValue', function () {
      var _this = this;

      var participants = this.get('participants') || [];
      var searchValue = this.get('searchValue');
      return searchValue && !this.get('addressformat').isValidNumber(searchValue, null, true) || participants.some(function (participant) {
        return (// we only check if the participant does not have an entity, meaning its a raw number/input
          !participant.entity && !_this.get('addressformat').isValidNumber(participant.value, null, true)
        );
      });
    }),
    disablePhoneButton: Ember.computed('phoneDisabled', 'phone.webrtcPhoneWithIssues', 'searchValue', 'participants.@each', 'queue', 'multipleQueueParticipants', 'selfInParticipantList', 'enableCallOnBehalfOfQueue', function () {
      var phoneDisabled = this.get('phoneDisabled') || this.get('phone.webrtcPhoneWithIssues');
      var multipleQueueParticipants = this.get('multipleQueueParticipants');
      var noParticipants = !!this.get('searchValue') ? false : this.get('participants.length') === 0;
      var selfInParticipantList = this.get('selfInParticipantList');
      var queueInUse = this.get('enableCallOnBehalfOfQueue');
      var emptyQueueField = queueInUse && !this.get('queue');
      return !!(phoneDisabled || noParticipants || multipleQueueParticipants || selfInParticipantList || emptyQueueField);
    }),
    shrinkDialpad: Ember.computed('sendDtmf', 'addingParticipants', function () {
      return !this.get('sendDtmf') && !this.get('addingParticipants');
    }),

    /**
     * @deprecated ember observers should not be used
     *
     * Focus the dialpad so we can listen to keydown events [PCUCC-89]
     */
    focusDialpad: Ember.on('init', Ember.observer('showDialpad', 'sendDtmf', function () {
      if (this.get('showDialpad') && this.get('sendDtmf')) {
        Ember.run.once(this, function () {
          var dialpadNumbersContainer = this.$('.dialpad-numbers-container');

          if (dialpadNumbersContainer) {
            dialpadNumbersContainer.focus();
          }
        });
      }
    })),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('queuedDTMF', []);
      this.set('phoneNumbersCollection', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('enableCallOnBehalfOfQueue')) {
        this.initQueue();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('participants').clear();
      this.get('addedPhoneNumbers').clear();

      if (this.get('dtmfTimer')) {
        Ember.run.cancel(this.get('dtmfTimer'));
      }
    },

    /**
     * Initializes and sets the default queue into the corresponding input
     */
    initQueue: function initQueue() {
      var _this2 = this;

      // this check was performed inside of the call-onbehalf component, so with the new work we need to set it here instead.
      if (!this.get('queue')) {
        this.get('queueService').getDefaultQueue('call').then(function (queue) {
          _this2.set('queue', queue);
        }).catch(function (error) {
          _this2.get('logger').error('There was an issue getting the default queue', error);
        });
      }
    },

    /**
     * Invoked when a new participant has been selected (or removed) and formats it
     * for the creation call request.
     *
     * @param target the new value to set the participant property to
     */
    participantSelected: function participantSelected(target) {
      var id = Ember.get(target, 'id');
      var phoneNumber = Ember.get(target, 'phoneNumber') || Ember.get(target, 'bestPhoneNumber');

      if (id) {
        var type = Ember.get(target, 'type');
        this.get('participants').pushObject({
          entity: type === 'external' ? {
            type: 'externalcontacts/contact'
          } : target,
          externalOrganization: Ember.get(target, 'externalOrganization'),
          id: Ember.get(target, 'id'),
          name: Ember.get(target, 'name') || phoneNumber,
          value: phoneNumber || id
        });
      } else {
        this.get('participants').pushObject({
          name: phoneNumber,
          value: phoneNumber
        });
      }

      this.actionValueChange(this.get('participants'));
    },
    dialDtmfNumber: function dialDtmfNumber(number) {
      var conversationId = undefined;
      var participantId = undefined;
      var validState = this.get('session.features.acdDialpadDialing') || this.get('session.useMultipanel') ? this.get('interaction.selectedInteraction.isConnected') || this.get('interaction.selectedInteraction.isDialing') : this.get('interaction.selectedInteraction.isConnected');

      if (this.get('phone.isInteractionViewOpen') && this.get('interaction.selectedInteraction.isCall') && validState) {
        conversationId = this.get('interaction.selectedInteraction.id');
        participantId = this.get('interaction.selectedInteraction.participantForCurrentUser.id');
      } else if (this.get('phone.activeCall')) {
        conversationId = this.get('phone.activeCall.id');
        participantId = this.get('phone.activeCall.myself.id');
      }

      this.get('queuedDTMF').push(number); // Needed for functional tests to inform when the dial should occur

      this.logger.info('sendingDtmfTones', {
        conversationId: conversationId,
        participantId: participantId,
        number: number
      });
      this.set('dtmfTimer', Ember.run.debounce(this, this._dialDtmfNumber, conversationId, participantId, DTMF_DELAY_MS));
    },
    resetKeydown: function resetKeydown() {
      this.set('isKeydownReset', true);
    },

    /**
     * Handle keydown events when dialpad is open
     * PCUCC-89
     */
    handleKeydown: function handleKeydown(event) {
      if (!this.get('isKeydownReset') || !this.get('sendDtmf')) {
        return;
      }

      if (event.type === 'keydown') {
        // prevent holding key
        // unless it's the shift key
        if (!event.shiftKey) {
          this.set('isKeydownReset', false);
        } // translate the keydown to a digit


        var key = parseInt(event.key, 10);

        if (isNaN(key)) {
          // key may be '*' or '#' or '+'
          key = event.key.toLowerCase();
        }

        var number = this.get('numbers').find(function (num) {
          return num.val === key;
        }); // check if key exists

        if (number !== undefined) {
          Ember.set(number, 'pressed', true); // call the action to handle DTMF

          this.send('onAddNumber', key);
        } else if (LETTER_TO_DIALPAD_NUMBER[key] !== undefined) {
          number = this.get('numbers').find(function (num) {
            return num.val === LETTER_TO_DIALPAD_NUMBER[key];
          });

          if (number !== undefined) {
            Ember.set(number, 'pressed', true); // call the action to handle DTMF

            this.send('onAddNumber', LETTER_TO_DIALPAD_NUMBER[key]);
          }
        } else if (event.code !== undefined && event.code.toLowerCase() === 'escape') {
          // close dialpad if user presses escape key
          this.send('onToggleDialpad');
        } // remove pressed


        Ember.run.later(function () {
          if (number !== undefined) {
            Ember.set(number, 'pressed', false);
          }
        }, DTMF_DELAY_MS);
      }
    },
    _dialDtmfNumber: function _dialDtmfNumber(currentCallId, participantId) {
      var numbers = this.get('queuedDTMF').join('');
      this.set('queuedDTMF', []);

      if (numbers) {
        this.get('phone').dialDtmfNumber(currentCallId, participantId, numbers);
      }
    },
    _bulkAddParticipants: function _bulkAddParticipants(participants) {
      var _this3 = this;

      var phone = this.get('phone');
      return phone.bulkAddParticipants(participants).then(function () {
        _this3._reset();
      }).catch(function (error) {
        _this3.logger.error('Could not join participants into conference call.', error);

        _this3._showCallError('purecloud.error.dialpad.participants');
      });
    },
    _determineMakeCall: function _determineMakeCall() {
      if (this.get('disablePhoneButton')) {
        return;
      }

      this.logger.debug('DIALPAD:', this.get('addingParticipants'));
      var queue = this.get('queue');

      if (this.get('addingParticipants')) {
        var participants = this.get('participants') || [];
        var number = (this.get('value') || '').trim();

        if (participants !== null && participants.length === 0 && !(0, _isEmpty.default)(number)) {
          participants = [{
            entity: null,
            value: number
          }];
        }

        var search = this.get('searchValue');

        if (participants != null && search) {
          var formatted = this.get('addressformat').formatAddress(search);
          participants.push({
            entity: null,
            value: formatted ? formatted : search
          });
        }

        Ember.run.throttle(this, this._bulkAddParticipants, participants, 2000);
      } else if (this.get('participants.length') >= 2) {
        var _participants = this.get('participants');

        Ember.run.throttle(this, this._makeConferenceCall, _participants, queue, 2000);
      } else if (this.get('participants.length') <= 1) {
        var _number = this.get('searchValue');

        var party = this.get('participants.firstObject');
        var contactId;

        if (party) {
          var entity = party.entity,
              id = party.id;
          var entityExists = entity;
          var entityNotContact = entityExists && entity.type !== 'externalcontacts/contact';
          _number = entityExists && entityNotContact ? party.entity : party.value;
          contactId = entityExists && !entityNotContact ? id : undefined;
        }

        var isValidNumber = _number && (typeof _number !== 'string' || _number.trim().length);

        if (!isValidNumber) {
          return;
        }

        Ember.run.throttle(this, this._makeCall, _number, queue, contactId, 2000);
        var focusTarget = '#interactions-heading';
        (0, _focusManagement.doFocus)(this, focusTarget);
      }
    },
    _makeConferenceCall: function _makeConferenceCall(participants, queue) {
      var _this4 = this;

      var phone = this.get('phone');
      return phone.makeConferenceCall(participants, queue).then(function () {
        _this4._reset();

        if (_this4.get('enableCallOnBehalfOfQueue')) {
          _this4.get('queueService').setDefaultQueue(queue, 'call');
        }
      }).catch(function (error) {
        _this4.logger.error('Could not make call.', error);

        _this4._showCallError('purecloud.error.dialpad.call');
      });
    },
    _makeCall: function _makeCall(number, queue, externalContactId) {
      var _this5 = this;

      var phone = this.get('phone');
      return phone.makeCall(number, null, queue, externalContactId).then(function () {
        _this5._reset();

        if (_this5.get('enableCallOnBehalfOfQueue')) {
          _this5.get('queueService').setDefaultQueue(queue, 'call');
        }
      }).catch(function (error) {
        _this5.logger.error('Could not make call to #{number}.', error);

        _this5._showCallError('purecloud.error.dialpad.call', error);
      });
    },

    /**
     * Resets the user driven properties on this component back to their initial state
     */
    _reset: function _reset() {
      this.get('participants').clear();
      this.actionValueChange(this.get('participants'));
      this.set('searchValue', '');
      this.set('queue', null);
      this.actionClose();
    },
    _showCallError: function _showCallError(key) {
      var message = this.get('intl').lookup(key);
      this.get('phone').showFeedbackErrorNotification(message);
    },

    /**
     * @action
     * @optional
     *
     * Invoked when the value of the input has changed. Defaults to no-op.
     * Adding lint ignore since this action is optional, we don't want to warn the console
     * when it fired. It's treated as a no-op in this case.
     *
     * @param { any[] } _value the value to invoke through the action
     */
    // eslint-disable-next-line no-unused-vars
    actionValueChange: function actionValueChange(_value) {},

    /**
     * @action
     *
     * closure action called when the dialpad component intends to close itself
     */
    actionClose: function actionClose() {
      return (0, _acd.warnClosureAction)('actionClose');
    },

    /**
     * @action
     */
    openStationPicker: function openStationPicker() {
      return (0, _acd.warnClosureAction)('openStationPicker');
    },
    actions: {
      /**
       * Invoked when a queue has been selected by the user
       *
       * @param {object} queue the selected queue
       */
      onQueueSelected: function onQueueSelected(queue) {
        this.set('queue', queue);
      },

      /**
       * Invoked when a participant has been selected
       *
       * @param participant the selected participant
       */
      onParticipantSelected: function onParticipantSelected(participant) {
        this.participantSelected(participant);
      },

      /**
       * Invoked when a participant has been removed
       *
       * @param participant the participant to remove
       */
      onParticipantRemoved: function onParticipantRemoved(participant) {
        // An ember array is needed here for change detection to work properly
        var participants = Ember.A(this.get('participants') || []);
        var index = participants.findIndex(function (r) {
          return r.value === participant.value;
        });

        if (index >= 0) {
          this.get('participants').removeAt(index, 1);
          this.actionValueChange(this.get('participants'));
        }
      },

      /**
       * Removes the corresponding participant record based on the index provided
       *
       * @param {number} index the index of the participant to remove
       */
      onParticipantRemoveByIndex: function onParticipantRemoveByIndex(index) {
        this.get('participants').removeAt(index, 1);
      },

      /**
       * Invoked when the user has submitted the form and is ready to create the interaction.
       * Intended to be used for when the user pressed the enter key while in an empty search bar
       */
      onSubmit: function onSubmit() {
        this._determineMakeCall();
      },
      onHandleKeydown: function onHandleKeydown(event) {
        this.handleKeydown(event);
      },
      onResetKeydown: function onResetKeydown() {
        this.resetKeydown();
      },
      onToggleDialpad: function onToggleDialpad() {
        this.toggleProperty('showDialpad');
      },
      onAddNumber: function onAddNumber(number) {
        if (!this.get('phone.phoneDisabled')) {
          if (this.get('phone.dtmf') && this.get('sendDtmf')) {
            this.dialDtmfNumber(number);
          }

          if (this.get('numberInputMode')) {
            this.set('dialpadValue', number);
          } else {
            var searchValue = this.get('searchValue');
            searchValue += "".concat(number);
            this.set('searchValue', searchValue);
          }
        }
      },
      onUpdateDialpadValue: function onUpdateDialpadValue(value) {
        if (!value) {
          this.set('value', '');
        } else {
          this.set('value', value);

          if (this.get('numberInputMode') && this.get('hasInvalidNumber')) {
            this.set('hasInvalidNumber', false);
          }
        }
      },
      onMakeCall: function onMakeCall() {
        this._determineMakeCall();
      },
      onCollectPhoneNumbers: function onCollectPhoneNumbers() {
        var addedPhoneNumbers = this.get('addedPhoneNumbers');
        var newNumbers = (addedPhoneNumbers || []).map(function (entry) {
          return {
            name: entry.id,
            number: entry.value
          };
        });
        var mergedArray = this.get('phoneNumbersCollection').concat(newNumbers);

        if (!this.get('hasInvalidNumber')) {
          this.set('phoneNumbersCollection', (0, _uniqBy.default)(mergedArray, 'number'));
          this.get('addedPhoneNumbers').clear();
          this.actionClose();
        }
      },
      onCancelCollectPhoneNumbers: function onCancelCollectPhoneNumbers() {
        this.get('addedPhoneNumbers').clear();
        this.set('hasInvalidNumber', false);
        this.actionClose();
      },
      openStationPicker: function openStationPicker() {
        this.openStationPicker();
      }
    }
  });

  _exports.default = _default;
});