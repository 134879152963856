define("web-directory/components/preferences-notifications/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Component = Ember.Component;

  function preferenceDefaults() {
    return {
      notify: false,
      notificationPrompt: true,
      showGroupNotifications: false
    };
  }

  var _default = Component.extend({
    classNames: ['preferences-notifications'],
    chat: inject.service(),
    application: inject.service(),
    preferences: inject.service(),
    permissions: inject.service(),
    settings: inject.service(),
    session: inject.service(),
    webrtcSettings: inject.service(),
    canViewChat: Ember.computed.reads('permissions.canViewBusinessChat'),
    allowDisableProfileNotifications: Ember.computed.readOnly('session.features.webrtcDisableDeviceProfileNotifications'),
    disableProfileNotifications: Ember.computed.reads('webrtcSettings.settings.directorySettings.disableProfileNotifications'),
    showCheckBrowser: false,
    hideSoundSelector: false,
    loading: false,
    actions: {
      sendTestNotification: function sendTestNotification() {
        this.get('chat').sendTestNotification();
      },
      changeSetting: function changeSetting(key) {
        this.toggleProperty(key);

        if (key === 'showGroupNotifications') {
          return this.saveGroupNotifyChange();
        } else {
          return this.saveNotifyChange();
        }
      },
      changeProfileNotificationSetting: function changeProfileNotificationSetting() {
        if (!this.get('allowDisableProfileNotifications')) {
          return;
        }

        this.toggleProperty('disableProfileNotifications');
        this.get('webrtcSettings').updateSetting('directorySettings.disableProfileNotifications', this.get('disableProfileNotifications'));
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('loading', true);
      this.loadPreferences().then(function () {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.set('loading', false);
        });
      });
    },
    loadPreferences: function loadPreferences() {
      var _this2 = this;

      return this.get('preferences').getPreference('notifications.global').then(function () {
        var preferences = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : preferenceDefaults();

        _this2.setProperties(preferences);
      });
    },
    saveNotifyChange: function saveNotifyChange() {
      var preferences = this.get('preferences');
      var permission = Notification.permission;
      var notify = this.get('notify');
      var notificationPreferences = this.getProperties(['notify', 'notificationPrompt', 'showGroupNotifications']);

      if (notify && (permission === 'denied' || permission === 'default')) {
        window.Notify.requestPermission();
        this.set('showCheckBrowser', true);
      } else {
        this.set('showCheckBrowser', false);
      }

      return preferences.getPreference('notifications.global').then(function () {
        var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        prefs = Object.assign({}, prefs, notificationPreferences);
        return preferences.setPreference('notifications.global', prefs);
      });
    },
    saveGroupNotifyChange: function saveGroupNotifyChange() {
      var _this3 = this;

      if (!this.get('notify')) {
        this.set('showGroupNotifications', false);
      }

      var preferences = this.get('preferences');
      return preferences.getPreference('notifications.global').then(function () {
        var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        prefs.showGroupNotifications = _this3.get('showGroupNotifications');
        return preferences.setPreference('notifications.global', prefs);
      });
    }
  });

  _exports.default = _default;
});