define("web-directory/components/embedded-admin/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger", "web-directory/components/embedded-admin/helpers", "boon-js"], function (_exports, _lodash, _logger, _helpers, _boonJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, Ember.Evented, {
    classNames: ['admin-v2'],
    adminNav: Ember.inject.service(),
    adminui: Ember.inject.service(),
    intl: Ember.inject.service(),
    engage: Ember.inject.service(),
    session: Ember.inject.service(),
    sidebar: Ember.inject.service(),
    frameService: Ember.inject.service('center-stage-frame'),
    navigationSubMenuFeatureToggleEnabled: Ember.computed.readOnly('session.features.epcDecompNavigationSubMenu'),
    name: 'admin',
    path: null,
    currentRoute: null,
    currentPageCrumbs: null,
    isHome: computed('breadcrumbs', function () {
      // Flag for showing the expand/collapse toggle in the breadcrumb bar
      return _lodash.default.find(this.get('breadcrumbs.crumbs') || [], {
        key: 'ADMIN_HOME'
      });
    }),
    breadcrumbs: computed('location', 'adminui.menuJSON', 'currentPageCrumbs', function () {
      var breadcrumbs = this.get('adminui').getMenuBreadcrumbs(this.get('location'));
      var currentPageCrumbs = this.get('currentPageCrumbs');

      if (currentPageCrumbs) {
        Ember.set(breadcrumbs, 'crumbs', _lodash.default.union(breadcrumbs.crumbs, currentPageCrumbs));
      } else if (!Ember.isEmpty(breadcrumbs) && !Ember.isEmpty(breadcrumbs.crumbs)) {
        // always remove link from last breadcrumb
        var lastBreadcrumb = _lodash.default.last(breadcrumbs.crumbs);

        if (Object.prototype.hasOwnProperty.call(lastBreadcrumb, 'hash')) {
          Ember.set(lastBreadcrumb, 'hash', false);
        }
      }

      return breadcrumbs;
    }),
    ensureRouteVisibility: function ensureRouteVisibility() {
      this.get('sidebar').ensureRouteVisibility();
    },
    showFreeTrialBanner: computed('session.freeTrial', function () {
      return this.get('session.freeTrial') && this.get('session.freeTrial.startTimestamp') && !this.get('session.freeTrial.endTimestamp');
    }),
    freeTrialDaysRemaining: computed('session.freeTrial', function () {
      if (this.get('showFreeTrialBanner')) {
        return String(this.get('session.freeTrial.daysRemaining'));
      } else {
        return '0';
      }
    }),
    navStructure: computed('session.isAuthenticated', 'adminui.adminEntries', 'adminNav.fullNav', 'session.features', function () {
      var _this = this;

      var adminNav; // Make deep copies in order to not manulate the object for anyone else

      if (this.get('session.isAuthenticated')) {
        adminNav = Ember.copy(this.get('adminNav.fullNav'), true);
      } else {
        adminNav = Ember.copy(this.get('adminui.adminEntries'), true);
      } // Add direct links for entries that match a center stage item and the toggle (if set) is turned on


      var isItemFoundInFrameService = function isItemFoundInFrameService(route) {
        var frameService = _this.get('frameService');

        var routeFeatureToggle = frameService.toggleForPath(route);
        var stopOldAdmin = routeFeatureToggle && routeFeatureToggle.useRedirctFromOldAdmin ? routeFeatureToggle.useRedirctFromOldAdmin : false;
        var routeToggleOn = routeFeatureToggle === null ? true : (0, _boonJs.getEvaluator)(routeFeatureToggle.key)(_this.get('session.features'));
        return !!(routeToggleOn && frameService.baseRouteForPath(route) || stopOldAdmin);
      };

      adminNav = updateKnownCenterStageClientLinks(adminNav, isItemFoundInFrameService);
      return adminNav;
    }),
    location: computed('path', 'adminui.adminPath', function () {
      var queryPath = this.get('path');
      var adminPath = this.get('adminui.adminPath');

      if (!adminPath && queryPath) {
        return 'directory/people';
      } else {
        return adminPath;
      }
    }),
    src: computed('location', 'navStructure', function () {
      var location = this.get('location');
      var navStructure = this.get('navStructure');
      var current = null;

      if (!navStructure.length) {
        return;
      }

      _lodash.default.forOwn(navStructure, function (heading) {
        _lodash.default.forOwn(heading.subLinks, function (item) {
          current = (0, _helpers.getIfNavLinkMatches)(location, item) || current;
        });
      });

      var src = null;

      if (current) {
        if (current.emberRoute) {
          this.sendAction('gotoRoute', current.emberRoute);
          return;
        }

        if (typeof current.basePath === 'undefined') {
          current.basePath = 'admin';
        }

        if (!current.subRoute) {
          current.subRoute = location;
        }

        src = "/".concat(current.basePath);
        src += "/#/".concat(location);

        if (current.basePath === 'admin') {
          src += '?context=directory&showMenu=false';
        }

        var queryPath = this.get('path');

        if (queryPath) {
          src += "&modal=".concat(queryPath);
        }

        var queryParams = window.location.hash.match(/\?.*/); // Add locale as queryparam for altocloud

        if (_lodash.default.includes(src, '/journey/admin') || _lodash.default.includes(src, '/journey-micro/')) {
          var locale = this.get('intl.isoLocale');
          var localParam = "locale=".concat(encodeURIComponent(locale));

          if (!queryParams) {
            queryParams = ['?' + localParam];
          } else if (!_lodash.default.includes(queryParams[0], localParam)) {
            queryParams[0] += '&' + localParam;
          }
        }

        if (queryParams !== null && queryParams[0].length) {
          var existingParams = src.match(/\?.*/);

          if (existingParams !== null && existingParams[0].length) {
            queryParams[0] = queryParams[0].replace('?', '&');
          }

          src += queryParams[0];
        }
      } else {
        if (window.location.href.match(/onboarding/)) {
          _lodash.default.forOwn(navStructure, function (heading) {
            _lodash.default.forOwn(heading.subLinks, function (item) {
              if (item.href.match(/onboarding/)) {
                current = item;
                return false;
              }
            });
          });

          var p = window.location.href.replace(/.*onboarding/, '');
          src = '/onboarding/#' + p;
        } else {
          // No item match in menu.json or onboarding, src it to admin-v1 and let it handle routing
          return '/admin/#/' + location + '?context=directory&showMenu=false';
        }
      }

      this.set('currentRoute', current);
      return src;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.startMessageListener();
      this.get('engage').on('navTreeUpdate', this, this.sendNavTree);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.stopMessageListener();
      this.get('engage').off('navTreeUpdate');
    },
    handleNavigationMessage: function handleNavigationMessage(data) {
      data.data.hash = data.data.hash.replace(/^#/, '');
      var currentRoute = this.get('currentRoute');
      var hash = '#/admin';

      if (currentRoute && currentRoute.routePrefix) {
        hash += currentRoute.routePrefix;
      }

      hash += "".concat(data.data.hash);
      window.location.hash = hash;

      var title = _lodash.default.get(data.data, 'title');

      if (title) {
        document.title = title;
      }

      if (!_lodash.default.get(data, 'data.skip')) {
        this.set('currentPageCrumbs', null); // clear page crumbs if there's a route change
      }
    },
    handleMessageEvent: function handleMessageEvent(event) {
      var _this2 = this;

      Ember.run(function () {
        try {
          if (!event || !event.originalEvent) {
            return;
          }

          var _event$originalEvent = event.originalEvent,
              source = _event$originalEvent.source,
              data = _event$originalEvent.data;

          if (!source) {
            return;
          }

          if (source.name !== _this2.get('name')) {
            return;
          }

          if (!source.document || !source.document.location) {
            return;
          }

          var location = source.document.location;

          if (location.host !== window.location.host) {
            return;
          }

          if (!data) {
            return;
          }

          switch (data.type) {
            case 'content:route:changed':
              {
                Ember.run.debounce(_this2, _this2.handleNavigationMessage, data, 250);
                break;
              }

            case 'content:adminui:breadcrumbs:changed':
              {
                if (!_lodash.default.isEmpty(data.data)) {
                  _lodash.default.each(data.data, function (crumb) {
                    if (Object.prototype.hasOwnProperty.call(crumb, 'hash') && crumb.hash && !crumb.hash.match(/^#\/admin/)) {
                      Ember.set(crumb, 'hash', crumb.hash.replace(/^#/, '#/admin')); // replace admin-ui route with web-directory admin route prefix
                    }
                  });

                  _this2.set('currentPageCrumbs', data.data);
                }

                break;
              }

            case 'toast':
              {
                _this2.get('adminui').toast(data.data);

                break;
              }

            case 'request:engageNavTree':
              {
                _this2.sendNavTree();

                break;
              }
          }
        } catch (error) {
          if (error && error.name === 'SecurityError') {
            return;
          }

          Ember.Logger.error('Error handling frame message:', {
            error: error
          });
        }
      });
    },
    startMessageListener: function startMessageListener() {
      var _this3 = this;

      var event = "message.".concat(this.elementId);

      var handle = function handle(event) {
        return _this3.handleMessageEvent(event);
      };

      Ember.$(window).on(event, handle);
    },
    stopMessageListener: function stopMessageListener() {
      var event = "message.".concat(this.elementId);
      Ember.$(window).off(event);
    },
    sendMessage: function sendMessage(type, data) {
      var iframe = this.$('iframe')[0];
      var message = {
        type: type,
        data: data
      };
      this.logger.debug('Sending message type', type, data);
      iframe.contentWindow.postMessage(message, window.location);
    },
    sendNavTree: function sendNavTree() {
      var navTree = this.get('engage').navTree;
      this.sendMessage('directory:engageNavTree:changed', navTree);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments); // Move sidebar menu toggle button to breadcrumbs bar


      var $navMenu = this.get('navigationSubMenuFeatureToggleEnabled') ? $('.admin-v2 .navigation-sub-menu-v2:not(.is-nested)') : $('.admin-v2 .navigation-sub-menu:not(.is-nested)');
      var $breadcrumbBar = $('.breadcrumb-bar');

      if ($navMenu && $navMenu.length > 0 && !$($navMenu[0]).hasClass('is-hidden')) {
        var $buttonRowElement = $($navMenu[0]).find('.action-row');
        $buttonRowElement.removeClass('hidden');

        if ($breadcrumbBar && $breadcrumbBar.length > 0) {
          $breadcrumbBar.find('.action-row').remove();
          $breadcrumbBar.prepend($buttonRowElement.clone());
          $buttonRowElement.addClass('hidden');
        }
      } else if ($breadcrumbBar && $breadcrumbBar.length > 0) {
        $breadcrumbBar.find('.action-row').remove();
      }
    }
  });

  _exports.default = _default;

  var updateKnownCenterStageClientLinks = function updateKnownCenterStageClientLinks() {
    var navMenu = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var findInCenterStage = arguments.length > 1 ? arguments[1] : undefined;
    navMenu.forEach(function (nav) {
      if (nav.subLinks) {
        nav.subLinks = updateKnownCenterStageClientLinks(nav.subLinks, findInCenterStage);
      }

      var route = '/admin/' + nav.href;

      if (findInCenterStage(route)) {
        // Make the nav menu not use link-to and instead supply the full url.
        // This way we can be routed correctly to the center-stage-router and not the admin one
        nav.direct = true;
        nav.href = new URL('#' + route, window.location).toString();
      }
    });
    return navMenu;
  };
});