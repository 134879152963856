define("web-directory/components/toggle-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "04kckDRJ",
    "block": "{\"statements\":[[1,[33,[\"app-img\"],null,[[\"app\",\"imgType\",\"titleMode\"],[[28,[\"itemConfig\"]],\"icon\",\"none\"]]],false],[0,\"\\n\"],[6,[\"if\"],[[28,[\"attentionStarburst\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-icon\",{\"attrs\":[[15,\"class\",\"unread-badge icon-certificate\"],[15,\"icon-name\",\"notification\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"if\"],[[28,[\"itemConfig\",\"name\"]]],null,{\"statements\":[[0,\"    \"],[9,\"gux-tooltip\",{\"attrs\":[[16,\"for\",[34,[[26,[\"emberId\"]]]]],[15,\"placement\",\"bottom\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n        \"],[11,\"span\",[]],[15,\"aria-hidden\",\"true\"],[13],[1,[28,[\"itemConfig\",\"name\"]],false],[14],[0,\"\\n    \"]]}],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/toggle-item/template.hbs"
    }
  });

  _exports.default = _default;
});