define("web-directory/services/fullscreen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      Service = Ember.Service;

  var _default = Service.extend(Ember.Evented, {
    session: inject.service(),
    isFullScreen: false,
    handleFullScreenChange: function handleFullScreenChange() {
      var _this = this;

      Ember.run(function () {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
          var fullScreen = document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement;

          _this.set('isFullScreen', !!fullScreen);

          if (!_this.get('isFullScreen')) {
            _this.endFullScreen();
          }
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(document).on('webkitfullscreenchange', this.handleFullScreenChange.bind(this));
      Ember.$(document).on('fullscreenchange', this.handleFullScreenChange.bind(this));
      Ember.$(document).on('mozfullscreenchange', this.handleFullScreenChange.bind(this));
      Ember.$(document).on('MSFullscreenChange', this.handleFullScreenChange.bind(this));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      Ember.$(document).off('webkitfullscreenchange', this.handleFullScreenChange.bind(this));
      Ember.$(document).off('fullscreenchange', this.handleFullScreenChange.bind(this));
      Ember.$(document).off('mozfullscreenchange', this.handleFullScreenChange.bind(this));
      Ember.$(document).off('MSFullscreenChange', this.handleFullScreenChange.bind(this));
    },
    startFullScreen: function startFullScreen() {
      var isAcd = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var requestingElem = arguments.length > 1 ? arguments[1] : undefined;
      //Ensure messengerElement in under orgspan-app element to support toast notification while in fullscreen
      var messengerElement = Ember.$('ul.messenger');
      var orgspanApp = Ember.$('.orgspan-app');

      if (!Ember.$.contains(orgspanApp, messengerElement)) {
        messengerElement.appendTo(orgspanApp);
      }

      var hostedContext = this.get('applicationService.hostedContext');

      if (hostedContext && hostedContext.isHosted()) {
        $('.orgspan-app').addClass('hosted-fullscreen');
      } else {
        var elem = requestingElem ? $(requestingElem)[0] : $('.orgspan-app')[0];
        var requestFullscreen = elem.requestFullscreen || elem.webkitRequestFullscreen || elem.mozRequestFullScreen || elem.msRequestFullscreen;
        requestFullscreen.apply(elem);
      }

      if (!isAcd) {
        $('.orgspan-app').removeClass('fullscreen-v1');
        $('.orgspan-app').addClass('fullscreen-v2');
      } else {
        $('.orgspan-app').removeClass('fullscreen-v2');
        $('.orgspan-app').addClass('fullscreen-v1');
      }

      this.trigger('startFullscreen');
    },
    endFullScreen: function endFullScreen() {
      var hostedContext = this.get('applicationService.hostedContext');

      if (hostedContext && hostedContext.isHosted()) {
        $('.orgspan-app').removeClass('hosted-fullscreen');
      } else {
        var exitFullscreen = document.exitFullscreen || document.webkitExitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || window.msExitFullscreen;
        var ret = exitFullscreen.apply(document);

        if (ret && ret.catch) {
          ret.catch(function () {});
        }
      }

      this.trigger('endFullscreen');
    }
  });

  _exports.default = _default;
});