define("web-directory/components/application-preferences/preferences-sound/component", ["exports", "ember-webrtc-components/mixins/webrtc-service/auto-save-devices", "ember-purecloud-components/mixins/logger"], function (_exports, _autoSaveDevices, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var sounds = {
    phone: 'phone',
    ringer: 'phone',
    chat: 'chat',
    interaction: 'interaction',
    digital: 'digital'
  };
  var deviceVolumePreferencePath = 'deviceVolumes';
  var debounceTimeout = 500;

  var _default = Ember.Component.extend(_autoSaveDevices.default, _logger.default, {
    classNames: ['sound-preferences'],
    phone: Ember.inject.service(),
    sound: Ember.inject.service(),
    chat: Ember.inject.service(),
    preferences: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissions: Ember.inject.service(),
    webrtcSettings: Ember.inject.service(),
    ring: Ember.inject.service(),
    volt: Ember.inject.service(),
    minCallVolume: 10,
    minPhoneRingVolume: 0,
    minChatNotificationVolume: 0,
    minInteractionNotificationVolume: 10,
    defaultVolume: 100,
    callVolume: 100,
    ringerVolume: 100,
    chatNotificationVolume: 100,
    interactionNotificationVolume: 100,
    videoNotificationVolume: 100,
    soundRunLater: null,
    advancedMicSettingsExpanded: false,
    skipServerUpdates: true,
    outputDevices: Ember.computed.reads('webrtc.outputDeviceList'),
    currentOutputDevice: null,
    autoGain: Ember.computed.alias('settings.generalSettings.advancedMicSettings.autoGain'),
    echoCancellation: Ember.computed.alias('settings.generalSettings.advancedMicSettings.echoCancellation'),
    noiseSuppression: Ember.computed.alias('settings.generalSettings.advancedMicSettings.noiseSuppression'),
    notificationOutputPicker: Ember.computed.reads('session.features.notificationOutputPicker'),
    hasOutputDevices: Ember.computed('outputDevices.[]', function () {
      return this.get('outputDevices').length > 0;
    }),
    ringtones: Ember.computed.reads('sound.ringtones'),
    settings: Ember.computed.reads('webrtcSettings.settings'),
    ringtoneSelection: Ember.computed.alias('settings.generalSettings.ringtoneSelection'),
    videoIcon: Ember.computed('videoNotificationVolume', function () {
      return this.get('videoNotificationVolume') === 0 ? 'video-disable' : 'video';
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentOutputDevice', this.get('webrtc.defaultOutputDevice'));

      try {
        this.loadVolumesForCurrentDevice();
      } catch (err) {
        Ember.Logger.error(err);
      }

      this.updateOutputTranslations();
    },
    micPermissionGranted: Ember.computed.reads('webrtc.hasMicPermission'),
    ringerIsMuted: Ember.computed('ringerVolume', function () {
      return this.get('ringerVolume') === 0;
    }),
    chatNotificationIsMuted: Ember.computed('chatNotificationVolume', function () {
      return this.get('chatNotificationVolume') === 0;
    }),
    currentDeviceSanitizedLabel: Ember.computed('currentOutputDevice', function () {
      var currentDevice = this.get('currentOutputDevice');

      if (!currentDevice) {
        return '';
      }

      if (currentDevice.get('deviceId') === 'default') {
        return 'default';
      }

      var deviceLabel = currentDevice.get('label');
      var sanatizedLabel = this.get('webrtc').sanitizeDeviceLabel(deviceLabel) || '';

      if (sanatizedLabel.indexOf('webrtcDevices.default') > -1) {
        sanatizedLabel = this.get('intl').t('preferences.sound.systemDefault');
      }

      return sanatizedLabel;
    }),
    currentDevicePreferencePath: Ember.computed('currentOutputDevice', function () {
      var sanitizedLabel = this.get('currentDeviceSanitizedLabel');
      return "".concat(deviceVolumePreferencePath, ".").concat(sanitizedLabel);
    }),
    currentVolumes: Ember.computed('callVolume', 'ringerVolume', 'chatNotificationVolume', 'interactionNotificationVolume', function () {
      return {
        callVolume: this.get('callVolume'),
        ringerVolume: this.get('ringerVolume'),
        chatNotificationVolume: this.get('chatNotificationVolume'),
        interactionNotificationVolume: this.get('interactionNotificationVolume')
      };
    }),
    outputDeviceChanged: Ember.observer('currentOutputDevice', function () {
      this.loadVolumesForCurrentDevice();
    }),
    phoneVolumeChange: Ember.observer('callVolume', 'ringerVolume', 'skipServerUpdates', function () {
      var currentVolumes = this.get('currentVolumes');
      var preferences = this.get('preferences');
      var devicePreferencePath = this.get('currentDevicePreferencePath'); // this is so we don't send 4 server updates when we change the selected device

      if (!this.get('skipServerUpdates')) {
        preferences.setPreference(devicePreferencePath, currentVolumes);
        this.get('webrtcSettings').updateSetting("volumeSettings.".concat(this.get('currentDeviceSanitizedLabel')), this.get('currentVolumes'));
      }

      if (this._shouldUpdatePhoneVolumes()) {
        var newPhoneVolumes = {
          callVolume: currentVolumes.callVolume,
          ringerVolume: currentVolumes.ringerVolume
        };
        this.setAppVolumes(newPhoneVolumes);
      }
    }),
    notificationVolumeChange: Ember.observer('chatNotificationVolume', 'interactionNotificationVolume', 'skipServerUpdates', function () {
      var currentVolumes = this.get('currentVolumes');
      var preferences = this.get('preferences');
      var devicePreferencePath = this.get('currentDevicePreferencePath'); // this is so we don't send 4 server updates when we change the selected device

      if (!this.get('skipServerUpdates')) {
        preferences.setPreference(devicePreferencePath, currentVolumes);
        this.get('webrtcSettings').updateSetting("volumeSettings.".concat(this.get('currentDeviceSanitizedLabel')), this.get('currentVolumes'));
      }

      if (this._shouldUpdateNotificationVolumes()) {
        var newNotificationVolumes = {
          chatNotificationVolume: currentVolumes.chatNotificationVolume,
          interactionNotificationVolume: currentVolumes.interactionNotificationVolume
        };
        this.setAppVolumes(newNotificationVolumes);
      }
    }),
    // Video's join/leave notification volume.
    videoNotificationVolumeChange: Ember.observer('videoNotificationVolume', function () {
      this.set('settings.voltSettings.notificationVolume', this.get('videoNotificationVolume'));
      this.get('webrtcSettings').updateSettings(this.get('settings'));
    }),
    _shouldUpdatePhoneVolumes: function _shouldUpdatePhoneVolumes() {
      var selectedDevice = this.get('currentOutputDevice');
      var currentWebrtcDevice = this.get('webrtc.defaultOutputDevice');
      var selectedDeviceIsCurrentOutput = currentWebrtcDevice ? selectedDevice.get('deviceId') === currentWebrtcDevice.get('deviceId') : false;
      return selectedDeviceIsCurrentOutput;
    },
    _shouldUpdateNotificationVolumes: function _shouldUpdateNotificationVolumes() {
      var usingSingleDeviceForSoundOutput = !this.get('notificationOutputPicker');
      var selectedDevice = this.get('currentOutputDevice');
      var currentWebrtcDevice;

      if (usingSingleDeviceForSoundOutput) {
        currentWebrtcDevice = this.get('webrtc.defaultOutputDevice');
      } else {
        currentWebrtcDevice = this.get('webrtc.defaultNotificationDevice');
      }

      var selectedDeviceIsCurrentOutput = currentWebrtcDevice ? selectedDevice.get('deviceId') === currentWebrtcDevice.get('deviceId') : false;
      return selectedDeviceIsCurrentOutput;
    },
    setAppVolumes: function setAppVolumes(volumes) {
      var phone = this.get('phone');

      if (this.volumeValueIsDefined(volumes.callVolume)) {
        phone.set('callVolume', volumes.callVolume);
      }

      if (this.volumeValueIsDefined(volumes.ringerVolume)) {
        phone.set('ringerVolume', volumes.ringerVolume);
      }

      var chat = this.get('chat');
      var chatVolumes = {};

      if (this.volumeValueIsDefined(volumes.chatNotificationVolume)) {
        chatVolumes.chatNotificationVolume = volumes.chatNotificationVolume;
      }

      if (this.volumeValueIsDefined(volumes.interactionNotificationVolume)) {
        this.set('ring.interactionVolume', volumes.interactionNotificationVolume);
        chatVolumes.interactionNotificationVolume = volumes.interactionNotificationVolume;
      }

      chat.updateVolumes(chatVolumes, this.get('skipServerUpdates'));
    },
    volumeValueIsDefined: function volumeValueIsDefined(volumeValue) {
      return volumeValue !== null && volumeValue !== undefined;
    },
    loadVolumesForCurrentDevice: function loadVolumesForCurrentDevice() {
      var _this = this;

      var haveDevice = this.currentDeviceIsValid();

      if (!haveDevice) {
        return;
      }

      var promise;
      this.logger.debug('using device volumes from webrtc settings endpoint');
      promise = Ember.RSVP.resolve(this.get("webrtcSettings.settings.volumeSettings.".concat(this.get('currentDeviceSanitizedLabel'))));

      if (this.get('settings.voltSettings.notificationVolume') || this.get('settings.voltSettings.notificationVolume') === 0) {
        this.set('videoNotificationVolume', this.get('settings.voltSettings.notificationVolume'));
      }

      return promise.then(function (volumes) {
        _this.set('skipServerUpdates', true);

        if (!volumes) {
          _this.logger.info('no device volumes saved, using defaults');

          _this.send('resetAllVolumes');
        } else {
          var callVolume = volumes.callVolume !== undefined ? volumes.callVolume : _this.get('defaultVolume');
          var ringerVolume = volumes.ringerVolume !== undefined ? volumes.ringerVolume : _this.get('defaultVolume');
          var chatNotificationVolume = volumes.chatNotificationVolume !== undefined ? volumes.chatNotificationVolume : _this.get('defaultVolume');
          var interactionNotificationVolume = volumes.interactionNotificationVolume !== undefined ? volumes.interactionNotificationVolume : _this.get('defaultVolume');

          _this.setProperties({
            callVolume: callVolume,
            ringerVolume: ringerVolume,
            chatNotificationVolume: chatNotificationVolume,
            interactionNotificationVolume: interactionNotificationVolume
          });
        }

        _this.set('skipServerUpdates', false);
      });
    },
    currentDeviceIsValid: function currentDeviceIsValid() {
      var currentDevice = this.get('currentOutputDevice');

      var validDevice = this._validDevice(currentDevice);

      if (!validDevice) {
        this.setCurrentDeviceToFirstInDeviceList();
        currentDevice = this.get('currentOutputDevice');
        validDevice = this._validDevice(currentDevice);
      }

      return validDevice;
    },
    _validDevice: function _validDevice(webrtcDevice) {
      if (!webrtcDevice) {
        return false;
      }

      var deviceHasIdAndLabel = !!webrtcDevice.get('deviceId') && !!webrtcDevice.get('label');
      return deviceHasIdAndLabel;
    },
    setCurrentDeviceToFirstInDeviceList: function setCurrentDeviceToFirstInDeviceList() {
      var currentDevice = this.get('outputDevices')[0];

      if (!currentDevice) {
        currentDevice = this.getSystemDefaultDevice();
      }

      this.set('currentOutputDevice', currentDevice);
    },
    getSystemDefaultDevice: function getSystemDefaultDevice() {
      // Create a fake device to represent the system default for browsers that don't let us enumerate the output devices
      return Ember.Object.create({
        deviceId: 'SystemDefault',
        label: this.get('intl').t('preferences.sound.systemDefault')
      });
    },
    updateOutputTranslations: function updateOutputTranslations() {
      this.get('webrtc').enumerateDevices();
    },
    updateAdvancedMicSettings: function updateAdvancedMicSettings() {
      this.get('webrtcSettings').updateSetting('generalSettings.advancedMicSettings', this.get('settings.generalSettings.advancedMicSettings'));
    },
    updatePropDebounced: function updatePropDebounced(prop, val) {
      if (this.isDestroyed || this.isDestroying) return;
      this.set(prop, val);
    },
    actions: {
      toggleAdvancedMicSettings: function toggleAdvancedMicSettings() {
        this.toggleProperty('advancedMicSettingsExpanded');
      },
      toggleAutoGain: function toggleAutoGain(ev) {
        if (ev.key && ev.key !== 'Enter' && ev.key !== ' ') {
          return;
        }

        var value = this.toggleProperty('autoGain');
        this.updateAdvancedMicSettings();
        this.logger.info('Sound Preference: Mic Gain changed', {
          value: value
        });
      },
      toggleNoiseSuppression: function toggleNoiseSuppression(ev) {
        if (ev.key && ev.key !== 'Enter' && ev.key !== ' ') {
          return;
        }

        var value = this.toggleProperty('noiseSuppression');
        this.updateAdvancedMicSettings();
        this.logger.info('Sound Preference: Noise Suppression changed', {
          value: value
        });
      },
      toggleEchoCancellation: function toggleEchoCancellation(ev) {
        if (ev.key && ev.key !== 'Enter' && ev.key !== ' ') {
          return;
        }

        var value = this.toggleProperty('echoCancellation');
        this.updateAdvancedMicSettings();
        this.logger.info('Sound Preference: Echo Cancellation changed', {
          value: value
        });
      },
      changeOutputDevice: function changeOutputDevice(event) {
        var id = event.target.value;

        if (this.get('currentOutputDevice.deviceId') !== id) {
          var newDevice = this.get('webrtc.outputDeviceList').findBy('deviceId', id);
          this.set('currentOutputDevice', newDevice);
        }
      },
      playCallVolumeSound: function playCallVolumeSound() {
        var callVolume = this.get('callVolume');

        if (this.get('ringtoneSelection')) {
          this._playSound(this.get('ringtoneSelection'), callVolume);
        } else {
          this._playSound(sounds.phone, callVolume);
        }
      },
      playRingerSound: function playRingerSound() {
        var ringerVolume = this.get('ringerVolume');

        if (this.get('ringtoneSelection')) {
          this._playSound(this.get('ringtoneSelection'), ringerVolume);
        } else {
          this._playSound(sounds.phone, ringerVolume);
        }
      },
      playChatNotificationSound: function playChatNotificationSound() {
        var chatNotificationVolume = this.get('chatNotificationVolume');

        this._playSound(sounds.chat, chatNotificationVolume);
      },
      playInteractionNotificationSound: function playInteractionNotificationSound() {
        var interactionNotificationVolume = this.get('interactionNotificationVolume');

        this._playSound(sounds.digital, interactionNotificationVolume);
      },
      toggleRingerMute: function toggleRingerMute() {
        this.toggleMute('ringerVolume');
      },
      toggleChatSoundMute: function toggleChatSoundMute() {
        this.toggleMute('chatNotificationVolume');
      },
      toggleCallVolumeMinVolume: function toggleCallVolumeMinVolume() {
        var currentVolume = this.get('callVolume');

        if (currentVolume === this.get('minCallVolume')) {
          this.set('callVolume', this.get('defaultVolume'));
        } else {
          var minVolume = this.get('minCallVolume');
          this.set('callVolume', minVolume);
        }
      },
      toggleInteractionNotificationVolumeMinVolume: function toggleInteractionNotificationVolumeMinVolume() {
        var currentVolume = this.get('interactionNotificationVolume');

        if (currentVolume === this.get('minInteractionNotificationVolume')) {
          this.set('interactionNotificationVolume', this.get('defaultVolume'));
        } else {
          var minVolume = this.get('minInteractionNotificationVolume');
          this.set('interactionNotificationVolume', minVolume);
        }
      },
      selectCallVolume: function selectCallVolume(e) {
        Ember.run.debounce(this, this.updatePropDebounced, 'callVolume', e.target.value, debounceTimeout);
      },
      selectRingerVolume: function selectRingerVolume(e) {
        Ember.run.debounce(this, this.updatePropDebounced, 'ringerVolume', e.target.value, debounceTimeout);
      },
      selectChatNotificationVolume: function selectChatNotificationVolume(e) {
        Ember.run.debounce(this, this.updatePropDebounced, 'chatNotificationVolume', e.target.value, debounceTimeout);
      },
      selectInteractionNotificationVolume: function selectInteractionNotificationVolume(e) {
        Ember.run.debounce(this, this.updatePropDebounced, 'interactionNotificationVolume', e.target.value, debounceTimeout);
      },
      selectVideoNotificationVolume: function selectVideoNotificationVolume(e) {
        Ember.run.debounce(this, this.updatePropDebounced, 'videoNotificationVolume', e.target.value, debounceTimeout);
      },
      resetAllVolumes: function resetAllVolumes() {
        var defaultVolume = this.get('defaultVolume');
        this.set('callVolume', defaultVolume);
        this.set('ringerVolume', defaultVolume);
        this.set('chatNotificationVolume', defaultVolume);
        this.set('interactionNotificationVolume', defaultVolume);
        this.set('videoNotificationVolume', defaultVolume);

        if (this._shouldUpdatePhoneVolumes()) {
          this.setAppVolumes({
            callVolume: defaultVolume,
            ringerVolume: defaultVolume
          });
        }

        if (this._shouldUpdateNotificationVolumes()) {
          this.setAppVolumes({
            chatNotificationVolume: defaultVolume,
            interactionNotificationVolume: defaultVolume
          });
        }
      },
      changeRingtoneSelection: function changeRingtoneSelection(event) {
        var ringtoneSelection = event.target.value;
        var previewVolume = this.get('ringerVolume');

        this._playSound(ringtoneSelection, previewVolume);

        if (!this.get('settings.generalSettings')) {
          this.set('settings.generalSettings', {
            ringtoneSelection: ringtoneSelection
          });
        } else {
          this.set('settings.generalSettings.ringtoneSelection', ringtoneSelection);
        }

        this.get('webrtcSettings').updateSettings(this.get('settings'));
      },
      toggleVideoNotificationVolume: function toggleVideoNotificationVolume() {
        var currentVolume = this.get('videoNotificationVolume');

        if (currentVolume === 0) {
          this.set('videoNotificationVolume', this.get('defaultVolume'));
        } else {
          this.set('videoNotificationVolume', 0);
        }
      },
      // Video's join/leave notification sound.
      playVideoNotificationSound: function playVideoNotificationSound() {
        this.get('volt').playVideoNotificationPreview(this.get('currentOutputDevice'));
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.stopAllSounds();
    },
    stopAllSounds: function stopAllSounds() {
      var _this2 = this;

      for (var s in sounds) {
        this.get('sound').stopSound(sounds[s]);
      }

      if (this.get('ringtoneSelection')) {
        this.get('ringtones').forEach(function (ringtone) {
          _this2.get('sound').stopSound(ringtone.id);
        });
      } // Stops the Ember.run.later in _playSound


      Ember.run.cancel(this.get('soundRunLater'));
    },
    toggleMute: function toggleMute(volumeSetting) {
      if (this.get(volumeSetting) === 0) {
        this.set(volumeSetting, this.get('defaultVolume'));
      } else {
        this.set(volumeSetting, 0);
      }
    },
    _playSound: function _playSound(sound, volume) {
      var volumeDecimalValue = volume / 100;

      if (volumeDecimalValue > 0) {
        var outputDeviceId = this.get('currentOutputDevice.deviceId') !== 'SystemDefault' ? this.get('currentOutputDevice.deviceId') : null;
        this.get('sound').playSound(sound, volumeDecimalValue, outputDeviceId);
        var runLater = Ember.run.later(this, function () {
          this.get('sound').stopSound(sound);
        }, 2500);
        this.set('soundRunLater', runLater);
      }
    }
  });

  _exports.default = _default;
});