define("web-directory/models/person", ["exports", "ember-data", "lodash", "ember-purecloud-components/utils/jid-helpers", "web-directory/mixins/entity", "web-directory/mixins/chat-user", "web-directory/mixins/entity-presence", "web-directory/models/group"], function (_exports, _emberData, _lodash, _jidHelpers, _entity, _chatUser, _entityPresence, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FULL_PERSON = _exports.BASE_PERSON_FIELDS = _exports.BASE_PERSON_EXPANDS = _exports.BASE_PERSON = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /* /platform/api/v2/users/{id}/profile (or /platform/api/v2/profiles/users) default field list (fl query parameter) */
  var BASE_PERSON_FIELDS = ['extMongoId', 'general', 'contactInfo', 'primaryContactInfo', 'images.profile', 'version'];
  /* /platform/api/users expand query parameter list */

  _exports.BASE_PERSON_FIELDS = BASE_PERSON_FIELDS;
  var BASE_PERSON_EXPANDS = ['routingStatus', 'primaryPresence', 'conversationSummary', 'outOfOffice', 'geolocation', 'presence', 'locations', 'authorization'];
  _exports.BASE_PERSON_EXPANDS = BASE_PERSON_EXPANDS;
  var BASE_PERSON = {
    fl: BASE_PERSON_FIELDS.join(','),
    expand: BASE_PERSON_EXPANDS.join(',')
  };
  _exports.BASE_PERSON = BASE_PERSON;
  var FULL_PERSON = {
    fl: '*',
    expand: BASE_PERSON_EXPANDS.join(',')
  };
  _exports.FULL_PERSON = FULL_PERSON;
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  var permissionRoutes = ['person.detail', 'person.detail.index', 'card.detail']; // We need to change how data gets merged in Ember-Data to support our field
  // config...not sure of a way around this...

  function merge(original, updates) {
    _lodash.default.forEach(updates, function (sectionValue, sectionKey) {
      if (_typeof(sectionValue) !== 'object' || Array.isArray(sectionValue)) {
        original[sectionKey] = sectionValue;
        return;
      }

      _lodash.default.forEach(sectionValue, function (fieldValue, fieldKey) {
        if (!original[sectionKey]) {
          original[sectionKey] = {};
        }

        Ember.set(original[sectionKey], fieldKey, fieldValue);
      });
    });
  }

  function clearProperties(original, updates, changedKeys, type) {
    var originalKeys = Object.keys(original);
    originalKeys.forEach(function (sectionKey) {
      if (updates[sectionKey] === undefined) {
        set(original, sectionKey, {});
        changedKeys.push(sectionKey);
      } else {
        if (type === 'group') {
          var sectionData = original[sectionKey];
          var fieldKeys = Object.keys(sectionData);
          fieldKeys.forEach(function (fieldKey) {
            var pathKey = "".concat(sectionKey, ".").concat(fieldKey);

            if (get(updates, pathKey) === undefined) {
              set(original, pathKey, {});
              changedKeys.push(pathKey);
            }
          });
        }
      }
    });
  }
  /**
   * @param {object | number} contactCenter contact center conversations
   * @param {object | number} enterprise enterprise  conversations
   * @returns {number} The sum of the active conversations for the two arguments, or 0.
   */


  function activityCounter(contactCenter, enterprise) {
    var contactCenterCount = _typeof(contactCenter) === 'object' ? contactCenter.active : contactCenter || 0;
    var enterpriseCount = _typeof(enterprise) === 'object' ? enterprise.active : enterprise || 0;
    return contactCenterCount + enterpriseCount;
  }

  var Internal = _emberData.default.InternalModel.prototype;
  var _setupData = Internal.setupData;

  Internal.setupData = function (data) {
    var type = data.modelName || data.type;

    if (type === 'person' || type === 'group') {
      var changedKeys = [];

      if (this.hasRecord) {
        changedKeys = this._changedKeys(data.attributes);
      }

      merge(this._data, data.attributes); // Partial reloading is not a thing for people/groups
      // So we've gotta clear the data that no longer exists

      if (this.isReloading) {
        clearProperties(this._data, data.attributes, changedKeys, type);
      }

      this.pushedData();
      var record = this.record || this._record;

      if (record) {
        record._notifyProperties(changedKeys);
      }

      if (this.didInitializeData) {
        this.didInitializeData();
      }
    } else {
      _setupData.apply(this, arguments);
    }
  };

  var PersonModel = _emberData.default.Model.extend(_entity.default, _chatUser.default, _entityPresence.default, {
    ajax: Ember.inject.service(),
    search: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    preferences: Ember.inject.service(),
    permissions: Ember.inject.service(),
    presenceSubscription: Ember.inject.service(),
    _readWritePermissions: null,
    _directReports: null,
    _siblings: null,
    _superiors: null,
    namedPermissions: Ember.computed.reads('application.session.namedPermissions'),
    // attributes guaranteed to be on models
    parent: _emberData.default.attr(),
    path: _emberData.default.attr(),
    children: _emberData.default.attr(),
    roleIds: _emberData.default.attr(),
    expands: _emberData.default.attr(),
    version: _emberData.default.attr(),
    divisionId: _emberData.default.attr(),
    _activities: computed.alias('expands'),
    activities: computed.alias('_activities'),
    // private values just used to simplify computed functions
    _activityCall: computed.reads('activities.conversationSummary.call'),
    _activityCallback: computed.reads('activities.conversationSummary.callback'),
    _activityChat: computed.reads('activities.conversationSummary.message'),
    _activityWebChat: computed.reads('activities.conversationSummary.chat'),
    _activityEmail: computed.reads('activities.conversationSummary.email'),
    guid: computed.reads('id'),
    // this computed is used in ember-purecloud-components (EPC) addon
    // to reduce changes across deprecated projects, we are defaulting this to true.
    // Once EPC is removed, we can remove this property.
    showConversationSummaryIndicator: true,
    presenceList: null,
    // We will want to switch completely to 'default' as the only default presence source
    // but will be blocked until the Presence Service is only returning 'default'.
    currentPresenceSource: 'PURECLOUD',
    init: function init() {
      this._super.apply(this, arguments);

      this.set('presenceList', Ember.A());
      this.set('personDetail', Ember.getOwner(this).lookup('controller:person/detail'));

      if (this.onProfilePage()) {
        this.get('readWritePermissions');
      } // convert current presence source to 'default'


      this.set('currentPresenceSource', 'default');
    },
    totalCalls: computed('_activityCall', function () {
      var contactCenter = this.get('_activityCall.contactCenter.active');
      var enterprise = this.get('_activityCall.enterprise.active');
      return activityCounter(contactCenter, enterprise);
    }),
    totalCallbacks: computed('_activityCallback', function () {
      var contactCenter = this.get('_activityCallback.contactCenter.active');
      var enterprise = this.get('_activityCallback.enterprise.active');
      return activityCounter(contactCenter, enterprise);
    }),
    totalChats: computed('_activityChat', function () {
      var contactCenter = this.get('_activityChat.contactCenter.active');
      var enterprise = this.get('_activityChat.enterprise.active');
      return activityCounter(contactCenter, enterprise);
    }),
    totalWebChats: computed('_activityWebChat', function () {
      var contactCenter = this.get('_activityWebChat.contactCenter.active');
      var enterprise = this.get('_activityWebChat.enterprise.active');
      return activityCounter(contactCenter, enterprise);
    }),
    totalEmails: computed('_activityEmail', function () {
      var contactCenter = this.get('_activityEmail.contactCenter.active');
      var enterprise = this.get('_activityEmail.enterprise.active');
      return activityCounter(contactCenter, enterprise);
    }),
    activityValues: computed('totalCalls', 'totalCallbacks', 'totalChats', 'totalEmails', function () {
      return {
        calls: this.get('totalCalls') + this.get('totalCallbacks'),
        emails: this.get('totalEmails'),
        chats: this.get('totalChats') + this.get('totalWebChats')
      };
    }),
    title: Ember.computed('general.title.@each.value', function () {
      var field = this.getFieldValue('general.title');
      return field === undefined ? '' : Ember.get(field, 'value');
    }),
    department: Ember.computed('general.department.@each.value', function () {
      var field = this.getFieldValue('general.department');
      return field === undefined ? '' : Ember.get(field, 'value');
    }),
    email: Ember.computed('primaryContactInfo', function () {
      var field = this.getFieldValue('primaryContactInfo.email');
      return field === undefined ? '' : Ember.get(field, 'value');
    }),
    email_main: Ember.computed('contactInfo.email_main', function () {
      var field = this.getFieldValue('contactInfo.email_main');
      return field === undefined ? '' : Ember.get(field, 'value');
    }),
    phone: Ember.computed('primaryContactInfo', function () {
      var field = this.getFieldValue('primaryContactInfo.voice');
      return field === undefined ? '' : Ember.get(field, 'value');
    }),
    integrationPhone: Ember.computed('contactInfo.phone_other', function () {
      var otherPhones = this.get('contactInfo.phone_other');

      if (otherPhones && otherPhones.length > 0) {
        return otherPhones.find(function (phone) {
          return phone && phone.value && phone.value.integration;
        });
      }

      return null;
    }),
    agentName: Ember.computed('agent', function () {
      var field = this.getFieldValue('agent.name');
      return field === undefined ? '' : Ember.get(field, 'value');
    }),
    uploadedProfilePictures: Ember.computed('uploads.images', function () {
      var field = this.getFieldValue('uploads.images');

      if (field && field.length && field[0].value) {
        return field;
      }

      return [];
    }),
    uploadedInformalPhotos: Ember.computed('images.informal', function () {
      var field = this.getFieldValue('images.informal');

      if (field && field.length && field[0].value) {
        return field;
      }

      return [];
    }),
    socialGroups: Ember.computed('groups.social.[]', function () {
      return this.getGroupsByType('groups.social');
    }),
    officialGroups: Ember.computed('groups.official.[]', function () {
      return this.getGroupsByType('groups.official');
    }),
    distributionGroups: Ember.computed('purecloud.distributionGroups', function () {
      return this.getGroupsByType('purecloud.distributionGroups');
    }),
    ownedGroups: Ember.computed('id', function () {
      var _this = this;

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('search').performSearch(_this.get('guid'), 0, 'groups', [], ['owners.owners.guid'], 'EXACT', [], 100).then(function (_ref) {
          var results = _ref.results;

          if (results) {
            resolve(results);
          } else {
            resolve([]);
          }
        }).catch(function (err) {
          reject(err);
        });
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    manager: Ember.computed('relationships.manager.[]', 'relationships.manager.@each.value', function () {
      var field = this.getFieldValue('relationships.manager');
      var managerId = Ember.get(field, 'guid') || Ember.get(field, 'value');
      var promise;

      if (managerId) {
        promise = this.get('store').findRecord('person', managerId, BASE_PERSON);
      } else {
        promise = Ember.RSVP.resolve({});
      }

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    managerSiblings: Ember.computed('manager', 'manager.isFulfilled', function () {
      var field = this.getFieldValue('relationships.manager');
      var managerId = Ember.get(field, 'value');
      var promise;

      if (managerId) {
        promise = this.get('manager').then(function (person) {
          return person.get('siblings');
        }).then(function (siblings) {
          if (siblings.get('length')) {
            return siblings.filterBy('state', 'active');
          }

          return [];
        });
      } else {
        promise = Ember.RSVP.resolve([]);
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    otherRelationships: Ember.computed('relationships.relationships.[]', function () {
      var values = this.getFieldValue('relationships.relationships');
      var ids = values.mapBy('value');
      var promise = Ember.RSVP.resolve([]);

      if (ids && !_lodash.default.isEmpty(ids) && ids[0]) {
        promise = this.get('store').findByIds('person', ids, BASE_PERSON);
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    groupedOtherRelationships: Ember.computed('relationships.relationships', function () {
      var fieldValue = this.getFieldValue('relationships.relationships');

      if (!fieldValue) {
        return Ember.RSVP.resolve([]);
      }

      var ids = fieldValue.mapBy('value');
      var promise;

      if (ids && !_lodash.default.isEmpty(ids) && ids[0]) {
        promise = this.store.findByIds('person', ids).then(function (persons) {
          // Group relationships by label key.
          var groupedRelationships = [];

          _lodash.default.each(fieldValue, function (personEntry) {
            var person = persons.findBy('id', personEntry.value);

            if (person.get('state') === 'deleted') {
              return;
            }

            var relationship = groupedRelationships.findBy('labelKey', personEntry.labelKey);

            if (relationship) {
              relationship.people.push(person);
            } else {
              groupedRelationships.push({
                labelKey: personEntry.labelKey,
                i18nKey: 'person_relationships_relationships_labelKey_' + personEntry.labelKey.replace('.', '_'),
                people: [person]
              });
            }
          });

          return groupedRelationships;
        });
      } else {
        promise = Ember.RSVP.resolve([]);
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    adminAssistants: Ember.computed('relationships.administrativeAssistant.[]', function () {
      var _this2 = this;

      if (this.get('_adminAssistantsRequest')) {
        return this.get('_adminAssistantsRequest');
      }

      var promise = Ember.RSVP.resolve([]);
      var field = this.getFieldValue('relationships.administrativeAssistant');

      if (!field) {
        return _emberData.default.PromiseArray.create({
          promise: promise
        });
      }

      if (typeof field.mapBy !== 'function') {
        Ember.Logger.warn('adminAssistants had unexpected value', {
          field: field
        });
        return _emberData.default.PromiseArray.create({
          promise: promise
        });
      }

      var ids = field.mapBy('value');
      var cachedData = this.get('_adminAssistants'); // Check cached data and resolve with it if it matches

      if (!!cachedData && cachedData.length > 0) {
        var cachedIds = Ember.A(cachedData).mapBy('id');

        if (cachedIds.length === ids.length) {
          var sameIds = cachedIds.filter(function (id) {
            return ids.includes(id);
          });

          if (sameIds.length === cachedIds.length) {
            return _emberData.default.PromiseArray.create({
              promise: Ember.RSVP.resolve(cachedData)
            });
          }
        }
      }

      if (ids && !_lodash.default.isEmpty(ids) && ids[0]) {
        promise = this.store.findMany('person', ids, BASE_PERSON);
      }

      var promiseArray = _emberData.default.PromiseArray.create({
        promise: promise
      });

      this.set('_adminAssistantsRequest', promiseArray);
      promiseArray.then(function (results) {
        _this2.set('_adminAssistants', results);
      }).finally(function () {
        return _this2.set('_adminAssistantsRequest', null);
      });
      return promiseArray;
    }),
    superiors: Ember.computed('_superiors', 'path.[]', 'relationships.manager.[]', 'relationships.manager.@each.value', function () {
      var _this3 = this;

      if (this._loadingSuperiors && this.get('_superiors')) {
        return this.get('_superiors');
      }

      var cache = this.get('_superiors');

      if (cache) {
        return cache.then(function (people) {
          var manager = _this3.getFieldValue('relationships.manager');

          if (!manager || _lodash.default.isEmpty(manager)) {
            _this3.set('_superiors', null);

            return [];
          } else if (people.length === 0 && manager.value) {
            _this3.set('_superiors', null);

            return _this3.store.findSuperiors('person', _this3.get('id'), BASE_PERSON).finally(function () {
              return _this3._loadingSuperiors = false;
            });
          }

          return people;
        });
      }

      this._loadingSuperiors = true;
      var superiors = this.store.findSuperiors('person', this.get('id'), BASE_PERSON).finally(function () {
        return _this3._loadingSuperiors = false;
      });
      this.set('_superiors', superiors);
      return superiors;
    }),
    directReports: Ember.computed('children.[]', function () {
      var _this4 = this;

      if (this.get('_currentReportsRequest')) {
        return _emberData.default.PromiseArray.create({
          promise: this.get('_currentReportsRequest')
        });
      }

      var id = this.get('id');
      this.get('logger').info('Refreshing direct reports:', {
        id: id
      });
      var promise = Ember.RSVP.resolve([]);
      var children = this.get('children');

      if (!Array.isArray(children)) {
        children = [];
      }

      var directReportCount = this.getFieldValue('relationships.directReportCount');
      var cachedReports = this.get('_directReports.content') || [];
      var attemptedToLoad = !!this.get('_directReports'); // Check for the existence of the children prop

      var dataDiscrepancy = !_lodash.default.isEmpty(this.get('children')) && children.length !== directReportCount.value;
      var noDirectReports = directReportCount.value === 0 && cachedReports.length === 0;
      var doNotLoad = dataDiscrepancy ? children.length === 0 : noDirectReports && attemptedToLoad;

      var cachedIsSame = _lodash.default.isEqual(Ember.A(children).sort(), Ember.A(cachedReports).mapBy('id').sort());

      if (doNotLoad) {
        this.get('logger').info('No direct reports needed to be loaded:', {
          id: id
        });
      } else if (cachedIsSame && this.get('_directReports')) {
        this.get('logger').info('Resolving with cached direct reports:', {
          id: id
        });
        promise = this.get('_directReports') || Ember.RSVP.resolve([]);
      } else {
        this.get('logger').info('Loading fresh direct reports', {
          id: id
        });
        promise = this.store.findDirectReports('person', this.get('id'), BASE_PERSON);
        this.set('_directReports', _emberData.default.PromiseArray.create({
          promise: promise
        })); // We don't use the promise array because we want to keep cached results
        // and request tracking separate (don't want to clear results)

        this.set('_currentReportsRequest', promise);
        promise.finally(function () {
          _this4.set('_currentReportsRequest', null);
        });
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    totalReports: Ember.computed('jid', function () {
      var jid = this.get('jid');
      var entityId = (0, _jidHelpers.getEntityObjectId)(jid);
      var id = entityId || this.get('id');
      var promise = this.get('search').performSearch(id, 0, 'users', [], ['path'], 'EXACT').then(function (results) {
        if (results && results.results && results.results._meta) {
          return results.results._meta.total;
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    siblings: Ember.computed('manager', 'selfAndSiblings', function () {
      var _this5 = this;

      var promise = this.get('selfAndSiblings').then(function (siblings) {
        return siblings.filter(function (person) {
          return person.get('id') !== _this5.get('id') && person.get('state') === 'active';
        });
      }).catch(function () {
        return [];
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    selfAndSiblings: Ember.computed('manager', function () {
      var promise = this.get('manager').then(function (person) {
        if (person && person.get) {
          return person.get('directReports');
        }

        return [];
      }).catch(function () {
        return [];
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    descendants: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.resolve([])
      });
    }),
    readWritePermissions: Ember.computed(function () {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        resolve({
          read: [],
          write: []
        });
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    roles: Ember.computed.reads('application.session.roleNames'),

    /* TODO: Start moving these to permissions service */
    isAdmin: Ember.computed('application.session.roleNames', 'application.session.user.isSuper', function () {
      if (this.get('application.session.user.isSuper')) {
        return true;
      }

      return _lodash.default.filter(this.get('application.session.roleNames'), 'isAdmin').length > 0;
    }),

    /* TODO: Start moving these to permissions service */
    isContentUser: Ember.computed('application.session.namedPermissions', function () {
      return _lodash.default.indexOf(this.get('application.session.namedPermissions'), 'content_management_user') > -1;
    }),

    /* TODO: Start moving these to permissions service */
    isContentAdmin: Ember.computed('application.session.namedPermissions', function () {
      return _lodash.default.indexOf(this.get('application.session.namedPermissions'), 'content_management_admin') > -1;
    }),
    isNotBusy: Ember.computed('application.session.person.presence', function () {
      if (this.get('application.session.person.presence') !== 'busy') {
        return true;
      }

      return false;
    }),

    /* TODO: Start moving these to permissions service */
    isEngageUser: Ember.computed('isEngageAgent', 'isEngageSupervisor', function () {
      return this.get('isEngageAgent') || this.get('isEngageSupervisor');
    }),

    /* TODO: Start moving these to permissions service */
    isEngageSupervisor: Ember.computed.alias('permissions.hasSupervisorAccess'),
    isEngageAgent: Ember.computed.alias('permissions.canGoOnQueue'),

    /* TODO: Start moving these to permissions service */
    isCommunicateUser: Ember.computed('application.session.namedPermissions', function () {
      return _lodash.default.includes(this.get('application.session.namedPermissions'), 'unified_communications');
    }),
    updatePermissionsOnRouteChange: Ember.observer('application.currentRouteName', function () {
      if (this.onProfilePage()) {
        this.get('readWritePermissions');
      }
    }),
    clearDirectReportsCache: function clearDirectReportsCache() {
      this.set('_directReports', null);
      this.set('children', []);
    },
    onProfilePage: function onProfilePage() {
      var onProfilePage = false;
      var route = this.get('application.currentRouteName');

      if (route) {
        onProfilePage = permissionRoutes.indexOf(this.get('application.currentRouteName')) > -1;
      }

      var personDetailController = this.get('personDetail');

      if (onProfilePage && personDetailController) {
        var personId = personDetailController.get('model.id');
        return this.get('id') === personId;
      }

      return false;
    },
    setProfilePicture: function setProfilePicture(image) {
      this.setPicture(image, 'images.profile');
      this.notifyPropertyChange('images.profile');
      this.notifyPropertyChange('profilePicture');
    },
    setAgentPicture: function setAgentPicture(image) {
      this.setPicture(image, 'agent.profileImage');
      this.notifyPropertyChange('agent.profileImage');
    },
    setPicture: function setPicture(image, path) {
      var imageSection = this.get(path);

      if (imageSection === undefined) {
        this.set(path, {});
        imageSection = this.get(path);
      }

      if (imageSection && imageSection.length) {
        var profileImage = imageSection[0];
        Ember.set(profileImage, 'ref', image.value);
        Ember.set(profileImage, 'value.fieldId', image._id);
        Ember.set(profileImage, 'value.fieldPath', 'uploads.images');
      }
    },
    getGroupsByType: function getGroupsByType(type) {
      var _this6 = this;

      var field = this.get('fieldConfig').getFieldByKey(type);

      if (!field) {
        return _emberData.default.PromiseArray.create({
          promise: Ember.RSVP.resolve([])
        });
      }

      var membershipIds;
      var memberships = this.getFieldValue(type);
      var promise;

      if (!_lodash.default.isEmpty(memberships) && memberships[0].value && memberships[0].value.length) {
        membershipIds = memberships.mapBy('value').filter(function (id) {
          if (_this6.store.hasRecordForId('group', id)) {
            return !_this6.store.recordForId('group', id).get('isDeleted');
          }

          return true;
        });
        promise = this.store.findMany('group', membershipIds, _group.BASE_GROUP).then(function (groups) {
          if (!groups) {
            return [];
          }

          return groups.rejectBy('state', 'deleted');
        });
      } else {
        promise = Ember.RSVP.resolve([]);
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    },
    getFieldPathForFieldValue: function getFieldPathForFieldValue(fieldValue, type) {
      var _this7 = this;

      if (type === 'sms' || type === 'voice') {
        type = 'phone';
      }

      var fieldPaths = this.fieldConfig.getFieldListByType(type).mapBy('fieldPath');
      return _lodash.default.find(fieldPaths, function (path) {
        var value = _this7.getFieldValue(path);

        if (Array.isArray(value)) {
          var found = _lodash.default.find(value, function (actual) {
            if (fieldValue.value && fieldValue.value.fieldId) {
              return actual._id === fieldValue.value.fieldId;
            }

            return actual._id === fieldValue._id;
          });

          return !_lodash.default.isEmpty(found);
        } else {
          if (fieldValue.value && fieldValue.value.fieldId) {
            return value._id === fieldValue.value.fieldId;
          }

          return value._id === fieldValue._id;
        }
      });
    },
    getContactFieldValuesByType: function getContactFieldValuesByType(type) {
      var _this8 = this;

      var fieldType = type;
      var collection = [];

      if (type === 'sms' || type === 'voice') {
        fieldType = 'phone';
      }

      var fields = this.fieldConfig.getFieldListByType(fieldType);

      var values = _lodash.default.map(fields, function (field) {
        var path = field.getFieldPath();

        var value = _this8.getFieldValue(path);

        if (!Array.isArray(value)) {
          value = [value];
        }

        var filteredEntries = _lodash.default.filter(value, function (value) {
          if (type === 'email') {
            return Ember.get(value, 'value.length') > 0;
          }

          return Ember.get(value, 'value.number.length') > 0 || Ember.get(value, 'value.extension.length') > 0;
        });

        if (type === 'sms') {
          filteredEntries = filteredEntries.filterBy('value.acceptsSMS');
        }

        return filteredEntries;
      });

      _lodash.default.forEach(values, function (value) {
        if (Array.isArray(value)) {
          collection = collection.concat(value);
        } else if (value && _typeof(value) === 'object') {
          collection.push(value);
        }
      });

      return collection;
    },
    getPermissableSections: function getPermissableSections(permissions) {
      var sections = _lodash.default.map(permissions, function (fieldPath) {
        return fieldPath.split('.')[0];
      });

      return _lodash.default.uniq(sections);
    },
    getReadableSections: function getReadableSections() {
      var permissions = this.get('_readWritePermissions.read');
      return this.getPermissableSections(permissions);
    },
    getWriteableSections: function getWriteableSections() {
      var permissions = this.get('_readWritePermissions.write');
      return this.getPermissableSections(permissions);
    },
    getReadableFieldsForSection: function getReadableFieldsForSection(section) {
      var permissions = this.get('_readWritePermissions.read');

      var filteredPaths = _lodash.default.filter(permissions, function (fieldPath) {
        return fieldPath.indexOf(section.key) === 0;
      });

      return _lodash.default.map(filteredPaths, function (fieldPath) {
        return fieldPath.split('.')[1];
      });
    },
    getWriteableFieldsForSection: function getWriteableFieldsForSection(sectionKey) {
      var permissions = this.get('_readWritePermissions.write');

      var filteredPaths = _lodash.default.filter(permissions, function (fieldPath) {
        return fieldPath.indexOf(sectionKey) === 0;
      });

      return _lodash.default.map(filteredPaths, function (fieldPath) {
        return fieldPath.split('.')[1];
      });
    },
    setPrimaryContact: function setPrimaryContact(type, entry) {
      var fieldPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var fieldData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

      if (type === 'phone') {
        type = 'voice';
      }

      var path = "primaryContactInfo.".concat(type);
      var primary = this.getFieldValue(path, true);

      if (!fieldPath) {
        fieldPath = this.getFieldPathForFieldValue(entry, type);
      }

      if (Array.isArray(primary)) {
        primary = primary[0];
      }

      var fieldId = entry._id || entry.tempId;

      if (fieldData) {
        fieldId = fieldData.get('value._id') || fieldId;
      }

      Ember.set(primary, '_id', null);
      Ember.set(primary, 'ref', entry.value);
      Ember.set(primary, 'value', {
        fieldId: fieldId,
        fieldPath: fieldPath
      });
      delete primary._id;
      delete primary.rel;
      Ember.set(this, path, [primary], {
        overwrite: true
      });
    },
    integrationContactInfo: function integrationContactInfo(key) {
      var phones = this.get('contactInfo.phone_other');

      if (phones && phones.length > 0) {
        return phones.find(function (phone) {
          return phone && phone.value && phone.value.integration === key;
        });
      }

      return null;
    }
  });

  PersonModel.reopenClass({
    apiEndpoint: 'v2/people'
  });
  var _default = PersonModel;
  _exports.default = _default;
});