define("web-directory/routes/engage/telephony-admin-redirect", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    redirect: function redirect(model) {
      // Redirect old PCBA/engage Telephony Admin routes to iframe-coordinator app, e.g.
      // #/engage/telephonyAdmin/phoneManagement/baseSettings -> #/admin/telephony/phone-management/base-settings
      var path = model.telephonyPath.replace(/^\//, '').replace('authorizedServices', 'certificate-authorities'); // 'authorized services' is old, the new route should be certificate-authorities

      var parts = path.split('/');

      for (var index = 0; index < 2 && index < parts.length; index++) {
        parts[index] = _lodash.default.kebabCase(parts[index]);
      }

      this.replaceWith('/admin/telephony/' + parts.join('/'));
    }
  });

  _exports.default = _default;
});