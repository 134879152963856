define("web-directory/app-providers/scripter-gadget-base-provider", ["exports", "web-directory/utils/boolean-expressions"], function (_exports, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;

  var buildScripterUrl = function buildScripterUrl(origin, route) {
    var urlParams = new URLSearchParams(window.location.search);
    var scripterGadgetVersion = urlParams.get('scripterGadgetVersion');
    var local = scripterGadgetVersion === 'local';
    return scripterGadgetVersion ? local ? "http://localhost:9091/scripter/#/".concat(route) : "".concat(origin, "/scripter/").concat(scripterGadgetVersion, "/#/").concat(route) : "".concat(origin, "/scripter/#/").concat(route);
  }; // Note: You need to be careful of when you call payload.get. An interaction that will work on
  // but for a workItem that is not valid and it will blow up. This is the reason for the repeat of myselfIsCoachOrMonitor.
  // I am doing that here since we are now safe inside the payload of an interaction. Abstraction that up
  // would clean it up but also make it to where you could cause a breaking change very quickly.


  var scriptProviderConfigs = [{
    id: 'voice',
    group: 'conversation',
    startBehavior: 'eager',
    route: function route(appContext) {
      return "interaction/".concat(appContext.id);
    },
    shouldBuild: function shouldBuild(appContext, features) {
      if (appContext.type !== 'conversation') return false;
      if (!features['PURE-6186_Scripter_Gadget_GCUI']) return false;
      var myselfIsCoachOrMonitor = appContext.payload.get('myselfIsMonitor') || appContext.payload.get('myselfIsCoach') || appContext.payload.get('myselfIsMonitorV2'); // TODO: https://inindca.atlassian.net/browse/CC-4727 - Required for GA that we check for script on the conversation and block adding appDef.

      return !myselfIsCoachOrMonitor && (appContext.payload.get('type') === 'call' || appContext.payload.get('type') === 'callback');
    }
  }, {
    id: 'digital',
    group: 'contextual',
    startBehavior: 'lazy',
    route: function route(appContext) {
      return "interaction/".concat(appContext.id);
    },
    shouldBuild: function shouldBuild(appContext, features) {
      if (appContext.type !== 'conversation') return false;
      if (!features['PURE-6186_Scripter_Gadget_GCUI']) return false;
      var myselfIsCoachOrMonitor = appContext.payload.get('myselfIsMonitor') || appContext.payload.get('myselfIsCoach') || appContext.payload.get('myselfIsMonitorV2');
      var isInConsult = appContext.payload.get('iAmConsultInitiator') && appContext.payload.get('isThereAConsultAgentParticipant'); // TODO: https://inindca.atlassian.net/browse/CC-4727 - Required for GA that we check for script on the conversation and block adding appDef.

      return !myselfIsCoachOrMonitor && !isInConsult && appContext.payload.get('type') !== 'call' && appContext.payload.get('type') !== 'callback';
    }
  }, {
    id: 'workItem',
    group: 'contextual',
    startBehavior: 'lazy',
    route: function route(appContext) {
      return "workItem/".concat(appContext.id);
    },
    shouldBuild: function shouldBuild(appContext, features) {
      var _appContext$payload$w;

      if (appContext.type !== 'workitem') return false;
      if (!features['PURE-4948_scripter_workItem']) return false; // @ts-ignore

      if (!((_appContext$payload$w = appContext.payload.workitem.script) !== null && _appContext$payload$w !== void 0 && _appContext$payload$w.id)) return false;
      return true;
    }
  }];

  var buildScripterGadgetDef = function buildScripterGadgetDef(url, displayName) {
    return {
      id: 'scripts',
      displayName: displayName,
      type: 'iframe',
      icon: {
        type: 'sparkV3SvgSprite',
        classNames: ['scripter']
      },
      trustedDataPassing: true,
      // Going with eager as this is as close as we can get to existing behavior.
      startBehavior: 'lazy',
      lifecycleConfig: {
        bootstrap: true,
        stop: true,
        stopTimeoutMillis: 5000,
        // Added a longer bootstrap timeout to handle reduced network conditions
        bootstrapTimeoutMillis: 15000,
        bootstrapReadyTimeoutMillis: 15000,
        loadTimeoutMillis: 15000
      },
      allow: ['clipboard-write'],
      sandbox: ['allow-same-origin', 'allow-scripts', 'allow-modals', 'allow-popups', 'allow-forms', 'allow-downloads'],
      src: new URL(url)
    };
  };

  var _default = Ember.Object.extend({
    // Injections
    application: Ember.inject.service('application'),
    intl: Ember.inject.service('intl'),
    session: Ember.inject.service('session'),
    // Attributes
    type: 'programmatic',
    trustedProvider: true,
    monitorInteractionUpdates: false,
    id: 'scriptsProvider',
    features: Ember.computed.readOnly('session.features'),
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var application = this.get('application');

      if (application.runtime.local) {
        return "https://apps.".concat(application.runtime.publicDomainName);
      }

      return window.location.origin;
    }),
    getAvailableAppSizes: function getAvailableAppSizes() {
      return ['small', 'medium', 'large', 'xlarge'];
    },
    getAppGroup: function getAppGroup(_appDef) {
      var appDef = _appDef;
      return appDef.group;
    },
    getAppConfigs: function getAppConfigs(appContext) {
      var _this = this;

      var appDefs = [];
      var gadgetOrigin = this.get('gadgetTargetOrigin');
      var foundProviderConfig = scriptProviderConfigs.find(function (c) {
        return c.shouldBuild(appContext, _this.get('features'));
      });

      if (foundProviderConfig) {
        var url = buildScripterUrl(gadgetOrigin, foundProviderConfig.route(appContext));
        var scripterAppDef = buildScripterGadgetDef(url, this.get('intl').t('purecloud.acdInteraction.scripts'));
        scripterAppDef.group = foundProviderConfig.group;
        scripterAppDef.startBehavior = foundProviderConfig.startBehavior;
        appDefs.push(scripterAppDef);
      }

      return Ember.RSVP.resolve(appDefs);
    }
  });

  _exports.default = _default;
  var boolExpr = (0, _booleanExpressions.and)((0, _booleanExpressions.perm)('scripter:publishedScript:view'), (0, _booleanExpressions.or)((0, _booleanExpressions.ft)('PURE-6186_Scripter_Gadget_GCUI'), (0, _booleanExpressions.ft)('PURE-4948_scripter_workItem')));
  _exports.boolExpr = boolExpr;
});