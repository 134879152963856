define("web-directory/components/tags-input/component", ["exports", "web-directory/utils/acd"], function (_exports, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tags-input', 'form-control'],

    /**
     * @attribute
     *
     * the placeholder text shown on the input
     */
    placeholder: null,

    /**
     * @attribute
     *
     * autofocuses the main input if `true`
     */
    autofocus: false,

    /**
     * @attribute
     *
     * The value to render in the tag input as tags
     */
    value: Ember.A([]),

    /**
     * @attribute
     *
     * the current value of the search input
     */
    searchValue: '',

    /**
     * @attribute
     *
     * class names passed in to be applied to the input tag
     */
    inputClasses: '',

    /**
     * @attribute
     *
     * `true` if the user is allowed to add tags that have the same value. This component will
     * check against the current tags before firing an event to the parent to make sure there
     * are not other tags that have the same value. It will prevent the event from emitting
     * if it the value has already been added.
     */
    allowSameValueTags: false,

    /**
     * @attribute
     *
     * When a user adds a comma or pastes a string into the input that includes commas, this
     * will allow the data to be quickly parsed and applied as tags in the input
     */
    allowCommaSeparatedParsing: false,

    /**
     * @attribute
     *
     * Applies the text as a label to the tag input for the screenreader.
     */
    label: null,

    /**
     * Reference to the main input
     */
    inputRef: undefined,

    /**
     * Essentially the value that we need to save in order to get the backspace
     * hotkey working properly. `true` will allow the last tag to be focused if
     * the user presses the Backspace key in an empty input. `false` prevents
     * that from happening.
     */
    autofocusTag: false,

    /**
     * Quick reference to the main input's id for querying
     */
    inputId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "TagsInput");
    }),
    didUpdateAttrs: function didUpdateAttrs(attrs) {
      this._super.apply(this, arguments);

      var newAttrs = attrs.newAttrs,
          oldAttrs = attrs.oldAttrs;

      if (newAttrs.searchValue || oldAttrs.searchValue) {
        var _newAttrs$searchValue, _newAttrs$searchValue2, _oldAttrs$searchValue;

        if (!((_newAttrs$searchValue = newAttrs.searchValue) !== null && _newAttrs$searchValue !== void 0 && _newAttrs$searchValue.value) && ((_newAttrs$searchValue2 = newAttrs.searchValue) === null || _newAttrs$searchValue2 === void 0 ? void 0 : _newAttrs$searchValue2.value) !== ((_oldAttrs$searchValue = oldAttrs.searchValue) === null || _oldAttrs$searchValue === void 0 ? void 0 : _oldAttrs$searchValue.value)) {
          this.set('autofocusTag', false); // if the searchValue resets to empty, refocus the input for the user

          this.focusInput();
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var input = this.input();

      if (input) {
        input.addEventListener('keyup', this.keyupHandler.bind(this));
        input.addEventListener('keydown', this.keydownHandler.bind(this));
        input.addEventListener('input', this.inputHandler.bind(this));
        input.addEventListener('focus', this.actionInputFocus.bind(this));

        if (this.get('autofocus')) {
          this.focusInput();
        }

        if (this.get('inputClasses')) {
          input.classList.add(this.get('inputClasses'));
        }
      }
    },
    didDestroyElement: function didDestroyElement() {
      var _this$input, _this$input2, _this$input3, _this$input4;

      this._super.apply(this, arguments);

      (_this$input = this.input()) === null || _this$input === void 0 ? void 0 : _this$input.removeEventListener('keyup', this.keyupHandler.bind(this));
      (_this$input2 = this.input()) === null || _this$input2 === void 0 ? void 0 : _this$input2.removeEventListener('keydown', this.keydownHandler.bind(this));
      (_this$input3 = this.input()) === null || _this$input3 === void 0 ? void 0 : _this$input3.removeEventListener('input', this.inputHandler.bind(this));
      (_this$input4 = this.input()) === null || _this$input4 === void 0 ? void 0 : _this$input4.removeEventListener('focus', this.actionInputFocus.bind(this));
    },

    /**
     * Grabs a ref to the main input that is being rendered in this component
     *
     * @returns either the stored input ref, or the newly found input ref
     */
    input: function input() {
      var ref = this.get('inputRef');

      if (ref) {
        return ref;
      }

      ref = document.getElementById(this.get('inputId'));
      this.set('inputRef', ref);
      return ref;
    },

    /**
     * Handles the keyup based hotkeys that a user may use while navigating the tags input
     *
     * @param event The event emitted from the browser during the keyup event
     */
    keyupHandler: function keyupHandler(event) {
      var _this$input6;

      if (event.key === 'Backspace') {
        var _this$input5;

        // we only want to focus a tag if there is NO value in the input.
        // we want to make sure the input has been found first though.
        if (this.input() && !((_this$input5 = this.input()) !== null && _this$input5 !== void 0 && _this$input5.value) && this.get('autofocusTag')) {
          var index = this.get('value').length - 1;
          this.$(".tag-remove-".concat(index)).trigger('focus');
        }
      } // the user can only autofocus a tag if there is no input value.


      this.set('autofocusTag', !((_this$input6 = this.input()) !== null && _this$input6 !== void 0 && _this$input6.value));
    },

    /**
     * Sets the previous value property to the value at the moment pressing the keydown
     * to set a saved moment to compare the keyup handler to so we know how to handle
     * the Backspace event on an empty input.
     *
     * @param event The event emitted from the browser during the keydown event
     */
    keydownHandler: function keydownHandler(event) {
      // we want to prevent the user from rapidly deleting the keys while holding down a backspace or delete key
      if (event.repeat) {
        this.set('autofocusTag', false);
      }

      if (event.key === 'Enter') {
        var _this$input7;

        var value = (_this$input7 = this.input()) === null || _this$input7 === void 0 ? void 0 : _this$input7.value.trim(); // we only want to invoke the add if there is some sort of input

        if (value) {
          // if this component is in a form, without this it will submit the form which
          // we don't want to do in this use case
          event.preventDefault();

          if (this.get('allowSameValueTags')) {
            this.actionAdd(value);
          } else {
            var exists = this.get('value').find(function (item) {
              return item.value.trim() === value;
            });

            if (!exists) {
              this.actionAdd(value);
            }
          }
        } else if (!event.repeat) {
          this.actionSubmit();
        }
      }
    },

    /**
     * Handles the users inputs into the main input element. We need to invoke the action
     * to the parent (if listening), and also parse out a comma separated value if the component
     * has been configured to do so.
     * @param event the input event originating from the main html input element
     */
    inputHandler: function inputHandler(event) {
      this.actionInputChange(event);

      if (this.get('allowCommaSeparatedParsing')) {
        var input = this.input();

        if (input) {
          var value = input.value.trim();

          if (value.includes(',')) {
            var tags = value.split(',').reduce(function (arr, current) {
              var text = current.trim();

              if (text) {
                arr.push(text);
              }

              return arr;
            }, []);

            if (tags.length) {
              // we don't want to invoke the change in input if we are parsing the
              // input and automically adding the tags here instead.
              this.actionBulkAdd(tags);
            }
          }
        }
      }
    },

    /**
     * Resets the input back to an empty state
     */
    resetInput: function resetInput() {
      var input = this.input();

      if (input) {
        input.value = '';
        this.focusInput();
      }
    },

    /**
     * Quick action with 1 frame timeout to focus the main input
     */
    focusInput: function focusInput() {
      var _this = this;

      setTimeout(function () {
        var _this$input8;

        return (_this$input8 = _this.input()) === null || _this$input8 === void 0 ? void 0 : _this$input8.focus();
      }, 100);
    },

    /**
     * Invoked when the user types while focused on the tag removal button. If the user
     * presses the backspace button while focused, the tag will be signaled for removal.
     * Due to the click handler already supporting "enter" key presses, we do not need
     * to set that use case up here.
     *
     * @param index the index of the tag to signal for removal
     * @param event the keyboard event to determine which key is pressed
     */
    _tagKeyUp: function _tagKeyUp(index, event) {
      // we want to delete a tag if the user presses the `Backspace` button
      // in addition to the built in "enter" press that triggers the buttons click event
      if (event.key === 'Backspace') {
        this.actionRemove(index);
        this.focusInput();
      }
    },

    /**
     * Initiates the signal of removal to the parent component to remove a tag at the index provided.
     * This will trigger a refocus on the element to provide a seamless experience.
     *
     * @param index the index of the tag to remove
     */
    _removeTag: function _removeTag(index) {
      this.actionRemove(index);
      this.set('autofocusTag', false);
      this.focusInput();
    },

    /**
     * @action
     *
     * Invoked when the user opts to remove a specific tag at an index
     *
     * @param index the index of the tag to remove
     */
    actionRemove: function actionRemove(index) {
      return (0, _acd.warnClosureAction)('actionRemove', {
        index: index
      });
    },

    /**
     * @action
     *
     * Invoked when a user wants to add a single tag based on keyboard input
     *
     * @param value the value of the tag to emit to the parent
     */
    actionAdd: function actionAdd(value) {
      return (0, _acd.warnClosureAction)('actionAdd', {
        value: value
      });
    },

    /**
     * @action
     * @optional this isn't required since not all tag inputs require a submission handler
     *
     * Invoked when a user wants to submit the tags input value. This occurs when there
     * is no value in the input and the user hits enter. If there is a value and the user
     * hits enter, then the `actionAdd` event will be invoked.
     */
    actionSubmit: function actionSubmit() {},

    /**
     * @action
     *
     * Invoked when a user wants to add multiple tags based on keyboard input
     *
     * @param value the value of the tag to emit to the parent
     */
    actionBulkAdd: function actionBulkAdd(value) {
      return (0, _acd.warnClosureAction)('actionBulkAdd', {
        value: value
      });
    },

    /**
     * @action
     * @optional
     *
     * An optional closure action to add additional logic to this component that should run
     * when the main input has been focused. Defaults to a no-op
     *
     * Note: If not setupm we do not need to warn the user about the missing closure action
     * since this is an optional feature of the component. It is not required for the component
     * to function properly.
     */
    actionInputFocus: function actionInputFocus() {
      return undefined;
    },

    /**
     * @action
     * @optional
     *
     * An optional closure action to add additional logic to this component that will be invoked
     * when the main input has it's value changed. Defaults to a no-op.
     *
     * @param value the value of the input
     */
    actionInputChange: function actionInputChange(_event) {
      return undefined;
    },
    actions: {
      /**
       * Invoked when the user clicks on the removal button tied to a corresponding tag. The index
       * provided will be the tag that gets removed.
       *
       * @param index the index of the tag to remove
       */
      onRemoveTag: function onRemoveTag(index) {
        this._removeTag(index);
      },

      /**
       * Invoked when the user uses the keyboard while focused on a tag remove button. We need this to
       * affect the keyup event because if we use keydown, it will try and remove all of the tags very quickly
       * whereas the keyup forces the user to release key first before we detect another removal
       *
       * @param index the index of the tag button being evented on
       * @param event the browser event data
       */
      onTagKeyUp: function onTagKeyUp(index, event) {
        this._tagKeyUp(index, event);
      }
    }
  });

  _exports.default = _default;
});