define("web-directory/models/interaction/call-interaction", ["exports", "web-directory/models/interaction/interaction", "lodash", "web-directory/mixins/acd/session-share"], function (_exports, _interaction, _lodash, _sessionShare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      Evented = Ember.Evented;

  var _default = _interaction.default.extend(_sessionShare.default, Evented, {
    type: 'call',
    conversation: null,
    acdcallback: Ember.inject.service(),
    session: Ember.inject.service(),
    // consult transfer
    onConsult: computed('conversation.connectedOnConsult.[]', function () {
      var connectedOnConsultArr = this.get('conversation.connectedOnConsult') || [];
      return connectedOnConsultArr.some(function (p) {
        return p.consultParticipantId;
      });
    }),
    iAmConsultInitiator: computed('consultInitiator.userId', 'onConsult', 'session.person.guid', function () {
      return this.get('onConsult') && this.get('consultInitiator.userId') === this.get('session.person.guid');
    }),
    iAmConsulted: computed('consultAgentParticipant.userId', 'onConsult', 'session.person.guid', function () {
      return this.get('onConsult') && this.get('consultAgentParticipant.userId') === this.get('session.person.guid');
    }),
    iAmConsultObject: computed('consultParticipant.userId', 'onConsult', 'session.person.guid', function () {
      return this.get('onConsult') && this.get('consultParticipant.userId') === this.get('session.person.guid');
    }),
    consultInitiator: computed('participants.[]', 'participantForCurrentUser', 'session.features.acdMultiConsultInitiators', function () {
      if (this.get('session.features.acdMultiConsultInitiators')) {
        var currParticipant = this.get('participantForCurrentUser');
        var disconnectType = Ember.get(currParticipant, 'calls.firstObject.disconnectType');

        if (!!currParticipant.attributes.consultInitiator && !disconnectType) {
          return currParticipant;
        }
      }

      var participants = this.get('participants') || [];
      return participants.find(function (participant) {
        var disconnectType = Ember.get(participant, 'calls.firstObject.disconnectType');

        if (!!participant.attributes.consultInitiator && !disconnectType) {
          return participant;
        }
      });
    }),
    consultParticipant: computed('participants.[]', function () {
      var participant = _lodash.default.find(this.get('participants'), function (participant) {
        if (participant.consultParticipantId) {
          return participant.id === participant.consultParticipantId && participant.calls && participant.calls[0].disconnectType === undefined;
        }

        return false;
      });

      return participant;
    }),
    isThereAConsultAgentParticipant: computed.bool('consultAgentParticipant'),
    consultAgentParticipant: computed('participants.[]', function () {
      if (this.get('onConsult')) {
        var participant = _lodash.default.findLast(this.get('participants'), function (participant) {
          var attributes = participant.attributes;

          if (_lodash.default.isEqual('true', attributes.consultInitiator) || participant.id === participant.consultParticipantId || participant.monitoredParticipantId !== undefined || participant.coachedParticipantId !== undefined || participant.bargedParticipantId !== undefined || participant.calls && participant.calls[0].disconnectType !== undefined) {
            return false;
          }

          return true;
        });

        return participant;
      }

      return false;
    }),

    /**
     * `true` if the consulting agent has been confined by the initiating agent in a consult. Confinement
     * takes precedent over hold in the UI.
     */
    isConsultAgentParticipantConfined: computed.readOnly('consultAgentParticipant.calls.firstObject.confined'),

    /**
     * `true` if the consulting agent has placed themselves on hold.
     */
    isConsultAgentParticipantHeld: computed.readOnly('consultAgentParticipant.calls.firstObject.held'),

    /**
     * `true` if the consulting agent has muted themselves on the call
     */
    isConsultAgentParticipantMuted: computed.bool('consultAgentParticipant.calls.firstObject.muted'),

    /**
     * @deprecated confinement and holding are being separated so we should use the `isConsultAgentParticipantConfined` and `isConsultAgentParticipantHeld` instead based on the exact use case.
     */
    isConsultAgentParticipantHolding: computed('consultAgentParticipant.calls.firstObject.confined', 'consultAgentParticipant.calls.firstObject.held', function () {
      if (this.get('consultAgentParticipant.calls.firstObject.confined') || this.get('consultAgentParticipant.calls.firstObject.held')) {
        return true;
      }

      return false;
    }),

    /**
     * `true` if the initiating agent has confined the consulting participant. Do to customers not
     * using the UI, we only track their hold state based on if they have been confined or not by
     * the initiating agent.
     */
    isConsultParticipantHolding: computed.bool('consultParticipant.calls.firstObject.confined'),

    /**
     * `true` if the consulting participant has muted themselves are we are able to detect that.
     * We are not always able to reliably tell if a user has muted themselves or not.
     */
    isConsultParticipantMuted: computed.bool('consultParticipant.calls.firstObject.muted'),

    /**
     * `true` if the consult initaor has muted themselves
     */
    isConsultInitiatorMuted: computed.bool('consultInitiator.calls.firstObject.muted'),

    /**
     * `true` if the intiating agent has been confined
     */
    isConsultInitiatorHolding: computed.bool('consultInitiator.calls.firstObject.confined'),
    // TODO (ACE-4539): keeping this for now b/c we need to discuss scenarios where we might not want to display the "Secure Pause" button
    canTogglePause: true,
    formattedCustomerPhoneNumber: computed('participantForCustomer.address', '{isCall,isCallback,currentCallback}', function () {
      var _this = this;

      var addressformat = this.get('addressformat');

      var format = function format(property) {
        return addressformat.formatAddressForDisplay(_this.get(property));
      };

      if (this.get('isCallback')) {
        return format('currentCallback.callbackNumbers.firstObject');
      }

      if (this.get('isCall')) {
        if (this.get('participantForCustomer.address')) {
          return format('participantForCustomer.address');
        }

        if (this.get('participantForCustomer.calls.firstObject.self.addressNormalized')) {
          return format('participantForCustomer.calls.firstObject.self.addressNormalized');
        }

        if (this._isOutbound()) {
          return format('participantForCurrentUser.calls.firstObject.other.addressNormalized');
        }

        return null;
      }

      return null;
    }),
    _isOutbound: function _isOutbound() {
      var direction = this.get('participantForCurrentUser.calls.firstObject.direction') || '';
      return this.get('customerParticipants.length') === 0 && direction.toUpperCase() === 'OUTBOUND';
    },
    needsCallWrapUp: computed('acdcallback.callbacksRequiringCallWrapUp.[]', function () {
      return this.get('acdcallback').needsCallWrapUp(this);
    })
  });

  _exports.default = _default;
});