define("web-directory/components/preference-setting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var run = Ember.run,
      assert = Ember.assert,
      computed = Ember.computed,
      Component = Ember.Component;

  var _default = Component.extend({
    classNames: ['preference-setting', 'settings-preference'],
    type: null,
    value: null,
    choices: null,
    headerKey: null,
    labelKey: null,
    subLabelKey: null,
    subLabelAction: null,
    setPreference: null,
    extraClassNames: null,
    // Allows checkboxes to be disabled when saving
    allowCheckboxDisable: false,
    saving: false,
    mainWorkTimer: null,
    savingTimer: null,
    successTimer: null,
    actions: {
      selectValue: function selectValue(e) {
        var value = e.target.value;
        var ret = this.get('setPreference')(value);
        this.handleSave(ret);
      },
      selectValueChecked: function selectValueChecked(e) {
        var value = e.target.checked;
        var ret = this.get('setPreference')(value);
        this.handleSave(ret);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      assert('The `setPreference` action needs to be a closure action: {{... setPreference=(action "myAction")}}', typeof this.get('setPreference') === 'function');
    },
    willDestroyElement: function willDestroyElement() {
      var timers = this.getProperties('savingTimer', 'successTimer', 'mainWorkTimer');
      run.cancel(timers.savingTimer);
      run.cancel(timers.successTimer);
      run.cancel(timers.mainWorkTimer);

      this._super.apply(this, arguments);
    },
    isSelect: computed.equal('type', 'select'),
    isCheckbox: computed.equal('type', 'checkbox'),
    shouldDisableCheckbox: computed('saving', function () {
      return this.get('allowCheckboxDisable') && this.get('saving');
    }),
    handleSave: function handleSave(promise) {
      var _this = this;

      // Done to avoid set on destroyed element error if component is recycled
      var mainWorkTimer = run.later(this, function () {
        if (!promise || !promise.then) {
          return;
        }

        _this.set('saving', true);

        promise.then(function () {
          // More of an illusion that work was actually done (API save tends to be too fast)
          var savingTimer = run.later(function () {
            _this.set('saving', false);

            _this.set('success', true);
          }, 250);
          var successTimer = run.later(function () {
            _this.set('success', false);
          }, 1250);

          _this.setProperties({
            savingTimer: savingTimer,
            successTimer: successTimer
          });
        }).catch(function () {
          _this.set('saving', false);

          _this.set('error', true);
        });
      }, 0);
      this.set('mainWorkTimer', mainWorkTimer);
    }
  });

  _exports.default = _default;
});