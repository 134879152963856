define("web-directory/services/acdvoicemail", ["exports", "web-directory/mixins/conversation", "ember-purecloud-components/utils/browser"], function (_exports, _conversation, _browser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Service = Ember.Service,
      inject = Ember.inject;
  var BASE_URI = 'api/v2/voicemail';

  var _default = Service.extend(_conversation.default, {
    application: inject.service(),
    ajax: inject.service(),
    getVoicemailMediaInfo: function getVoicemailMediaInfo(voicemailId) {
      var formatId = _browser.default.getSuggestedAudioTypeFrom(['webm', 'wav']);

      var url = "".concat(this.get('application').pcV2Uri(BASE_URI), "/messages/").concat(voicemailId, "/media?formatId=").concat(formatId);
      return this.makeRequest(url, {}, 'get');
    },
    getVoicemailWithTranscription: function getVoicemailWithTranscription(voicemailId) {
      var url = "".concat(this.get('application').pcV2Uri(BASE_URI), "/messages/").concat(voicemailId, "/?expand=transcription");
      return this.makeRequest(url, {}, 'get');
    },
    getUserAlertTime: function getUserAlertTime(userId) {
      var url = "".concat(this.get('application').pcV2Uri(BASE_URI), "/userpolicies/").concat(userId);
      return this.makeRequest(url, {}, 'get');
    },
    getGroupAlertTime: function getGroupAlertTime(groupRingId) {
      var url = "".concat(this.get('application').pcV2Uri(BASE_URI), "/groups/").concat(groupRingId, "/policy");
      return this.makeRequest(url, {}, 'get');
    }
  });

  _exports.default = _default;
});