define("web-directory/services/log-capture", ["exports", "supportability-log-capture"], function (_exports, _supportabilityLogCapture) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _isStarted = false;
  var _loggers = [];
  var browserLog = new _supportabilityLogCapture.BrowserLog();

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    application: Ember.inject.service(),
    session: Ember.inject.service('session'),
    stashLogger: Ember.inject.service('stash-logger'),
    isStarted: function isStarted() {
      return _isStarted;
    },
    _registerFrameLoggers: function _registerFrameLoggers() {
      document.querySelectorAll('iframe').forEach(function (frame) {
        try {
          var _console = frame.contentWindow.console;

          _loggers.push(_console);
        } catch (e) {//This can happen if we've an encountered a frame that is being blocked by CORS.
        }
      });
    },
    restart: function restart() {
      if (_isStarted) {
        this.stop();
        this.start();
      }
    },
    start: function start() {
      var _this = this;

      if (!this.get('session.user.logCaptureEnabled')) {
        return;
      }

      _loggers.push(console);

      _loggers.push(this.get('stashLogger').getOriginalLogger());

      this._registerFrameLoggers();

      var levels = ['info', 'log', 'warn', 'error'];

      if (this.get('session.features.browser-log-debug-enabled')) {
        levels.push('debug');
      }

      var options = {
        loggers: _loggers,
        levels: levels,
        sizeLimit: 5 * 1024,
        //5kb
        interval: 30 * 1000 //30 seconds

      };
      browserLog.start(function (buffer) {
        return _this._flushCallback(buffer);
      }, options);
      window.addEventListener('beforeunload', this.stop);
      _isStarted = true;
    },
    stop: function stop() {
      if (_isStarted) {
        browserLog.bypass(console, 'browser log stopping', 'debug');
        browserLog.stop();
        _isStarted = false;
        _loggers = [];
      }
    },
    _flushCallback: function _flushCallback(buffer) {
      var _this2 = this;

      if (!buffer || !buffer.length) {
        return;
      }

      browserLog.bypass(console, 'recieved buffered log messages', 'debug');
      var url = this.get('application').pcV2Uri('api/v2/diagnostics/logcapture/browser/entries');
      this.get('ajax').post(url, {
        data: buffer
      }).catch(function (error) {
        if (error.status === 403) {
          browserLog.bypass(console, 'the browser log capture session has ended', 'debug');

          _this2.stop();
        }

        browserLog.bypass(console, 'an error occurred posting log messages', 'error');
      });
    },
    willDestroy: function willDestroy() {
      this.stop();
      window.removeEventListener('beforeunload', this.stop);
    }
  });

  _exports.default = _default;
});