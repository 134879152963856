define("web-directory/components/command-view/component", ["exports", "web-directory/services/apps-api-handlers/open-window-handler", "web-directory/services/events"], function (_exports, _openWindowHandler, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getOwner = Ember.getOwner;
  var DEFAULT_PANEL = 'panel-not-active';

  var _default = Ember.Component.extend({
    classNameBindings: [':command-view', ':orgspan-app', 'session.authInProgress:authenticating:', 'session.isAuthenticated:authenticated:unauthenticated', 'session.hasAppAccess:authorized:unauthorized', 'session.features.ATLAS-99_nav-system:nav-system-v2:', 'application.errorState:application-error', 'session.logoutInProgress:logging-out:', 'application.currentRouteClass', 'application.adminUIContext:adminui-context:directory-context', 'application.isPopout:is-popout', 'showNavbarAlert:has-incoming-conversation', 'volt.invitations.length:has-incoming-conversation', 'webrtc.incomingInvitations.length:has-incoming-conversation', 'phone.onCall:on-call'],
    navV2IsOpen: false,
    currentPanel: DEFAULT_PANEL,
    auditDrawerOpen: false,
    acdworkitem: Ember.inject.service('acdworkitem'),
    application: Ember.inject.service('application'),
    apps: Ember.inject.service('apps'),
    // @ts-ignore
    engage: Ember.inject.service('engage'),
    events: Ember.inject.service('events'),
    // @ts-ignore
    frameService: Ember.inject.service('center-stage-frame'),
    interaction: Ember.inject.service('interaction'),
    permissions: Ember.inject.service('permissions'),
    phone: Ember.inject.service('phone'),
    session: Ember.inject.service('session'),
    // @ts-ignore
    settings: Ember.inject.service('settings'),
    sidebar: Ember.inject.service('sidebar'),
    webrtc: Ember.inject.service('webrtc'),
    trial: Ember.inject.service('trial'),
    intl: Ember.inject.service('intl'),
    freeTrialStatus: '',
    init: function init() {
      this._super.apply(this, arguments); // @ts-ignore


      this.get('events').on(_events.TOGGLE_NAV_EVENT, this, this.handleNavToggle);
      var remaining = this.get('trial').freeTrialDaysRemaining;
      var remainingText = this.get('intl').t('freeTrial.trialStatus', {
        number: remaining
      });
      this.set('freeTrialStatus', remainingText);
    },
    // @ts-ignore
    handleNavToggle: function handleNavToggle(evt) {
      if (evt) {
        this.set('navV2IsOpen', !!evt.open);
      }
    },
    engageNavStructure: Ember.computed.readOnly('engage.navTree'),
    trialSupport: Ember.computed.reads('application.trialSupportDisplayed'),
    showLoader: Ember.computed.reads('application.showLoader'),
    showAdminAuditFooter: Ember.computed.and('application.isAdminRoute', 'permissions.canViewAudits'),
    currentRouteClass: Ember.computed('application.currentRouteName', function () {
      var name = this.get('application.currentRouteName');

      if (name) {
        return name.replace(/[\.\/]+/g, '-');
      }

      return '';
    }),
    isAgentRoute: Ember.computed('application.currentRouteName', function () {
      if (this.get('application.currentRouteName') === 'agent-ui') {
        return true;
      }

      return false;
    }),
    alertingConversation: Ember.computed('interaction.alertingInteractions.firstObject', 'phone.incomingNonAcdConversations.firstObject', 'acdworkitem.alertingWorkitems.firstObject', function () {
      return this.get('interaction.alertingInteractions.firstObject') || this.get('phone.incomingNonAcdConversations.firstObject') || this.get('acdworkitem.alertingWorkitems.firstObject');
    }),
    showAppChrome: Ember.computed('session.isAuthenticated', 'session.hasAppAccess', 'application.{errorState,adminUIContext}', function () {
      return this.get('session.isAuthenticated') && this.get('session.hasAppAccess') && !this.get('application.errorState') && !this.get('application.adminUIContext');
    }),
    standaloneHandlers: Ember.computed(function () {
      var _this = this;

      var handlers = [];
      handlers.push(_openWindowHandler.default.create({
        // @ts-ignore
        isActiveApp: function isActiveApp(id) {
          return id === _this.get('activeStandaloneAppId');
        }
      }, getOwner(this).ownerInjection()));
      return handlers;
    }),
    actions: {
      handleNavToggle: function handleNavToggle() {
        this.toggleProperty('navV2IsOpen');
      },
      // @ts-ignore
      handlePanelSelect: function handlePanelSelect(panel) {
        this.set('currentPanel', panel);
      },
      answerAcdInteraction: function answerAcdInteraction() {
        // @ts-ignore
        this.get('interaction').answerInteraction();
      },
      openAcdInteraction: function openAcdInteraction() {
        this.get('interaction').openAcdInteraction();
      },
      // @ts-ignore
      sendToVoicemail: function sendToVoicemail(conversation) {
        // @ts-ignore
        this.get('phone').sendToVoicemail(conversation);
      },
      // @ts-ignore
      answerUcCall: function answerUcCall(conversation) {
        // @ts-ignore
        this.get('phone').answer(conversation);
      },
      answerWorkitemInteraction: function answerWorkitemInteraction() {
        var _this2 = this;

        // @ts-ignore
        var alertingWorkitem = this.get('acdworkitem.alertingWorkitems.firstObject');

        if (alertingWorkitem) {
          // @ts-ignore
          this.get('acdworkitem').answer(alertingWorkitem).then(function () {
            // @ts-ignore
            _this2.get('interaction').openAcdInteraction(); // @ts-ignore


            _this2.get('acdworkitem').handleWorkitemAnsweredEvent(alertingWorkitem);
          }) // @ts-ignore
          .catch(function (error) {
            return Ember.logger.error('Failed to answer workitem interaction.', {
              error: error
            });
          });
        }
      },
      // @ts-ignore
      onStandaloneAppInstanceApiCall: function onStandaloneAppInstanceApiCall(apiCall) {
        this.get('apps').handleAppApiCall(apiCall, this.get('standaloneHandlers'));
      },
      safeClosePreferencesModal: function safeClosePreferencesModal() {
        this.get('settings').close();
      },
      onDisplayTrialSupport: function onDisplayTrialSupport() {
        this.get('application').displayTrialSupport();
      }
    }
  });

  _exports.default = _default;
});