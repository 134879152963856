define("web-directory/components/entity-profile/add-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qs74MgMi",
    "block": "{\"statements\":[[11,\"span\",[]],[16,\"class\",[34,[\"add-new-section \",[33,[\"if\"],[[28,[\"addingSection\"]],\"adding-section\"],null]]]],[13],[0,\"\\n    \"],[9,\"gux-button\",{\"attrs\":[[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"toggleAddingSection\"],null],null]],\"args\":[[],[]],\"locals\":[],\"statements\":[[0,\"\\n        \"],[11,\"span\",[]],[15,\"aria-hidden\",\"true\"],[13],[9,\"gux-icon\",{\"attrs\":[[15,\"icon-name\",\"ic-add-bold\"],[15,\"decorative\",\"true\"]],\"args\":[[],[]],\"locals\":[],\"statements\":[]}],[14],[0,\"\\n        \"],[1,[33,[\"t\"],[\"person.personProfile.addNewSection\"],null],false],[0,\"\\n    \"]]}],[0,\"\\n\"],[14],[0,\"\\n\\n\"],[11,\"div\",[]],[16,\"class\",[34,[\"new-sections \",[33,[\"if\"],[[28,[\"addingSection\"]],\"adding-section\"],null]]]],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"content\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"emptySections\"]]],null,{\"statements\":[[0,\"            \"],[1,[33,[\"entity-profile/section-icon\"],null,[[\"sectionInfo\",\"addSection\"],[[28,[\"sectionInfo\"]],[33,[\"action\"],[[28,[null]],\"addSection\"],null]]]],false],[0,\"\\n\"]],\"locals\":[\"sectionInfo\"]},{\"statements\":[[0,\"            \"],[11,\"span\",[]],[15,\"class\",\"text-center\"],[13],[0,\"\\n                \"],[1,[33,[\"t\"],[\"person.personProfile.addNewSectionEmpty\"],null],false],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\"],[14]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "web-directory/components/entity-profile/add-section/template.hbs"
    }
  });

  _exports.default = _default;
});