define("web-directory/models/interaction/interaction", ["exports", "ember-inflector", "lodash/each", "web-directory/mixins/acd/after-call-work", "web-directory/mixins/acd/interaction-state", "web-directory/mixins/acd/interaction-timers", "web-directory/mixins/acd/participants", "web-directory/mixins/relate/relate-features", "web-directory/utils/acd", "web-directory/utils/acdParticipantStatuses", "web-directory/utils/dates", "web-directory/utils/log-e164-info"], function (_exports, _emberInflector, _each, _afterCallWork, _interactionState, _interactionTimers, _participants, _relateFeatures, _acd, _acdParticipantStatuses, _dates, _logE164Info) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MEDIA_TYPES = void 0;

  var _Ember$Object;

  var MEDIA_TYPES = ['chats', 'calls', 'callbacks', 'emails', 'screenshares', 'cobrowsesessions', 'videos', 'messages'];
  _exports.MEDIA_TYPES = MEDIA_TYPES;
  var mixins = [_participants.default, _interactionState.default, _afterCallWork.default, _interactionTimers.default, _relateFeatures.default];

  function isGuid(id) {
    if (!id) {
      return false;
    }

    return !!id.match(/^[a-z0-9-]{36}$/i);
  }

  var _default = (_Ember$Object = Ember.Object).extend.apply(_Ember$Object, mixins.concat([{
    /*
     * Things not created by the ember container typically cannot use injection.
     * We've 'hacked' this to keep it working by setting the container locally
     * on this object from the creating object. Ideally, our model objects would be
     * more lightweight than this and service calls would be made from components or
     * services that use this model.
     *
     * TL;DR this class is in need of some refactoring.
     */
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    addressformat: Ember.inject.service(),
    interaction: Ember.inject.service(),
    permissions: Ember.inject.service(),
    agentAssistChecks: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    externalContactId: Ember.computed.or('participantForCustomer.externalContactId', 'participantForCustomer.externalContact.id'),
    type: '',
    notes: '',
    participants: null,
    _verifiedOnDisconnect: false,
    callbackScheduled: false,
    callbackDate: undefined,
    callbackTime: null,
    callbackTimeZone: null,
    callbackPhone: null,
    preferMyself: false,
    agentOwned: false,
    isInternalProvider: true,
    timeCreated: null,
    callbackQueue: null,

    /**
     * The max amount of participants that can be added into a conference.
     * This is currently the only concrete indicator that a conversation has been converted
     * into a conference. This will only be provided if the conversation is, or has been, a conference.
     */
    conferenceMaxParticipants: Ember.computed.readOnly('__interaction_metadata__.maxParticipants'),

    /**
     * `true` if the conversation was a conference at any point or if still is a conference.
     * Todo: Update this when the backend provides additional information detailing conferencing
     */
    wasConference: Ember.computed.bool('conferenceMaxParticipants'),
    isChat: Ember.computed.equal('type', 'chat'),
    isCall: Ember.computed.equal('type', 'call'),
    isCallback: Ember.computed.equal('type', 'callback'),
    isEmail: Ember.computed.equal('type', 'email'),
    isRealtimeInteraction: Ember.computed.or('isCall', 'isCallback', 'isChat'),
    isSms: Ember.computed.equal('type', 'message'),
    startTimestamp: Ember.computed('participantForCurrentUser.connectedTime', 'timeCreated', function () {
      var connectedTime = this.get('participantForCurrentUser.connectedTime');
      var timeCreated = this.get('timeCreated');

      if (connectedTime) {
        return (0, _dates.default)(connectedTime).valueOf();
      } else if (timeCreated) {
        return timeCreated.valueOf();
      }

      return (0, _dates.default)().valueOf();
    }),
    communicationId: Ember.computed('customerParticipants.[]', 'participants.[]', 'type', 'consultParticipantId', function () {
      var PURPOSE = 'purpose';
      var participants = this.get('customerParticipants');
      var type = this.get('type');

      if (participants) {
        var participant = (0, _acd.getTrueCustomerIfMultipleCustomersExist)(this);

        if (!participant) {
          var consultParticipantId = this.get('consultParticipantId') || participants.mapBy('consultParticipantId').pop();

          if (this.get('isCall') && participants.length > 1 && consultParticipantId) {
            participant = participants.findBy('id', consultParticipantId);
          } else {
            participant = participants.findBy(PURPOSE, 'customer') || participants.findBy(PURPOSE, 'external');
          }
        }

        if (participant) {
          try {
            var mediaName = (0, _emberInflector.pluralize)(type).camelize();
            var communicationForMedia = participant[mediaName] || [];
            var communication = communicationForMedia[0];
            return communication ? communication.id : null;
          } catch (e) {
            return null;
          }
        }
      }

      return null;
    }),
    connectedCommunication: Ember.computed('type', 'participantForCurrentUser.{calls,chats,emails,messages,callbacks}', function () {
      var mediaChannels = null;

      switch (this.get('type')) {
        case 'call':
          mediaChannels = this.get('participantForCurrentUser.calls');
          break;

        case 'chat':
          mediaChannels = this.get('participantForCurrentUser.chats');
          break;

        case 'email':
          mediaChannels = this.get('participantForCurrentUser.emails');
          break;

        case 'message':
          mediaChannels = this.get('participantForCurrentUser.messages');
          break;

        case 'callback':
          mediaChannels = this.get('participantForCurrentUser.callbacks') || this.get('participantForCurrentUser.calls');
          break;

        default:
          mediaChannels = [];
      }

      var connectedMedia = mediaChannels.find(function (entry) {
        return entry.state === 'connected';
      });
      return connectedMedia;
    }),
    agentCommunicationId: Ember.computed.reads('connectedCommunication.id'),

    /**
     * Returns a boolean value based on if the selected interaction is an active conference.
     * An active conference is if there are 3 or more connected or connecting participants on a conversation.
     * If a participant has not yet connected, then this is still considered an active conference as far as the
     * UI is concerned. We can detect if the conversation was morphed into a conference based on `maxParticipants`
     * but that doesn't indicate if it's considered an active one (i.e. showing the conference indicator).
     */
    isActiveConference: Ember.computed('allConferenceParticipants.[]', 'participantForCurrentUser.consultParticipantId', function () {
      if (this.get('onConsult') || // Todo: replace this check with a more explicit check detailing what is a conference
      !this.get('conferenceMaxParticipants')) {
        return false;
      } // Note that `allConferenceParticipants` actually includes participants that are still connecting


      var participants = this.get('allConferenceParticipants') || [];
      return participants.length >= 2 && !!this.get('conferenceMaxParticipants');
    }),
    isAgentAssistACWEnabled: Ember.computed('session.features', 'agentAssistChecks.assistantState', 'permissions.canViewSummary', 'type', function () {
      var supportedMediaTypes = ['message'];
      var summarySupportForVoiceFTEnabled = this.get('session.features')['AI-768.voice-summarisation'] && this.get('session.features')['syn-AI-768-beta'];

      if (summarySupportForVoiceFTEnabled) {
        supportedMediaTypes.push('call');

        if (this.get('session.features')['CX.AI-914.smart-advisor-callback']) {
          supportedMediaTypes.push('callback');
        }
      }

      var isMediaTypeSupported = supportedMediaTypes.includes(this.get('type'));
      var assistantState = this.get('agentAssistChecks.assistantState') || new Map();
      return assistantState.get(this.get('id')) && isMediaTypeSupported && this.get('session.features')['CX.AI-863.add-acw-wrap-up'] && this.get('permissions.canViewSummary');
    }),
    myselfIsMonitor: Ember.computed.bool('conversation.myselfIsMonitor'),
    myselfIsCoach: Ember.computed.bool('conversation.myselfIsCoach'),
    myselfIsBarged: Ember.computed.bool('conversation.myselfIsBarged'),
    myselfIsMCB: Ember.computed.or('conversation.myselfIsMonitor', 'conversation.myselfIsCoach', 'conversation.myselfIsBarged'),
    monitoredParticipant: Ember.computed.readOnly('conversation.monitoredParticipant'),
    coachedParticipant: Ember.computed.readOnly('conversation.coachedParticipant'),
    bargedParticipant: Ember.computed.readOnly('conversation.bargedParticipant'),
    monitoredParticipantPerson: Ember.computed.readOnly('conversation.monitoredParticipantPerson'),
    coachedParticipantPerson: Ember.computed.readOnly('conversation.coachedParticipantPerson'),
    bargedParticipantPerson: Ember.computed.readOnly('conversation.bargedParticipantPerson'),

    /**
     * `true` if the current user is monitoring an agent - digital only at this point
     */
    myselfIsMonitorV2: Ember.computed.and('participantForCurrentUser.monitoredParticipantId', 'session.features.DgiMon', 'isSms'),

    /**
     * Returns the most recent transfer object from the `recentTransfers` array on the interaction object.
     * The `recentTransfers` object is created in descending order from when it was created so the most recent
     * is the last in the list.
     */
    mostRecentTransfer: Ember.computed.readOnly('recentTransfers.lastObject'),

    /**
     * This will return the currently active attended transfer is one is available. If none is returned then the conversation
     * is not being actively transferred. If one is found, then this conversation is currently an attended transfer.
     *
     * Note: all conversations transferred from the queue are "technically" attended. However we only want the attended transfers
     * from agent-to-agent thus we look for a `userId` on the initiator object to make sure we are not referencing a queue transfer.
     */
    activeAttendedTransfer: Ember.computed('mostRecentTransfer.{transferType,state,initiator}', function () {
      var mostRecentTransfer = this.get('mostRecentTransfer') || {};

      if (mostRecentTransfer) {
        var type = (mostRecentTransfer.transferType || '').toLowerCase();
        var state = (mostRecentTransfer.state || '').toLowerCase();
        var initiator = !!mostRecentTransfer.initiator ? mostRecentTransfer.initiator.userId : null;

        if (initiator && type === 'attended' && state === 'active') {
          return mostRecentTransfer;
        }
      }

      return null;
    }),

    /**
     * Returns `true` if the most recent transfer was canceled by the other agent being transferred to or
     * by the agent who initiated the transfer.
     *
     * Todo: update with logic to differentiate between when the initial agent cancels vs the other agent
     * declining the invite. Currently there is no way to tell the difference without comparing against user
     * UI action which seems too hacky/over engineering.
     */
    wasTransferCanceled: Ember.computed('recentTransfers.[]', 'participantForCurrentUser', function () {
      var participantForCurrentUser = this.get('participantForCurrentUser');
      var recentTransfer = this.get('recentTransfers.lastObject');
      return recentTransfer.transferType === 'attended' && recentTransfer.state === 'canceled' && recentTransfer.initiator.userId === participantForCurrentUser.userId;
    }),

    /**
     * Returns `true` if the most recent transfer was canceled by the other agent being transferred to or
     * by the agent who initiated the transfer.
     *
     * Todo: update with logic to differentiate between when the initial agent cancels vs the other agent
     * declining the invite. Currently there is no way to tell the difference without comparing against user
     * UI action which seems too hacky/over engineering.
     */
    wasTransferCompleted: Ember.computed('recentTransfers.[]', 'participantForCurrentUser', function () {
      var participantForCurrentUser = this.get('participantForCurrentUser');
      var recentTransfer = this.get('recentTransfers.lastObject');
      return recentTransfer.transferType === 'attended' && recentTransfer.state === 'complete' && recentTransfer.initiator.userId === participantForCurrentUser.userId;
    }),

    /**
     * `true` if the interaction is currently being transferred to another participant.
     * Will return `false` if the interaction has been answered by another participant or
     * if the current user has not initiated a transfer.
     */
    isAttendedTransferring: Ember.computed('type', 'activeAttendedTransfer', 'transferringToParticipant.{calls,chats,emails,messages,callbacks}.firstObject.{state}', 'participantForCurrentUser.{calls,chats,emails,messages,callbacks}.firstObject.{state}', function () {
      // if there is an active attended transfer then this conversation is being transferred
      var isActiveAttendedTransfer = !!this.get('activeAttendedTransfer');
      var currentUserParticipant = this.get('participantForCurrentUser');

      if (currentUserParticipant && isActiveAttendedTransfer) {
        var type = this.get('type');
        var currentParticipantState = this.communicationState(currentUserParticipant, type);
        return currentParticipantState !== _acdParticipantStatuses.default.DISCONNECTED.toLowerCase() && currentParticipantState !== _acdParticipantStatuses.default.TERMINATED.toLowerCase();
      }

      return false;
    }),
    customerDetails: Ember.computed('participantForCurrentUser', 'participantForCustomer', 'participantForCustomer.name', 'isEmail', 'draft', 'messages.[]', 'draft.to', 'draft.to.@each.email', function () {
      var details = {};
      var customer = this.get('participantForCustomer');
      var NO_NAME_LOCALE_KEY = "purecloud.acdInteraction.noName";
      var noName;

      if (customer && customer.name) {
        details.name = customer.name;
      } else if (this.get('formattedCustomerPhoneNumber')) {
        details.name = this.get('formattedCustomerPhoneNumber');
      } else {
        noName = true;
        details.name = this.get('intl').lookup(NO_NAME_LOCALE_KEY);
      }

      if (customer && customer.attributes) {
        (0, _each.default)(customer.attributes, function (value, property) {
          var parts = property.split('.');
          property = parts[parts.length - 1];
          details[property] = value;
        });
      }

      if (this.get('isEmail') && noName) {
        var fromEmail = this.get('messages.firstObject.from.email');
        var fromName = this.get('messages.firstObject.from.name');
        var from = fromName || fromEmail;

        if (from) {
          // Outbound emails can use the first
          // to address in the first message
          details.name = from;
        } else {
          var messageTo = this.get('messages.firstObject.to.firstObject.email');
          var draftTo = this.get('draft.to.firstObject.email');
          details.name = draftTo || messageTo;
        }
      } else if (this.get('isSms') && noName) {
        // this is true for web messaging as well as SMS
        var toAddress = this.get('participantForCurrentUser.messages.firstObject.toAddress.addressNormalized');
        var fromAddress = this.get('participantForCustomer.messages.firstObject.fromAddress.addressNormalized');
        details.isSmsDefault = true;

        if (!isGuid(fromAddress)) {
          details.name = this.get('addressformat').formatAddressForDisplay(fromAddress);
        } else if (!isGuid(toAddress)) {
          details.name = this.get('addressformat').formatAddressForDisplay(toAddress);
        } else {
          details.name = this.get('intl').lookup(NO_NAME_LOCALE_KEY);
        }
      }

      return details;
    }),
    isInteractionActive: Ember.computed('isAfterCallWorkCompleted', 'isDisconnected', 'isTerminated', 'participantsForCurrentUser.[]', 'isActive', function () {
      var _this = this;

      var isCallStillActive = function isCallStillActive() {
        var isCompleted = _this.get('isAfterCallWorkCompleted') && (_this.get('isDisconnected') || _this.get('isTerminated'));

        return !isCompleted;
      };

      var type = this.get('type');
      var result = false;

      if (type === 'call') {
        result = isCallStillActive();
      } else {
        result = this.get('isActive');
      }

      this.get('logger').debug("ACD: isInteractionActive id: ".concat(this.get('id'), " isActive: ").concat(result, ". Update will be ignored if isActive is false."));
      return result;
    }),
    isActive: Ember.computed('participantForCurrentUser', 'isDisconnected', 'isParked', 'isTerminated', 'isAfterCallWorkCompleted', function () {
      if (!this.get('participantForCurrentUser') || this.get('session.useParkEmail') && this.get('isParked')) {
        return false;
      }

      var isActive = !(this.get('isDisconnected') || this.get('isTerminated')) || !this.get('isAfterCallWorkCompleted');
      return isActive;
    }),
    active: Ember.computed.reads('isActive'),
    modelSet: function modelSet(property, value) {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        this.set(property, value);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('timeCreated', (0, _dates.default)()); // added to log information about the structure of customer phone, sms, or whatsapp

      (0, _logE164Info.default)(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.cleanup();
    },
    cleanup: function cleanup() {
      if (this.get('isPlacingCallTimer')) {
        Ember.run.cancel(this.get('isPlacingCallTimer'));
      }
    },

    /**
     * Returns whatever the communication state of the given participant is in context of a conversation.
     *
     * @param {Participant} participant the participant object to check against
     * @param {CommunicationType} type the type of communication media that is being displayed
     * @returns the current state of the latest media item of the participant or null if not determined
     */
    communicationState: function communicationState(participant, type) {
      var state = Ember.get(participant, "".concat(type, "s.firstObject.state"));
      return state ? state : null;
    }
  }]));

  _exports.default = _default;
});