define("web-directory/components/target-dropdown/data/filter-store-config.const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.baseStoreConfig = void 0;
  var searchAll = '*';
  var baseStoreConfig = {
    user: {
      store: 'public-api-user',
      options: function options(search) {
        return {
          query: search ? [{
            value: search,
            type: 'QUERY_STRING'
          }] : undefined,
          pageNumber: 1,
          pageSize: 10,
          sortBy: 'name',
          sortOrder: 'ASC'
        };
      }
    },
    queue: {
      store: 'queue',
      options: function options(search, divisionAgnostic, myQueuesOnly) {
        return {
          name: "**".concat(search ? search : searchAll, "**"),
          pageNumber: 1,
          pageSize: 10,
          sortBy: 'name',
          sortOrder: 'ASC',
          divisionAgnostic: divisionAgnostic,
          myQueuesOnly: myQueuesOnly
        };
      }
    },
    external: {
      store: 'externalcontacts/contact',
      options: function options(search) {
        return {
          pageNumber: 1,
          pageSize: 10,
          expandField: 'externalOrganization,division',
          searchTerm: "".concat(search)
        };
      }
    }
  };
  _exports.baseStoreConfig = baseStoreConfig;
});