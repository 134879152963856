define("web-directory/components/app-embed/component", ["exports", "web-directory/models/app-api-call", "web-directory/utils/apps", "lodash"], function (_exports, _appApiCall, _apps, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      computed = Ember.computed,
      run = Ember.run,
      isBlank = Ember.isBlank,
      typeOf = Ember.typeOf;
  var defaultInternalSandboxOptions = ['allow-same-origin'];
  var API_USER_MESSAGE_PREFIX = 'PureCloud Apps API Error: ';
  var AppEmbedComponent = Component.extend({
    tagName: 'iframe',
    classNameBindings: [':app-embed', 'appConfig.internal:internal-app:external-app', 'appMarkerClass'],
    attributeBindings: ['name', 'src', 'sandbox', 'ariaHiddenAttr:aria-hidden', 'hiddenAttr:hidden', 'permissionsPolicy:allow', 'viewId:data-app-id', 'role'],
    // Attributes
    appConfig: null,
    hidden: false,
    onAppLoad: null,
    onAppApiCall: null,
    name: computed('appConfig.id', function () {
      var appId = this.get('appConfig.id');

      if (!appId) {
        appId = 'unknown';
      }

      return Ember.String.dasherize("app-".concat(appId));
    }),
    src: computed.reads('appConfig.url'),
    sandbox: computed('appConfig.id', 'appConfig.sandbox', 'appConfig.internal', function () {
      var finalSandboxOptions = [];
      var appSandboxSettings = this.get('appConfig.sandbox');

      if (appSandboxSettings && Array.isArray(appSandboxSettings)) {
        finalSandboxOptions = appSandboxSettings;
      }

      if (this.get('appConfig.internal') === true) {
        finalSandboxOptions = _lodash.default.union(finalSandboxOptions, defaultInternalSandboxOptions);
      }

      return finalSandboxOptions.join(' ');
    }),
    ariaHiddenAttr: computed('hidden', function () {
      return this.get('hidden') ? 'true' : 'false';
    }),
    hiddenAttr: computed.bool('hidden'),

    /*
     * Grant specific features to the src domain.  This attribute will be consulted by a subset of
     * our supported browsers (e.g. Chrome, Safari) and ignored by the rest. If all of our supported
     * browsers use this attribute in the future, we can deny by default and use an opt-in paradigm.
     */
    permissionsPolicy: computed('appConfig.permissionsPolicy', function () {
      var configPolicy = this.get('appConfig.permissionsPolicy');
      if (!configPolicy || !Array.isArray(configPolicy)) return '';
      return configPolicy.join('; ');
    }),
    viewId: computed('appConfig.id', function () {
      var appId = this.get('appConfig.id');
      if (!appId) return;
      return _apps.default.buildViewId(appId);
    }),
    role: 'presentation',
    appMarkerClass: computed('appConfig.id', function () {
      var result = '';
      var appId = this.get('appConfig.id');

      if (appId) {
        result = Ember.String.dasherize("app-".concat(appId));
      }

      return result;
    }),
    // Listener tracking vars
    $lastFrame: null,
    lastFrameSrc: null,
    lastFrameLoaded: false,
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      var $iframe = this.$();

      if ($iframe && !isBlank($iframe.attr('src'))) {
        this.$lastFrame = $iframe;
        this.lastFrameSrc = $iframe.attr('src');

        this._monitorFrameLoad(this.$lastFrame);
      }
    },
    willRender: function willRender() {
      this._super.apply(this, arguments);

      var $iframe = this.$();

      if ($iframe !== this.$lastFrame || this.get('src') !== this.lastFrameSrc) {
        // New iframe or url
        if (this.$lastFrame && !this.lastFrameLoaded) {
          // remove previous listener
          this.$lastFrame.off("load.".concat(this.elementId));
        }

        this.lastFrameLoaded = false;

        if ($iframe && !isBlank(this.get('src'))) {
          this.$lastFrame = $iframe;
          this.lastFrameSrc = this.get('src');

          this._monitorFrameLoad(this.$lastFrame);
        } else {
          this.$lastFrame = null;
          this.lastFrameSrc = null;
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$(window).on("message.".concat(this.elementId, " onmessage.").concat(this.elementId), run.bind(this, '_handlePostMessage'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.$lastFrame && !this.lastFrameLoaded) {
        // remove previous listener
        this.$lastFrame.off("load.".concat(this.elementId));
      }

      Ember.$(window).off("message.".concat(this.elementId, " onmessage.").concat(this.elementId));
    },
    _monitorFrameLoad: function _monitorFrameLoad($iframe) {
      var _this = this;

      var onAppLoad = this.get('onAppLoad');

      if (onAppLoad && typeof onAppLoad === 'function') {
        // Not using jQuery.one as I want the ability to explicitly remove this
        // listener in case it doesn't fire for some reason
        $iframe.on("load.".concat(this.elementId), function () {
          run(function () {
            _this.lastFrameLoaded = true;
            $iframe.off("load.".concat(_this.elementId));

            if (!_this.get('isDestroyed')) {
              onAppLoad();
            }
          });
        });
      }
    },
    _handlePostMessage: function _handlePostMessage(evt) {
      var appApiHandler = this.get('onAppApiCall');
      var appUrl = this.get('appConfig.url'); // Base state check

      if (!appApiHandler || !appUrl || this.get('isDestroying') || this.get('isDestoyed') || !evt.originalEvent || !evt.originalEvent.source || !evt.originalEvent.origin || evt.originalEvent.origin.trim().length === 0) {
        // Fail fast - Invalid/Noop state or invalid postMessage event
        return;
      } // Validate this postMessage is from our app's frame


      var $iframe = this.$();
      var iframeWindow = null;

      try {
        iframeWindow = $iframe.length === 1 ? $iframe[0].contentWindow : null;
      } catch (e) {
        Ember.Logger.error(_apps.default.buildApiLogMsg('Error occurred obtaining iframe window for app message source validation'), e);
        iframeWindow = null;
      }

      if (!iframeWindow || evt.originalEvent.source !== iframeWindow) {
        return;
      } // Validate message sender is still the app

      /* eslint-disable no-console */


      var evtOrigin = evt.originalEvent.origin;

      if (evtOrigin === 'null') {
        var sandboxPermissions = this.get('appConfig.sandbox');
        var hasSameOrigin = sandboxPermissions && Ember.isArray(sandboxPermissions) && sandboxPermissions.indexOf('allow-same-origin') >= 0;
        var messagePrefix = "".concat(API_USER_MESSAGE_PREFIX, "API Message origin could not be determined.  ");

        if (hasSameOrigin) {
          Ember.Logger.warn(_apps.default.buildApiLogMsg("App posted message with origin reported as 'null' but has 'allow-same-origin' sandbox flag."), {
            appUrl: appUrl,
            remoteData: {
              appId: this.get('appConfig.id')
            }
          });
          console.error("".concat(messagePrefix, "Please notify the PureCloud dev team at https://developer.mypurecloud.com"));
        } else {
          console.error("".concat(messagePrefix, "Ensure your app has the 'allow-same-origin' permission enabled."));
        }

        return;
      }

      try {
        if (!this._validateMessageOrigin(evtOrigin, appUrl)) {
          Ember.Logger.error(_apps.default.buildApiLogMsg('App posted message from invalid origin.'), {
            appUrl: appUrl,
            origin: evtOrigin,
            expectedOrigin: appUrl,
            remoteData: {
              appId: this.get('appConfig.id')
            }
          });
          console.error("".concat(API_USER_MESSAGE_PREFIX, "For security reasons, you can only submit an API call from the registered app's domain."));
          return;
        }
      } catch (e) {
        Ember.Logger.error(_apps.default.buildApiLogMsg('Error occurred when validating apps message origin.'), {
          appUrl: appUrl,
          origin: evtOrigin,
          expectedOrigin: appUrl,
          remoteData: {
            appId: this.get('appConfig.id')
          }
        }, e);
        return;
      }

      var apiCall = null;
      var failureReason = null;

      try {
        // Strict parse
        apiCall = (0, _appApiCall.parseMessage)(evt.originalEvent.data, true);
      } catch (parseError) {
        // TECHDEBT: Remove lenient retry after deprecation period
        if (parseError.message === 'missingProtocol') {
          try {
            // Re-parse leniently to allow for short deprecation period and logging
            apiCall = (0, _appApiCall.parseMessage)(evt.originalEvent.data, false);
            Ember.Logger.warn(_apps.default.buildApiLogMsg('App posted message without protocol information'), {
              appUrl: appUrl,
              remoteData: {
                appId: this.get('appConfig.id')
              }
            });
            console.warn(API_USER_MESSAGE_PREFIX, 'Deprecation Warning.  Protocol information will soon be required for all app api calls.', "We recommend using the official Client Apps SDK (".concat(_apps.default.SDK_REPO_URL, ")."));
          } catch (lenientParseFailure) {
            failureReason = lenientParseFailure;
          }
        } else {
          failureReason = parseError;
        }
      }
      /* eslint-enable no-console */


      if (apiCall) {
        if (!this.get('appsSdkEnabled')) {
          var msg = 'Client App SDK is disabled. Incoming API calls will be ignored.';
          Ember.Logger.warn(_apps.default.buildApiLogMsg(msg), {
            appUrl: appUrl,
            remoteData: {
              appId: this.get('appConfig.id')
            }
          });
          return;
        }

        apiCall.set('internalPcCall', false);
        apiCall.set('srcApp', this.get('appConfig'));
        appApiHandler(apiCall);
      } else {
        Ember.Logger.warn(_apps.default.buildApiLogMsg('App posted invalid message'), {
          appUrl: appUrl,
          failureReason: failureReason,
          remoteData: {
            appId: this.get('appConfig.id')
          }
        });
      }
    },

    /**
     * Validates if origin is the base url of expected.
     *
     * URLs will be normalized (e.g. default ports and paths) for the comparison as
     * the browser may drop defaults or make other slight mods to the origin from
     * the explicit URL.  Because of the need to parse the URLs, urls must explicitly
     * include the protocol or the check will fail fast.
     *
     * @returns true if origin is the base url of expected; false otherwise.
     */
    _validateMessageOrigin: function _validateMessageOrigin(origin, expected) {
      try {
        return _lodash.default.startsWith(this._normalizeUrl(expected), this._normalizeUrl(origin));
      } catch (e) {
        return false;
      }
    },
    _normalizeUrl: function _normalizeUrl(url) {
      if (!url || typeOf(url) !== 'string' || !url.match(/^https?:\/\/[^\s\/\:\?#]+/)) {
        throw new Error('invalidUrl');
      }

      var parser = document.createElement('a');
      parser.href = url;

      if (!parser.hostname) {
        throw new Error('invalidUrl');
      }

      var result = "".concat(parser.protocol, "//").concat(parser.hostname);
      var normalizedPort = parser.port;

      if (!normalizedPort) {
        switch (parser.protocol) {
          case 'http:':
            normalizedPort = '80';
            break;

          case 'https:':
            normalizedPort = '443';
            break;

          default:
            break;
        }
      }

      if (normalizedPort) {
        result += ":".concat(normalizedPort);
      }

      result += parser.pathname + parser.search + parser.hash;
      return result;
    }
  });
  var _default = AppEmbedComponent;
  _exports.default = _default;
});