define("web-directory/utils/feature-toggles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    acdAAEnabledToast: true,
    // ACE-2960
    acdAnnounceMonitoring: true,
    // ACE-2630
    acdAnnounceMonitoringDigital: true,
    // ACE-2630
    acdConnectedToTwoCallsFix: true,
    // SERVOPS-34068 fix ACE-2594
    acdDialpadDialing: true,
    // Partially Enabled | ACE-1061 | SERVOPS-12594 | can remove with useMultiPanel
    acdDivisionPresenceProfileToggle: true,
    // Secondary translation fix for regions | ACE-3661
    acdEmailEnhancementsPhase1: true,
    // Partially Enabled | ACE-2543 | PURE-4715
    acdEmailNewDraftFlow: true,
    acdEmailNewLineOnPaste: true,
    // Partially Enabled | ACE-1350 | SERVOPS-17148
    acdEmailPreviewPerformance: true,
    // SERVOPS-35725
    acdEmailReloadImageBtn: true,
    // Partially Enabled | ACE-786 | SERVOPS-8993
    acdEmberTooltips: true,
    // ACE-3876 Ember tooltip new style parity
    acdHybridToastNoActiveQueue: true,
    // Partially Enabled | ACE-1440
    acdInteractionProcessingChain: true,
    // Remove Me
    acdLastLogin: true,
    acdMaxThirdPartyMessageLimit: true,
    // PURE-5733
    acdMessagingPerformance: true,
    acdMultiConsultInitiators: true,
    // ACE-2670
    acdMultipanel: true,
    // ACE-2967
    acdMultipanelForced: true,
    // ACE-4054
    acdNormalizedMessages: true,
    // ACE-2199
    acdOutboundWhatsApp: true,
    // PURE-2802 | ACE-2511
    acdParkEmail: true,
    // Partially Enabled | ACE-2880 | MSGUI-358 | PURE-4201
    acdSelectionEnabled: true,
    acdTagsInput: true,
    // ACE-3640 | ACE-3655
    acdTransferPermissions: true,
    //PURE-4261
    'ACE-4129_acdEnhancedSelection': true,
    // ACE-4102 | ACE-4129
    'ACE-4461_org-settings-fetch': true,
    'ACT-1205': true,
    // ACT-1205 Adding Agent Greeting from Agent UI Settings to Preferences
    'ACT-851_custom-fields-ui': true,
    // ACT-851 Replace ember-engine-custom-fields with custom-fields-ui
    'adminUI.v3.IFC.migration': true,
    agentAddressOnBehalfOfQueue: true,
    // MSG-5191 | PURE-3846 | TODO MSG-5859: remove toggle once direct routing is GA
    agentUIDefaultPanel3129: true,
    agentUIV2MigrateWrapupCodes: true,
    'AI-1001': true,
    'AI-1004': true,
    'AI-1014.agent-copilot': true,
    'AI-1025': true,
    // ACE-4351 | AI-1025 Facebook Social Channel Support
    'AI-1105.copilot-acw-readability-improvements': true,
    'AI-1118_copilot-improved-user-experience': true,
    'AI-1199_summary_workbench': true,
    // AI-1199 Summary Workbench
    'AI-1206_guides_for_va': true,
    // AI-1206 Guides for Virtual Agents
    'AI-768.voice-summarisation': true,
    // TODO: AA-2639: Remove once Voice Summarization has been released
    'AI-800_GMiner_FAQ_Mining': true,
    // GM-2306
    'ATLAS-467_integrate-connections-ui': true,
    // ATLAS-467: Integrate Connections UI IFC client into Web Directory
    'ATLAS-536_EnableContentSquare': true,
    'ATLAS-99_nav-system': true,
    auraACWGadgets: true,
    //AURA-88
    backgroundAssistantScreenRecording: true,
    // WEM-80
    'browser-log-debug-enabled': true,
    //PURE-3205
    'callbacks.inherit.routing.from.inbound.call': true,
    // CB-325
    'coaching.central-ui': true,
    'coaching.overdue.ui': true,
    'coaching.usability.ui': true,
    'coaching.wem-addon': true,
    'coaching.wfm': true,
    'cobrowse.popoutAuthWindowFlow': true,
    cobrowseAssist: true,
    'comui-disable-legacy-api-url': true,
    conversationSummary3256: true,
    // ATEAM-1229
    conversationSummaryProfileCard: true,
    cookieConsentNotification: true,
    'CX.AI-571.smart-advisor': true,
    // AI-571
    'CX.AI-572.smart-advisor-products': true,
    'CX.AI-572.smart-advisor-voice': true,
    'CX.AI-863.add-acw-wrap-up': true,
    'CX.AI-914.smart-advisor-callback': true,
    'CX.AI-921.co-pilot': true,
    DgiMon: true,
    // AI-537
    digitalFirstPURE2942: true,
    'DX.AI-818.co-pilot-advanced-summarization': true,
    'DX.AI-865.wrap-up-code-prediction': true,
    'email.admin.ui.refresh.PURE-4712': true,
    emergencyLocation: true,
    enableUIAjaxRetryHeader: true,
    'epc.decomp.drop-down.chat-preferences': true,
    epcDecompDropDownGroupProfileAllMembers: true,
    epcDecompDropDownGroupProfileEdit: true,
    epcDecompEntityContactGroup: true,
    epcDecompEntityLocation: true,
    epcDecompNavigationSubMenu: true,
    epcDecompPresenceLabel: true,
    extendedChatCommands: true,
    // used for disabling internal commands outside of our org
    freeTrial: true,
    // OB-272
    'GAME-3913_contests_phase_1': true,
    // GAME-3913
    'gcucc-ui-PCUCC-3498': true,
    'gcucc-ui-PCUCC-3734': true,
    // PCUCC-3734
    'gcucc.ui.addChatWakeUpListener': true,
    // PCUCC-1810
    'gcucc.ui.apps.collaborateChat': true,
    // PCUCC-1893; use for collaborate-chat client app
    'gcucc.ui.apps.faxes': true,
    // use ucc ui faxes client app
    'gcucc.ui.apps.voicemail': true,
    // use ucc ui voicemail client app
    'gcucc.ui.chatBadging': true,
    // PCUCC-1008
    'gcucc.ui.PCUCC-3104': true,
    'gcucc.ui.PURE-4771': true,
    'gcucc.ui.removeGcUserFromGroup.PCUCC-2543': true,
    // PCUCC-2543
    'gcucc.ui.useIntegrationPresenceSourceDirectorySearch': true,
    // PCUCC-1007
    'gcuccui-2090-clean-room-data': true,
    'GCUCCUI-2587_includeBadge': true,
    'gpr.AP-8265.showCustomKpiLink': true,
    // AP-15781
    groupProileNoIndividualsSave: true,
    // PLUG-188
    hawkClientScreenShare: true,
    hideAdhocRecordingsUi: true,
    // PCUCC-4176
    inboxSharing: true,
    'integrations.actions.decomposition.PLTRES-424': true,
    // INTEGRATIONS ACTIONS ADMIN UI DECOMPOSITION
    'integrations.decomposition.REG-1827': true,
    // INTEGRATIONS ADMIN UI DECOMPOSITION
    interactionNameQueueCallingQueue: true,
    // ACE-1872
    'learning.release.assessment.ui': true,
    'learning.restrict-access': true,
    messagingGadget: true,
    // MG-470
    'ner-PURE-4556': true,
    // NER-780
    'never-free-seating': true,
    notificationOutputPicker: true,
    playRecordingFromSelectedOutput: true,
    'PLUG-74_user-image-upload': true,
    postinoEmailDraftChangedEvents: true,
    'PURE-3256_useConversationDetails': true,
    // // ATEAM-1229
    'PURE-3835': true,
    // ACE-3769 Division name in interaction header, roster group, and outbound dialog search.
    'PURE-4948_scripter_workItem': true,
    // CC-4814
    'PURE-5007_digitalConsult': true,
    // PURE-5007
    'PURE-5112_group-profile': true,
    // PURE-5112 group profile decomp
    'PURE-5186_CoreRulesAndDecisions': true,
    'PURE-5356_acd_vm_transcription': true,
    'PURE-5421_CannedResponse_Gadget': true,
    // PURE-5421
    'PURE-5459_ContentManagement_Decomposition': true,
    'pure-5570-cobrowse-appv2': true,
    'PURE-5705_CloudTrunkStatusAndMetrics': true,
    'PURE-5797_MESSAGING_TWITTER_PRIVATE_DIRECT_MESSAGES': true,
    // ACE-4322 | PURE-5797 X (Twitter) Social Channel Support
    'PURE-5822-work-items-panel-manager-support': true,
    'PURE-6104_no_snatch_phone': true,
    'PURE-6186_Scripter_Gadget_GCUI': true,
    // CC-4815
    'PURE-6259_WorkAutomationRename': true,
    // PURE-6259
    'PURE-6472_Disconnected_Conversation_Icons': true,
    // ACE-4511 | PURE-6472: Update to new icons for disconnected conversations
    'PURE3301-dynamicGroups': true,
    pure4201EmailParking: true,
    // ACE-2880 backend
    'qm.release.evaluation.assignee': true,
    // WEM-742
    'qm.release.evaluation.disputes': true,
    // WEM-689
    'qm.release.pure-2967.timezone.selection': true,
    //PURE-2967
    'qm.ui.qualityAdmin.encryptionKeys': true,
    'qm.ui.qualityAdmin.qualityForms': true,
    'relate-ui-profilePanelRefresh': true,
    // RELATE-11001
    relateDynamicSchema: true,
    responseManagementMessagingTemplate: true,
    showVideoInThumbnail: true,
    // Show video on thumbnail if there is a screen stream, and participant is major, and video is not muted
    'switchboardUI.notificationRules': true,
    // WEM-578
    'syn-AI-768-beta': true,
    // TODO: AA-2639: Remove once Voice Summarization has been released
    'TC-351': true,
    // TC-351 - Rewrite of /api/v2/stations API
    telephonyAdminUIHideCloudEdges: true,
    // PURE-6208
    uiOptimizeChatParticipantsLoading: true,
    userRecordingDownload: true,
    // CM-5713: feature toggle for new API to download user recordings for Calls.
    'webmessaging.4194.messengerAdminRedesign': true,
    // PURE-4194
    webrtcClientNetworkProbeAutoAdjust: true,
    webrtcDebugger: true,
    // used to disable in prod
    webrtcDefaultDeviceProfile: true,
    //PURE-5609
    webrtcDisableDeviceProfileNotifications: true,
    // PURE-5609
    webrtcHeadsetControlsOrchestration: true,
    webrtcMMSDnsCheck: true,
    webrtcRelayOnly: true,
    webrtcTrackBasedActions: true,
    webrtcTroubleshooterIframe: true,
    webrtcVideoDirectLink: true,
    webTelemetryRaw: true,
    webUiDarkTheme: true,
    'WEM-885_ReprocessInteractionsUI': true,
    wfpDecisionsDataDownload: true // WFM-7356

  };
  _exports.default = _default;
});