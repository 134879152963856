define("web-directory/components/command-panel/panel-navigation/component", ["exports", "web-directory/services/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['command-panel-nav'],
    events: Ember.inject.service('events'),
    definition: null,
    actions: {
      selectDrawer: function selectDrawer(panelId, drawerId) {
        this.get('events').triggerEvent(_events.TOGGLE_ACTIVE_DRAWER_EVENT, {
          panelId: panelId,
          drawerId: drawerId
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});