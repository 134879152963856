define("web-directory/components/preferences-notifications-desktop/component", ["exports", "lodash", "ember-purecloud-components/mixins/logger"], function (_exports, _lodash, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      on = Ember.on,
      computed = Ember.computed,
      observer = Ember.observer,
      Component = Ember.Component;

  var _default = Component.extend(_logger.default, {
    classNames: ['preferences-notifications-desktop'],
    intl: inject.service(),
    chat: inject.service(),
    application: inject.service(),
    preferences: inject.service(),
    session: inject.service(),
    settings: inject.service(),
    webrtcSettings: inject.service(),
    shouldSetDesktopPreferences: false,
    showCheckBrowser: false,
    hidePlaySound: false,
    hideSoundSelector: false,
    playSound: true,
    soundName: 'PureCloud Notification',
    shouldShowBrowseForSound: false,
    bounceDockIcon: 1,
    showToast: true,
    flashTaskbar: true,
    showGroupNotifications: false,
    soundOptions: [],
    isMac: false,
    isWindows: false,
    useDesktopGroupNotificationSetting: false,
    allowDisableProfileNotifications: Ember.computed.readOnly('session.features.webrtcDisableDeviceProfileNotifications'),
    disableProfileNotifications: Ember.computed.reads('webrtcSettings.settings.directorySettings.disableProfileNotifications'),
    bounceDockIconOptions: computed(function () {
      var intl = this.get('intl');
      return [{
        value: 0,
        label: intl.lookup('preferences.notifications.dockBounceNone').toString()
      }, {
        value: 1,
        label: intl.lookup('preferences.notifications.dockBounceOnce').toString()
      }, {
        value: 2,
        label: intl.lookup('preferences.notifications.dockBounceContinually').toString()
      }];
    }),
    loadPreferences: on('init', function () {
      var _this = this;

      this.isMac = this.get('application.hostedContext').isMac();
      this.isWindows = this.get('application.hostedContext').isWindows();
      this.get('application.hostedContext').requestDesktopPromise({
        cmd: 'getSoundList'
      }).then(function (val) {
        _this.set('soundOptions', val);
      });
      this.get('application.hostedContext').requestDesktopPromise({
        cmd: 'getPreferences',
        prefs: this.prefNames()
      }).then(function () {
        var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        _this.set('playSound', prefs.notification_playSound);

        _this.set('soundName', prefs.notification_soundName);

        _this.set('bounceDockIcon', prefs.notification_bounceDockIcon);

        _this.set('showToast', prefs.notification_showToast);

        _this.set('flashTaskbar', prefs.notification_flashTaskbar);

        if (_this.get('useDesktopGroupNotificationSetting')) {
          _this.set('showGroupNotifications', prefs.notification_showGroupNotifications);
        }

        _this.shouldSetDesktopPreferences = true;
      });

      if (!this.get('useDesktopGroupNotificationSetting')) {
        this.get('preferences').getPreference('notifications.global').then(function () {
          var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

          _this.set('showGroupNotifications', prefs.showGroupNotifications);
        });
      }
    }),
    prefNames: function prefNames() {
      var prefs = ['notification_playSound', 'notification_soundName', 'notification_showToast'];

      if (this.isMac) {
        prefs.push('notification_bounceDockIcon');
      }

      if (this.isWindows) {
        prefs.push('notification_flashTaskbar');
      }

      return prefs;
    },
    prefDict: function prefDict() {
      var prefs = {
        notification_playSound: this.playSound,
        notification_soundName: this.soundName,
        notification_showToast: this.showToast
      };

      if (this.get('useDesktopGroupNotificationSetting')) {
        prefs.notification_showGroupNotifications = this.showGroupNotifications;
      }

      if (this.isMac) {
        prefs.notification_bounceDockIcon = this.bounceDockIcon;
      }

      if (this.isWindows) {
        prefs.notification_flashTaskbar = this.flashTaskbar;
      }

      return prefs;
    },
    modifySoundOptions: function modifySoundOptions(val) {
      var opts = this.get('soundOptions');

      var custom = _lodash.default.find(opts, function (opt) {
        return opt.value === 'Custom';
      });

      if (custom !== null) {
        Ember.set(custom, 'label', val);
      }
    },
    changeDesktopPreferences: Ember.observer('playSound', 'soundName', 'bounceDockIcon', 'showToast', 'flashTaskbar', function () {
      if (this.get('showToast')) {
        this.set('hidePlaySound', false);
      } else {
        this.set('hidePlaySound', true);
      }

      if (this.get('playSound')) {
        this.set('hideSoundSelector', false);
      } else {
        this.set('hideSoundSelector', true);
      }

      if (this.isWindows) {
        this.set('shouldShowBrowseForSound', this.get('soundName') === 'Custom');
      }

      if (this.shouldSetDesktopPreferences) {
        this.get('application.hostedContext').requestDesktopPromise({
          cmd: 'setPreferences',
          prefs: this.prefDict()
        });
      }
    }),
    changeGroupNotificationPreference: observer('showGroupNotifications', function () {
      var _this2 = this;

      if (!this.get('useDesktopGroupNotificationSetting')) {
        var preferences = this.get('preferences');
        preferences.getPreference('notifications.global').then(function () {
          var prefs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          prefs.showGroupNotifications = _this2.get('showGroupNotifications');
          preferences.setPreference('notifications.global', prefs);
        });
      }
    }),
    actions: {
      togglePlaySound: function togglePlaySound() {
        this.toggleProperty('playSound');
      },
      toggleShowToast: function toggleShowToast() {
        this.toggleProperty('showToast');
      },
      toggleFlashTaskbar: function toggleFlashTaskbar() {
        this.toggleProperty('flashTaskbar');
      },
      browseForSound: function browseForSound() {
        var _this3 = this;

        return this.get('application.hostedContext').requestDesktopPromise({
          cmd: 'browseForSound'
        }).then(function (val) {
          _this3.modifySoundOptions(val.newCustomLabel);
        });
      },
      playSoundSample: function playSoundSample() {
        this.get('application.hostedContext').requestDesktopPromise({
          cmd: 'playSound',
          soundName: this.soundName
        });
      },
      sendTestNotification: function sendTestNotification() {
        this.get('chat').sendTestNotification();
      },
      changeProfileNotificationSetting: function changeProfileNotificationSetting() {
        if (!this.get('allowDisableProfileNotifications')) {
          return;
        }

        this.toggleProperty('disableProfileNotifications');
        this.get('webrtcSettings').updateSetting('directorySettings.disableProfileNotifications', this.get('disableProfileNotifications'));
      }
    }
  });

  _exports.default = _default;
});