define("web-directory/utils/app-provider", ["exports", "boon-js", "web-directory/utils/boolean-expressions"], function (_exports, _boonJs, _booleanExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePortNumber = _exports.default = _exports._buildProviderConfig = _exports._buildAppProviderComputed = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _buildAppProviderComputed = function _buildAppProviderComputed(providerConfig) {
    var _Ember;

    var dependencies = ['permissions.donutPermissions.[]', 'session.features'];
    var keys = Object.keys(providerConfig);
    keys.forEach(function (currKey) {
      if (providerConfig[currKey].dependencies) {
        dependencies = [].concat(_toConsumableArray(dependencies), _toConsumableArray(providerConfig[currKey].dependencies));
      }
    });
    dependencies = _toConsumableArray(new Set(dependencies));

    var evaluateProviderExpression = function evaluateProviderExpression(expr, features, permissions) {
      return (0, _booleanExpressions.buildSymbolMap)(expr, {
        ft: function ft(keyName) {
          return features[keyName];
        },
        perm: function perm(keyName) {
          return permissions.validateDonutPermission(keyName);
        },
        bool: function bool(keyName) {
          return keyName === 'true';
        }
      });
    };

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependencies).concat([function () {
      var _this = this;

      var validProviders = [];
      var permissions = this.get('permissions');
      var features = this.get('session.features');
      keys.forEach(function (currKey) {
        var boolExpr = providerConfig[currKey].boolExpr;
        var oldProvider = providerConfig[currKey].providerInstance;
        var validProvider;

        if (boolExpr) {
          try {
            validProvider = providerConfig[currKey].evaluator(evaluateProviderExpression(boolExpr, features, permissions));
          } catch (error) {
            validProvider = false;
          }
        } else {
          validProvider = true;

          _this.logger.warn('There are no feature toggle or permission restrictions for provider: ', {
            key: currKey
          });
        }

        if (validProvider) {
          if (!oldProvider) {
            providerConfig[currKey].providerInstance = Ember.getOwner(_this).lookup('app-provider:' + currKey);
          }

          validProviders.push(providerConfig[currKey].providerInstance);
        } else {
          if (oldProvider) {
            oldProvider.destroy();
            providerConfig[currKey].providerInstance = null;
          }
        }
      });
      return validProviders;
    }]));
  };

  _exports._buildAppProviderComputed = _buildAppProviderComputed;

  var _buildProviderConfig = function _buildProviderConfig(providerConfigMap, logger) {
    if (!providerConfigMap) {
      return {};
    }

    var keys = Object.keys(providerConfigMap);
    var providerConfig = {};
    keys.forEach(function (currKey) {
      var boolExpr = providerConfigMap[currKey];
      providerConfig[currKey] = {
        providerInstance: null,
        boolExpr: boolExpr
      };

      try {
        if (boolExpr) {
          providerConfig[currKey].evaluator = (0, _boonJs.getEvaluator)(boolExpr);
          var currDependencies = (0, _boonJs.parse)(boolExpr).filter(function (curr) {
            return curr.name === 'IDENTIFIER';
          }).map(function (curr) {
            var _curr$value$split = curr.value.split(':'),
                _curr$value$split2 = _slicedToArray(_curr$value$split, 2),
                valueTag = _curr$value$split2[0],
                value = _curr$value$split2[1];

            if (!value || value === '') {
              throw new Error('Invalid element in boolean expression');
            }

            if (valueTag === 'ft') {
              return 'session.features.' + value;
            } else if (valueTag === 'perm' || valueTag === 'bool' && (value === 'true' || value === 'false')) {
              return '';
            } else {
              throw new Error('Invalid element in boolean expression');
            }
          }).filter(function (curr) {
            return curr !== '';
          });
          providerConfig[currKey].dependencies = currDependencies;
        }
      } catch (err) {
        delete providerConfig[currKey];
        logger.warn('Invalid feature toggle and permission restrictions expression for provider: ', {
          key: currKey
        });
      }
    });
    return providerConfig;
  };

  _exports._buildProviderConfig = _buildProviderConfig;

  var validatePortNumber = function validatePortNumber(portToValidate, defaultPort) {
    var portInt = +portToValidate;

    if (isNaN(portInt) || portInt <= 0 || portInt >= 65535) {
      return defaultPort;
    }

    return portInt;
  };

  _exports.validatePortNumber = validatePortNumber;
  var _default = {
    _buildProviderConfig: _buildProviderConfig,
    _buildAppProviderComputed: _buildAppProviderComputed
  };
  _exports.default = _default;
});