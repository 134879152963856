define("web-directory/services/presence-subscription", ["exports", "lodash", "ember-purecloud-components/services/streaming", "web-directory/utils/dates", "web-directory/utils/entity-fields", "ember-purecloud-components/mixins/logger", "ember-ajax/errors", "web-directory/utils/presence-utils", "web-directory/mixins/entity-presence"], function (_exports, _lodash, _streaming, _dates, _entityFields, _logger, _errors, _presenceUtils, _entityPresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      observer = Ember.observer,
      run = Ember.run,
      Service = Ember.Service;

  var _default = Service.extend(_logger.default, {
    eventMap: {
      presence: 'primaryPresenceEvent',
      outofoffice: 'outOfOfficeEvent',
      conversationsummary: 'conversationSummaryEvent',
      routingStatus: 'routingStatusEvent',
      geolocation: 'geolocationEvent'
    },
    trackedGuids: null,
    intl: inject.service(),
    ajax: inject.service(),
    store: inject.service(),
    pigeon: inject.service(),
    session: inject.service(),
    streaming: inject.service(),
    application: inject.service(),
    notification: inject.service(),
    batchDataRequests: inject.service(),
    presenceService: inject.service('presence'),
    permissions: inject.service(),
    phoneIntegrations: inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('permissions.canViewIntegrationPresence')) {
        this.eventMap.integrationpresence = 'integrationpresence';
        delete this.eventMap.presence;
        this.set('eventMap', this.eventMap);
      }

      _lodash.default.forIn(this.eventMap, function (value, key) {
        return _this.get('pigeon').on(key, _this, _this.events[value]);
      });

      this.set('trackedGuids', Ember.Object.create());
      this.subscribeToMyself();
      this.get('streaming').subscribeToConnection(this);
    },
    willDestroy: function willDestroy() {
      var _this2 = this;

      this._super.apply(this, arguments);

      _lodash.default.forIn(this.eventMap, function (value, key) {
        return _this2.get('pigeon').off(key, _this2, _this2.events[value]);
      });
    },
    _getPresenceDefinition: function _getPresenceDefinition(presence) {
      if (typeof presence === 'string') {
        return this.get('session').getPresence(presence);
      } else {
        return this.get('presenceService').getPresenceDefinition(presence);
      }
    },
    isPresence: function isPresence(presence, testValue) {
      var definition = this._getPresenceDefinition(presence);

      return (0, _entityPresence.isPresence)(this.get('session.presenceMap'), definition, testValue);
    },
    subscribeToMyself: function subscribeToMyself() {
      var guid = this.get('session.person.guid');
      var id = this.get('session.person.id');

      if (guid && id) {
        var pigeon = this.get('pigeon');
        this.logger.debug('subscribing to myself');
        this.set("trackedGuids.".concat(guid), true);

        this._getTopicsForUser(guid).forEach(function (topic) {
          return pigeon.registerPriorityTopic(topic);
        });
      }
    },
    _getTopicsForUser: function _getTopicsForUser(guid) {
      if (!guid) {
        return [];
      }

      return ["v2.users.".concat(guid, "?").concat(Object.keys(this.eventMap).join('&'))];
    },
    _guidFromTopicName: function _guidFromTopicName(topic) {
      var guidPattern = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gi;
      var guids = topic.match(guidPattern);

      if (guids && guids.length > 0) {
        return guids[0];
      }

      return '';
    },
    connect: _streaming.default.streamingEvent(function () {
      run.debounce(this, this.refreshPeopleActivityState, 1000);
    }),
    refreshPeopleActivityState: function refreshPeopleActivityState() {
      var personId = this.get('session.person.guid');
      var ids = Object.keys(this.get('trackedGuids'));

      if (personId) {
        ids.push(personId);
      }

      if (!_lodash.default.isEmpty(ids)) {
        this.logger.debug('PRESENCE-SUBSCRIPTION: Reloading activity state for people: ', ids.length);
        this.get('store').findMany('person', ids, _entityFields.FL.person);
      }
    },
    watchAuthForSubscribe: observer('session.isAuthenticated', 'session.person.guid', function () {
      this.subscribeToMyself();
    }),
    subscribeToPresence: function subscribeToPresence(person) {
      var _this3 = this;

      var pigeon = this.get('pigeon');
      var id = person.get('id');
      var guid = person.get('guid');
      var jid = person.get('jid');
      this.set("trackedGuids.".concat(guid), true);

      var callback = function callback() {};

      if (this.get('streaming.connection') && jid && jid !== this.get('session.person.jid')) {
        if (0 === person.get('presenceSubscriptionCounter')) {
          this._getTopicsForUser(guid).forEach(function (topic) {
            return pigeon.registerTopic(topic);
          });

          Ember.run.once(this, this.refreshUser, id);
        }

        person.incrementProperty('presenceSubscriptionCounter');

        callback = function callback() {
          if (0 < person.get('presenceSubscriptionCounter')) {
            person.decrementProperty('presenceSubscriptionCounter');
          }

          if (0 === person.get('presenceSubscriptionCounter')) {
            _this3._getTopicsForUser(guid).forEach(function (topic) {
              return pigeon.unRegisterTopic(topic);
            });

            _this3.set("trackedGuids.".concat(guid), undefined);
          }
        };
      }

      return callback;
    },
    refreshUser: function refreshUser(id) {
      this.get('batchDataRequests').refreshUser(id);
    },
    bulkSubscribeToPresence: function bulkSubscribeToPresence(people) {
      var _this4 = this;

      var callbacks = people.map(function (p) {
        return _this4.subscribeToPresence(p);
      });
      return function () {
        callbacks.forEach(function (cb) {
          return cb();
        });
      };
    },
    autoSubscribe: function autoSubscribe(people, context) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var callbackKey = "".concat(type, "PresenceSubscriptionCallback");
      var previousCallback = context.get(callbackKey);
      var newCallback = null;

      if (people) {
        newCallback = this.bulkSubscribeToPresence(people);
      }

      if (typeof previousCallback === 'function') {
        previousCallback();
      }

      try {
        context.set(callbackKey, newCallback);
      } catch (e) {
        // sometimes we are trying to clear out subscription callbacks after an object was destroyed by ember
        // we dont want to run the function, but we can't use `set`, so clear it out forcefully
        if (!newCallback) {
          context[callbackKey] = newCallback;
        }
      }
    },
    changePresence: function changePresence(presence) {
      var _this5 = this;

      var promise = Ember.RSVP.resolve();
      var session = this.get('session');
      var isOutOfOffice = session.get('person.isOutOfOffice');
      var currentPresence = session.get('person.primaryPresence.presenceDefinition');

      if (isOutOfOffice) {
        promise = this.toggleOutOfOffice();
      }

      presence = this._getPresenceDefinition(presence);
      session.set('person.primaryPresence.presenceDefinition', presence);
      return promise.then(function () {
        if (presence && presence.id) {
          return _this5.get('presenceService').setUsersPresence(session.get('person'), presence).catch(function (err) {
            _this5.logger.error('PRESENCE-SUBSCRIPTION: Failed to set presence with public api', {
              error: err
            });

            session.set('person.primaryPresence.presenceDefinition', currentPresence);

            if ((0, _errors.isConflictError)(err)) {
              _this5._alertDeletedPresence(presence);
            }
          });
        }
      });
    },
    _alertDeletedPresence: function _alertDeletedPresence(presence) {
      var intl = this.get('intl');
      var title = intl.t('chat.presence.errorSettingDeletedPresence.title');
      var message = intl.t('chat.presence.errorSettingDeletedPresence.message');
      this.get('notification').error(title, message);
      this.get('presenceService').removeDeletedPresence(presence);
    },
    changeRoutingStatus: function changeRoutingStatus(status) {
      var _this6 = this;

      var id = this.get('session.person.guid');
      var url = this.get('application').pcV2Uri("api/v2/users/".concat(id, "/routingStatus"));
      var data = {
        userId: id,
        status: status,
        startTime: new Date(_dates.default.now()).toISOString()
      };
      this.get('ajax').put(url, {
        data: data
      }).catch(function (error) {
        _this6.logger.error('PRESENCE-SUBSCRIPTION: Error when changing routing status', {
          error: error
        });
      });
    },
    toggleOutOfOffice: function toggleOutOfOffice() {
      return this.setOutOfOffice(null, null, !this.get('session.person.isOutOfOffice'));
    },
    doUserPresenceStatusChanged: function doUserPresenceStatusChanged(person, statusUpdate) {
      var source = statusUpdate.source;
      var phoneIntegrations = this.get('phoneIntegrations');
      var isSourceOnUsableList = phoneIntegrations.canViewThirdPartyPresence(source);

      if (isSourceOnUsableList) {
        // set the integration presence value
        // using Ember.set as person is an object by this point
        Ember.set(person, 'activities.integrationPresence', statusUpdate);
      } else {
        var existingPresenceIndex = null;
        var existingPresenceObj = null;

        if (person.get('presenceList')) {
          existingPresenceObj = person.get('presenceList').find(function (presence, index) {
            if (presence.source.toLowerCase() === source.toLowerCase()) {
              existingPresenceIndex = index;
              return true;
            }
          });
        }

        if (existingPresenceObj && existingPresenceIndex > -1) {
          var presenceListStored = person.get('presenceList');
          presenceListStored[existingPresenceIndex] = Ember.Object.create(statusUpdate);
          person.notifyPropertyChange('presenceList');
        } else {
          person.get('presenceList').pushObject(Ember.Object.create(statusUpdate));
        }
      }
    },
    doUserPrimaryPresenceSourceChanged: function doUserPrimaryPresenceSourceChanged(person, statusUpdate) {
      var newSource = statusUpdate.source;
      var phoneIntegrations = this.get('phoneIntegrations');
      var isThirdPartySource = !!phoneIntegrations.getIntegrationFromSource(newSource);

      if (!isThirdPartySource) {
        // convert PURECLOUD -> default
        if ((0, _presenceUtils.isPurecloudSource)(newSource)) {
          newSource = 'default';
        }

        person.set('currentPresenceSource', newSource);
      }
    },
    handleIntegrationPresenceEvent: function handleIntegrationPresenceEvent(presenceEvent) {
      var _this7 = this;

      Ember.run.join(function () {
        _this7.get('batchDataRequests').getUser(presenceEvent.userId).then(function (person) {
          var eventType = presenceEvent.eventType;

          if (eventType === 'UserPresenceStatusChanged') {
            _this7.doUserPresenceStatusChanged(person, presenceEvent);
          } else if (eventType === 'UserPrimaryPresenceSourceChanged') {
            _this7.doUserPrimaryPresenceSourceChanged(person, presenceEvent);
          }
        });
      });
    },
    setOutOfOffice: function setOutOfOffice(startDate, endDate, isIndefinite) {
      var _this8 = this;

      var newOutOfOffice = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var url = this.get('application').pcV2Uri("api/v2/users/".concat(this.get('session.person.guid'), "/outofoffice"));
      var isOutOfOffice = this.get('session.person.isOutOfOffice'); // SHIA-927: Client workaround until CORE-4132 is done; this lets the streaming service know the user is available xmpp presence while ooto.

      if (newOutOfOffice) {
        var availablePresence = this.get('session').getPresence('available');
        this.get('session.person').set('activities.outOfOffice.active', true);
        this.get('session.user').set('outOfOffice.active', true);
        this.get('presenceService').setUsersPresence(this.get('session.person'), availablePresence).catch(function (err) {
          _this8.logger.error('PRESENCE-SUBSCRIPTION: Failed to set presence to available during out of office', {
            err: err
          });
        });
      }

      var payload = {
        startDate: startDate,
        endDate: endDate,
        indefinite: isIndefinite
      };
      var request = Ember.$.ajax({
        url: url,
        type: 'put',
        contentType: 'application/json; charset=UTF-8',
        dataType: 'json',
        data: JSON.stringify(payload),
        cache: false
      });
      var promise = Ember.RSVP.cast(request);
      promise.then(function (data) {
        _this8.get('session.person').set('activities.outOfOffice', data);

        _this8.get('session.user').set('outOfOffice', data);
      }).catch(function (error) {
        _this8.logger.error('PRESENCE-SUBSCRIPTION: Failed to toggle out of office to: ', {
          newOutOfOffice: newOutOfOffice,
          error: error
        });

        _this8.get('session.person').set('activities.outOfOffice.active', isOutOfOffice);

        _this8.get('session.user').set('outOfOffice.active', isOutOfOffice);
      });
      return promise;
    },
    startOfTime: function startOfTime() {
      return '1970-01-01T12:00:00.000Z';
    },
    endOfTime: function endOfTime() {
      return '9999-12-31T00:00:00.000Z';
    },
    rightAboutMeow: function rightAboutMeow() {
      return (0, _dates.default)().subtract(1, 'year').toISOString();
    },
    handleRoutingStatusError: function handleRoutingStatusError(errorCode) {
      if (typeof errorCode === 'string' && errorCode.toLowerCase() === 'user.noskills') {
        var intl = this.get('intl');
        var title = intl.t('notification.purecloud.errorSkillsRouting.title');
        var message = intl.t('notification.purecloud.errorSkillsRouting.message');
        this.get('notification').error(title, message);
      }
    },
    events: {
      conversationSummaryEvent: function conversationSummaryEvent(conversationSummary, _ref) {
        var _this9 = this;

        var topicName = _ref.topicName;

        var guid = this._guidFromTopicName(topicName);

        Ember.run.join(function () {
          _this9.get('batchDataRequests').getUser(guid).then(function (person) {
            person.set('activities.conversationSummary', conversationSummary);
          });
        });
      },
      outOfOfficeEvent: function outOfOfficeEvent(outOfOfficeDef, _ref2) {
        var _this10 = this;

        var topicName = _ref2.topicName;

        var guid = this._guidFromTopicName(topicName);

        Ember.run.join(function () {
          _this10.get('batchDataRequests').getUser(guid).then(function (person) {
            return person.set('activities.outOfOffice', outOfOfficeDef);
          });
        });
      },
      routingStatusEvent: function routingStatusEvent(_ref3) {
        var _this11 = this;

        var errorInfo = _ref3.errorInfo,
            id = _ref3.id,
            routingStatus = _ref3.routingStatus;

        if (errorInfo && errorInfo.errorCode) {
          this.handleRoutingStatusError(errorInfo.errorCode);
        }

        Ember.run.join(function () {
          _this11.get('batchDataRequests').getUser(id).then(function (person) {
            return person.set('activities.routingStatus', routingStatus);
          });
        });
      },
      integrationpresence: function integrationpresence(event) {
        if (this.get('permissions.canViewIntegrationPresence')) {
          this.handleIntegrationPresenceEvent(event);
        }
      },
      primaryPresenceEvent: function primaryPresenceEvent(primaryPresence, _ref4) {
        var _this12 = this;

        var topicName = _ref4.topicName;

        var guid = this._guidFromTopicName(topicName);

        Ember.run.join(function () {
          _this12.get('batchDataRequests').getUser(guid).then(function (person) {
            person.set('activities.presence', primaryPresence);
          });
        });
      },
      geolocationEvent: function geolocationEvent(geolocation, _ref5) {
        var _this13 = this;

        var topicName = _ref5.topicName;
        this.logger.debug('PRESENCE-SUBSCRIPTION: geolocationEvent', arguments);

        var guid = this._guidFromTopicName(topicName);

        if (geolocation && (geolocation.city || geolocation.region || geolocation.country)) {
          this.logger.debug('PRESENCE-SUBSCRIPTION: gelocationEvent user/self', guid, this.get('session.person.guid'));

          if (guid && guid === this.get('session.person.guid')) {
            this.logger.debug('PRESENCE-SUBSCRIPTION: adding geolocation to self');
            this.set('session.person.activities.geolocation', geolocation);
          }

          Ember.run.join(function () {
            _this13.get('batchDataRequests').getUser(guid).then(function (person) {
              person.set('activities.geolocation', geolocation);
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});