define("web-directory/helpers/full-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fullNameHelper = Ember.Helper.extend({
    intl: Ember.inject.service(),
    names: Ember.inject.service(),
    compute: function compute(params) {
      var externalProfile = params[0];

      if (externalProfile) {
        return this.get('names').fullName(externalProfile) || Ember.get(externalProfile, 'firstName');
      }

      return this.get('intl').lookup('purecloud.unknown');
    }
  });
  var _default = fullNameHelper;
  _exports.default = _default;
});