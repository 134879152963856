define("web-directory/components/single-select-target-dropdown/component", ["exports", "ember-purecloud-components/utils/email", "web-directory/utils/acd"], function (_exports, _email, _acd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['single-select-target-dropdown'],
    classNameBindings: ['hideTargetDropdown:hide-target-dropdown'],

    /**
     * Logger service for console logs, warns, and errors that are intended to keep
     */
    logger: Ember.inject.service('stashLogger'),

    /**
     * `true` if the input should be autofocused once it has rendered in the dom
     */
    autofocus: false,

    /**
     * @attribute
     *
     * The currently authenticated user information
     */
    authUser: null,

    /**
     * @attribute
     *
     * the type of interaction this is in context of
     */
    interactionType: null,

    /**
     * @attribute
     *
     * The placeholder text shown on the input. This value needs to be translated
     * before passing into this component.
     */
    placeholder: null,

    /**
     * @attribute
     *
     * The clearTranslation is aria text shown on the input clear button.
     * This value needs to be translated before passing into this component.
     */
    clearTranslation: null,

    /**
     * @attribute
     *
     * the filters to be included in this target selector
     */
    includeFilters: [],

    /**
     * @attribute
     *
     * UC conversation has participants
     */
    conversationHasActiveParticipants: false,

    /**
     * @attribute
     *
     * The currently selected value of the component. This value should be passed
     * in and stored by the parent. We do not want to mutate this value from within the
     * component itself.
     */
    value: null,

    /**
     * @attribute this value can be mutated within this component
     *
     * @example 2-way data binding
     * searchValue=(mut searchValue)
     *
     * the value of the main input. if updated by parent, it will automatically up the input
     * in the template and will search once input has been focused.
     */
    searchValue: '',

    /**
     * `true` if the target dropdown is open
     */
    open: false,

    /**
     * this value is to know if the input that shouldn't allow enter behaviour
     */
    disableInputEnter: false,

    /**
     * @attribute
     *
     * `true` if the target dropdown logic should be supressed and the dropdown be hidden.
     */
    hideTargetDropdown: false,

    /**
     * A mock interaction object to set the type of for target selection context
     */
    mockInteraction: Ember.computed('interactionType', function () {
      return {
        type: this.get('interactionType')
      };
    }),

    /**
     * @attribute
     * Allows user to transfer call to another user's voicemail
     */
    showDirectTransferToVoicemailDropdown: false,

    /**
     * Invoked when the element has been inserted into the DOM
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('hideTargetDropdown')) {
        this.hideOnClickOutside(this.element);
      }

      if (this.get('autofocus')) {
        setTimeout(function () {
          var input = _this.getInput();

          if (input) {
            input.focus();
          }
        }, 100);
      }
    },

    /**
     * Invoked when the component has been destroyed
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.removeEventListener('click', this.outsideClickListener);
    },

    /**
     * reference to the listener for when the element has been clicked outside of.
     */
    outsideClickListener: function outsideClickListener(event) {
      return console.warn('The outside click listener has not been set', event);
    },

    /**
     * Listeners to determine if the user has clicked outside of the element being
     * passed through. If the element has been clicked outside of, then the dropdown
     * will close.
     *
     * @param element the element to check against
     */
    hideOnClickOutside: function hideOnClickOutside(element) {
      var _this2 = this;

      if (element) {
        try {
          this.outsideClickListener = function (event) {
            if (!element.contains(event.target)) {
              _this2.set('open', false);
            }
          };

          document.addEventListener('click', this.outsideClickListener);
        } catch (error) {
          console.error('There was an issue setting up the click handler', error);
        }
      }
    },

    /**
     * Gets reference to the input. Due to the input reference changing when values
     * are selected (because of #if helper), we should fetch it every time to make sure
     * we have the most up to date one.
     */
    getInput: function getInput() {
      var input = $("input#".concat(this.elementId))[0];
      return input;
    },

    /**
     * Resets the search facet of the component back to it's initial state.
     */
    resetSearch: function resetSearch() {
      var input = this.getInput();

      if (input) {
        input.value = '';
      }

      this.updateSearch('');
    },

    /**
     * Updates the `searchValue` property and any other additional logic that may need
     * to go along with that in one isolated place.
     *
     * @param value the value to update the search property with
     */
    updateSearch: function updateSearch(value) {
      this.set('searchValue', value);
      this.set('open', !!value);
    },

    /**
     * Clears the currently selected value in the component and refocuses on the input
     * after a quick delay. We need to give the template a moment to render the input
     * back before trying to focus back into it.
     */
    clearSelection: function clearSelection() {
      var _this3 = this;

      this.targetSelected(null);
      setTimeout(function () {
        var input = _this3.getInput();

        if (input) {
          input.focus();
        }
      }, 0);
    },

    /**
     * Invoked when the user presses the enter key while focused in the input element.
     * We check to see if the current search value is a valid value to be added as a raw
     * tag.
     */
    onInputEnter: function onInputEnter() {
      // if the input is a queue suppress enter behavior of the search bar
      if (this.get('disableInputEnter')) return;
      var searchValue = this.get('searchValue');

      switch (this.get('interactionType')) {
        case 'email':
          if ((0, _email.isValidEmailAddress)(searchValue)) {
            this.targetSelected({
              email: searchValue
            });
            this.resetSearch();
          }

          break;

        default:
          if (searchValue.trim()) {
            this.targetSelected({
              phoneNumber: searchValue
            });
            this.resetSearch();
          }

          break;
      }
    },

    /**
     * @attribute
     * Method passed as a prop. If not passed, we warn.
     * @param data
     */
    transferToVoicemailClick: function transferToVoicemailClick(data) {
      (0, _acd.warnClosureAction)('transferToVoicemailClick', data);
    },

    /**
     * @action passed from the parent
     *
     * Handles the selection of a target
     *
     * @param target the selected data corresponding to the target
     */
    targetSelected: function targetSelected(target) {
      return (0, _acd.warnClosureAction)('targetSelected', target);
    },
    actions: {
      /**
       * Invoked when the user inputs data into the main input of the component.
       *
       * @param event the event data provided by the input
       */
      onInputChange: function onInputChange(event) {
        var _ref = event.target,
            value = _ref.value;
        this.updateSearch(value);
      },

      /**
       * Invoked whenever the user presses down a key on the main input of this component.
       *
       * @param event data corresponding to the keypress
       */
      onKeypress: function onKeypress(event) {
        if (event.key === 'Enter') {
          // prevents the first row of the target dropdown from selecting in this context
          event.preventDefault();
          this.onInputEnter();
        }
      },

      /**
       * Invoked when the user selects data from within the target selector component
       *
       * @param target the data that was selected
       */
      onTargetSelected: function onTargetSelected(target) {
        this.set('open', false);
        this.targetSelected(target);
        this.resetSearch();
      },
      onTransferToVoicemail: function onTransferToVoicemail(data) {
        this.transferToVoicemailClick(data);
      },

      /**
       * Clears the currently selected item and resets the input
       */
      onClearSelection: function onClearSelection() {
        this.clearSelection();
      },

      /**
       * Invoked when the user focused the input
       */
      onInputFocus: function onInputFocus() {
        if (this.get('searchValue')) {
          this.set('open', true);
        }
      }
    }
  });

  _exports.default = _default;
});