define("web-directory/components/target-dropdown/rows/person-row/component", ["exports", "web-directory/utils/acd-ts", "web-directory/mixins/logger", "web-directory/components/target-dropdown/data"], function (_exports, _acdTs, _logger, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_logger.default, {
    classNames: ['data-row', 'person-row'],
    classNameBindings: ['presence'],
    attributeBindings: ['disabled', 'readOnly', 'tabindex'],
    intl: Ember.inject.service('intl'),
    logger: Ember.inject.service('stashLogger'),

    /**
     * @Attribute
     *
     * The person data to use
     */
    person: null,

    /**
     * @Attribute
     *
     * `true` if the element cannot be interacted with
     */
    interactable: true,

    /**
     * @Attribute
     *
     * The type of the current interaction this component is being used for if applicable
     */
    interactionType: null,

    /**
     * @Attribute
     *
     * Array of the permissions that are required for this person to be selected
     * in whatever context this dropdown is in. The person needs to have ALL of the permissions
     * that are included in this array. i.e This is treated as `&&` instead of `||`.
     *
     * Todo: Allow both '&&' and '||' logic for comparing permissions when the need arises
     */
    requiredPermissions: [],

    /**
     * @Attribute
     *
     * `true` if the row should be rendered as read only without button interactions
     */
    readOnly: false,

    /**
     * @attribute
     * actual value passed from target-dropdown
     */
    showDirectTransferToVoicemailDropdown: false,

    /**
     * @attribute
     *
     * actual value passed from target-dropdown
     */
    conversationHasActiveParticipants: false,

    /**
     * A controller to easily remove all of the event listeners from the component
     */
    abortController: new AbortController(),

    /**
     * `true` if the element is disabled
     */
    disabled: Ember.computed('readOnly', 'interactable', 'hasRequiredPermission', function () {
      try {
        return !this.get('readOnly') && (!this.get('interactable') || !this.get('hasRequiredPermission'));
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the disabled state', {
          remoteData: {
            error: error
          }
        });
        return true;
      }
    }),

    /**
     * The order in which the component can be tabbed when rendered.
     * If the component is disabled or readonly, then no tabbing allowed,
     * otherwise tab in rendered order
     */
    tabindex: Ember.computed('readOnly', 'disabled', function () {
      try {
        return this.get('readOnly') || this.get('disabled') ? -1 : 0;
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the tabindex', {
          remoteData: {
            error: error
          }
        });
        return -1;
      }
    }),

    /**
     * The computed name of the person being rendered
     */
    name: Ember.computed.readOnly('person.name'),

    /**
     * The computed name of the person being rendered
     */
    title: Ember.computed.readOnly('person.title'),

    /**
     * All of the permissions that this person has been given.
     */
    permissions: Ember.computed.readOnly('person.data.expands.authorization.permissions'),

    /**
     * `true` if the person has to correct permissions to be selected in whatever context
     * this dropdown is being used in. Depends on the `requiredPermissions` property.
     */
    hasRequiredPermission: Ember.computed('requiredPermissions.[]', 'permissions', function () {
      try {
        var _this$get, _this$get2;

        var requiredPermissions = (_this$get = this.get('requiredPermissions')) !== null && _this$get !== void 0 ? _this$get : [];
        var permissions = (_this$get2 = this.get('permissions')) !== null && _this$get2 !== void 0 ? _this$get2 : [];

        if (requiredPermissions.length && permissions.length) {
          var agentHasRequiredPermissions = requiredPermissions.every(function (required) {
            return permissions.includes(required);
          });
          return agentHasRequiredPermissions || !permissions.includes("routing:agent:onqueue");
        } // If there are no requiredPermissions and the person has any permissions, then return `true`.


        return !requiredPermissions.length && permissions.length;
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the "hasRequriedPermission" value', {
          remoteData: {
            error: error
          }
        });
        return false;
      }
    }),

    /**
     * Reference to the persons active conversations and intended to be displayed in the data row
     */
    conversationSummaryData: Ember.computed.readOnly('person.data.expands.conversationSummary'),

    /**
     * The persons active conversation summary data containing information for interaction icon display.
     * Will filter out any keys not configured or disabled in the `ConversationSummaryConfigData`.
     */
    conversationSummary: Ember.computed('conversationSummaryData', function () {
      var _this = this;

      try {
        var summaryData = _data.ConversationSummaryConfigData;
        var keys = Object.keys(summaryData);
        return keys.reduce(function (value, key) {
          var data = summaryData[key];

          if (data.enabled) {
            var summary = _this.get('conversationSummaryData');

            var count = summary[key] ? _this._getActiveCount(summary[key]) : 0;
            var translationKey = count === 1 ? "purecloud.conversationSummary.".concat(key, ".singular") : "purecloud.conversationSummary.".concat(key, ".plural");
            value.push(_objectSpread({
              key: key,
              count: count,
              tooltip: _this.get('intl').lookup(translationKey, {
                count: count
              })
            }, data));
          }

          return value;
        }, []);
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the "conversationSummary" value', {
          remoteData: {
            error: error
          }
        });
        return [];
      }
    }),

    /**
     * The persons current presence in the app. Dictates if they are offline, available, away, etc
     */
    presence: Ember.computed.readOnly('person.activityPresence'),

    /**
     * The label to display on the row depicting the users current presence
     */
    presenceLabel: Ember.computed('person.presenceLabel', function () {
      try {
        var primary = this.get('person.presenceLabel');
        return primary !== null && primary !== void 0 ? primary : null;
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the "presenceLabel" value', {
          remoteData: {
            error: error
          }
        });
        return null;
      }
    }),

    /**
     * The sub presence label displaying for the user.
     * if null or undefined then we will only display the primary `presenceLabel`
     */
    secondaryPresenceLabel: Ember.computed('person.secondaryPresenceLabel', 'presenceLabel', function () {
      try {
        var secondary = this.get('person.secondaryPresenceLabel');
        return secondary != null && secondary !== this.get('presenceLabel') ? secondary : null;
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the "secondaryPresenceLabel" value', {
          remoteData: {
            error: error
          }
        });
        return null;
      }
    }),
    summaryTooltipId: Ember.computed('elementId', function () {
      return "".concat(this.get('elementId'), "-person-row-summary");
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('abortController', new AbortController());
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.get('interactable') && !this.get('readOnly') && !this.get('disabled') && !this.get('showMoreMenu')) {
        var _this$get3 = this.get('abortController'),
            signal = _this$get3.signal;

        this.get('element').addEventListener('click', this.actionSelected.bind(this), {
          signal: signal
        });
        this.get('element').addEventListener('keyup', function (event) {
          if (event.key === 'Enter') {
            _this2.actionSelected(_this2);
          }
        }, {
          signal: signal
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('abortController').abort();
    },

    /**
     * Fetches the total number of active interactions pertaining to the provided conversation summary metadata.
     *
     * @param summary The conversation summary metadata to check against
     * @returns A number equaling the active interactions
     */
    _getActiveCount: function _getActiveCount(summary) {
      try {
        return Object.values(summary).reduce(function (accumulator, currentValue) {
          return accumulator + Object.values(currentValue).reduce(function (acc, curr) {
            return acc + curr;
          }, 0);
        }, 0);
      } catch (error) {
        this.get('logger').error('Person Row: There was an issue determining the "_getActiveCount" value', {
          remoteData: {
            error: error
          }
        });
        return 0;
      }
    },

    /**
     * @attribute
     * Method passed as a prop. If not passed, we warn.
     * @param data
     */
    transferToVoicemailClick: function transferToVoicemailClick(data) {
      (0, _acdTs.warnClosureAction)('transferToVoicemailClick', data);
    },

    /**
     * @action
     * Closure action to be invoked when a data row has been selected
     *
     * @param data the data being passed after selected
     */
    actionSelected: function actionSelected() {
      return (0, _acdTs.warnClosureAction)('actionSelected');
    },
    actions: {
      /**
       * Invoked when the user selects to transfer call to voicemail of selected user
       *
       * @param data Participant data of selcted user
       * transferToVoicemailClick is passed as a prop from acd-interactions-panel/interaction/component.js(agent transfer) or /conversation-controls/component.js(regular calls)
       */
      invokeTransferToVoicemail: function invokeTransferToVoicemail(data) {
        this.transferToVoicemailClick(data);
      },
      preventDefault: function preventDefault(event) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});