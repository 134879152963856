define("web-directory/mixins/password-rules", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractServerPasswordRulesMessages = extractServerPasswordRulesMessages;
  _exports.maxPasswordLength = void 0;
  var MAX_PASSWORD_LENGTH = 400;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    passwordLength: MAX_PASSWORD_LENGTH,
    attachBehavior: Ember.on('didInsertElement', function () {
      var _this = this;

      var intl = this.get('intl');
      this.get('ajax').ajaxGet('/platform/api/v2/organizations/passwordrequirements').then(function (passwordRequirements) {
        var minPasswordLength = Ember.get(passwordRequirements, 'minimumLength') || 8;
        var minPasswordDigits = Ember.get(passwordRequirements, 'minimumDigits') || 1;
        var minPasswordLetters = Ember.get(passwordRequirements, 'minimumLetters') || 0;
        var minPasswordSpecials = Ember.get(passwordRequirements, 'minimumSpecials') || 1;
        var minPasswordUpper = Ember.get(passwordRequirements, 'minimumUpper') || 1;
        var minPasswordLower = Ember.get(passwordRequirements, 'minimumLower') || 1;

        _this.set('minPasswordLength', minPasswordLength);

        var options = {};
        options.common = {
          minChar: minPasswordLength,
          onKeyUp: function onKeyUp(evt, data) {
            var weak = intl.lookup('auth.password.strength.weak').toString(); // complex second boolean to handle situation where plugin thinks password is wrong

            if (data.verdictLevel >= 1 || data.verdictText === weak && data.score === 0 && data.verdictLevel === -1) {
              _this.set('isValidFromPasswordRulesPlugin', true);
            } else {
              _this.set('isValidFromPasswordRulesPlugin', false);
            }
          }
        };
        options.rules = {
          activated: {
            wordNotEmail: false,
            wordLength: false,
            wordSimilarToUsername: false,
            wordSequences: false,
            wordTwoCharacterClasses: false,
            wordRepetitions: false,
            wordLowercase: false,
            wordUppercase: false,
            wordOneNumber: false,
            wordThreeNumbers: false,
            wordOneSpecialChar: false,
            wordTwoSpecialChar: false,
            wordUpperLowerCombo: false,
            wordLetterNumberCombo: false,
            wordLetterNumberCharCombo: false
          }
        };

        var intlAuthRule = function intlAuthRule(rule, opts) {
          return intl.t("auth.password.validation.".concat(rule), opts);
        };

        var intlAuthVerdict = function intlAuthVerdict(verdict) {
          return intl.t("auth.password.strength.".concat(verdict));
        };

        options.ui = {
          container: '.pwstrength-container',
          showErrors: true,
          showPopover: false,
          showStatus: true,
          showProgressBar: false,
          showVerdictsInsideProgressBar: true,
          viewports: {
            progress: '.pwstrength-progress',
            verdict: '.pwstrength-progress'
          },
          scores: [1, 6, 18, 36],
          //[14, 26, 40, 50],
          colors: [1, 6],
          //[26, 50]
          verdicts: [intlAuthVerdict('weak'), intlAuthVerdict('normal'), intlAuthVerdict('medium'), intlAuthVerdict('strong'), intlAuthVerdict('veryStrong')],
          errorMessages: {
            wordLength: intlAuthRule('wordLength'),
            wordNotEmail: intlAuthRule('wordNotEmail'),
            wordSimilarToUsername: intlAuthRule('wordSimilarToUsername'),
            wordTwoCharacterClasses: intlAuthRule('wordTwoCharacterClasses'),
            wordRepetitions: intlAuthRule('wordRepetitions'),
            wordSequences: intlAuthRule('wordSequences'),
            minPasswordLength: intlAuthRule('wordLength'),
            minPasswordDigits: intlAuthRule('minPasswordDigits', {
              minPasswordDigits: minPasswordDigits
            }),
            minPasswordLetters: intlAuthRule('minPasswordLetters', {
              minPasswordLetters: minPasswordLetters
            }),
            minPasswordSpecials: intlAuthRule('minPasswordSpecials', {
              minPasswordSpecials: minPasswordSpecials
            }),
            maxPasswordLength: intlAuthRule('passwordLengthError'),
            minPasswordLower: intlAuthRule('passwordLowerError', {
              minPasswordLower: minPasswordLower
            }),
            minPasswordUpper: intlAuthRule('passwordUpperError', {
              minPasswordUpper: minPasswordUpper
            })
          },
          spanError: function spanError(options, key) {
            var text = options.ui.errorMessages[key];
            return '<span class="error-text" aria-live="polite">' + text + '</span>';
          }
        };

        var $password = _this.$('.pwstrength-container input:password');

        $password.pwstrength(options);

        if (minPasswordLength) {
          $password.pwstrength('addRule', 'minPasswordLength', function (options, word, score) {
            if (word && word.length < minPasswordLength) {
              return score;
            }

            return 0;
          }, -100, true);
        }

        if (minPasswordDigits) {
          $password.pwstrength('addRule', 'minPasswordDigits', function (options, word, score) {
            if (word.replace(/[^0-9]/g, '').length < minPasswordDigits) {
              return score;
            }

            return 0;
          }, -100, true);
        }

        if (minPasswordLetters) {
          $password.pwstrength('addRule', 'minPasswordLetters', function (options, word, score) {
            if (word.replace(/[^A-Za-z]/g, '').length < minPasswordLetters) {
              return score;
            }

            return 0;
          }, -100, true);
        } // see: https://bitbucket.org/inindca/auth-api/src/master/pkg/model/organization_models.go#lines-24


        if (minPasswordSpecials) {
          $password.pwstrength('addRule', 'minPasswordSpecials', function (options, word, score) {
            if (word.replace(/[^!"“”'#$%&‘’()*+,-.\/\\:;<=>?@\[\]^_`{|}~]/g, '').length < minPasswordSpecials) {
              return score;
            }

            return 0;
          }, -100, true);
        }

        if (minPasswordUpper) {
          $password.pwstrength('addRule', 'minPasswordUpper', function (options, word, score) {
            if (word.replace(/[^A-Z]/g, '').length < minPasswordUpper) {
              return score;
            }

            return 0;
          }, -100, true);
        }

        if (minPasswordLower) {
          $password.pwstrength('addRule', 'minPasswordLower', function (options, word, score) {
            if (word.replace(/[^a-z]/g, '').length < minPasswordLower) {
              return score;
            }

            return 0;
          }, -100, true);
        }

        $password.pwstrength('addRule', 'maxPasswordLength', function (options, word, score) {
          if (word && word.length > MAX_PASSWORD_LENGTH) {
            return score;
          }

          return 0;
        }, -100, true);
      });
    }),
    removeBehavior: Ember.on('willDestroyElement', function () {
      this.$('.pwstrength-container input:password').pwstrength('destroy'); // The plugin does not clean up these CSS classes.

      this.$('.pwstrength-container').removeClass('has-error has-warning has-success');
    })
  });
  /*
  
   {
   'code': 400,
   'detail': 'Failed to meet password requirements',
   'payload': {
   'code': 'PASSWORD_REQUIREMENTS',
   'requirements': {
   'minPasswordLetters': 10,
   'minPasswordSpecials': 2
   }
   },
   'reason': 'Bad Request'
   }
  
   */


  _exports.default = _default;
  var maxPasswordLength = MAX_PASSWORD_LENGTH;
  _exports.maxPasswordLength = maxPasswordLength;

  function extractServerPasswordRulesMessages(response) {
    var messages = [];
    var errorMessage = Ember.get(response, 'payload.reason');

    if (!errorMessage) {
      errorMessage = Ember.get(response, 'detail');
    }

    if (errorMessage) {
      messages.push(errorMessage);
    }

    var payload = Ember.get(response, 'payload');
    var code = Ember.get(response, 'payload.code');
    var requirements = Ember.get(response, 'payload.requirements');

    if (code === 'PASSWORD_REQUIREMENTS' && requirements) {
      var validationKey = function validationKey(name) {
        return "auth.password.validation.".concat(name);
      };

      var maxAccountInactivity = requirements.maxAccountInactivity,
          minPasswordUpper = requirements.minPasswordUpper,
          minPasswordLower = requirements.minPasswordLower,
          minPasswordLength = requirements.minPasswordLength,
          minPasswordDigits = requirements.minPasswordDigits,
          minPasswordLetters = requirements.minPasswordLetters,
          minPasswordSpecials = requirements.minPasswordSpecials,
          minPasswordAge = requirements.minPasswordAge,
          passwordExpirationTime = requirements.passwordExpirationTime;

      if (maxAccountInactivity > 0) {
        var age = _dates.default.duration(maxAccountInactivity, 'days').humanize();

        messages.push({
          key: validationKey('maxAccountInactivity'),
          options: {
            age: age
          }
        });
      }

      if (minPasswordUpper > 0) {
        messages.push({
          key: validationKey('passwordUpperError'),
          options: {
            minPasswordUpper: minPasswordUpper
          }
        });
      }

      if (minPasswordLower > 0) {
        messages.push({
          key: validationKey('passwordLowerError'),
          options: {
            minPasswordLower: minPasswordLower
          }
        });
      }

      if (minPasswordLength > 0) {
        messages.push({
          key: validationKey('minPasswordLength'),
          options: {
            minPasswordLength: minPasswordLength
          }
        });
      }

      if (minPasswordDigits > 0) {
        messages.push({
          key: validationKey('minPasswordDigits'),
          options: {
            minPasswordDigits: minPasswordDigits
          }
        });
      }

      if (minPasswordLetters > 0) {
        messages.push({
          key: validationKey('minPasswordLetters'),
          options: {
            minPasswordLetters: minPasswordLetters
          }
        });
      }

      if (minPasswordSpecials > 0) {
        messages.push({
          key: validationKey('minPasswordSpecials'),
          options: {
            minPasswordSpecials: minPasswordSpecials
          }
        });
      }

      if (minPasswordAge > 0) {
        var _age = _dates.default.duration(minPasswordAge, 'seconds').humanize();

        messages.push({
          key: validationKey('minPasswordAge'),
          options: {
            age: _age
          }
        });
      }

      if (passwordExpirationTime > 0) {
        var _age2 = _dates.default.duration(passwordExpirationTime, 'days').humanize();

        messages.push({
          key: validationKey('passwordExpirationTime'),
          options: {
            age: _age2
          }
        });
      }
    } else if (code === 'REQUIRED_FIELD') {
      var key = "auth.password.validation.required.".concat(payload.field);
      messages.push({
        key: key,
        options: {}
      });
    } else if (code === 'BAD_VALUE') {
      var _key = 'auth.password.validation.badValue.default';

      if (payload.field === 'password') {
        _key = 'auth.password.validation.badValue.password';
      }

      messages.push({
        key: _key,
        options: {}
      });
    }

    return messages;
  }
});