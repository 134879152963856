define("web-directory/app-providers/conversation-details-interapptions-provider", ["exports", "web-directory/utils/boolean-expressions", "web-directory/utils/types", "web-directory/utils/app-provider"], function (_exports, _booleanExpressions, _types, _appProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.boolExpr = void 0;
  var inject = Ember.inject,
      RSVP = Ember.RSVP;

  var hasAdditionalCustomerInformation = function hasAdditionalCustomerInformation(interaction) {
    var LEGACY_PARTICIPANT_DATA_CARD_KEY_PREFIX = 'conversationSummary.participantData.';
    var PARTICIPANT_DATA_CARD_KEY_PREFIX = 'conversationDetails.participantData.';
    var PARTICIPANT_DATA_CARD_KEY_PREFIXES = [LEGACY_PARTICIPANT_DATA_CARD_KEY_PREFIX, PARTICIPANT_DATA_CARD_KEY_PREFIX];
    var customerParticipantData = interaction.get('participantForCustomer.attributes') || {};
    return Object.keys(customerParticipantData).some(function (key) {
      return PARTICIPANT_DATA_CARD_KEY_PREFIXES.some(function (prefix) {
        return key.startsWith(prefix);
      });
    });
  };

  var ConversationDetailsInterapptionsProvider = Ember.Object.extend({
    // Injections
    application: inject.service('application'),
    intl: inject.service('intl'),
    permissions: inject.service('permissions'),
    // Attributes
    id: 'conversationDetailsInterapptionsProvider',
    type: 'programmatic',
    group: 'contextual',
    trustedProvider: true,
    monitorInteractionUpdates: true,
    // Derived
    runtimeEnv: Ember.computed('application.envDetails.runtime', function () {
      return this.get('application').get('runtime') || undefined;
    }),
    gadgetTargetOrigin: Ember.computed('application.runtime', function () {
      var runtimeEnv = this.get('application.runtime');

      if (runtimeEnv.local) {
        return "https://apps.".concat(runtimeEnv.publicDomainName);
      }

      return window.location.origin;
    }),
    // Methods
    getAppConfigs: function getAppConfigs(appContext) {
      var appContextType = appContext.type,
          payload = appContext.payload;

      switch (appContextType) {
        case 'conversation':
          {
            var appDefs = []; // do not show Conversation Details if I am monitoring or coaching the conversation

            if (appContext.payload.get('myselfIsMonitor') || appContext.payload.get('myselfIsCoach') || appContext.payload.get('myselfIsMonitorV2')) {
              return appDefs;
            }

            var permissions = this.get('permissions');
            var agentHasViewPermission = permissions.validateDonutPermission('agentUI:conversationSummary:view');
            var hasParticipantData = hasAdditionalCustomerInformation(payload);

            if (agentHasViewPermission) {
              if (hasParticipantData || payload.get('isChat')) {
                // set app as conversation details
                appDefs.push(buildConversationDetailsGadgetDef({
                  gadgetOrigin: this.get('gadgetTargetOrigin'),
                  displayName: this.get('intl').t('purecloud.acdInteraction.conversationSummary')
                }));
              }
            } else if (payload.get('isChat')) {
              // set app as interaction details
              appDefs.push(buildConversationDetailsGadgetDef({
                gadgetOrigin: this.get('gadgetTargetOrigin'),
                displayName: this.get('intl').t('purecloud.acdInteraction.details.details'),
                route: "interaction-details"
              }));
            }

            return RSVP.resolve(appDefs);
          }

        case 'workitem':
          {
            return RSVP.resolve([]);
          }

        default:
          {
            (0, _types.assertNever)(appContextType);
          }
      }
    }
  });

  var buildConversationDetailsGadgetDef = function buildConversationDetailsGadgetDef(_ref) {
    var gadgetOrigin = _ref.gadgetOrigin,
        displayName = _ref.displayName,
        _ref$route = _ref.route,
        route = _ref$route === void 0 ? '' : _ref$route;
    // Allow to use specific versions for the Conversation Details gadget via query parameters of Web Directory:
    //  - If conversationDetailsVersion is set to local, use localhost version of the gadget (which runs on port 3000 by default, but could be overridden using conversationDetailsPort)
    //  - If conversationDetailsVersion is set to something else than local, then it's considered as a time machine version.
    var urlParams = new URLSearchParams(window.location.search);
    var version = (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get('conversationDetailsVersion')) || '';
    var url = version === 'local' ? "http://localhost:".concat((0, _appProvider.validatePortNumber)(urlParams.get('conversationDetailsPort') || '', 3000), "/#/") : version ? "".concat(gadgetOrigin, "/conversation-details/").concat(version, "/#/") : "".concat(gadgetOrigin, "/conversation-details/#/");
    return {
      id: 'conversation-details',
      displayName: displayName,
      type: 'iframe',
      monochromicIcon: {
        type: 'sparkV3SvgSprite',
        classNames: ['user-directory']
      },
      startBehavior: 'lazy',
      sandbox: ['allow-scripts', 'allow-same-origin', 'allow-forms'],
      src: new URL("".concat(url).concat(route)),
      trustedDataPassing: true,
      lifecycleConfig: {
        bootstrap: true,
        stop: true
      }
    };
  };

  var boolExpr = (0, _booleanExpressions.and)((0, _booleanExpressions.ft)('conversationSummary3256'), (0, _booleanExpressions.ft)('PURE-3256_useConversationDetails'));
  _exports.boolExpr = boolExpr;
  var _default = ConversationDetailsInterapptionsProvider;
  _exports.default = _default;
});