define("web-directory/components/upload-picture/component", ["exports", "lodash", "web-directory/mixins/promise-loading", "ember-webrtc-components/mixins/webrtc-service/auto-save-devices", "fileapi", "web-directory/components/upload-picture/helpers"], function (_exports, _lodash, _promiseLoading, _autoSaveDevices, _fileapi, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var computed = Ember.computed,
      observer = Ember.observer;
  var webcamWidth = 640;
  var webcamHeight = 480;

  var _default = Ember.Component.extend(_promiseLoading.default, _autoSaveDevices.default, {
    classNames: ['upload-picture'],
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    application: Ember.inject.service(),
    webrtc: Ember.inject.service(),
    notification: Ember.inject.service(),
    fileUpload: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    logger: Ember.inject.service('stash-logger'),
    currentFile: null,
    currentFileInfo: null,
    currentCoords: null,
    currentXHR: null,
    currentImageId: null,
    selectedCamera: null,
    cameraPending: false,
    cameraError: false,
    startingMedia: false,
    imageUrl: null,
    imageUrlData: null,
    uploadingImageUrl: false,
    attrs: {
      confirm: function confirm() {},
      upload: null,
      autoTakePicture: false,
      entity: null,
      uploadType: null
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this.createDropZone);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.send('cancelCamera');

      if (this.get('dropzone')) {
        this.get('dropzone').destroy();
      }
    },
    actions: {
      uploadError: function uploadError() {
        if (this.attrs.upload) {
          this.attrs.upload(this.get('cameraError'));
        }
      },
      uploadImage: function uploadImage() {
        var _this = this;

        if (this.attrs.upload) {
          this.attrs.upload(this.get('currentFile'));
          return;
        }

        var file = this.get('currentFile');
        var promise;

        if (this.get('uploadingImageUrl')) {
          promise = Ember.RSVP.resolve(this.get('imageUrlData'));
        } else {
          promise = this.upload(file);
        }

        var coords = this.get('currentCoords');
        var left = coords.x + (coords.x2 - coords.x) / 2 - 21;
        var top = coords.y + (coords.y2 - coords.y) / 2 - 21;
        var entityType = Ember.get(this, 'attrs.entity.value.type');

        if (entityType === 'group') {
          // using the public api group image requests we don't need to worry
          // about linking an image.
          promise.then(function (result) {
            // set image Id
            _this.set('currentImageId', result.id);

            return;
          }).then(this.showPictures.bind(this)).catch(function (err) {
            _this.send('clearImage');

            var intl = _this.get('intl');

            var groupId = _this.get('entity').guid;

            var orgId = _this.get('entity').orgId;

            _this.get('logger').error("failed to upload image for group ".concat(groupId, ", of organization ").concat(orgId), {
              error: err
            });

            _this.get('notification').error(intl.t('notification.titles.profileSaveError').toString(), intl.t('notification.fieldSection.error').toString(), {
              forceBrowserNotification: true,
              tag: 'image-upload'
            });
          });
        } else {
          if (this.get('session.features.PLUG-74_user-image-upload')) {
            var userId = this.get('entity').id;
            promise.then(this.get('entity').reload()).then(this.showPictures.bind(this)).catch(function (err) {
              _this.send('clearImage');

              var intl = _this.get('intl');

              var orgId = _this.get('entity').orgId;

              _this.get('logger').error("failed to upload image for user ".concat(userId, ", of organization ").concat(orgId), {
                error: err
              });

              _this.get('notification').error(intl.t('notification.titles.profileSaveError').toString(), intl.t('notification.fieldSection.error').toString(), {
                forceBrowserNotification: true,
                tag: 'image-upload'
              });
            });
          } else {
            promise.then(this.linkImage.bind(this)).then(this.showPictures.bind(this)).catch(function (err) {
              var intl = _this.get('intl');

              var userId = _this.get('entity').guid;

              var orgId = _this.get('entity').orgId;

              _this.get('logger').error("failed to upload image for user ".concat(userId, ", of organization ").concat(orgId), {
                error: err
              });

              _this.get('notification').error(intl.t('notification.titles.profileSaveError').toString(), intl.t('notification.fieldSection.error').toString(), {
                forceBrowserNotification: true,
                tag: 'image-upload'
              });
            });
          }
        }

        this.addIndicatorForPromise(promise, "#".concat(this.get('elementId'), " .image"));
        this.$('.loading-indicator i').css({
          top: top,
          left: left
        });
      },
      uploadImageUrl: function uploadImageUrl() {
        var _this2 = this;

        var application = this.get('application');
        var imageUrl = this.get('imageUrl');

        if (!imageUrl) {
          return;
        }

        this.set('uploadingImageUrl', true);
        (0, _helpers.uploadImageUrl)(application, imageUrl).then(function (data) {
          _this2.set('imageUrlData', data);

          _this2.displayImage(data);

          _this2.setupFileFromUrl();
        }).catch(function (err) {
          var intl = _this2.get('intl');

          var groupId = _this2.get('entity').guid;

          var orgId = _this2.get('entity').orgId;

          _this2.get('logger').error("upload ImageUrl failed for group ".concat(groupId, " of organization ").concat(orgId), {
            error: err
          });

          _this2.get('notification').error(intl.t('notification.titles.profileSaveError').toString(), intl.t('notification.fieldSection.error').toString(), {
            forceBrowserNotification: true,
            tag: 'image-upload'
          });

          _this2.set('uploadingImageUrl', false);

          _this2.set('imageUrl', '');
        });
      },
      clearImage: function clearImage() {
        this.$('.image').empty();
        this.set('currentFile', null);
        this.set('currentFileInfo', null);
        this.set('currentFileCoords', null);
        this.set('currentImageId', null);
        this.set('uploadingImageUrl', false);
        Ember.run.scheduleOnce('afterRender', this, this.createDropZone);
      },
      startCamera: function startCamera() {
        var _this3 = this;

        if (this.get('startingMedia')) {
          return;
        }

        this.send('cancelCamera');
        this.set('startingMedia', true);
        this.set('cameraPending', true);
        var options = {
          requestAudio: false,
          requestVideo: true
        };

        if (this.get('selectedCamera')) {
          options.camera = this.get('selectedCamera');
        }

        return this.get('webrtc').createMedia(options).then(function (media) {
          _this3.set('cameraStream', media);

          var stream = media.localStreams[0];

          _this3.get('webrtc').attachMediaStream(stream, _this3.$('.camera-preview')[0], {
            muted: true,
            mirror: true
          });

          _this3.set('startingMedia', false);

          if (_this3.get('autoTakePicture')) {
            _this3.send('takePicture');
          }
        }).catch(function (err) {
          _this3.set('cameraError', _this3.get('intl').lookup("images.error.".concat(err.name)).toString());

          if (_this3.get('autoTakePicture')) {
            _this3.send('uploadError');
          }
        });
      },
      takePicture: function takePicture() {
        var _this4 = this;

        this.set('countdown', true);
        Ember.run.later(function () {
          var video = _this4.$('video')[0];

          _this4.set('countdown', false);

          var canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height); //convert to desired file format

          var dataUrl = canvas.toDataURL('image/jpeg');
          var asBlob = window.dataURLtoBlob(dataUrl);
          var pictureInfo = {
            width: webcamWidth,
            height: webcamHeight,
            type: 'image/jpeg'
          };
          var fileName = 'webcam';

          if (_this4.get('autoTakePicture')) {
            fileName = _this4.get('intl').lookup('chat.commands.selfie.command').toString();
          }

          var asFile = new File([asBlob], "".concat(fileName, ".jpeg"), pictureInfo);

          _this4.onTookPicture(dataUrl, asFile, pictureInfo);

          _this4.send('cancelCamera');
        }, 3000);
      },
      cancelCamera: function cancelCamera() {
        var cameraStream = this.get('cameraStream');

        if (cameraStream && cameraStream.localStreams) {
          try {
            cameraStream.localStreams.forEach(function (stream) {
              stream.getTracks().forEach(function (track) {
                return track.stop();
              });
            });
          } catch (e) {
            var groupId = this.get('entity').guid;
            var orgId = this.get('entity').orgId;
            this.get('logger').error("Error stopping camera stream for group ".concat(groupId, " of organization ").concat(orgId), {
              error: e
            });
          }
        }

        this.set('cameraPending', false);
        this.set('cameraStream', null);
      }
    },
    switchCamera: observer('selectedCamera', function () {
      if (this.get('cameraPending')) {
        this.send('startCamera');
        this.get('webrtc').updateDefaultDevices({
          camera: this.get('selectedCamera')
        });
      }
    }),
    canUseCamera: computed('webrtc.hasCamera', function () {
      return this.get('webrtc.hasCamera') && (window.navigator.getUserMedia || window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia);
    }),
    init: function init() {
      this._super.apply(this, arguments); // Calling enumerateDevices will conduct translation PCM-316


      this.get('webrtc').enumerateDevices();

      if (this.get('autoTakePicture')) {
        this.send('startCamera');
      }
    },
    getImageDataFromXhr: function getImageDataFromXhr(xhr) {
      if (xhr) {
        if (typeof xhr.responseText === 'string') {
          return JSON.parse(xhr.responseText);
        } else if (_typeof(xhr.responseText) === 'object') {
          return xhr.responseText;
        } else if (typeof xhr === 'string') {
          return JSON.parse(xhr);
        }
      }

      return xhr;
    },
    initCrop: function initCrop() {
      var aspectRatio = 1;
      var size = this.get('currentFileInfo.height') * this.get('currentFileInfo.ratio');
      var type = this.get('entity.type');
      var uploadType = this.get('uploadType');

      if (type === 'floor' || uploadType === 'informal') {
        aspectRatio = null;
      }

      if (this.get('currentFileInfo').width <= this.get('currentFileInfo').height) {
        size = this.get('currentFileInfo').width * this.get('currentFileInfo').ratio;
      }

      this.$('img').Jcrop({
        onSelect: this.handleCropChange.bind(this),
        onChange: this.handleCropChange.bind(this),
        aspectRatio: aspectRatio,
        // TODO: get this ratio from field config.
        setSelect: [0, 0, size, size]
      });
    },
    linkImage: function linkImage(xhr) {
      var image = this.getImageDataFromXhr(xhr);
      var crop = this.get('currentCoords');
      var info = this.get('currentFileInfo');
      var uploadType = this.get('uploadType');
      var promise = (0, _helpers.linkImage)(this.get('entity'), image, info, crop, uploadType);
      var coords = this.get('currentCoords');
      var left = coords.x + (coords.x2 - coords.x) / 2 - 21;
      var top = coords.y + (coords.y2 - coords.y) / 2 - 21;
      this.addIndicatorForPromise(promise, "#".concat(this.get('elementId'), " .image"));
      this.$('.loading-indicator i').css({
        top: top,
        left: left
      });
      return promise;
    },
    showPictures: function showPictures() {
      var _this5 = this;

      var entity = this.get('entity');

      if (!entity) {
        throw new Error('Missing entity for component');
      }

      if (entity.get('type') === 'group') {
        /*
           https://github.com/emberjs/data/issues/3704
           I hit what I believe is an ember-data 2.16 bug where the store was not being
           updated after a findRecord request (even with reload: true specified).
           As a result we make the request ourselves and update the store.
        */
        var store = this.get('store');
        var serializer = store.serializerFor('group');
        var model = store.modelFor('group');
        var adapter = store.adapterFor('group');
        var url = adapter.urlForFindRecord(entity.id);
        var fieldsObj = {
          fl: '*'
        };
        var data = Object.assign({}, data, fieldsObj);
        var traditional = true;
        this.get('ajax').request(url, {
          method: 'GET',
          data: data,
          traditional: traditional
        }).then(function (result) {
          var santizedResult = serializer.normalizeSingleResponse(store, model, result, entity.id, 'findRecord');
          entity.set('version', santizedResult.data.attributes.version);
          entity.set('images', santizedResult.data.attributes.images);

          _this5.attrs.confirm();
        });
      } else {
        entity.reload().then(function () {
          _this5.attrs.confirm();
        });
      }
    },
    handleCropChange: function handleCropChange(coords) {
      if (coords.x === coords.x2 && coords.y === coords.y2) {
        var ratio = this.get('currentFileInfo.ratio');
        coords = {
          x: 0,
          y: 0,
          x2: this.get('currentFileInfo.width') * ratio,
          y2: this.get('currentFileInfo.height') * ratio
        };
      }

      this.set('currentCoords', coords);
    },
    displayImage: function displayImage(xhr) {
      var data = this.getImageDataFromXhr(xhr);
      var container = this.$('div.image');
      var width = container.width();
      var ratio = width / data.dimensions.x;
      var height = data.dimensions.y * ratio;
      var image = $("<img src=\"".concat(data.url, "\" />")).css({
        width: width,
        height: height
      });
      container.html(image);
      var currentFileInfo = this.get('currentFileInfo');

      if (_lodash.default.isEmpty(currentFileInfo) || !currentFileInfo.ratio) {
        if (!width) {
          width = 100;
        }

        if (!height) {
          height = 100;
        }

        var fileInfo = {
          ratio: ratio,
          width: width,
          height: height
        };
        this.set('currentFileInfo', fileInfo);
      }

      return xhr;
    },
    setupFileFromUrl: function setupFileFromUrl() {
      var _this6 = this;

      var container = this.$('div.image');
      var $image = this.$('div.image img');
      var image = $image[0];

      var currentFile = _fileapi.default.Image(image);

      this.set('currentFile', currentFile);
      $image.on('load', function () {
        Ember.run(function () {
          var ratio = container.width() / image.naturalWidth;
          var width = container.width();
          var height = image.naturalHeight * ratio;

          _this6.set('currentFileInfo', {
            ratio: ratio,
            width: image.naturalWidth,
            height: image.naturalHeight
          });

          $image.width(width);
          $image.height(height);

          _this6.initCrop();
        });
      });
    },
    upload: function upload(file) {
      var _this7 = this;

      var entityType = this.attrs.entity.value.get('type');

      if (entityType === 'group') {
        var gId = this.attrs.entity.value.get('id');
        var uploadType = this.get('uploadType');
        var imageCategory = uploadType === 'informal' ? 'gallery' : 'profile';
        var coords = this.get('currentCoords');
        var imgData = {
          // the server side directory requires that the file size width/height be used
          // for x2/y2
          boundingBox: {
            x: coords.x,
            x2: file.name === 'webcam.jpeg' ? webcamWidth : file.width,
            // webcam files are always 640 * 480
            y: coords.y,
            y2: file.name === 'webcam.jpeg' ? webcamHeight : file.height
          },
          groupImageType: imageCategory
        };
        var formData = new window.FormData();
        formData.append('file', file);
        formData.append('groupImageRequest', new Blob([JSON.stringify(imgData)], {
          type: 'application/json'
        }));
        var uri = this.get('application').pcV2Uri("api/v2/groups/".concat(gId, "/images"));
        return this.get('fileUpload').upload(uri, formData);
      } else {
        if (this.get('session.features.PLUG-74_user-image-upload')) {
          var uId = this.attrs.entity.value.get('id');

          var _uploadType = this.get('uploadType');

          var _coords = this.get('currentCoords');

          var _imgData = {
            // the server side directory requires that the file size width/height be used
            // for x2/y2
            boundingBox: {
              x: _coords.x,
              x2: file.name === 'webcam.jpeg' ? webcamWidth : file.width,
              // webcam files are always 640 * 480
              y: _coords.y,
              y2: file.name === 'webcam.jpeg' ? webcamHeight : file.height
            },
            userImageType: _uploadType
          };

          var _formData = new window.FormData();

          _formData.append('file', file);

          _formData.append('userImageRequest', new Blob([JSON.stringify(_imgData)], {
            type: 'application/json'
          }));

          var _uri = this.get('application').pcV2Uri("api/v2/users/".concat(uId, "/images"));

          return this.get('fileUpload').upload(_uri, _formData);
        } else {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            _fileapi.default.upload({
              url: '/directory/api/v2/images',
              files: {
                file: file
              },
              headers: {
                Authorization: "bearer ".concat(_this7.get('session.accessToken'))
              },
              complete: function complete(err, xhr, file, options) {
                if (!err) {
                  resolve(xhr, file, options);
                } else {
                  _this7.send('clearImage');

                  reject(xhr);
                }
              }
            });
          });
        }
      }
    },
    onTookPicture: function onTookPicture(dataUri, file) {
      var _this8 = this;

      this.set('currentFile', file);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var container = _this8.$('div.image');

        var width = container.width();
        var image = $("<img src=\"".concat(dataUri, "\" />"));
        container.html(image);
        image.width(width);
        image.one('load', function () {
          var width = image.width();
          var ratio = width / image[0].naturalWidth;

          _this8.set('currentFileInfo', {
            width: width,
            height: image.height(),
            ratio: ratio
          });

          _this8.initCrop();
        });

        if (_this8.get('autoTakePicture')) {
          _this8.send('uploadImage');
        }
      });
    },
    onChange: function onChange(file) {
      var _this9 = this;

      var container = this.$('div.image');

      if (/^image/.test(file.type)) {
        _fileapi.default.getInfo(file, function (err, info) {
          _this9.handleFileInfo(err, info, file);
        });
      } else {
        if (file) {
          container.html(this.get('intl').t('images.genericFileTypeMessage').toString());
        }

        _fileapi.default.reset(event.currentTarget);
      }
    },
    handleFileInfo: function handleFileInfo(err, info, file) {
      var _this10 = this;

      var container = this.$('div.image');

      if (!err) {
        var image = _fileapi.default.Image(file);

        var ratio = container.width() / info.width;
        this.set('currentFile', file);
        this.set('currentFileInfo', info);
        this.set('currentFileInfo.ratio', ratio);
        var width = container.width();
        var height = info.height * ratio;
        image.preview(width, height).get(function (err, img) {
          if (width && height) {
            var uri = img.toDataURL();

            var _image = $("<img src=\"".concat(uri, "\" />"));

            container.html(_image);

            _this10.initCrop();
          }
        });
      } else {
        container.html(this.get('intl').t('images.genericFileTypeMessage').toString());
      }

      _fileapi.default.reset(event.currentTarget);
    },
    createDropZone: function createDropZone() {
      var _this11 = this;

      if (this.get('dropzone')) {
        this.get('dropzone').destroy();
      }

      var dropzone = new window.Dropzone("#".concat(this.get('elementId'), " .dropzone"), {
        acceptedFiles: 'image/*',
        url: '/file/post',
        uploadMultiple: false,
        maxFiles: 1,
        clickable: '.browse',
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxFilesize: 10,
        dictInvalidFileType: this.get('intl').t('images.genericFileTypeMessage').toString(),
        dictRemoveFile: this.get('intl').t('common.remove').toString(),
        dictFileTooBig: this.get('intl').t('images.fileTooLarge').toString(),
        dictCancelUpload: this.get('intl').t('images.cancelUpload').toString(),
        dictUploadCanceled: this.get('intl').t('images.uploadCanceled').toString()
      });
      dropzone.on('thumbnail', function (file) {
        Ember.run(function () {
          return _this11.onChange(file);
        });
      });
      dropzone.on('addedfile', function () {
        var _this12 = this;

        Ember.run(function () {
          if (_this12.files.length > 1) {
            _this12.removeFile(_this12.files[0]);
          }
        });
      });
      this.set('dropzone', dropzone);
    }
  });

  _exports.default = _default;
});