define("web-directory/serializers/person", ["exports", "lodash", "web-directory/serializers/purecloud", "web-directory/serializers/application", "web-directory/mixins/logger", "web-directory/components/entity-fields/validators"], function (_exports, _lodash, _purecloud, _application, _logger, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFields(record) {
    var config = record.fieldConfig;

    _lodash.default.forEach(config.sections, function (section) {
      _lodash.default.forEach(section.fieldList, function (field) {
        var type = field.get('type');

        if (_validators.default[type]) {
          var validator = _validators.default[type];
          var values = record.getFieldValue(field.getFieldPath());

          if (typeof validator.cleanFieldForEntity === 'function') {
            if (Array.isArray(values)) {
              _lodash.default.forEach(values, function (value) {
                validator.cleanFieldForEntity(record, field, value);
              });
            } else {
              validator.cleanFieldForEntity(record, field, values);
            }
          }
        }
      });
    });
  }

  function extractSectionData(record, json) {
    var section = record._currentSection;

    if (section) {
      var key = section.get('key');

      if (Object.prototype.hasOwnProperty.call(json, key)) {
        var data = {
          _id: json._id
        };
        data[key] = json[key];
        return data;
      }
    }

    return json;
  }

  var _default = _purecloud.default.extend(_logger.default, {
    session: Ember.inject.service(),
    phoneIntegrations: Ember.inject.service(),
    serialize: function serialize(snapshot, options) {
      var record = snapshot.record;
      cleanFields(record);

      var json = this._super(snapshot, options);

      json = extractSectionData(record, json);
      delete json._id;
      return (0, _application.filterMissingFields)(record, json);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, model, data, guid) {
      if (data) {
        data.id = guid || data.guid || data.id;
      }

      return this._super.apply(this, arguments);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload || !payload.entities) {
        return this._super.apply(this, arguments);
      }

      if (payload.meta && payload.meta.usingIntegrationSource && requestType === 'findMany') {
        if (!payload.meta.integrationSource) {
          this.logger.warn("We are using an integration source, but I'm not sure which integration source it is:", {
            meta: payload.meta
          });
          return this._super.apply(this, arguments);
        }

        var entities = (payload.entities || []).map(function (entity) {
          var data = Object.assign({}, entity); // note: we may not need to do this and instead just return 'data'

          if (data.expands && (data.expands.integrationPresence === undefined || data.expands.integrationPresence === null)) {
            // Default to empty
            data.expands.integrationPresence = {};
          }

          return data;
        });
        var data = Object.assign({}, payload, {
          entities: entities
        });
        return this._super(store, primaryModelClass, data, id, requestType);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});