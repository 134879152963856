define("web-directory/components/user-dropdown/elm-presence-selector/component", ["exports", "web-directory/elm-modules", "ember-purecloud-components/mixins/logger", "ember-purecloud-components/utils/dates"], function (_exports, _elmModules, _logger, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject,
      computed = Ember.computed;

  var _default = Component.extend(_logger.default, {
    classNames: ['status-menu'],
    Elm: _elmModules.default,
    lastErrorTime: 0,
    lastErrorMsg: '',
    elmService: inject.service('elm'),
    session: inject.service(),
    presence: inject.service(),
    presenceSubscription: inject.service(),
    intl: inject.service(),
    notification: inject.service(),
    clock: inject.service(),
    presenceChoices: computed('presence.primaryPresences.[]', 'presence.secondaryPresences', function () {
      var _this = this;

      return this.get('presence.primaryPresences').map(function (presence) {
        var result = presence.getProperties('id', 'key', 'label');

        var secondaryPresences = _this.sortSecondaryStatuses(presence.get('secondaryPresences'));

        if (secondaryPresences.length !== 0) {
          result.secondaryPresences = secondaryPresences.map(function (secondary) {
            return secondary.getProperties('id', 'label', 'key');
          });
        }

        return result;
      });
    }),
    flags: computed('elmService.appContext', 'presenceChoices.[]', function () {
      return {
        appContext: this.get('elmService.appContext'),
        presenceChoices: this.get('presenceChoices')
      };
    }),
    elmPorts: null,
    sortSecondaryStatuses: function sortSecondaryStatuses(secondaryPresences) {
      return secondaryPresences.sort(function (a, b) {
        var presenceA = a.get('label');
        var presenceB = b.get('label'); // Extract numeric parts and remaining parts from the strings

        var numPartA = parseInt(presenceA, 10);
        var numPartB = parseInt(presenceB, 10); // Check if both strings start with numbers

        if (!isNaN(numPartA) && !isNaN(numPartB)) {
          if (numPartA !== numPartB) {
            return numPartA - numPartB;
          } // If numeric parts are equal, compare the rest of the string


          return presenceA.slice(numPartA.toString().length).localeCompare(presenceB.slice(numPartB.toString().length));
        } // If not both strings start with numbers, compare them as usual strings


        return presenceA.localeCompare(presenceB);
      });
    },
    sendElmData: function sendElmData(portName, data) {
      var disableLog = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var sendPort = this.get("elmPorts.".concat(portName));

      if (sendPort) {
        if (!disableLog) {
          this.logger.debug(["Sending on ".concat(portName, " to Elm module"), data]);
        }

        sendPort.send(data);
      } else {
        this.logger.debug(["".concat(portName, " not ready to send"), data]);
      }
    },
    sendAppContext: function sendAppContext(data) {
      this.sendElmData('appContext', data);
    },
    sendPresenceChoices: function sendPresenceChoices(data) {
      this.sendElmData('presenceChoices', data);
    },
    sendCurrentTime: function sendCurrentTime() {
      // Due to server offset, we might end up with fractional milliseconds, so lets drop that
      this.sendElmData('currentTime', Math.floor(_dates.default.now()), true);
    },
    contextUpdater: Ember.observer('elmService.appContext', function () {
      Ember.run.once(this, 'sendAppContext', this.get('elmService.appContext'));
    }),
    choicesUpdater: Ember.observer('presenceChoices.[]', function () {
      Ember.run.once(this, 'sendPresenceChoices', this.get('presenceChoices'));
    }),
    onClockTick: function onClockTick() {
      Ember.run.once(this, 'sendCurrentTime');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('clock').on('clockTick', this, this.onClockTick);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('clock').off('clockTick', this, this.onClockTick);
    },
    actions: {
      setupPorts: function setupPorts(ports) {
        var _this2 = this;

        ports.actions.subscribe(function (message) {
          try {
            _this2.logger.debug('Elm sent:', message);

            if (message.action) {
              _this2.send(message.action, new Ember.Object(message.payload));
            }
          } catch (e) {
            // We need to catch even if the notification throws an error, to prevent the
            // Elm runtime from halting on the exception.
            try {
              var title = _this2.get('intl').t('chat.presence.actionError.title');

              var _message = _this2.get('intl').t('chat.presence.actionError.message');

              _this2.get('notification').error(title, _message);
            } catch (e2) {
              setTimeout(function () {
                throw e2;
              }, 0);
            }
          }
        });
        this.set('elmPorts', ports);
      },
      notifyError: function notifyError(errorInfo) {
        var nowTime = Date.now();
        var title = this.get('intl').t(errorInfo.title);
        var userMessage = this.get('intl').t(errorInfo.userMessage);
        var compositeMsg = "".concat(title, " ").concat(userMessage);

        if (this.get('lastError') != compositeMsg || nowTime - this.get('lastErrorTime') > 5000) {
          this.set('lastError', compositeMsg);
          this.set('lastErrorTime', nowTime);
          this.get('notification').error(title, "".concat(userMessage));
        }

        this.logger.error("".concat(errorInfo.logMessage));
      },
      changePresence: function changePresence(presence) {
        var _this3 = this;

        var currentId = this.get('session.person.primaryPresence.presenceDefinition.id');
        var currentModifiedDate = this.get('session.person.primaryPresence.modifiedDate'); // If we select on queue while we are on queue, don't do anything

        if (presence.get('presence') === 'on_queue') {
          var onQueuePresence = this.get('presence').getPresence('on_queue');
          var onQueuePresenceId = onQueuePresence ? onQueuePresence.get('presence.id') : null;

          if (onQueuePresenceId === currentId) {
            return;
          }
        }

        if (presence.get('presence.id') !== currentId) {
          // Do a local set of presence modified time for smooth UI transition
          this.set('session.person.primaryPresence.modifiedDate', new Date((0, _dates.default)().utc().valueOf()).toISOString());
        }

        this.get('presenceSubscription').changePresence(presence.get('presence')).catch(function () {
          _this3.set('session.person.primaryPresence.modifiedDate', currentModifiedDate);
        });
      },
      setOutOfOffice: function setOutOfOffice() {
        this.get('presenceSubscription').setOutOfOffice(null, null, false, true);
      }
    },
    init: function init() {
      this._super.apply(this, arguments); // reload to make sure we have latest activity in case of subscription error


      var person = this.get('session.person');

      if (person) {
        person.reload();
      }
    }
  });

  _exports.default = _default;
});