define("web-directory/components/acd-interactions-panel/interaction-session-share/interaction-co-browse-next/component", ["exports", "web-directory/models/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
      inject = Ember.inject;
  var BASE_APP_CONFIG = {
    sandbox: ['allow-scripts', 'allow-same-origin', 'allow-forms', 'allow-modals', 'allow-popups'],
    path: 'cobrowse-next/viewer.html',
    query: "lang=".concat(_app.GC_LANG_TAG_URL_REPLACEMENT_TOKEN, "&env=").concat(_app.PC_ENV_URL_REPLACEMENT_TOKEN, "&conversationId=").concat(_app.GC_CONVERSATION_ID_URL_REPLACEMENT_TOKEN, "&usePopupAuth=").concat(_app.GC_USE_POPUP_AUTH_URL_REPLACEMENT_TOKEN)
  };

  var _default = Component.extend({
    _intl: inject.service('intl'),
    _apps: inject.service('apps'),
    fullscreen: inject.service(),
    // Ember Comp Bits
    classNames: ['interaction-co-browse-next'],

    /**
     * @attribute
     * Interaction model passed in
     */
    interaction: null,

    /**
     * @attribute
     * Determines whether fullscreen button is shown or not
     */
    allowFullscreen: true,
    // Props
    appName: 'Co-Browse Viewer',
    cobrowseInterapption: null,
    isFullScreen: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.appName = this.get('_intl').t('purecloud.acdInteraction.cobrowseNext.viewerInterapptionName');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var interaction = this.get('interaction');
      this.set('cobrowseInterapption', this._buildCobrowseInterapption(interaction));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('fullscreen').endFullScreen();
    },
    _buildCobrowseInterapption: function _buildCobrowseInterapption(interaction) {
      if (!interaction || !interaction.get('id')) {
        return null;
      }

      var appCfg = Object.assign({
        name: this.appName,
        //"fullscreen 'none'" is explicitly required to disable fullscreen for nested iframes
        permissions: this.get('allowFullscreen') ? ['fullscreen'] : ["fullscreen 'none'"]
      }, BASE_APP_CONFIG);
      var cobrowseInterapption = this.get('_apps').buildValidInterapption('dynamic-app-provider-cobrowse-next', 'cobrowse-viewer', appCfg, true);
      cobrowseInterapption.set('interactionId', interaction.get('id'));
      cobrowseInterapption.set('interactionType', 'conversation');
      return cobrowseInterapption;
    },
    closeCobrowse: function closeCobrowse() {
      this.setProperties({
        'interaction.cobrowseSessionShareActive': false,
        'interaction.sessionShareActiveOnLoad': false,
        'interaction.isCobrowseV2Disconnected': true,
        'interaction.isCobrowseV2': false
      });
    },
    fullScreen: function fullScreen() {
      if (!this.isFullScreen) {
        this.get('fullscreen').startFullScreen();
        this.isFullScreen = !this.isFullScreen;
      } else {
        this.get('fullscreen').endFullScreen();
        this.isFullScreen = !this.isFullScreen;
      }
    },
    actions: {
      onAppApiCall: function onAppApiCall(apiCall) {
        switch (apiCall.action) {
          case 'closeScreenShare':
            this.closeCobrowse();
            break;

          case 'fullscreen':
            this.fullScreen();
            break;

          default:
            this.get('_apps').handleAppApiCall(apiCall);
            break;
        }
      }
    }
  });

  _exports.default = _default;
});