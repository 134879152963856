define("web-directory/adapters/person", ["exports", "ember-data", "web-directory/services/store", "web-directory/models/person"], function (_exports, _emberData, _store, _person) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var inject = Ember.inject,
      computed = Ember.computed;
  var RESTAdapter = _emberData.default.RESTAdapter;

  var _default = RESTAdapter.extend({
    ajaxService: inject.service('ajax'),
    session: inject.service(),
    application: inject.service(),
    integrationsPresenceSvc: inject.service('integrations-presence'),
    host: computed.reads('application.purecloudV2BaseUri'),
    namespace: 'api/v2',
    buildQuery: function buildQuery() {
      return {
        fl: '*',
        expand: _person.BASE_PERSON.expand
      };
    },
    baseUrl: function baseUrl(id) {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/users/").concat(id);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this.baseUrl(id), "/profile");
    },
    urlForFindRecord: function urlForFindRecord(id) {
      // the id provided should be the the user guid
      return "".concat(this.baseUrl(id), "/profile");
    },
    urlForFindMany: function urlForFindMany() {
      return "".concat(this.get('host'), "/").concat(this.get('namespace'), "/profiles/users");
    },
    // Need to overwrite the private function to load activities we need
    findRecord: function findRecord(store, type, id, options) {
      var url = this.buildURL(type.modelName, id, options, 'findRecord');
      var query = this.buildQuery(options);
      var usingIntegrationSource = options && options.adapterOptions && options.adapterOptions.integrationPresenceSource; // use the provided presence source

      if (usingIntegrationSource) {
        query.integrationPresenceSource = options.adapterOptions.integrationPresenceSource;
        query.expand = "".concat(query.expand, ",integrationPresence");
      }

      return this.ajax(url, 'GET', {
        data: query
      });
    },
    // Need to overwrite so that we can provide the version on all updates
    // and make sure data isnt key'd by type
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);

      if (data[type.modelName]) {
        data = data[type.modelName];
      }

      Ember.set(data, 'version', snapshot.attr('version'));
      return this.ajax(this.urlForUpdateRecord(snapshot.id, type.modelName), 'PUT', {
        data: data
      });
    },
    // allows looking up by jid or by guid, but not both
    findMany: function findMany(store, type, ids, snapshotsOrOptions) {
      var url = this.buildURL(type.modelName, ids, snapshotsOrOptions, 'findMany');
      var guids = ids.filter(function (id) {
        return id.indexOf('@') === -1;
      });
      var jids = ids.filter(function (id) {
        return id.indexOf('@') > -1;
      });

      if (jids.length > 0 && guids.length > 0) {
        return Ember.RSVP.reject(new Error('Mixed jid and guid person lookup'));
      }

      if (jids.length > 0) {
        return this._findMany(url, jids, 'jids', snapshotsOrOptions);
      }

      if (guids.length > 0) {
        return this._findMany(url, guids, 'guids', snapshotsOrOptions);
      }

      return Ember.RSVP.reject(new Error('findMany person without ids'));
    },
    findFavorites: function findFavorites() {
      var adapter = this.store.adapterFor('application');
      return adapter.findFavorites.apply(adapter, arguments);
    },
    findSuperiors: function findSuperiors(store, id, params) {
      var _this = this;

      var url = "".concat(this.baseUrl(id), "/superiors");
      return this.ajax(url, 'GET', {
        data: params
      }).then(function (superiors) {
        if (!superiors || !superiors.length) {
          return [];
        }

        return _this.findMany(store, 'person', superiors.mapBy('id'));
      });
    },
    findDirectReports: function findDirectReports(store, id, params) {
      var _this2 = this;

      var url = "".concat(this.baseUrl(id), "/directreports");
      return this.ajax(url, 'GET', {
        data: params
      }).then(function (directReports) {
        if (!directReports || !directReports.length) {
          return [];
        }

        return _this2.findMany(store, 'person', directReports.mapBy('id'));
      });
    },
    _findMany: function _findMany(url, ids, type, options) {
      var _this3 = this;

      var key = 'id';

      if (type === 'jids') {
        key = 'jid';
      }

      var baseRequestData = {
        fl: '*',
        expand: _person.BASE_PERSON.expand
      };
      var batchAmount = 25; // use the provided presence source

      var usingIntegrationSource = options && options.adapterOptions && options.adapterOptions.integrationPresenceSource; // use the provided presence source

      if (usingIntegrationSource) {
        baseRequestData.integrationPresenceSource = options.adapterOptions.integrationPresenceSource;
        baseRequestData.expand = "".concat(baseRequestData.expand, ",integrationPresence");
      }

      var promises = (0, _store.batchEntityIds)(ids, batchAmount).map(function (ids) {
        var requestData = Object.assign({}, baseRequestData, _defineProperty({}, key, ids.join(',')));

        if (_this3.get('session.features.enableUIAjaxRetryHeader')) {
          var promise = _this3.get('ajaxService').raw(url, {
            method: 'GET',
            contentType: 'application/json',
            data: requestData,
            dataType: 'json',
            retry: true
          });

          return promise.then(function (_ref) {
            var response = _ref.response;
            return Ember.RSVP.resolve(response);
          }).catch(function (_ref2) {
            var response = _ref2.response;
            return Ember.RSVP.reject(response);
          });
        } else {
          return _this3.ajax(url, 'GET', {
            data: requestData
          });
        }
      });
      return Ember.RSVP.all(promises).then(function (res) {
        var data = {
          entities: res.map(function (d) {
            return d.entities;
          }).flat()
        };

        if (usingIntegrationSource) {
          data.meta = {
            integrationSource: options && options.adapterOptions && options.adapterOptions.integrationPresenceSource,
            usingIntegrationSource: true
          };
        }

        return data;
      });
    },
    _loadActivities: function _loadActivities(id) {
      var url = "".concat(this.get('host'), "/").concat(this.get('namespace'), "/users/").concat(id);
      return this.ajax(url, 'GET', {
        data: {
          expand: _person.BASE_PERSON.expand
        }
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status < 400) {
        return this._super.apply(this, arguments);
      }

      payload.errors = [{
        status: status,
        title: payload.message,
        details: payload
      }];
      return this._super(status, headers, payload, requestData);
    }
  });

  _exports.default = _default;
});