define("web-directory/components/acd-interactions-panel/interaction-schedule-callback/component", ["exports", "web-directory/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Promise = Ember.RSVP.Promise;
  var DefaultMaxOwnedCallbackDelayHours = 2;
  var DefaultDebounce = 250;

  var _default = Ember.Component.extend({
    classNames: ['sub-panel', 'interaction-schedule-callback', 'tabpanel-scheduleCallback'],
    classNameBindings: ['enabled'],
    attributeBindings: ['role', 'tabindex'],
    role: 'tabpanel',
    tabindex: '-1',
    addressformat: Ember.inject.service('addressformat'),
    communication: Ember.inject.service('communication'),
    intl: Ember.inject.service('intl'),
    notification: Ember.inject.service('notification'),
    timezoneService: Ember.inject.service('timezones'),
    permissions: Ember.inject.service('permissions'),
    queue: Ember.inject.service('queue'),
    application: Ember.inject.service('application'),
    ajax: Ember.inject.service('ajax'),
    logger: Ember.inject.service('stash-logger'),

    /**
     * @attribute
     * `true` if the panel should be rendered in an enabled state. This controls whether or not the `enabled`
     * class will be applied to this component and what additional stylings will be applied for this enabled
     * state.
     *
     * @todo remove the enabled flag and just default to enabled and use conditional binding in the
     * implementation instead.
     */
    enabled: false,

    /**
     * @attribute
     */
    interaction: null,

    /**
     * @attribute
     */
    agentAddedCallbackNumbers: [],

    /**
     * @attribute
     */
    authUser: null,

    /**
     * @attribute
     */
    callbacksInheritRoutingFromInboundCallToggle: null,
    isSchedulingCallback: false,
    isAdminAgentOwnedCallbackAllowed: false,
    maxOwnedCallbackDelayHours: DefaultMaxOwnedCallbackDelayHours,
    isAdminCallbackQueueSelectionAllowed: false,
    callbacksInheritRoutingFromInboundCall: false,
    isCustomerFirstCallbackQueue: false,
    timeTooEarly: null,
    timeTooLate: null,
    timeTooLateMessage: null,
    queueOptions: null,
    timezoneOptions: null,
    timezones: [],
    phoneNumberOptions: null,

    /**
     * the queue id of the previous interaction. This determines if additional queue options should
     * be fetched upon receiving new attrs.
     */
    previousQueueId: null,

    /**
     * `true` if the phone number provided is invalid
     */
    invalidPhoneNumber: false,
    callbackScheduled: Ember.computed.alias('interaction.callbackScheduled'),
    callbackDate: Ember.computed.alias('interaction.callbackDate'),
    callbackTime: Ember.computed.alias('interaction.callbackTime'),
    callbackTimeZone: Ember.computed.alias('interaction.callbackTimeZone'),
    callbackQueue: Ember.computed.alias('interaction.callbackQueue'),
    callbackPhone: Ember.computed.alias('interaction.callbackPhone'),
    preferMyself: Ember.computed.alias('interaction.preferMyself'),
    agentOwned: Ember.computed.alias('interaction.agentOwned'),
    canViewChat: Ember.computed.readOnly('permissions.canViewBusinessChat'),
    scheduleCallbackDisabled: Ember.computed.or('callbackScheduled', 'timeTooEarly', 'timeTooLate'),
    preferMyselfDisabled: Ember.computed.or('callbackScheduled', 'agentOwned', 'isCustomerFirstQueueSelected'),
    preferMyselfInvalid: Ember.computed.and('preferMyself', 'preferMyselfDisabled'),
    agentOwnedDisabled: Ember.computed.or('callbackScheduled', 'preferMyself', 'callbackQueue', 'isCustomerFirstQueueSelected'),
    dialedNumber: Ember.computed.readOnly('interaction.participantForCustomer.calls.firstObject.self.addressNormalized'),
    isCustomerFirstQueueSelected: Ember.computed('callbackQueue', 'callbackQueue.callbackMode', 'isCustomerFirstCallbackQueue', function () {
      return this.get('callbackQueue.callbackMode') === 'CustomerFirst' || !this.get('callbackQueue') && this.get('isCustomerFirstCallbackQueue');
    }),
    preferMyselfTooltip: Ember.computed('callbackQueue.callbackMode', function () {
      if (this.get('isCustomerFirstQueueSelected')) {
        return this.get('intl').lookup('purecloud.acdInteraction.scheduleCallback.preferMyselfDisabledForCFC');
      }

      return null;
    }),
    availableNumbers: Ember.computed('interaction', 'interaction.participantForCurrentUser.callbacks.firstObject.callbackNumbers', 'dialedNumber', function () {
      var _Ember$get,
          _this = this;

      var interaction = this.get('interaction');
      if (!interaction) return [];
      var participantNumbers = (_Ember$get = Ember.get(interaction, 'participantForCurrentUser.callbacks.firstObject.callbackNumbers')) !== null && _Ember$get !== void 0 ? _Ember$get : [];
      var dialedNumber = this.get('dialedNumber');
      if (dialedNumber) participantNumbers.push(dialedNumber);
      var callbackNumbers = participantNumbers.reduce(function (value, curr) {
        var formatted = _this.makePhoneNumberOption(curr);

        if (!value.has(formatted.id)) {
          value.set(formatted.id, formatted);
        }

        return value;
      }, new Map());
      return _toConsumableArray(callbackNumbers.values());
    }),
    updateValidationProperties: Ember.observer('callbackDate', 'callbackTime', 'agentOwned', 'preferMyselfInvalid', 'callbackQueue', function () {
      if (this.get('preferMyselfInvalid')) {
        this.set('preferMyself', false);
      }

      var callbackDateTime = this.getCallbackDateTime();

      if (callbackDateTime) {
        var timeTooLateDays = 30;
        var currMoment = (0, _dates.default)();
        var timeTooEarly = (0, _dates.default)(callbackDateTime).isBefore(currMoment);
        this.get('logger').info({
          CallbackDateTime: callbackDateTime,
          CurrentDateTime: currMoment,
          timeTooEarly: timeTooEarly
        });
        var timeTooLate = (0, _dates.default)(callbackDateTime).isAfter((0, _dates.default)().add(timeTooLateDays * 24, 'hours'));
        var timeTooLateMessage = this.get('intl').t('purecloud.acdInteraction.scheduleCallback.timeTooLate', {
          timeTooLateDays: timeTooLateDays
        });

        if (this.get('agentOwned')) {
          timeTooLate = (0, _dates.default)(callbackDateTime).isAfter((0, _dates.default)().add(this.get('maxOwnedCallbackDelayHours'), 'hours'));
          timeTooLateDays = parseFloat((this.get('maxOwnedCallbackDelayHours') / 24).toFixed(2));
          timeTooLateMessage = this.get('intl').t('purecloud.acdInteraction.scheduleCallback.timeTooLate', {
            timeTooLateDays: timeTooLateDays
          });
        }

        if (this.get('callbackQueue')) {
          this.set('agentOwned', false);
        }

        this.setProperties({
          timeTooEarly: timeTooEarly,
          timeTooLate: timeTooLate,
          timeTooLateMessage: timeTooLateMessage
        });
      }
    }),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this._setupQueueOptions();

      this._fetchConversationSettings();

      this.get('timezoneService').getTimeZones().then(function (timezones) {
        var _timezones$map;

        if (_this2.get('isDestroyed') || _this2.get('isDestroying')) return;

        _this2.set('timezones', (_timezones$map = timezones === null || timezones === void 0 ? void 0 : timezones.map(function (t) {
          return {
            id: t.id,
            text: t.localNameWithFormattedOffset
          };
        })) !== null && _timezones$map !== void 0 ? _timezones$map : []);

        _this2._setupTimezoneOptions();
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var interaction = this.get('interaction');

      if (interaction) {
        this._setInteractionDefaults();

        this._setupPhoneNumberOptions();

        var queueId = Ember.get(interaction, 'participantForCurrentUser.queueId');

        if (this.get('previousQueueId') !== queueId) {
          this.set('previousQueueId', queueId);
          this.get('queue').getQueue(queueId).then(function (queue) {
            var _queue$agentOwnedRout, _queue$agentOwnedRout2, _queue$agentOwnedRout3, _queue$agentOwnedRout4, _queue$mediaSettings, _queue$mediaSettings$;

            if (_this3.get('isDestroyed') || _this3.get('isDestroying')) return;

            _this3.set('isAdminAgentOwnedCallbackAllowed', (_queue$agentOwnedRout = queue === null || queue === void 0 ? void 0 : (_queue$agentOwnedRout2 = queue.agentOwnedRouting) === null || _queue$agentOwnedRout2 === void 0 ? void 0 : _queue$agentOwnedRout2.enableAgentOwnedCallbacks) !== null && _queue$agentOwnedRout !== void 0 ? _queue$agentOwnedRout : false);

            _this3.set('maxOwnedCallbackDelayHours', (_queue$agentOwnedRout3 = queue === null || queue === void 0 ? void 0 : (_queue$agentOwnedRout4 = queue.agentOwnedRouting) === null || _queue$agentOwnedRout4 === void 0 ? void 0 : _queue$agentOwnedRout4.maxOwnedCallbackDelayHours) !== null && _queue$agentOwnedRout3 !== void 0 ? _queue$agentOwnedRout3 : DefaultMaxOwnedCallbackDelayHours);

            _this3.set('isCustomerFirstCallbackQueue', (queue === null || queue === void 0 ? void 0 : (_queue$mediaSettings = queue.mediaSettings) === null || _queue$mediaSettings === void 0 ? void 0 : (_queue$mediaSettings$ = _queue$mediaSettings.callback) === null || _queue$mediaSettings$ === void 0 ? void 0 : _queue$mediaSettings$.mode) === 'CustomerFirst');
          }).catch(function () {
            // if an error we default back to initial values
            _this3.set('isAdminAgentOwnedCallbackAllowed', false);

            _this3.set('maxOwnedCallbackDelayHours', DefaultMaxOwnedCallbackDelayHours);

            _this3.set('isCustomerFirstCallbackQueue', false);
          });
        }
      }
    },
    makePhoneNumberOption: function makePhoneNumberOption(number) {
      if (!this.get('addressformat').isValidNumber(number, null, true) && this.get('addressformat').isValidNumber('+' + number, null, true)) {
        number = "+".concat(number);
      }

      var id = this.get('addressformat').formatAddress(number);
      return {
        id: id,
        text: this.get('addressformat').formatAddressForDisplay(id)
      };
    },
    getCallbackDateTime: function getCallbackDateTime() {
      var _this$get$id, _this$get;

      var callbackDate = this.get('callbackDate');
      var callbackTime = (0, _dates.default)(this.get('callbackTime')).local();
      if (!callbackDate || !callbackTime) return;
      var callbackDateTime = (0, _dates.default)();
      var callbackTimezoneId = (_this$get$id = (_this$get = this.get('callbackTimeZone')) === null || _this$get === void 0 ? void 0 : _this$get.id) !== null && _this$get$id !== void 0 ? _this$get$id : null;
      callbackDateTime.tz(callbackTimezoneId);
      callbackDateTime.set('year', callbackDate.year);
      callbackDateTime.set('month', callbackDate.month - 1);
      callbackDateTime.set('date', callbackDate.day);
      callbackDateTime.set('hour', callbackTime.hour());
      callbackDateTime.set('minute', callbackTime.minutes());
      callbackDateTime.set('second', callbackTime.seconds());
      return callbackDateTime.toISOString();
    },
    _getInboundRoutingData: function _getInboundRoutingData(initialData) {
      var _initialData$routingD;

      var result = {};
      var interaction = this.get('interaction');
      var conversationRoutingData;
      var participants = (interaction === null || interaction === void 0 ? void 0 : interaction.get('participantsForACD')) || [];
      var sortedParticipants = participants.sort(function (a, b) {
        return ((b === null || b === void 0 ? void 0 : b.connectedTime) || '').localeCompare((a === null || a === void 0 ? void 0 : a.connectedTime) || '');
      });
      var skillIds = new Set();
      var scoredAgents = {};

      if (initialData !== null && initialData !== void 0 && (_initialData$routingD = initialData.routingData) !== null && _initialData$routingD !== void 0 && _initialData$routingD.scoredAgents) {
        var _iterator = _createForOfIteratorHelper(initialData.routingData.scoredAgents),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;

            if (!scoredAgents[item.agent.id]) {
              scoredAgents[item.agent.id] = item;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      var _iterator2 = _createForOfIteratorHelper(sortedParticipants),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _participant$conversa, _participant$conversa2, _participant$conversa3, _participant$conversa4;

          var participant = _step2.value;

          if (participant !== null && participant !== void 0 && (_participant$conversa = participant.conversationRoutingData) !== null && _participant$conversa !== void 0 && (_participant$conversa2 = _participant$conversa.skills) !== null && _participant$conversa2 !== void 0 && _participant$conversa2.length || participant !== null && participant !== void 0 && (_participant$conversa3 = participant.conversationRoutingData) !== null && _participant$conversa3 !== void 0 && (_participant$conversa4 = _participant$conversa3.language) !== null && _participant$conversa4 !== void 0 && _participant$conversa4.id) {
            var _conversationRoutingD;

            conversationRoutingData = participant.conversationRoutingData;

            if (!conversationRoutingData) {
              continue;
            }

            if (conversationRoutingData.skills) {
              var _iterator3 = _createForOfIteratorHelper(conversationRoutingData.skills.map(function (s) {
                return s.id;
              })),
                  _step3;

              try {
                for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                  var skillId = _step3.value;
                  skillIds.add(skillId);
                }
              } catch (err) {
                _iterator3.e(err);
              } finally {
                _iterator3.f();
              }
            }

            if (conversationRoutingData.language && !result.languageId) {
              result.languageId = conversationRoutingData.language.id;
            }

            if (conversationRoutingData.priority !== undefined && result.priority === undefined) {
              result.priority = conversationRoutingData.priority;
            }

            var _iterator4 = _createForOfIteratorHelper((_conversationRoutingD = conversationRoutingData.scoredAgent) !== null && _conversationRoutingD !== void 0 ? _conversationRoutingD : []),
                _step4;

            try {
              for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                var _item = _step4.value;

                if (!scoredAgents[_item.agent.id]) {
                  scoredAgents[_item.agent.id] = _item;
                }
              }
            } catch (err) {
              _iterator4.e(err);
            } finally {
              _iterator4.f();
            }
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      if (Object.keys(scoredAgents).length > 0) {
        result.scoredAgents = Object.values(scoredAgents);
      }

      result.skillIds = Array.from(skillIds);
      return result;
    },
    getScheduleCallbackData: function getScheduleCallbackData() {
      var _Ember$get2;

      var interaction = this.get('interaction');
      var callbackPhone = this.get('callbackPhone');
      if (!interaction || !callbackPhone) return null;
      var voicemailId = undefined;

      if (Ember.get(interaction, 'hasVoicemail')) {
        voicemailId = Ember.get(interaction, 'participantForCurrentUser.callbacks.firstObject.voicemail.id');
      }

      var callbackQueue = this.get('callbackQueue');
      var result = {
        voicemailId: voicemailId,
        callbackNumbers: [Ember.get(callbackPhone, 'id')],
        callbackScheduledTime: this.getCallbackDateTime(),
        routingData: {
          routingFlags: this.get('agentOwned') ? ['AGENT_OWNED_CALLBACK'] : undefined,
          preferredAgentIds: this.get('preferMyself') ? [Ember.get(interaction, 'participantForCurrentUser.userId')] : [],
          scoredAgents: this.get('agentOwned') ? [{
            agent: {
              id: Ember.get(interaction, 'participantForCurrentUser.userId')
            },
            score: 100
          }] : [],
          queueId: callbackQueue ? (_Ember$get2 = Ember.get(callbackQueue, 'id')) !== null && _Ember$get2 !== void 0 ? _Ember$get2 : null : null
        }
      };

      if (this.get('callbacksInheritRoutingFromInboundCallToggle') && this.get('callbacksInheritRoutingFromInboundCall')) {
        var _result$routingData, _result$routingData$p, _result$routingData2, _result$routingData2$;

        var routingData = this._getInboundRoutingData(result);

        if (result !== null && result !== void 0 && (_result$routingData = result.routingData) !== null && _result$routingData !== void 0 && (_result$routingData$p = _result$routingData.preferredAgentIds) !== null && _result$routingData$p !== void 0 && _result$routingData$p.length) {
          delete routingData.preferredAgentIds;
        }

        if (result !== null && result !== void 0 && (_result$routingData2 = result.routingData) !== null && _result$routingData2 !== void 0 && (_result$routingData2$ = _result$routingData2.scoredAgents) !== null && _result$routingData2$ !== void 0 && _result$routingData2$.length) {
          delete routingData.scoredAgents;
        }

        if (routingData) {
          Object.assign(result.routingData, routingData);
        }
      }

      return result;
    },
    _setInteractionDefaults: function _setInteractionDefaults() {
      var _this$get2, _this$get3;

      var callbackDateTime = (0, _dates.default)().add(30, 'minutes');
      this.set('callbackTime', (_this$get2 = this.get('callbackTime')) !== null && _this$get2 !== void 0 ? _this$get2 : (0, _dates.default)(callbackDateTime));
      this.set('callbackDate', (_this$get3 = this.get('callbackDate')) !== null && _this$get3 !== void 0 ? _this$get3 : {
        day: callbackDateTime.date(),
        month: callbackDateTime.month() + 1,
        year: callbackDateTime.year()
      });
      var callbackPhone = this.get('callbackPhone');

      if (!callbackPhone) {
        var _this$get4, _availableNumbers$;

        var availableNumbers = (_this$get4 = this.get('availableNumbers')) !== null && _this$get4 !== void 0 ? _this$get4 : [];
        callbackPhone = this.get('dialedNumber') ? this.makePhoneNumberOption(this.get('dialedNumber')) : (_availableNumbers$ = availableNumbers[0]) !== null && _availableNumbers$ !== void 0 ? _availableNumbers$ : null;
      }

      this.set('callbackPhone', callbackPhone);

      this._determineInvalidPhoneNumber();
    },
    _fetchConversationSettings: function _fetchConversationSettings() {
      var _this4 = this;

      this.get('ajax').request(this.get('application').pcV2Uri("platform/api/v2/conversations/settings")).then(function (response) {
        var _response$allowCallba, _response$callbacksIn;

        if (_this4.get('isDestroyed') || _this4.get('isDestroying')) {
          return;
        }

        _this4.set('isAdminCallbackQueueSelectionAllowed', (_response$allowCallba = response === null || response === void 0 ? void 0 : response.allowCallbackQueueSelection) !== null && _response$allowCallba !== void 0 ? _response$allowCallba : false);

        _this4.set('callbacksInheritRoutingFromInboundCall', (_response$callbacksIn = response === null || response === void 0 ? void 0 : response.callbacksInheritRoutingFromInboundCall) !== null && _response$callbacksIn !== void 0 ? _response$callbacksIn : false);
      }).catch(function () {
        return _this4.set('isAdminCallbackQueueSelectionAllowed', false);
      });
    },
    _setupQueueOptions: function _setupQueueOptions() {
      var _this5 = this;

      this.set('queueOptions', {
        allowClear: true,
        placeholder: this.get('intl').lookup('purecloud.acdInteraction.scheduleCallback.optionalQueue'),
        search: function search(_search) {
          return new Promise(function (resolve) {
            return Ember.run.debounce(_this5, _this5._fetchQueues, _search, resolve, DefaultDebounce);
          }).then(function (rawQueues) {
            var _rawQueues$entities$m;

            return (_rawQueues$entities$m = rawQueues === null || rawQueues === void 0 ? void 0 : rawQueues.entities.map(function (_ref) {
              var _mediaSettings$callba;

              var id = _ref.id,
                  name = _ref.name,
                  mediaSettings = _ref.mediaSettings;
              return {
                id: id,
                text: name,
                callbackMode: mediaSettings === null || mediaSettings === void 0 ? void 0 : (_mediaSettings$callba = mediaSettings.callback) === null || _mediaSettings$callba === void 0 ? void 0 : _mediaSettings$callba.mode
              };
            })) !== null && _rawQueues$entities$m !== void 0 ? _rawQueues$entities$m : [];
          });
        }
      });
    },
    _fetchQueues: function _fetchQueues(search, resolve) {
      return this.get('queue').getQueues({
        name: search !== null && search !== void 0 ? search : undefined
      }).then(function (queues) {
        return resolve(queues);
      });
    },
    _setupTimezoneOptions: function _setupTimezoneOptions() {
      var _this6 = this;

      this.set('timezoneOptions', {
        allowClear: true,
        placeholder: this.get('intl').lookup('purecloud.acdInteraction.scheduleCallback.optionalTimeZone'),
        search: function search(_search2) {
          return new Promise(function (resolve) {
            return _this6._fetchTimezones(_search2, resolve);
          });
        }
      });
    },
    _fetchTimezones: function _fetchTimezones(search, resolve) {
      if (!search) resolve(this.get('timezones'));
      return resolve(this.get('timezones').filter(function (t) {
        return t.text.toLowerCase().includes(search === null || search === void 0 ? void 0 : search.toLowerCase().trim());
      }));
    },
    _setupPhoneNumberOptions: function _setupPhoneNumberOptions() {
      var _this7 = this;

      this.set('phoneNumberOptions', {
        noCache: true,
        placeholder: this.get('intl').lookup('purecloud.acdInteraction.scheduleCallback.requiredPhoneNumber'),
        createSearchChoice: function createSearchChoice(search) {
          var option = _this7.makePhoneNumberOption(search);

          _this7._determineInvalidPhoneNumber(option);

          return option;
        },
        search: function search(_search3) {
          return new Promise(function (resolve) {
            return _this7._fetchPhoneNumbers(_search3, resolve);
          });
        }
      });
    },
    _fetchPhoneNumbers: function _fetchPhoneNumbers(search, resolve) {
      var _this$get5,
          _this$get6,
          _this8 = this;

      var options = [];
      if ((_this$get5 = this.get('availableNumbers')) !== null && _this$get5 !== void 0 && _this$get5.length) options.push.apply(options, _toConsumableArray(this.get('availableNumbers')));

      if ((_this$get6 = this.get('agentAddedCallbackNumbers')) !== null && _this$get6 !== void 0 && _this$get6.length) {
        options.push.apply(options, _toConsumableArray(this.get('agentAddedCallbackNumbers').map(function (_ref2) {
          var number = _ref2.number;
          return _this8.makePhoneNumberOption(number);
        })));
      }

      if (!search) return resolve(options);
      return resolve(options.filter(function (_ref3) {
        var text = _ref3.text;
        return text.toLowerCase().includes(search.toLowerCase().trim());
      }));
    },
    _scheduleCallback: function _scheduleCallback() {
      var _this9 = this;

      var interaction = this.get('interaction');

      if (interaction && this.get('callbackPhone') && this.getCallbackDateTime()) {
        var data = this.getScheduleCallbackData();
        if (!data) return;
        this.set('isSchedulingCallback', true);
        this.get('communication').scheduleCallback(interaction, data).then(function () {
          return _this9.set('callbackScheduled', true);
        }).catch(function (err) {
          var _err$payload$message, _err$payload;

          var errMsg = (_err$payload$message = err === null || err === void 0 ? void 0 : (_err$payload = err.payload) === null || _err$payload === void 0 ? void 0 : _err$payload.message) !== null && _err$payload$message !== void 0 ? _err$payload$message : undefined;
          var key = errMsg && errMsg.includes('already scheduled') ? 'purecloud.acdInteraction.scheduleCallback.errors.alreadyScheduled' : 'purecloud.acdInteraction.scheduleCallback.errorMessage';

          var msg = _this9.get('intl').lookup(key);

          _this9.get('notification').error(null, msg);
        }).finally(function () {
          return _this9.set('isSchedulingCallback', false);
        });
      }
    },

    /**
     * Determines the value of `invalidPhoneNumber`.
     *
     * @note
     * Will default back to false since the submission logic determines that if there is no callbackPhone provided
     * the callback will not be scheduled. We do not want to show an error to the user about an invalid phone number
     * before they had the change to make a selection
     *
     * @param value optional argument to provide a value to determine the validity with. Mainly used for making new options.
     */
    _determineInvalidPhoneNumber: function _determineInvalidPhoneNumber(value) {
      var callbackPhone = value !== null && value !== void 0 ? value : this.get('callbackPhone');
      this.set('invalidPhoneNumber', callbackPhone ? !this.get('addressformat').isValidNumber(callbackPhone.id, null, true) : false);
    },
    actions: {
      onScheduleCallback: function onScheduleCallback() {
        this._scheduleCallback();
      },
      onTogglePreferMyself: function onTogglePreferMyself() {
        if (this.get('preferMyselfDisabled')) {
          return;
        }

        if (!this.get('callbackScheduled') && !this.get('agentOwned') && !this.get('callbackQueue')) {
          this.set('preferMyself', !this.get('preferMyself'));
        }
      },
      onToggleAgentOwned: function onToggleAgentOwned() {
        if (this.get('agentOwnedDisabled')) {
          return;
        }

        if (!this.get('callbackScheduled') && !this.get('preferMyself') && !this.get('callbackQueue')) {
          this.set('agentOwned', !this.get('agentOwned'));
        }
      },
      onPhoneNumberChange: function onPhoneNumberChange() {
        this._determineInvalidPhoneNumber();
      }
    }
  });

  _exports.default = _default;
});